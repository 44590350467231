import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CreditCardFlagOptionsEnum from '../../../commons/enums/creditCardFlagOptions';
import NumberUtils from '../../../commons/utils/numberUtils';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import LabelStatus from '../../../components/labels/LabelStatus';
import SuperUserService from '../../../services/SuperUserService';
import { TIME_TO_REVENUE_IN_DAYS_OPTIONS_TO_LABEL } from './timeToRevenueInDaysOptions';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

// component
function CreditCardRateSettingsModal({ modalVisible, setModalVisible, selectedCompany }) {
  const [dataSource, setDataSource] = useState([]);
  const [selectedFlagCardValueFilter, setSelectedFlagCardValueFilter] = useState(
    CreditCardFlagOptionsEnum.ANY,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      SuperUserService.getAllCreditCardRateByCompanyId({ companyId: selectedCompany.company.id })
        .then((dataResp) => {
          setDataSource(dataResp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, selectedCompany]);

  const filteredDataSource = useMemo(() => {
    if (!selectedFlagCardValueFilter) {
      return dataSource;
    }
    return dataSource.filter((pCreditCardRate) => pCreditCardRate.cardFlag === selectedFlagCardValueFilter);
  }, [dataSource, selectedFlagCardValueFilter]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const creditCardFlagOption = CreditCardFlagOptionsEnum.getById(selectedFlagCardValueFilter);
  const isCreditCardFlagAnySelected = selectedFlagCardValueFilter === CreditCardFlagOptionsEnum.ANY;

  return (
    <>
      <Dialog
        onClose={() => {
          setModalVisible(false);
        }}
        open={modalVisible}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h5" component="span">
            Taxas de Cartão de Crédito (%)
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <div style={{ marginTop: '5px' }}>
            {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
          </div>

          <ToggleButtonGroupStyled
            size="large"
            value={selectedFlagCardValueFilter}
            exclusive
            onChange={(e, newValue) => {
              if (newValue != null) {
                setSelectedFlagCardValueFilter(newValue);
              }
            }}
          >
            {CreditCardFlagOptionsEnum.getValues().map((optionFlagCard) => {
              const isCurrentSelected = optionFlagCard.id === selectedFlagCardValueFilter;
              return (
                <ToggleButtonStyled key={optionFlagCard.id} value={optionFlagCard.id} color="primary">
                  <span>
                    {optionFlagCard.name}{' '}
                    {optionFlagCard.id === CreditCardFlagOptionsEnum.ANY && (
                      <>
                        <FontAwesomeIconStatus
                          icon="asterisk"
                          status={isCurrentSelected ? null : 'primary'}
                          color={isCurrentSelected ? 'white' : null}
                          style={{ marginLeft: 5 }}
                        />
                      </>
                    )}
                  </span>
                </ToggleButtonStyled>
              );
            })}
          </ToggleButtonGroupStyled>
          <br />

          {isCreditCardFlagAnySelected && (
            <>
              <LabelStatus status="info" component="span">
                * Será utilizada as taxas configuradas aqui caso o usuário não especifique a bandeira do
                cartão quando registrar o pagamento.
              </LabelStatus>
            </>
          )}
          {selectedFlagCardValueFilter !== CreditCardFlagOptionsEnum.ANY && filteredDataSource.length === 0 && (
            <>
              <LabelStatus status="info" variant="body1">
                <LabelStatus status="info" component="span">
                  *{' '}
                </LabelStatus>{' '}
                Como não há taxa específica configurada para {creditCardFlagOption.name}, o sistema irá
                utilizar as taxas de <b>&apos;Qualquer&apos;</b>.
              </LabelStatus>
            </>
          )}

          {!isLoading && dataSource.length === 0 ? (
            <>
              <Alert severity="info">
                <Typography>Dica: </Typography>
                Aqui você pode configurar as taxas de cartão de crédito da sua maquininha para ter o registro
                de despesas e você saber quanto você &apos;perde&apos; com taxas (sua margem líquida).
              </Alert>
            </>
          ) : (
            <>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>Parcelas</TableCell>
                    <TableCell align="center">Bandeira</TableCell>
                    <TableCell align="right">Taxa (%)</TableCell>
                    <TableCell align="center">Recebe em:</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredDataSource.map((row, idx) => {
                    const { numberOfInstallment, timeToRevenueInDays, taxPercentage, cardFlag } = row;
                    const installmentsLabel =
                      numberOfInstallment === 1 ? 'À Vista' : `${numberOfInstallment}x`;

                    return (
                      <TableRow key={idx}>
                        <TableCell>{installmentsLabel}</TableCell>
                        <TableCell align="center">
                          {CreditCardFlagOptionsEnum.getById(cardFlag).name}
                        </TableCell>
                        <TableCell align="right">{NumberUtils.toDecimals(taxPercentage, 2)}</TableCell>
                        <TableCell align="center">
                          {TIME_TO_REVENUE_IN_DAYS_OPTIONS_TO_LABEL[timeToRevenueInDays]?.name ?? '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreditCardRateSettingsModal;
