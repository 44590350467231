import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { styled } from '@mui/material/styles';
import showNotification from '../../commons/helpers/showNotification';
import theme from '../../theme';

const FontAwesomeIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  cursor: 'pointer',
}));

// COMPONENT
function ButtonCopyToClipboard({ copyValue, className, style, icon, color }) {
  return (
    <CopyToClipboard
      text={copyValue}
      onCopy={() => {
        showNotification('success', 'Copiado!!!');
      }}
    >
      <FontAwesomeIconStyled
        icon={icon}
        size="lg"
        fixedWidth
        className={className}
        style={style}
        color={color ?? theme.palette.primary.main}
      />
    </CopyToClipboard>
  );
}

ButtonCopyToClipboard.defaultProps = {
  icon: 'copy',
};
export default ButtonCopyToClipboard;
