import React from 'react';

import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';
import yup from '../../commons/validators/customYup';
import { Form, Formik } from 'formik';
import { FormTextField } from '../../components/forms/FormikFields';
import showNotification from '../../commons/helpers/showNotification';
import FeatureService from '../../services/FeatureService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  companyEmail: yup.string().email().required(),
  comments: yup.string().max(255),
});

const DEFAULT_INITIAL_VALUES = {
  companyEmail: '',
  comments: '',
};

// COMPONENT
function FeatureAddCompanyEmailModal({ featureForm, open, onClose, onAddCompanyEmail }) {
  const [myInitialValues, setMyInitialValues] = React.useState(DEFAULT_INITIAL_VALUES);

  React.useEffect(() => {
    if (open) {
      setMyInitialValues({
        ...DEFAULT_INITIAL_VALUES,
        dateTime: new Date().getTime(),
      });
    }
  }, [open]);

  const myHandleSubmit = async (values, actions) => {
    try {
      const form = {
        companyEmail: values.companyEmail,
        comments: values.comments,
      };
      await FeatureService.addCompanyEmail(featureForm.id, form);
      showNotification('success', 'Adicionado com sucesso');
      onAddCompanyEmail();
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>
        <Typography component="span" variant="h5">
          {featureForm && featureForm.name}
        </Typography>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={myInitialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <FormTextField name="companyEmail" type="email" label="Email da Empresa" fullWidth />
              <FormTextField name="comments" label="OBS (Opcional)" fullWidth />
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
                sx={{ ml: 1 }}
              >
                Submit
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default FeatureAddCompanyEmailModal;
