const CUSTOMERS = [
  {
    id: 1,
    name: 'Rodrigo',
  },
  {
    id: 2,
    name: 'Rodrigo Ferreira',
  },
  {
    id: 3,
    name: 'Rodrigo Arantes',
  },
  {
    id: 4,
    name: 'Rogerio',
  },
  {
    id: 5,
    name: 'Roberto',
  },
];

const MockService = {
  search: (searchTerm) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          CUSTOMERS.filter((customerObject) => {
            return customerObject.name.toLowerCase().includes(searchTerm.toLowerCase());
          }),
        );
      }, 1000);
    });
  },
};

export default MockService;
