import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import AppLoadingIndicator from './AppLoadingIndicator';
import routes from './routes';
import AuthService from './services/AuthService';
import EmployeeService from './services/EmployeeService';
import { getCurrentUser, setBirthdays } from './stores/appStore';
import theme from './theme';

function App() {
  const content = useRoutes(routes);
  const [isStartingUp, setIsStartingUp] = React.useState(true);

  React.useEffect(() => {
    const initialCurrentUser = getCurrentUser();
    const isLoggedIn = initialCurrentUser != null && initialCurrentUser.id > 0;
    if (isLoggedIn) {
      setIsStartingUp(true);
      AuthService.checkCredentials().finally(() => {
        setIsStartingUp(false);
      });

      EmployeeService.findAllBirthdays().then((birthdays) => {
        setBirthdays(birthdays);
      });
    } else {
      setIsStartingUp(false);
    }
  }, []);

  if (isStartingUp) {
    return <AppLoadingIndicator />;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-br"
      localeText={{ datePickerToolbarTitle: 'Data', cancelButtonLabel: 'Cancelar' }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer limit={5} hideProgressBar theme="colored" />
        {content}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
