import PhoneUtils from './phoneUtils';
import { prepareMessageForAllVariables } from './messageHelpers';
import StorageService from '../../services/StorageService';
import showNotification from '../helpers/showNotification';

function getWhatsAppUrl() {
  const isWhatsAppDesktop = StorageService.getIsWhatsAppDesktop();
  const whatsAppUrl = isWhatsAppDesktop ? 'whatsapp://send' : 'https://web.whatsapp.com/send';
  return whatsAppUrl;
}

const WhatsUtils = {
  sendMessage({ phone, text, customerName, shouldNotCleanUpNumber, serviceName, date, startTime }) {
    if (!phone) {
      showNotification('warning', 'Nenhum telefone informado.');
      return;
    }

    const selectedMsgString = prepareMessageForAllVariables({
      message: text,
      customerName,
      serviceName,
      date,
      startTime,
    });

    const sanitizedPhone = shouldNotCleanUpNumber
      ? PhoneUtils.sanitizePhoneNumberRemovePlus(phone)
      : PhoneUtils.sanitizePhoneNumberWithCountryPrefix(phone);

    window.open(
      `${getWhatsAppUrl()}/?phone=${sanitizedPhone}&text=${encodeURIComponent(selectedMsgString)}`,
      '_blank',
    );
  },

  openWhatsApp(fullPhoneCleanedup) {
    window.open(`${getWhatsAppUrl()}/?phone=${fullPhoneCleanedup}`, '_blank');
  },
};
export default WhatsUtils;
