import Papa from 'papaparse';

const parseCsvFile = async (
  file,
  checkForReplacementCharacter,
  format = null,
  startTerm = null,
  removeQuotes = false,
) => {
  let csvString = await readFileAsync(file, format);

  if (checkForReplacementCharacter) {
    if (csvString.includes('�')) {
      throw new Error('It contains replacement character. Might be due to invalid encoding');
    }
  }
  if (removeQuotes) {
    csvString = csvString.replace(/['"]+/g, '');
  }

  let parsedLoans = [];
  if (startTerm) {
    const position = csvString.indexOf(startTerm);
    csvString = csvString.substring(position, csvString.length);
  }
  const { data } = await parseCsv({ csvString });
  parsedLoans = data;
  return parsedLoans;
};

export default parseCsvFile;

function readFileAsync(file, format) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    if (format) {
      reader.readAsText(file, format);
    } else {
      reader.readAsText(file);
    }
  });
}

function parseCsv({ csvString }) {
  return new Promise((resolve, reject) => {
    Papa.parse(csvString, {
      complete: (r) => {
        resolve(r);
      },
      error: (e) => {
        reject(e);
      },
      header: true,
      worker: true,
    });
  });
}
