import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Box, Grid } from '@mui/material';

import { addNewQuestionToForm, QuestionType } from './anamneseFormHelpers';

function AnamneseFormFieldArrayHeader({ push, questionsLength }) {
  return (
    <Grid container columnGap={1} mb={2}>
      <Box>
        <Button
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          color="primary"
          onClick={() => push(addNewQuestionToForm(QuestionType.BOOLEAN))}
        >
          Booleano
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => push(addNewQuestionToForm(QuestionType.TEXT))}
        >
          Textual
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="success"
          startIcon={<AddIcon />}
          onClick={() => push(addNewQuestionToForm(QuestionType.SINGLE))}
        >
          Resposta Única
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="warning"
          startIcon={<AddIcon />}
          onClick={() => push(addNewQuestionToForm(QuestionType.MULTI))}
        >
          Resposta Múltipla
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="info"
          startIcon={<AddIcon />}
          disabled={questionsLength < 1}
          onClick={() => push(addNewQuestionToForm(QuestionType.SECTION))}
        >
          Nova Seção
        </Button>
      </Box>
    </Grid>
  );
}

export default React.memo(AnamneseFormFieldArrayHeader);
