import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { Form, Formik } from 'formik';
import showNotification from '../../../commons/helpers/showNotification';
import NumberUtils from '../../../commons/utils/numberUtils';
import yup from '../../../commons/validators/customYup';
import { FormDateField, FormSelect } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';
import StripeDiscountCoupon from './StripeDiscountCoupon';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  planId: yup.number(),
  maxNumberUsers: yup.number().required(),
  ignoreDiffBetweenPlans: yup.boolean(),
});

const DEFAULT_INIT_VALUES = {
  planId: '',
  maxNumberUsers: '',
  ignoreDiffBetweenPlans: false,
};

// COMPONENT
function StripeCreateScheduleSubscription({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [stripePlans, setStripePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [discountCoupons, setDiscountCoupons] = useState([]);
  const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    if (open) {
      setStripePlans(null);
      setCoupon();
      setDiscountCoupons([]);
      setInitialValues({
        planId: '',
        maxNumberUsers: selectedCompany.maxNumberUsers,
        startDate: dayjs(),
        stripeDiscountId: null,
      });
    }
  }, [open, selectedCompany]);

  const PLAN_OPTIONS = React.useMemo(() => {
    return [1, 3, 6, 9, 12, 18, 25, 35, 50].map((numberOfUsers) => {
      return {
        id: numberOfUsers,
        name: `Empresarial - ${numberOfUsers} usuários `,
      };
    });
  }, []);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm('Você tem certeza que deseja criar um agendamento de assinatura Stripe?');

    if (answer) {
      SuperUserService.createStripeScheduleSubscription({
        companyId: selectedCompany.id,
        planId: values.planId,
        startDate: values.startDate,
        stripeDiscountId: coupon,
      })
        .then((stripeCustomerUpdateInfo) => {
          showNotification('success', 'Atualizado com successo.');
          onSave(stripeCustomerUpdateInfo, values.maxNumberUsers);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const handleChangeSelectedPlan = (planId, setFieldValue) => {
    setFieldValue('planId', planId);
    setCoupon();
    setDiscountCoupons([]);
    const plan = stripePlans.find((stripePlan) => stripePlan.id === +planId);
    if (plan) {
      setFieldValue('priceOverride', plan.price);
      const fetchData = async () => {
        const stripeDiscountCoupons = await SuperUserService.getStripeDiscountsCupons({
          companyId: selectedCompany.id,
          planId: planId,
        });
        setDiscountCoupons(stripeDiscountCoupons);
      };
      fetchData();
    }
  };

  const handleChangeMaxNumberUsers = (newSelection, setFieldValue) => {
    setIsLoading(true);
    setFieldValue('priceOverride', '');
    setFieldValue('planId', '');
    setCoupon();
    setDiscountCoupons([]);

    SuperUserService.getStripePlan(newSelection)
      .then((respPlan) => {
        setStripePlans(respPlan);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StripeDiscountCoupon
        discountCoupons={discountCoupons}
        open={modalDiscountOpen}
        onClose={() => {
          setModalDiscountOpen(false);
        }}
        onSave={(coupon) => {
          setCoupon(coupon);
        }}
      />
      <Dialog maxWidth="md" onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography component="span" variant="h5">
              Criar agendamento de Assinatura - {selectedCompany.name}
            </Typography>
          </Box>
        </DialogTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, setFieldValue, values, errors, isValid }) => (
            <>
              <DialogContent dividers>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Card sx={{ minWidth: 450, maxWidth: 800, display: 'table', alignSelf: 'center' }}>
                    <CardHeader title="Novo Plano" sx={{ flexDirection: 'column' }}></CardHeader>
                    <CardContent>
                      <Form>
                        <FormSelect
                          fullWidth
                          label="Plano"
                          name="maxNumberUsers"
                          dataSource={PLAN_OPTIONS}
                          onChange={(evt) => {
                            handleChangeMaxNumberUsers(evt.target.value, setFieldValue);
                          }}
                        />
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                          {isLoading && <LinearProgress size={24} />}
                          {stripePlans && (
                            <ToggleButtonGroupStyled
                              size="small"
                              value={`${values.planId}`}
                              onChange={(evt, newValue) => {
                                if (newValue != null) {
                                  handleChangeSelectedPlan(newValue, setFieldValue);
                                }
                              }}
                              exclusive
                            >
                              {stripePlans.map((plan) => (
                                <ToggleButtonStyled key={plan.id} value={`${plan.id}`}>
                                  <ToggleTextStyled>
                                    {plan.name} - {NumberUtils.toCurrency(plan.price, 2)}
                                  </ToggleTextStyled>
                                </ToggleButtonStyled>
                              ))}
                            </ToggleButtonGroupStyled>
                          )}
                          {!isLoading && stripePlans && stripePlans.length === 0 && (
                            <Typography variant="h5">Nenhum plano encontrado</Typography>
                          )}

                          <Typography color="error">{errors?.planId}</Typography>
                        </Box>
                      </Form>
                      {coupon > 0 ? (
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>
                            <FontAwesomeIconStatus status="info" icon={faCheckCircle} />
                            <b> Desconto aplicado: </b>
                            {discountCoupons.find((coupons) => coupons.id === coupon).name}
                          </Typography>
                          <IconButton onClick={() => setCoupon(0)} sx={{ mr: 1 }}>
                            <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                          </IconButton>
                        </Grid>
                      ) : (
                        <>
                          {discountCoupons.length > 0 && (
                            <Box display="flex" justifyContent={'flex-end'}>
                              <Button
                                sx={{ ml: 2 }}
                                onClick={() => {
                                  setModalDiscountOpen(true);
                                }}
                              >
                                Adicionar Cupom
                              </Button>
                            </Box>
                          )}
                        </>
                      )}

                      <Grid item xs={12}>
                        <FormDateField fullWidth label="Data de início" name="startDate" />
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </DialogContent>
              <Form>
                <DialogActionsStyled>
                  <Button variant="outlined" onClick={onClose}>
                    Fechar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isLoading || !isValid}
                  >
                    Salvar
                  </Button>
                </DialogActionsStyled>
              </Form>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default StripeCreateScheduleSubscription;
