import dayjs from 'dayjs';
import { ACCOUNT_PAID, ACCOUNT_TRIAL } from '../utils/accountUtils';

export const ACC_STATUS_PAID = 'PAID';
export const ACC_STATUS_PAID_GRACE_PERIOD = 'PAID_GRACE_PERIOD';
export const ACC_STATUS_TRIAL_ACTIVE = 'TRIAL';
export const ACC_STATUS_TRIAL_EXPIRED_OR_FREE = 'TRIAL_EXPIRED_OR_FREE';

export const PAYMENT_TYPE_STRIPE = 'STRIPE';
export const PAYMENT_TYPE_ASAAS = 'ASAAS';
export const PAYMENT_TYPE_APPLE = 'APPLE';
export const PAYMENT_TYPE_ANDROID = 'ANDROID';
export const PAYMENT_TYPE_PAYPAL = 'PAYPAL';

export function getAccountStatusName(company) {
  if (company.accountType === ACCOUNT_PAID) {
    if (company.paidGracePeriodEndDate == null) {
      return ACC_STATUS_PAID;
    } else {
      return ACC_STATUS_PAID_GRACE_PERIOD;
    }
  }

  if (
    company.accountType === ACCOUNT_TRIAL &&
    company.trialExpirationDate &&
    !dayjs().isAfter(dayjs(company.trialExpirationDate), 'day')
  ) {
    return ACC_STATUS_TRIAL_ACTIVE;
  }

  return ACC_STATUS_TRIAL_EXPIRED_OR_FREE;
}

export function getAccountStatusDescription(company) {
  if (company.accountType === ACCOUNT_PAID && company.paidGracePeriodEndDate) {
    const suffix = dayjs(company.paidGracePeriodEndDate).isBefore(dayjs()) ? 'Expirado' : 'Irá Expirar';
    return `PAGA - ${suffix} ${dayjs(company.paidGracePeriodEndDate).format('DD/MM/YY')}`;
  }

  if (company.accountType === ACCOUNT_TRIAL && company.trialExpirationDate) {
    const daysRemaning = Math.ceil(dayjs(company.trialExpirationDate).diff(dayjs(), 'days', true));
    return `${company.accountType} - ${dayjs(company.trialExpirationDate).format('DD/MM/YY')} - ${
      daysRemaning < 0 ? 'Expirado' : `Faltam ${daysRemaning} dias`
    }`;
  }

  return company.accountType === ACCOUNT_PAID ? 'PAGA' : company.accountType;
}
