import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import ConfirmationDialog from '../../../components/modals/ConfirmationDialog';
import HintText from '../../../components/labels/HintText';
import TableCellAction from '../../../components/table/TableCellAction';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import ExampleQuestionService from '../../../services/ExampleQuestionService';
import TableRowClickable from '../../../components/table/TableRowClickable';
import showNotification from '../../../commons/helpers/showNotification';
import ExampleQuestionListFilter from './ExampleQuestionListFilter';

const PaperStyled = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});

// COMPONENT
function ExampleQuestionListPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false });

  useEffect(() => {
    async function initData() {
      setIsLoading(true);
      try {
        const resp = await ExampleQuestionService.findAll(null, selectedCategoryId);
        setDataSource(resp);
      } finally {
        setIsLoading(false);
      }
    }

    initData();
  }, [selectedCategoryId]);

  const handleSelect = (obj) => {};

  const handleEdit = (obj) => {
    navigate(`${obj.id}`);
  };

  const handleDelete = async (targetObject) => {
    setModalConfirmDelete({
      open: true,
      targetObject,
    });
  };

  const handleDeleteConfirmation = async () => {
    setIsLoading(true);
    try {
      await ExampleQuestionService.delete(modalConfirmDelete.targetObject);
      setDataSource(dataSource.filter((data) => data.id !== modalConfirmDelete.targetObject.id));
      showNotification('success', 'Deletado com sucesso');
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* MODAL */}
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

      {/* CONTENT */}
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="overline">Crud example</Typography>
            <Button variant="contained" component={Link} to="novo">
              Adicionar Cliente
            </Button>
          </Box>

          <Box sx={{ mt: 1 }}>
            {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
          </Box>

          <PaperStyled square>
            {/* TOOLBAR */}
            <div>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <ExampleQuestionListFilter
                  selectedCategoryId={selectedCategoryId}
                  setSelectedCategoryId={setSelectedCategoryId}
                />
              </Box>
            </div>
            {dataSource?.length === 0 ? (
              <Box sx={{ mt: 5, mb: 5 }}>
                <HintText>Nenhum resultado encontrado.</HintText>
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titulo</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Funcionário</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Data Criação</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource?.map((question, i) => {
                    return (
                      <TableRowClickable
                        key={question.id}
                        onClick={() => {
                          handleSelect(question);
                        }}
                      >
                        <TableCell>{question.title}</TableCell>
                        <TableCell>{question.description}</TableCell>
                        <TableCell>{question.employee.name}</TableCell>
                        <TableCell>{question.exampleQuestionCategory.name}</TableCell>
                        <TableCell>{dayjs(question.createdAt).format('DD/MM/YY HH:mm')}</TableCell>

                        <TableCellAction
                          title="Editar"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleEdit(question);
                          }}
                          disabled={isLoading}
                        >
                          <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                        </TableCellAction>
                        <TableCellAction
                          title="Deletar"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDelete(question);
                          }}
                          disabled={isLoading}
                        >
                          <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                        </TableCellAction>
                      </TableRowClickable>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
}

export default ExampleQuestionListPage;
