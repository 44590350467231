import React, { useState } from 'react';

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PAYMENT_TYPE_STRIPE } from '../../commons/helpers/accountHelpers';
import exportToExcel from '../../commons/helpers/exportToExcel';
import showNotification from '../../commons/helpers/showNotification';
import maskUtils from '../../commons/utils/maskUtils';
import NumberUtils from '../../commons/utils/numberUtils';
import { isValidCNPJ, isValidCPF } from '../../commons/utils/personIdentifierDocumentNumberUtils';
import WhatsUtils from '../../commons/utils/whatsUtils';
import yup from '../../commons/validators/customYup';
import ButtonCopyToClipboard from '../../components/buttons/ButtonCopyToClipboard';
import { FormTextField } from '../../components/forms/FormikFields';
import HintText from '../../components/labels/HintText';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import SuperUserService from '../../services/SuperUserService';
import { getCurrentUser } from '../../stores/appStore';
import theme from '../../theme';
import AccountTypeInfo from './AccountTypeInfo';
import { ActionButton, MainButtons } from './MainButtons';
import CompanyOnlineSchedulingSettingsModal from './modals/CompanyOnlineSchedulingSettingsModal';
import CompanyWorkingScheduleModal from './modals/CompanyWorkingScheduleModal';
import CreditCardRateSettingsModal from './modals/CreditCardRateSettingsModal';
import EditCompanyEmailModal from './modals/EditCompanyEmailModal';
import EditPhoneNumberModal from './modals/EditPhoneNumberModal';
import EmployeeChangesCompanySubscriptionAuditLogModal from './modals/EmployeeChangesCompanySubscriptionAuditLogModal';
import ExportAppointmentsInternalModal from './modals/ExportAppointmentsInternalModal';
import ExportAppointmentsWithPaymentInfoModal from './modals/ExportAppointmentsWithPaymentInfoModal';
import ImportAgendaModal from './modals/ImportAgendaModal';
import ImportCustomersModal from './modals/ImportCustomers/ImportCustomersModal';
import LoginHistoryModal from './modals/LoginHistoryModal';
import PageBioSettingsForCompanyModal from './modals/PageBioSettingsForCompanyModal';
import PushNotificationForCompanyModal from './modals/PushNotificationForCompanyModal';
import ResetPasswordHistoryModal from './modals/ResetPasswordHistoryModal';
import SubscriptionSettingsForCompanyModal from './modals/SubscriptionSettingsForCompanyModal';
import UpdateStripeDiscountModal from './modals/UpdateStripeDiscountModal';
import UsersModal from './modals/UsersModal';
import { OccupationAreas } from './occupationAreas';
import { TopMainButtons } from './TopMainButtons';
import CompanyAccountAuditLogModal from './modals/CompanyAccountAuditLogModal';

const schema = yup.object().shape({
  phoneOrEmail: yup.string().min(4, 'O comprimento minimo é 4.').required(),
});

const INIT_VALUES = { phoneOrEmail: '' };

const PaperStyled = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});

const IconButtonStyled = styled(IconButton)(() => ({ marginLeft: '16px', padding: 0 }));

function ManageCompanyPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState('');
  const [initialValues, setInitialValues] = useState(INIT_VALUES);
  const [selectedCompany, setSelectedCompany] = useState();

  const [sherlockHolmesUser, setSherlockHolmesUser] = useState(null);
  const [appointmentCount, setAppointmentCount] = useState(null);
  const [topServicePackages, setTopServicePackages] = useState(null);
  const [creditCardSettingsModalOpen, setCreditCardSettingsModalOpen] = useState(false);
  const [debitCardRate, setDebitCardRate] = useState(null);
  const [topServices, setTopServices] = useState(null);
  const [isLoadingSherlock, setIsLoadingSherlock] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [maxNumberOfUsers, setMaxNumberOfUsers] = useState(3);
  const [maxNumberOfUsersCourtesy, setMaxNumberOfUsersCourtesy] = useState(0);
  const [tempPassword, setTempPassword] = useState();

  const [modalPageBioSettingsOpen, setModalPageBioSettingsOpen] = useState(false);
  const [modalCompanyOnlineSchedulingOpen, setModalCompanyOnlineSchedulingOpen] = useState(false);
  const [modalUsersOpen, setModalUsersOpen] = useState(false);
  const [modalLoginHistoryOpen, setModalLoginHistoryOpen] = useState(false);
  const [modalResetPasswordHistoryOpen, setModalResetPasswordHistoryOpen] = useState(false);
  const [modalWorkingScheduleOpen, setModalWorkingScheduleOpen] = useState(false);
  const [modalExportAppointmentsOpen, setModalExportAppointmentsOpen] = useState(false);
  const [modalExportAppointmentsWithPaymentsOpen, setModalExportAppointmentsWithPaymentsOpen] =
    useState(false);
  const [modalImportAgendaOpen, setModalImportAgendaOpen] = useState(false);
  const [modalImportCustomersOpen, setModalImportCustomersOpen] = useState(false);
  const [modalSendNotificationOpen, setModalSendNotificationOpen] = useState(false);
  const [modalSubscriptionSettingsOpen, setModalSubscriptionSettingsOpen] = useState(false);
  const [updateDiscountModalOpen, setUpdateDiscountModalOpen] = useState(false);

  const [modalEditPhoneNumberOpen, setModalEditPhoneNumberOpen] = useState(false);
  const [modalEditEmailAddressOpen, setModalEditEmailAddressOpen] = useState(false);
  const [
    modalEmployeeChangesCompanySubscriptionAuditLog,
    setModalEmployeeChangesCompanySubscriptionAuditLog,
  ] = useState(false);
  const [modalCompanyAccountAuditLogModal, setModalCompanyAccountAuditLogModal] = useState(false);

  const [modalConfirm, setModalConfirm] = useState({ open: false });

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('companyId')) {
      const companyId = searchParams.get('companyId');
      if (companyId) {
        searchParams.delete('companyId');
        setSearchParams(searchParams);
        handleSearchByCompanyId(companyId);
      }
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (sherlockHolmesUser === null) {
      setIsLoadingSherlock(true);
      SuperUserService.getSherlockHolmesInfo()
        .then((resp) => {
          setSherlockHolmesUser(resp);
        })
        .finally(() => {
          setIsLoadingSherlock(false);
        });
    }
  }, [sherlockHolmesUser]);

  const handleSearchByCompanyId = (companyId) => {
    setSherlockHolmesUser(null);
    setIsLoading(true);
    setSelectedCompany(null);
    SuperUserService.getCompanyInfoByCompanyId({ companyId: companyId })
      .then((companyInfo) => {
        setInitialValues({ phoneOrEmail: companyInfo.company.email });
        if (!companyInfo) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        } else {
          setSelectedCompany({
            ...companyInfo,
          });
          setMaxNumberOfUsers(companyInfo.company.maxNumberUsers);
          setMaxNumberOfUsersCourtesy(companyInfo.company.maxNumberUsersCourtesy ?? 0);
          setTempPassword(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCountAppointmentByCompanyId = () => {
    SuperUserService.getCountAppointmentByCompanyId({ companyId: selectedCompany.company.id })
      .then((countAppointment) => {
        setAppointmentCount(countAppointment);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTopServicePackagesByCompanyId = () => {
    SuperUserService.getTopServicePackagesByCompanyId({ companyId: selectedCompany.company.id })
      .then((topServicesPackage) => {
        setTopServicePackages(topServicesPackage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDebitCardRateByCompanyId = () => {
    setIsLoading(true);
    SuperUserService.getDeditCardRateByCompanyId({ companyId: selectedCompany.company.id })
      .then((debitCardRate) => {
        setDebitCardRate(debitCardRate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTopServicesByCompanyId = () => {
    SuperUserService.getTopServicesByCompanyId({ companyId: selectedCompany.company.id })
      .then((topServices) => {
        setTopServices(topServices);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const currentUser = getCurrentUser();

  const handleButtonClick = (button) => {
    switch (button.type) {
      case ActionButton.BIO:
        setModalPageBioSettingsOpen(true);
        break;
      case ActionButton.AGENDA:
        setModalCompanyOnlineSchedulingOpen(true);
        break;
      case ActionButton.USUARIOS:
        setModalUsersOpen(true);
        break;
      case ActionButton.HISTORICO_LOGIN:
        setModalLoginHistoryOpen(true);
        break;
      case ActionButton.LOGS_REC_SENHA:
        setModalResetPasswordHistoryOpen(true);
        break;
      case ActionButton.CREATE_TEMP_SENHA:
        openConfirmationModal(button, handleCreateNewTemporaryPasswordConfirmation);
        break;
      case ActionButton.LINK_ATIVACAO:
        openConfirmationModal(button, handleActivateLinkConfirmation);
        break;
      case ActionButton.LINK_ATIVACAO_ASAAS:
        openConfirmationModal(button, handleActivateLinkAsaasConfirmation);
        break;
      case ActionButton.LINK_UPGRADE:
        openConfirmationModal(button, handleUpgradeLinkConfirmation);
        break;
      case ActionButton.ACCOUNT_DELETE:
        openConfirmationModal(button, handleDeleteAccountConfirmation);
        break;
      case ActionButton.ACCOUNT_RESET:
        openConfirmationModal(button, handleResetAccountConfirmation);
        break;
      case ActionButton.SEND_EMAIL:
        openConfirmationModal(button, handleSendEmailConfirmation);
        break;
      case ActionButton.EXPEDIENTE:
        setModalWorkingScheduleOpen(true);
        break;
      case ActionButton.EXPORTAR_AGENDA:
        setModalExportAppointmentsOpen(true);
        break;
      case ActionButton.EXPORTAR_AGENDA_COM_PAGAMENTOS:
        setModalExportAppointmentsWithPaymentsOpen(true);
        break;
      case ActionButton.IMPORTAR_AGENDA:
        setModalImportAgendaOpen(true);
        break;
      case ActionButton.IMPORTAR_CLIENTES:
        setModalImportCustomersOpen(true);
        break;
      case ActionButton.EXPORTAR_CLIENTES:
        openConfirmationModal(button, handleExportClientsConfirmation);
        break;
      case ActionButton.DELETAR_CLIENTES:
        openConfirmationModal(button, handleDeleteClientsConfirmation);
        break;
      case ActionButton.ENVIAR_NOTIFICACAO:
        setModalSendNotificationOpen(true);
        break;
      case ActionButton.PAINEL_ASSINATURA:
        setModalSubscriptionSettingsOpen(true);
        break;
      case ActionButton.MUDAR_VENCIMENTO_STRIPE:
        openConfirmationModal(button, handleChangeStripeDueDateConfirmation);
        break;
      default:
        return showNotification('error', 'Ação inválida.');
    }
  };

  const openConfirmationModal = (button, confirmAction) => {
    setModalConfirm({
      open: true,
      title: button.title,
      message: button.modalConfirmMessage,
      textConfirm: button.textConfirm,
      handleConfirmation: confirmAction,
      dateFieldLabel: button.dateFieldLabel,
      messageStyle: button.messageStyle,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const connectToSuperUser = async () => {
    handleClose();
    if (selectedCompany == null) {
      showNotification('warning', 'Nenhuma empresa selecionada.');
      return;
    }
    setIsLoadingSherlock(true);
    try {
      const updatedSherlock = await SuperUserService.connectSherlockHolmesInfo(selectedCompany.company.id);
      setSherlockHolmesUser(updatedSherlock);
      showNotification('success', 'Conectado com sucesso!');
    } catch {
      setSherlockHolmesUser(null);
    } finally {
      setIsLoadingSherlock(false);
    }
  };

  const disconnectFromSuperUser = async () => {
    handleClose();
    setIsLoadingSherlock(true);
    try {
      await SuperUserService.disconnectSherlockHolmesInfo();
      setSherlockHolmesUser(null);
    } finally {
      setIsLoadingSherlock(false);
    }
    showNotification('success', 'Desligado com sucesso!');
  };

  const handleCreateNewTemporaryPasswordConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.createTempPassword(selectedCompany.masterUserId)
      .then((tempPassword) => {
        setTempPassword(tempPassword);
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleActivateLinkConfirmation = async (params) => {
    if (params.date && !params.date.isAfter(dayjs())) {
      showNotification('warning', 'Data invalida. Por favor informe uma data no futuro para agendar.');
      setModalConfirm((prev) => ({ ...prev, open: false }));
      return;
    }

    setIsLoading(true);
    SuperUserService.createTempAccessForCompany(
      selectedCompany.company.id,
      params.date != null ? params.date.format('YYYY-MM-DD') : null,
    )
      .then((companyTempAccessCode) => {
        if (companyTempAccessCode) {
          const link = `Prontinho, *é só clicar no link para fazer a ativação* \nhttps://portal.minhaagendaapp.com.br/reativar?code=${companyTempAccessCode.code}`;
          copy(link);
          showNotification('success', 'Link copiado com sucesso!');
        } else {
          showNotification('error', 'Não foi possivel gerar link!');
        }
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleActivateLinkAsaasConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.createTempAccessForCompany(selectedCompany.company.id, null, 'ATIVE_ASAAS')
      .then((companyTempAccessCode) => {
        if (companyTempAccessCode) {
          const link = `Prontinho, *é só clicar no link para fazer a ativação* \nhttps://portal.minhaagendaapp.com.br/asaas-ativar?code=${companyTempAccessCode.code}`;
          copy(link);
          showNotification('success', 'Link copiado com sucesso!');
        } else {
          showNotification('error', 'Não foi possivel gerar link!');
        }
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleUpgradeLinkConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.createTempAccessForCompany(selectedCompany.company.id)
      .then((companyTempAccessCode) => {
        if (companyTempAccessCode) {
          const link = `https://portal.minhaagendaapp.com.br/upgrade?code=${companyTempAccessCode.code}`;
          copy(link);
          showNotification('success', 'Link copiado com sucesso!');
        } else {
          showNotification('error', 'Não foi possivel gerar link!');
        }
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleDeleteAccountConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.deleteCompany(selectedCompany.company.id)
      .then(() => {
        setSelectedCompany(null);
        showNotification('success', 'Deletado com sucesso!');
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleResetAccountConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.resetCompany(selectedCompany.company.id)
      .then(() => {
        setSelectedCompany(null);
        showNotification('success', 'Resetado com sucesso!');
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleSendEmailConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.sendMarketingEmail(selectedCompany.company.id)
      .then(() => {
        showNotification('success', 'Email enviado com sucesso!');
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleExportClientsConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.getAllCustomers(selectedCompany.company.id)
      .then((customers) => {
        const fileName = `BaseDeClientes_${selectedCompany.company.name}_Gerado${dayjs().format(
          'DD-MM-YY HH:mm:ss',
        )}`;

        const preparedCustomers = customers.map((customer) => ({
          Nome: customer.name,
          Telefone: customer.phone1,
          'Telefone 2': customer.phone2,
          Email: customer.email,
          Endereço: customer.address,
          'Data Nascimento':
            customer.birthDate != null && customer.birthDate !== ''
              ? dayjs(customer.birthDate).format('L')
              : null,
          CPF: customer.cpf,
        }));
        exportToExcel(preparedCustomers, fileName);
        showNotification('success', 'Clientes exportados com sucesso.');
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleDeleteClientsConfirmation = async () => {
    setIsLoading(true);
    SuperUserService.deleteUnusedCustomers(selectedCompany.company.id)
      .then((resp) => {
        showNotification('success', `Clientes deletados com sucesso. Deletados ${resp}`);
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const handleChangeStripeDueDateConfirmation = async () => {
    if (!selectedCompany) {
      return;
    }
    if (selectedCompany.company.subscriptionType === PAYMENT_TYPE_STRIPE) {
      showNotification(
        'warning',
        'Você só pode criar link para mudar data de vencimento caso não seja stripe.',
      );
      return;
    }
    setIsLoading(true);
    SuperUserService.createTempAccessForCompany(selectedCompany.company.id)
      .then((companyTempAccessCode) => {
        if (companyTempAccessCode) {
          const link = `https://portal.minhaagendaapp.com.br/mudar-data-assinatura?code=${companyTempAccessCode.code}`;
          copy(link);
          showNotification('success', 'Link copiado com sucesso!');
        } else {
          showNotification('error', 'Não foi possivel gerar link!');
        }
      })
      .finally(() => {
        setIsLoading(false);
        setModalConfirm((prev) => ({ ...prev, open: false }));
      });
  };

  const onChangeMaxNumberUsers = (event) => {
    setModalConfirm({
      open: true,
      title: 'Alterar Número Usuários',
      message: `Você tem certeza que deseja alterar o número de usuários de ${maxNumberOfUsers} para ${event.target.value}?`,
      handleConfirmation: () => {
        setIsLoading(true);
        const newMaxNumberOfUsers = event.target.value;
        SuperUserService.updateMaxNumberOfUsers({
          maxNumberUsers: newMaxNumberOfUsers,
          companyId: selectedCompany.company.id,
        })
          .then(() => {
            setSelectedCompany({
              ...selectedCompany,
              company: {
                ...selectedCompany.company,
                maxNumberUsers: newMaxNumberOfUsers,
              },
            });
            setMaxNumberOfUsers(newMaxNumberOfUsers);
            showNotification('success', 'Atualizado com sucesso!');
          })
          .finally(() => {
            setModalConfirm((prev) => ({ ...prev, open: false }));
            setIsLoading(false);
          });
      },
    });
  };

  const onChangeMaxNumberUsersCourtesy = (event) => {
    setModalConfirm({
      open: true,
      title: 'Alterar Cortesia',
      message: 'Você tem certeza que deseja adicionar/remover cortesia?',
      handleConfirmation: () => {
        setIsLoading(true);
        const newMaxNumberUsersCourtesy = event.target.value;
        SuperUserService.updateMaxNumberOfUsersCourtesy({
          maxNumberUsers: newMaxNumberUsersCourtesy,
          companyId: selectedCompany.company.id,
        })
          .then(() => {
            setSelectedCompany({
              ...selectedCompany,
              company: {
                ...selectedCompany.company,
                maxNumberUsersCourtesy: newMaxNumberUsersCourtesy,
              },
            });
            setMaxNumberOfUsersCourtesy(newMaxNumberUsersCourtesy);
            showNotification('success', 'Atualizado com sucesso!');
          })
          .finally(() => {
            setModalConfirm((prev) => ({ ...prev, open: false }));
            setIsLoading(false);
          });
      },
    });
  };

  const handleSearchSubmit = (values) => {
    setSherlockHolmesUser(null);
    setInitialValues({ phoneOrEmail: values.phoneOrEmail });
    const searchTermRemovedEmailPrefix =
      values.phoneOrEmail.indexOf('mailto:') !== -1 ? values.phoneOrEmail.substring(7) : values.phoneOrEmail;
    setIsLoading(true);
    setSelectedCompany(null);
    setAppointmentCount(null);
    setTopServicePackages(null);
    setTopServices(null);
    setDebitCardRate(null);
    SuperUserService.getCompanyInfo({ phoneOrEmail: searchTermRemovedEmailPrefix })
      .then((companyInfo) => {
        if (!companyInfo) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        } else {
          setSelectedCompany({
            ...companyInfo,
          });
          setMaxNumberOfUsers(companyInfo.company.maxNumberUsers);
          setMaxNumberOfUsersCourtesy(companyInfo.company.maxNumberUsersCourtesy ?? 0);
          setTempPassword(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearchDeleted = () => {
    let searchTermSanitized = isSearch.indexOf('mailto:') !== -1 ? isSearch.substring(7) : isSearch;
    // searchTermSanitized = PhoneUtils.sanitizePhoneNumber(searchTermSanitized);
    setIsLoading(true);
    setSelectedCompany(null);
    setAppointmentCount(null);
    setTopServicePackages(null);
    setTopServices(null);
    setDebitCardRate(null);
    SuperUserService.getCompanyInfoDeleted({ phoneOrEmail: searchTermSanitized })
      .then((companyInfo) => {
        if (!companyInfo) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        } else {
          setSelectedCompany({
            ...companyInfo,
          });
          setMaxNumberOfUsers(companyInfo.company.maxNumberUsers);
          setMaxNumberOfUsersCourtesy(companyInfo.company.maxNumberUsersCourtesy ?? 0);
          setTempPassword(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSearchCpfCnpj = () => {
    setSelectedCompany(null);
    setAppointmentCount(null);
    setTopServicePackages(null);
    setTopServices(null);
    setDebitCardRate(null);
    if (isValidCPF(isSearch) || isValidCNPJ(isSearch)) {
      setIsLoading(true);
      SuperUserService.getCompanyInfoCpfCnpj({ cpfCnpj: isSearch })
        .then((companyInfo) => {
          if (!companyInfo) {
            showNotification('warning', 'Nenhum usuário encontrado.');
          } else {
            setSelectedCompany({
              ...companyInfo,
            });
            setMaxNumberOfUsers(companyInfo.company.maxNumberUsers);
            setMaxNumberOfUsersCourtesy(companyInfo.company.maxNumberUsersCourtesy ?? 0);
            setTempPassword(null);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showNotification('warning', 'CPF/CNPJ inválido.');
    }
  };

  return (
    <>
      {selectedCompany && (
        <>
          <EmployeeChangesCompanySubscriptionAuditLogModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalEmployeeChangesCompanySubscriptionAuditLog}
            setModalVisible={setModalEmployeeChangesCompanySubscriptionAuditLog}
          />

          <CompanyAccountAuditLogModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalCompanyAccountAuditLogModal}
            setModalVisible={setModalCompanyAccountAuditLogModal}
          />

          <UpdateStripeDiscountModal
            selectedCompany={selectedCompany.company}
            open={updateDiscountModalOpen}
            onClose={() => {
              setUpdateDiscountModalOpen(false);
            }}
            onSave={() => {
              setUpdateDiscountModalOpen(false);
            }}
          />

          <ConfirmationDialog
            title={modalConfirm.title}
            message={modalConfirm.message}
            onConfirm={modalConfirm.handleConfirmation}
            textConfirm={modalConfirm.textConfirm}
            open={modalConfirm.open}
            dateFieldLabel={modalConfirm.dateFieldLabel}
            messageStyle={modalConfirm.messageStyle}
            onClose={() => {
              setModalConfirm((prev) => ({ ...prev, open: false }));
            }}
          />

          <EditPhoneNumberModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalEditPhoneNumberOpen}
            setModalVisible={setModalEditPhoneNumberOpen}
            onSave={({ updatedPhone, updatedPhone2 }) => {
              setModalEditPhoneNumberOpen(false);
              setSelectedCompany({
                ...selectedCompany,
                company: {
                  ...selectedCompany.company,
                  phone: updatedPhone,
                  phone2: updatedPhone2,
                },
              });
            }}
          />

          <EditCompanyEmailModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalEditEmailAddressOpen}
            setModalVisible={setModalEditEmailAddressOpen}
            onSave={({ email: updatedEmail }) => {
              setModalEditEmailAddressOpen(false);
              setSelectedCompany({
                ...selectedCompany,
                company: {
                  ...selectedCompany.company,
                  email: updatedEmail,
                },
              });
            }}
          />

          {modalWorkingScheduleOpen && (
            <CompanyWorkingScheduleModal
              selectedCompany={selectedCompany.company}
              modalVisible={modalWorkingScheduleOpen}
              setModalVisible={setModalWorkingScheduleOpen}
            />
          )}

          <ExportAppointmentsInternalModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalExportAppointmentsOpen}
            setModalVisible={setModalExportAppointmentsOpen}
          />

          <ExportAppointmentsWithPaymentInfoModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalExportAppointmentsWithPaymentsOpen}
            setModalVisible={setModalExportAppointmentsWithPaymentsOpen}
          />

          <ImportAgendaModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalImportAgendaOpen}
            setModalVisible={setModalImportAgendaOpen}
          />

          <ImportCustomersModal
            selectedCompany={selectedCompany.company}
            modalVisible={modalImportCustomersOpen}
            setModalVisible={setModalImportCustomersOpen}
          />

          {modalSendNotificationOpen && (
            <PushNotificationForCompanyModal
              selectedCompany={selectedCompany.company}
              modalVisible={modalSendNotificationOpen}
              setModalVisible={setModalSendNotificationOpen}
            />
          )}

          {modalSubscriptionSettingsOpen && (
            <SubscriptionSettingsForCompanyModal
              selectedCompany={selectedCompany.company}
              modalVisible={modalSubscriptionSettingsOpen}
              setModalVisible={setModalSubscriptionSettingsOpen}
              onSave={(updatedCompanyInfo) => {
                setModalSubscriptionSettingsOpen(false);
                setSelectedCompany({
                  ...selectedCompany,
                  company: updatedCompanyInfo,
                });
              }}
            />
          )}
          {modalPageBioSettingsOpen && (
            <PageBioSettingsForCompanyModal
              companyPageBio={selectedCompany.companyPageBio}
              modalVisible={modalPageBioSettingsOpen}
              setModalVisible={setModalPageBioSettingsOpen}
              onSave={(pageBioUpdated) => {
                setModalPageBioSettingsOpen(false);
                setSelectedCompany({
                  ...selectedCompany,
                  companyPageBio: pageBioUpdated,
                });
              }}
            />
          )}

          {modalCompanyOnlineSchedulingOpen && (
            <CompanyOnlineSchedulingSettingsModal
              selectedCompany={selectedCompany}
              open={modalCompanyOnlineSchedulingOpen}
              onClose={() => {
                setModalCompanyOnlineSchedulingOpen(false);
              }}
              onSave={(updatedObject) => {
                setSelectedCompany({
                  ...selectedCompany,
                  companyOnlineScheduling: {
                    ...selectedCompany.companyOnlineScheduling,
                    canScheduleOnHolidays: updatedObject.canScheduleOnHolidays,
                    maxNumberOfFutureAppointments: updatedObject.maxNumberOfFutureAppointments,
                    timeZoneOffset: updatedObject.timeZoneOffset,
                    automaticAppointmentPayment: updatedObject.automaticAppointmentPayment,
                    allowSimultaneousAppointment: updatedObject.allowSimultaneousAppointment,
                    allowAppointmentAsGuest: updatedObject.allowAppointmentAsGuest,
                    birthDateFieldEnabled: updatedObject.birthDateFieldEnabled,
                  },
                });
              }}
            />
          )}

          <UsersModal
            selectedCompany={selectedCompany.company}
            open={modalUsersOpen}
            onClose={() => {
              setModalUsersOpen(false);
            }}
            onUpdate={({ masterUserId, company }) => {
              setSelectedCompany({
                ...selectedCompany,
                masterUserId: masterUserId,
                company: { ...company },
              });
            }}
          />

          <LoginHistoryModal
            userId={selectedCompany.masterUserId}
            open={modalLoginHistoryOpen}
            onClose={() => {
              setModalLoginHistoryOpen(false);
            }}
          />

          <ResetPasswordHistoryModal
            userId={selectedCompany.masterUserId}
            open={modalResetPasswordHistoryOpen}
            onClose={() => {
              setModalResetPasswordHistoryOpen(false);
            }}
          />
          <CreditCardRateSettingsModal
            selectedCompany={selectedCompany}
            modalVisible={creditCardSettingsModalOpen}
            setModalVisible={setCreditCardSettingsModalOpen}
          />
        </>
      )}

      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSearchSubmit}
              >
                {() => (
                  <Form>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FormTextField
                        name="phoneOrEmail"
                        label="Procure por email, telefone ou Cpf/Cnpj"
                        onChange={(e) => {
                          setIsSearch(e.target.value);
                        }}
                        sx={{ minWidth: '300px' }}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ ml: 2, minHeight: 36.5 }}
                      >
                        <FontAwesomeIcon icon="search" size="lg" color="primary" />
                        {isLoading && <CircularProgress size={18} />}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          handleSearchDeleted();
                        }}
                        color="primary"
                        disabled={isLoading}
                        sx={{ ml: 2, minHeight: 36.5 }}
                      >
                        <FontAwesomeIcon icon="search" size="lg" fixedWidth color="primary" />
                        Del
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          handleSearchCpfCnpj();
                        }}
                        color="primary"
                        disabled={isLoading}
                        sx={{ ml: 2, minHeight: 36.5 }}
                      >
                        <FontAwesomeIcon icon="search" size="lg" fixedWidth color="primary" />
                        CPF/CNPJ
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>

            <Tooltip title="Conectar para investigar">
              <IconButton edge="end" onClick={handleClick} sx={{ ml: 3, mr: 0 }} disabled={isLoadingSherlock}>
                <FontAwesomeIcon
                  icon="user-secret"
                  size="lg"
                  fixedWidth
                  color={
                    isLoadingSherlock
                      ? theme.palette.grey[500]
                      : sherlockHolmesUser && sherlockHolmesUser.connected
                      ? theme.palette.success.main
                      : theme.palette.primary.main
                  }
                />
              </IconButton>
            </Tooltip>

            {sherlockHolmesUser?.connected && (
              <Box>
                <Typography>
                  {sherlockHolmesUser.email} {'  '}
                  <ButtonCopyToClipboard copyValue={sherlockHolmesUser.email} />
                  {sherlockHolmesUser.connected ? ` -> ${sherlockHolmesUser.connectedCompanyEmail}` : ''}
                </Typography>
                {sherlockHolmesUser?.employeeEmail !== currentUser.email && (
                  <Box>
                    <Typography>{sherlockHolmesUser?.employeeName} esta conectado</Typography>
                  </Box>
                )}
              </Box>
            )}
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {sherlockHolmesUser?.connected ? (
                <MenuItem onClick={disconnectFromSuperUser}>Desconectar</MenuItem>
              ) : (
                <MenuItem onClick={connectToSuperUser}>Conectar</MenuItem>
              )}
            </Menu>
          </Box>

          <Box sx={{ mt: 1 }}>{isLoading ? <LinearProgress /> : <Box sx={{ height: '4px' }} />}</Box>

          <PaperStyled square>
            {/* TOOLBAR */}
            {!selectedCompany ? (
              <HintText>
                Nenhuma empresa selecionada. Digite nome, email ou telefone para pesquisar...
              </HintText>
            ) : (
              <Box>
                {selectedCompany?.otherResults?.length > 0 && (
                  <Alert severity="warning" style={{ marginBottom: '10px' }}>
                    Outros resultados:{' '}
                    {selectedCompany.otherResults.map((company, index) => (
                      <span key={`${company.id}`}>
                        <b
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setInitialValues({ phoneOrEmail: company.email });
                          }}
                        >
                          {company.email}
                        </b>
                        {company.phone && (
                          <b
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setInitialValues({ phoneOrEmail: company.phone });
                            }}
                          >
                            {' '}
                            - ({company.phone})
                          </b>
                        )}
                        {index < selectedCompany.otherResults?.length - 1 && ', '}
                      </span>
                    ))}
                  </Alert>
                )}
                <Box width={'100%'}>
                  {selectedCompany.foundByUser && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      Encontrado registro de empresa com email de usuario.
                    </Alert>
                  )}
                </Box>
                <TopMainButtons onClickButton={handleButtonClick} selectedCompany={selectedCompany} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box width={'80%'}>
                    {tempPassword && (
                      <Alert sx={{ mb: 2, width: '40%' }}>
                        <Typography>Nova Senha Temporária:</Typography>
                        <Typography variant="body2">
                          Senha: <Typography component="span">{tempPassword}</Typography>
                        </Typography>
                      </Alert>
                    )}

                    <Typography variant="h4" gutterBottom>
                      Informações Básicas
                    </Typography>
                    <Typography gutterBottom>
                      Nome:{' '}
                      <Typography color="textSecondary" component="span">
                        {selectedCompany.company.name}
                        {selectedCompany.company.locale && <>{` - ${selectedCompany.company.locale}`}</>}
                      </Typography>
                    </Typography>

                    <Typography gutterBottom>
                      Tel:{' '}
                      {selectedCompany.company.phone && (
                        <>
                          <Typography color="textSecondary" component="span">
                            {maskUtils.phone(selectedCompany.company.phone)}
                          </Typography>
                          <IconButtonStyled
                            edge="end"
                            aria-label="whatsapp"
                            onClick={() => {
                              WhatsUtils.sendMessage({
                                phone: selectedCompany.company.phone,
                                text: '',
                              });
                            }}
                          >
                            <WhatsAppIcon color="success" />
                          </IconButtonStyled>
                        </>
                      )}
                      {selectedCompany.company.phone2 && (
                        <>
                          <Typography color="textSecondary" component="span" sx={{ ml: 3 }}>
                            {maskUtils.phone(selectedCompany.company.phone2)}
                          </Typography>
                          <IconButtonStyled
                            edge="end"
                            aria-label="whatsapp"
                            onClick={() => {
                              WhatsUtils.sendMessage({
                                phone: selectedCompany.company.phone2,
                                text: '',
                              });
                            }}
                          >
                            <WhatsAppIcon color="success" />
                          </IconButtonStyled>
                        </>
                      )}
                      <IconButtonStyled
                        edge="end"
                        aria-label="Editar Fone"
                        onClick={() => {
                          setModalEditPhoneNumberOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButtonStyled>
                    </Typography>

                    <Typography gutterBottom>
                      Email:{' '}
                      <Typography color="textSecondary" component="span">
                        {selectedCompany.company.email}
                      </Typography>{' '}
                      <ButtonCopyToClipboard
                        copyValue={selectedCompany.company.email}
                        style={{ marginLeft: '5px' }}
                      />
                      <IconButtonStyled
                        edge="end"
                        aria-label="edit email"
                        onClick={() => {
                          setModalEditEmailAddressOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButtonStyled>
                    </Typography>

                    <Typography gutterBottom>
                      Área de Atuação:{' '}
                      <Typography color="textSecondary" component="span">
                        {OccupationAreas.getById(selectedCompany.company.occupationAreaId)?.name ?? '-'}
                      </Typography>{' '}
                    </Typography>
                    <Typography gutterBottom>
                      Conta criada em:{' '}
                      <Typography color="textSecondary" component="span">
                        {selectedCompany.company.createdAt
                          ? dayjs(selectedCompany.company.createdAt).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </Typography>
                    </Typography>
                    {selectedCompany.company.deleted && (
                      <Typography gutterBottom>
                        Conta deletada em:{' '}
                        <Typography color="textSecondary" component="span">
                          {selectedCompany.company.deletedAt
                            ? dayjs(selectedCompany.company.deletedAt).format('DD/MM/YYYY HH:mm')
                            : '-'}
                        </Typography>
                      </Typography>
                    )}

                    <Divider sx={{ my: 2, mr: 2 }} />
                    <Box display="flex" flexDirection="row" alignItems={'baseline'}>
                      <Typography variant="h4" gutterBottom>
                        Informações de Assinatura
                      </Typography>
                      <Button
                        sx={{ marginLeft: 2, marginBottom: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setModalCompanyAccountAuditLogModal(true);
                        }}
                      >
                        Histórico de Conta
                      </Button>
                      <Button
                        sx={{ marginLeft: 2, marginBottom: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setModalEmployeeChangesCompanySubscriptionAuditLog(true);
                        }}
                      >
                        Histórico de Assinatura
                      </Button>
                    </Box>
                    <AccountTypeInfo
                      company={selectedCompany.company}
                      onUpdateCompany={(updatedCompanyInfo) => {
                        setSelectedCompany({
                          ...selectedCompany,
                          company: updatedCompanyInfo,
                        });
                        setMaxNumberOfUsers(updatedCompanyInfo.maxNumberUsers);
                      }}
                    />
                    <Box display="flex" flexDirection="column" sx={{ mt: 2, width: '40%' }}>
                      {maxNumberOfUsers && (
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <FormControl fullWidth>
                            <InputLabel>Número de usuários ({maxNumberOfUsers})</InputLabel>
                            <Select
                              label={`Número de usuários (${maxNumberOfUsers})`}
                              value={maxNumberOfUsers}
                              onChange={onChangeMaxNumberUsers}
                              disabled={isLoading}
                            >
                              <MenuItem value={1}>Individual</MenuItem>
                              <MenuItem value={3}>Empresarial - 3 usuários</MenuItem>
                              <MenuItem value={6}>Empresarial - 6 usuários</MenuItem>
                              <MenuItem value={9}>Empresarial - 9 usuários</MenuItem>
                              <MenuItem value={12}>Empresarial - 12 usuários</MenuItem>
                              <MenuItem value={18}>Empresarial - 18 usuários</MenuItem>
                              <MenuItem value={25}>Empresarial - 25 usuários</MenuItem>
                              <MenuItem value={35}>Empresarial - 35 usuários</MenuItem>
                              <MenuItem value={50}>Empresarial - 50 usuários</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl fullWidth sx={{ ml: 2 }}>
                            <InputLabel>Usuários Cortesia ({maxNumberOfUsersCourtesy})</InputLabel>
                            <Select
                              value={maxNumberOfUsersCourtesy}
                              label={`Usuários Cortesia (${maxNumberOfUsersCourtesy})`}
                              onChange={onChangeMaxNumberUsersCourtesy}
                              disabled={isLoading}
                            >
                              <MenuItem value={0}>Nenhum (0)</MenuItem>
                              <MenuItem value={1}>
                                <Typography sx={{ color: 'green', fontWeight: 'bold' }} component="span">
                                  1 usuário
                                </Typography>
                              </MenuItem>
                              <MenuItem value={2}>
                                <Typography sx={{ color: 'green', fontWeight: 'bold' }} component="span">
                                  2 usuários
                                </Typography>
                              </MenuItem>
                              <MenuItem value={3}>
                                <Typography sx={{ color: 'green', fontWeight: 'bold' }} component="span">
                                  3 usuários
                                </Typography>
                              </MenuItem>
                            </Select>
                          </FormControl>

                          {/* <IconButton
                          edge="end"
                          onClick={() => {
                            const answer = window.confirm(
                              'Você tem certeza que deseja gerar o link de UPGRADE? Ele dura 48hs e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.',
                            );

                            if (answer) {
                              setIsLoading(true);
                              // SuperUserService.createTempAccessForCompany(selectedCompany.company.id)
                              //   .then(companyTempAccessCode => {
                              //     if (companyTempAccessCode) {
                              //       const link = `https://portal.minhaagendaapp.com.br/upgrade?code=${companyTempAccessCode.code}`;
                              //       copy(link);
                              //       showNotification('success', 'Link copiado com sucesso!');
                              //     } else {
                              //       showNotification('error', 'Não foi possivel gerar link!');
                              //     }
                              //   })
                              //   .finally(() => {
                              //     setIsLoading(false);
                              //   });
                            }
                          }}
                          color="secondary"
                        >
                          <FontAwesomeIcon color="#07e307" icon="crown" />
                        </IconButton> */}
                        </Box>
                      )}
                      <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setUpdateDiscountModalOpen(true);
                        }}
                      >
                        Aplicar desconto
                      </Button>
                      {selectedCompany?.stripeDiscountForCompany?.stripeDiscount && (
                        <Alert severity="warning" sx={{ mb: 1.2 }}>
                          Desconto aplicado atualmente:{' '}
                          <b>
                            {selectedCompany?.stripeDiscountForCompany?.stripeDiscount?.internalDescription}
                          </b>
                        </Alert>
                      )}
                    </Box>

                    <Divider sx={{ my: 2, mr: 2 }} />
                    <div id="additionalDetails">
                      <Typography variant="h4" gutterBottom>
                        Detalhes Adicionais
                      </Typography>
                      <div id="createdAt" style={{ display: 'none' }}>
                        <Typography gutterBottom>
                          Conta criada em:{' '}
                          <Typography color="textSecondary" component="span">
                            {selectedCompany.company.createdAt
                              ? dayjs(selectedCompany.company.createdAt).format('DD/MM/YYYY HH:mm')
                              : '-'}
                          </Typography>
                        </Typography>
                      </div>

                      <Typography gutterBottom>
                        Número de Atendimentos:{' '}
                        {appointmentCount == null ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleCountAppointmentByCompanyId();
                            }}
                            edge="end"
                            sx={{ p: '0px' }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        ) : (
                          <Typography color="textSecondary" component="span">
                            {appointmentCount}
                          </Typography>
                        )}
                      </Typography>

                      <Typography gutterBottom>
                        Top Serviços:{' '}
                        {topServices == null ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleTopServicesByCompanyId();
                            }}
                            edge="end"
                            sx={{ p: '0px' }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        ) : (
                          <Typography color="textSecondary" component="span">
                            {topServices.length > 0
                              ? topServices
                                  .map(
                                    (servicePackage) =>
                                      `${servicePackage.name} (${NumberUtils.toCurrency(
                                        servicePackage.price,
                                      )})`,
                                  )
                                  .join(', ')
                              : 'Nenhum'}
                          </Typography>
                        )}
                      </Typography>

                      <Typography gutterBottom>
                        Top Pacotes:{' '}
                        {topServicePackages == null ? (
                          <IconButton
                            onClick={() => {
                              handleTopServicePackagesByCompanyId();
                            }}
                            edge="end"
                            sx={{ p: '0px' }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        ) : (
                          <Typography color="textSecondary" component="span">
                            {topServicePackages.length > 0
                              ? topServicePackages
                                  .map(
                                    (servicePackage) =>
                                      `${servicePackage.name} (${NumberUtils.toCurrency(
                                        servicePackage.price,
                                      )})`,
                                  )
                                  .join(', ')
                              : 'Nenhum'}
                          </Typography>
                        )}
                      </Typography>

                      <Typography gutterBottom>
                        Taxas de cartão de crédito:{' '}
                        <IconButton
                          onClick={() => {
                            setCreditCardSettingsModalOpen(true);
                          }}
                          edge="end"
                          sx={{ p: '0px' }}
                        >
                          <VisibilityOff />
                        </IconButton>
                      </Typography>

                      <Typography gutterBottom>
                        Taxa de cartão de débito:{' '}
                        {debitCardRate == null ? (
                          <IconButton
                            onClick={() => {
                              handleDebitCardRateByCompanyId();
                            }}
                            edge="end"
                            sx={{ p: '0px' }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        ) : (
                          <Typography color="textSecondary" component="span">
                            {NumberUtils.toDecimals(debitCardRate, 2)} %
                          </Typography>
                        )}
                      </Typography>

                      <Typography gutterBottom>
                        Data do Ultimo Atendimento criado:{' '}
                        <Typography color="textSecondary" component="span">
                          {selectedCompany.lastAppointmentCreation
                            ? dayjs(selectedCompany.lastAppointmentCreation).format('DD/MM/YYYY HH:mm')
                            : '-'}
                        </Typography>
                      </Typography>

                      {selectedCompany && selectedCompany.company && (
                        <Box display="flex" flexDirection="column">
                          <div style={{ marginRight: '0.5rem' }}>
                            <Typography gutterBottom>
                              Permitir múltiplos agendamentos por hora:{' '}
                              <Typography color="textSecondary" component="span">
                                {selectedCompany.company.allowMultipleAppointmentsPerHour ? 'Sim' : 'Não'}
                              </Typography>
                            </Typography>
                          </div>
                          <div style={{ marginRight: '0.5rem' }}>
                            <Typography gutterBottom>
                              Permitir Agendamentos Sobrepostos:{' '}
                              <Typography color="textSecondary" component="span">
                                {selectedCompany.company.allowOverlappingAppointments ? 'Sim' : 'Não'}
                              </Typography>
                            </Typography>
                          </div>
                          <div style={{ marginRight: '0.5rem' }}>
                            <Typography gutterBottom>
                              Cobranças automáticas de agendamento:{' '}
                              <Typography color="textSecondary" component="span">
                                {selectedCompany.company.automaticAppointmentCharges ? 'Sim' : 'Não'}
                              </Typography>
                            </Typography>
                          </div>
                        </Box>
                      )}
                    </div>
                  </Box>

                  <Box width={'20%'}>
                    <MainButtons onClickButton={handleButtonClick} selectedCompany={selectedCompany} />
                  </Box>
                </Box>
              </Box>
            )}
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
}

export default ManageCompanyPage;
