import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// COMPONENT
function ExportAppointmentsIntenalFilterByEmployee({ selectedEmployee, setSelectedEmployee, employees }) {
  const handleClearClick = () => {
    setSelectedEmployee(0);
  };

  return (
    <Box marginTop={2} display="flex" flexDirection="row" alignItems="center" width={"100%"}>
      <FormControl fullWidth >
        <InputLabel>Filtrar por Profissional</InputLabel>
        <Select
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
          }}
          label="Filtrar por Profissional"
          endAdornment={
            selectedEmployee !== 0 && (
              <IconButton onClick={handleClearClick} sx={{ mr: 1 }}>
                <FontAwesomeIcon icon="times" />
              </IconButton>
            )
          }
        >
           <MenuItem value={0} key={0}>
              Todos profissionais
            </MenuItem>
          {employees?.map((employee) => (
            <MenuItem value={employee.id} key={employee.id}>
              {employee?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ExportAppointmentsIntenalFilterByEmployee;
