import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const exportToCsv = async (results, fileName) => {
  const fileType = 'text/csv;charset=UTF-8';
  const fileExtension = '.csv';

  const ws = XLSX.utils.json_to_sheet(results);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

export default exportToCsv;
