import React, { useState } from 'react';
import { Typography, Grid, LinearProgress, Tabs, Tab, Divider } from '@mui/material';
import yup from '../../commons/validators/customYup';
import { currentUserSelector, useAppStore, setCurrentUser } from '../../stores/appStore';
import ProfileService from '../../services/ProfileService';
import showNotification from '../../commons/helpers/showNotification';
import { Box } from '@mui/system';
import ProfileGeneralPage from './ProfileGeneralPage';
import ProfileSecurityPage from './ProfileSecurityPage';
import ProfileToolsPage from './ProfileToolsPage';

const schema = yup.object().shape({
  name: yup.string().min(2).max(50).required(),
});

function ProfileEditPage() {
  const currentUser = useAppStore(currentUserSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, tab) => {
    setValue(tab);
  };

  const initialValues = {
    name: currentUser.name,
    email: currentUser.email,
  };

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    try {
      await ProfileService.update(values);
      setCurrentUser({
        ...currentUser,
        name: values.name,
      });

      showNotification('success', 'Perfil atualizado com sucesso');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box sx={{ mt: 1 }}>
            {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
          </Box>
          <Typography variant="overline">Perfil</Typography>

          <Box sx={{ width: '100%' }}>
            <Tabs onChange={handleChange} value={value}>
              <Tab color="primary" label="Geral" />
              <Tab color="primary" label="Segurança" />
              <Tab color="primary" label="Ferramentas" />
            </Tabs>
            <Divider />
            {value === 0 && (
              <Grid item sm={6}>
                <ProfileGeneralPage
                  initialValues={initialValues}
                  schema={schema}
                  handleSubmit={handleSubmit}
                />
              </Grid>
            )}
            {value === 1 && (
              <Grid item sm={6}>
                <ProfileSecurityPage />
              </Grid>
            )}
            {value === 2 && (
              <Grid item sm={6}>
                <ProfileToolsPage />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileEditPage;
