/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { RenderTextField } from './FormTextField';
import { Field } from 'formik';

const NumberFormatCustom = forwardRef(({ onChange, name, decimalScale, prefix, ...other }, ref) => {
  return (
    <NumberFormat
      name={name}
      getInputRef={ref}
      {...other}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={decimalScale ?? 2}
      prefix={prefix != null ? prefix : 'R$'}
      fixedDecimalScale
      isNumericString
    />
  );
});

export const FormNumberFormatField = ({
  name,
  type,
  placeholder,
  inputProps,
  decimalScale,
  prefix,
  suffix,
  ...others
}) => {
  const componentInputProps = {
    ...inputProps,
    type,
    placeholder,
    inputComponent: NumberFormatCustom,
    inputProps: { decimalScale, prefix, suffix },
  };

  return <Field name={name} component={RenderTextField} inputProps={componentInputProps} {...others} />;
};

export const FormCurrencyField = ({ decimalScale, suffix, ...rest }) => {
  return <FormNumberFormatField {...rest} decimalScale={decimalScale ?? 0} suffix={suffix} />;
};
