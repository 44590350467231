/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Field, getIn } from 'formik';
import { Divider } from '@mui/material';

export const RenderRadioGroup = (props) => {
  const {
    field,
    onChange,
    form: { touched, errors },
    label,
    dataSource,
    labelStyle,
    divider,
    row,
  } = props;

  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <FormControl error={touch && error != null} component="fieldset">
      <FormLabel component="legend" style={labelStyle}>
        {label}
      </FormLabel>
      <RadioGroup
        name={field.name}
        value={field.value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(e);
        }}
        row={row}
      >
        {dataSource.map((d) => (
          <React.Fragment key={d.id}>
            <FormControlLabel value={`${d.id}`} control={<Radio color="primary" />} label={d.name} />
            {divider && <Divider />}
          </React.Fragment>
        ))}
      </RadioGroup>
      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export const FormRadioGroup = ({ name, dataSource, ...others }) => (
  <Field name={name} component={RenderRadioGroup} dataSource={dataSource} {...others} />
);
