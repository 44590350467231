import { forwardRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Field } from 'formik';

export const RenderCheckbox = forwardRef((props, ref) => {
  const {
    field,
    label,
    ...others
  } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={field.value}
          value={field.name}
          name={field.name}
          onChange={field.onChange}
          color="primary"
          ref={ref}
        />
      }
      label={label}
      {...others}
    />
  );
});
export const FormCheckbox = ({ name, ...others }) => (
  <Field name={name} component={RenderCheckbox} {...others} />
);
