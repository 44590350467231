import dayjs from 'dayjs';

export const ACCOUNT_PAID = 'PAID';
export const ACCOUNT_TRIAL = 'TRIAL';
export const ACCOUNT_FREE = 'FREE';

export const isNotPremium = (currentUser) => {
  if (currentUser && currentUser.principal && currentUser.principal.accountType) {
    const accountType = currentUser.principal.accountType;

    // valid TRIAL
    if (accountType === ACCOUNT_TRIAL) {
      const trialExpirationDate = currentUser.principal.trialExpirationDate
        ? dayjs(currentUser.principal.trialExpirationDate, 'YYYY-MM-DD')
        : null;
      return !trialExpirationDate || dayjs().isAfter(trialExpirationDate, 'day');
    }
    return accountType !== ACCOUNT_PAID;
  }
  return true;
};

export const getTrialTime = (currentUser) => {
  if (currentUser && currentUser.principal.accountType === ACCOUNT_TRIAL) {
    const trialExpirationDate = currentUser.principal.trialExpirationDate
      ? dayjs(currentUser.principal.trialExpirationDate, 'YYYY-MM-DD')
      : null;
    return trialExpirationDate != null && dayjs().isSameOrBefore(trialExpirationDate, 'day')
      ? trialExpirationDate
      : null;
  }
  return null;
};

export const getTrialRemainingDays = (currentUser) => {
  const trialTime = getTrialTime(currentUser);
  if (trialTime == null) {
    return null;
  }
  return Math.ceil(dayjs(trialTime).diff(dayjs(), 'days', true));
};

export const isPremium = (currentUser) => {
  return (
    currentUser?.principal?.accountType === ACCOUNT_PAID &&
    currentUser?.principal?.paidGracePeriodEndDate == null
  );
};

export const isAccountTypePaid = (currentUser) => {
  return currentUser?.principal?.accountType === ACCOUNT_PAID;
};

export const isPremiumWithGrace = (currentUser) => {
  return (
    currentUser?.principal?.accountType === ACCOUNT_PAID &&
    currentUser?.principal?.paidGracePeriodEndDate != null
  );
};

export const isUserTrial = (currentUser) => {
  return currentUser?.principal?.accountType === ACCOUNT_TRIAL;
};

export const BOLETO_EXPIRING = 'BOLETO_EXPIRING';
export const BOLETO_EXPIRED = 'BOLETO_EXPIRED';

export const getPremiumBoletoExpiringInfo = (currentUser) => {
  if (
    currentUser?.principal?.accountType === ACCOUNT_PAID &&
    currentUser?.principal?.paymentBoleto &&
    currentUser?.principal?.lastPaidAt
  ) {
    const daysSinceLastPayment = Math.ceil(dayjs().diff(currentUser.principal.lastPaidAt, 'days', true));

    if (daysSinceLastPayment > 25) {
      const lastPaidDate = dayjs(currentUser.principal.lastPaidAt);
      const nextPaymentDate = dayjs(currentUser.principal.lastPaidAt).add(1, 'month');
      // faltam 6 dias pra encerrar
      const obj = {
        status: dayjs().isAfter(nextPaymentDate, 'date') ? BOLETO_EXPIRED : BOLETO_EXPIRING,
        daysSinceLastPayment,
        lastPaidDate,
        nextPaymentDate,
      };
      return obj;
    }
  }
  return null;
};

export const getBoletoExpiringInfo = (lastPaidAt) => {
  if (lastPaidAt) {
    const momentNow = dayjs().startOf('day');

    const lastPaidDateMoment = dayjs(lastPaidAt).startOf('day');
    const nextPaymentDateMoment = dayjs(lastPaidDateMoment).add(1, 'month');

    const daysUntilNextPayment = nextPaymentDateMoment.diff(momentNow, 'days');

    // faltam 4-5 dias pra encerrar
    const obj = {
      isPaymentDue: daysUntilNextPayment <= 4,
      status: momentNow.isAfter(nextPaymentDateMoment, 'date') ? BOLETO_EXPIRED : BOLETO_EXPIRING,
      lastPaidDate: lastPaidDateMoment,
      nextPaymentDate: nextPaymentDateMoment,
      daysUntilNextPayment,
    };
    return obj;
  }
  return null;
};
