import React from 'react';
import { FormTextFieldWithMask } from './FormTextFieldWithMask';

const MASK = [{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }];
const DEFINITIONS = {
  '#': /[1-9]/,
};

export function FormPhoneField({ ...others }) {
  return <FormTextFieldWithMask mask={MASK} definitions={DEFINITIONS} {...others} />;
}
