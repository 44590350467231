import { Box, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FormSelect } from '../../../components/forms/formik/FormSelect';
import ExampleQuestionCategoryService from '../../../services/ExampleQuestionCategoryService';

// COMPONENT
function ExampleQuestionCategoryFormSelect(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    ExampleQuestionCategoryService.findAll()
      .then((resp) => {
        setDataSource(resp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormSelect
        fullWidth
        label="Categoria"
        name="exampleQuestionCategoryId"
        placeholder="Selecione uma categoria..."
        dataSource={dataSource}
        {...props}
      />
      {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
    </Box>
  );
}

export default ExampleQuestionCategoryFormSelect;
