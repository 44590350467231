import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { getAppointmentTagLabelById } from '../../../commons/enums/appointmentTags';
import exportToExcel from '../../../commons/helpers/exportToExcel';
import showNotification from '../../../commons/helpers/showNotification';
import yup from '../../../commons/validators/customYup';
import { FormBooleanRadioGroup, FormDateField, FormRadioGroup } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';
import ExportAppointmentsIntenalFilterByClient from './ExportAppointmentsIntenalFilterByClient';
import ExportAppointmentsIntenalFilterByEmployee from './ExportAppointmentsIntenalFilterByEmployee';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .max(yup.ref('endDate'), 'Data Inicio não pode ser maior que Data Fim.')
    .required(),
  endDate: yup.date().nullable().required(),
});

const EXCLUDE_DELETED = 'EXCLUDE_DELETED';
const INCLUDE_DELETED = 'INCLUDE_DELETED';
const ALL_RECORDS = 'ALL_RECORDS';
const DELETED_RECORDS = { EXCLUDE_DELETED, INCLUDE_DELETED, ALL_RECORDS };

const DEFAULT_INIT_VALUES = {
  startDate: null,
  endDate: null,
  byAppointmentDate: true,
  deletedRecords: DELETED_RECORDS.ALL_RECORDS,
  displayContainsComandaColumn: false,
};

// COMPONENT
function ExportAppointmentsInternalModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [employees, setEmployees] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      setInitialValues({
        ...DEFAULT_INIT_VALUES,
        timestamp: new Date().getTime(),
      });
      setSelectedEmployee(0);
      setSelectedClient(0);
      SuperUserService.getCompanyEmployees(selectedCompany.id)
        .then((employee) => {
          setEmployees(employee);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = async (values, actions) => {
    try {
      const deletedRecords = values.deletedRecords === DELETED_RECORDS.INCLUDE_DELETED ? true : false;
      const allRecords = values.deletedRecords === DELETED_RECORDS.ALL_RECORDS ? true : false;
      const respAppointments = await SuperUserService.getAppointmentReports({
        companyId: selectedCompany.id,
        employeeId: selectedEmployee > 0 ? selectedEmployee : null,
        clientId: selectedClient ? selectedClient.id : null,
        startDate: values.startDate.format('YYYY-MM-DD'),
        endDate: values.endDate.format('YYYY-MM-DD'),
        byActivityDate: !values.byAppointmentDate,
        deleted: deletedRecords,
        allRecords: allRecords
      });

      if (respAppointments.length === 0) {
        showNotification('warning', 'Nenhum resultado encontrado.');
        actions.setSubmitting(false);
        return;
      }

      const preparedDataSource = respAppointments.map((object) => {
        const baseObj = {
          Profissional: object.professionalName,
          Data: dayjs(object.date).format('ddd, DD/MM/YYYY'),
          Horário: `${object.startTime} às ${object.endTime}`,
          Cliente: object.customerName,
          'Serviço(s)': object.serviceName,
          // Situação: object.tag != null ? AppointmentTagsEnum.getById(object.tag).name : '-',
          Situação: object.tag != null ? getAppointmentTagLabelById(object.tag) : '-',
          Observação: object.comments ?? '',

          'Data Criação': object.createdAt ? dayjs(object.createdAt).format('L LT') : '',
          'Criado por': object.createdByName ?? '',
          'Data Atualização':
            object.updatedAt == null || object.createdAt === object.updatedAt
              ? ''
              : dayjs(object.updatedAt).format('L LT'),
          'Atualizado por':
            object.updatedAt == null || object.createdAt === object.updatedAt
              ? ''
              : object.updatedByName ?? '',
        };

        // include deleted by info
        if (deletedRecords === true || allRecords === true) {
          baseObj['Data Deleção'] = object.deletedAt ? dayjs(object.deletedAt).format('L LT') : '';
          baseObj['Deletado por'] = object.deletedByName ?? '';
        }
        // include activity date
        if (!values.byAppointmentDate) {
          baseObj['Data e hora da Atividade'] = object.activityDateTime
            ? dayjs(object.activityDateTime).format('L LT')
            : '';
        }
        if (values.displayContainsComandaColumn) {
          baseObj['Possui Comanda?'] = object.comandaId != null && object.comandaId > 0 ? 'Sim' : 'Não';
        }
        return baseObj;
      });

      const fileName = `RelatorioAgendamentos_${selectedCompany.name}_De${values.startDate.format(
        'DD-MM-YYYY',
      )}Ate${values.endDate.format('DD-MM-YYYY')}_Gerado${dayjs().format('DD-MM-YY HH:mm:ss')}`;
      exportToExcel(preparedDataSource, fileName);
      showNotification('success', 'Relatório gerado com sucesso.');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
      <DialogTitle variant="h5">Exportar Agendamentos - {selectedCompany.name}</DialogTitle>

      <Box sx={{ mt: 1 }}>
        {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
      </Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <FormBooleanRadioGroup
                labelFalse="Por Data de Atividade"
                labelTrue="Por Data de Agendamento"
                name="byAppointmentDate"
                row
              />
              <Box
                sx={{ mt: 2 }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormDateField label="Data Inicio" name="startDate" />
                <FormDateField label="Data Fim" name="endDate" />
              </Box>
              <Box
                sx={{ mt: 2 }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* <FormBooleanRadioGroup
                  labelTrue="Excluir Deletados"
                  labelFalse="Apenas Deletados"
                  name="excludeDeleted"
                  row
                /> */}
                <FormRadioGroup
                  name="deletedRecords"
                  dataSource={[
                    {
                      id: DELETED_RECORDS.EXCLUDE_DELETED,
                      name: 'Excluir Deletados',
                    },
                    {
                      id: DELETED_RECORDS.INCLUDE_DELETED,
                      name: 'Apenas Deletados',
                    },
                    {
                      id: DELETED_RECORDS.ALL_RECORDS,
                      name: 'Todos',
                    },
                  ]}
                  row
                />
              </Box>
              <ExportAppointmentsIntenalFilterByEmployee
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
                employees={employees}
              />
              <ExportAppointmentsIntenalFilterByClient
                companyId={selectedCompany.id}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
              />
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isLoading}>
                Exportar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ExportAppointmentsInternalModal;
