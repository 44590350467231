import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import showNotification from '../../commons/helpers/showNotification';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import FeatureService from '../../services/FeatureService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function FeaturePreviewModal({ featureForm, open, onClose, handleEdit, onUpdateCompanyCount }) {
  const [featureFormCompaniesReceived, setFeatureFormCompaniesReceived] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false, targetObject: null });

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      FeatureService.getCompanyEmailsById(featureForm.id)
        .then((resp) => {
          setFeatureFormCompaniesReceived(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, featureForm]);

  const handleDeleteConfirmation = async () => {
    setIsDeleting(true);
    try {
      await FeatureService.deleteCompanyEmails({
        featureRequestId: featureForm.id,
        companyEmailId: modalConfirmDelete.targetObject.id,
      });
      const newDataSource = featureFormCompaniesReceived.filter(
        (data) => data.id !== modalConfirmDelete.targetObject.id,
      );
      setFeatureFormCompaniesReceived(newDataSource);
      onUpdateCompanyCount(newDataSource.length, featureForm);
      showNotification('success', 'Deletado com sucesso');
    } finally {
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setIsDeleting(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

      <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
        {featureFormCompaniesReceived && (
          <DialogTitle>
            <Typography component="span" variant="h5">
              {featureForm && featureForm.name}
            </Typography>
          </DialogTitle>
        )}

        {!isLoading && featureForm && (
          <DialogContent dividers>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</Box>

              <Table>
                {featureForm.countCompanies > 0 ? (
                  <TableHead>
                    <TableRow key={`requesters`}>
                      <TableCell size="small">
                        <Typography variant="h5">Solicitantes</Typography>
                      </TableCell>
                      <TableCell size="small">
                        <Typography variant="h5">Criado em</Typography>
                      </TableCell>
                      <TableCell size="small">
                        <Typography variant="h5">Criado por</Typography>
                      </TableCell>
                      <TableCell size="small">
                        <Typography variant="h5">Deletar</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableRow key={`requesters`}>
                    <TableCell size="small" sx={{ borderBottom: 'none' }}>
                      <Typography variant="h5">Nenhum solicitante</Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableBody>
                  {featureFormCompaniesReceived?.map((featureCompanyEmail, i) => {
                    return (
                      <TableRow key={featureCompanyEmail.id}>
                        <TableCell size="small">{featureCompanyEmail.companyEmail}</TableCell>
                        <TableCell size="small">
                          {dayjs(featureCompanyEmail.createdAt).format('DD/MM/YY HH:mm')}
                        </TableCell>
                        <TableCell size="small">{featureCompanyEmail.employeeName}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setModalConfirmDelete({ open: true, targetObject: featureCompanyEmail });
                            }}
                            style={{ marginLeft: '10px' }}
                            disabled={isDeleting}
                          >
                            <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                            {isDeleting && <CircularProgress size={15} />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </DialogContent>
        )}
        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Box>
            <Button
              color="primary"
              onClick={() => handleEdit(featureForm)}
              disabled={isLoading || isDeleting}
              variant="contained"
            >
              Editar
            </Button>
          </Box>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default FeaturePreviewModal;
