const values = [
  {
    id: 'ANY',
    name: 'Qualquer',
  },
  {
    id: 'MASTER_CARD',
    name: 'MasterCard',
  },
  {
    id: 'VISA',
    name: 'Visa',
  },
  {
    id: 'ELO',
    name: 'Elo',
  },
  {
    id: 'HYPER_CARD',
    name: 'HiperCard',
  },
  {
    id: 'AMERICAN_EXPRESS',
    name: 'American Express',
  },
];

const ENUM_VALUES_MAP = values.reduce((obj, item) => {
  obj[item.id] = item;
  return obj;
}, {});

const CreditCardFlagOptionsEnum = {
  ...values.reduce((obj, item) => {
    obj[item.id] = item.id;
    return obj;
  }, {}),

  getById(id) {
    return ENUM_VALUES_MAP[id];
  },
  getValues() {
    return values;
  },
};

export default CreditCardFlagOptionsEnum;
