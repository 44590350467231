import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Button, ListItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';

const FontAwesomeIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  paddingLeft: 1,
  paddingRight: 2,
}));

//
function NavItem({ href, iconName, title, titleStyle, ...rest }) {
  const location = useLocation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: true,
        },
        location.pathname,
      )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main',
          }),
          '& svg': {
            mr: 1,
          },
        }}
        to={href}
      >
        {iconName && <FontAwesomeIconStyled icon={iconName} size="lg" fixedWidth />}
        <span style={titleStyle}>{title}</span>
      </Button>
    </ListItem>
  );
}

export default NavItem;
