import HttpService from './commons/HttpService';

const AnamneseService = {
  getAnamneseForms(companyId) {
    return HttpService.get(`/anamneses/forms`, { params: { companyId } });
  },

  getById(id) {
    return HttpService.get(`/anamneses/${id}`);
  },

  getByIdToClone(id) {
    return HttpService.get(`/anamneses/${id}/cloned`);
  },

  create(anamneseForm) {
    return HttpService.post(`/anamneses`, anamneseForm);
  },

  update(anamneseForm) {
    return HttpService.put(`/anamneses/${anamneseForm.id}`, anamneseForm);
  },

  save(anamneseForm) {
    if (anamneseForm.id && anamneseForm.id > 0) {
      return this.update(anamneseForm);
    }
    return this.create(anamneseForm);
  },

  delete(anamneseForm) {
    return HttpService.delete(`/anamneses/${anamneseForm.id}`);
  },

  updateEnabled(anamneseFormId, enabled) {
    if (enabled) {
      return HttpService.put(`/anamneses/${anamneseFormId}/enabled`);
    } else {
      return HttpService.put(`/anamneses/${anamneseFormId}/disabled`);
    }
  },

  reorderForms(anamneseForms) {
    return HttpService.put(`/anamneses/reorder`, anamneseForms);
  },
};

export default AnamneseService;
