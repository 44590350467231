import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import NumberUtils from '../../../commons/utils/numberUtils';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import HintText from '../../../components/labels/HintText';
import TableCellAction from '../../../components/table/TableCellAction';
import { getStatusTypeColor, status } from './asaasHelpers';

function AsaasSubscriptionHistoryTable({ asaasSubscription, handleEdit, handleDelete, isLoading }) {
  return (
    <>
      {isLoading ? (
        <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        <>
          {asaasSubscription?.length > 0 ? (
            <>
              <Typography
                component="span"
                variant="h5"
                sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
              >
                Assinatura
              </Typography>
              <Divider />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Próximo vencimento</TableCell>
                    <TableCell>Editar</TableCell>
                    <TableCell>Deletar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {asaasSubscription?.map((subscription, i) => {
                    return (
                      <TableRow key={subscription.id}>
                        <TableCell>{subscription.description}</TableCell>
                        <TableCell>{subscription.billingType}</TableCell>
                        <TableCell>{NumberUtils.toCurrency(subscription.value, 2)}</TableCell>
                        <TableCell>
                          <Chip
                            color={getStatusTypeColor(subscription.status)}
                            label={status[subscription.status]}
                          />
                        </TableCell>
                        <TableCell>{dayjs(subscription.nextDueDate).format('DD/MM/YY')}</TableCell>
                        <TableCellAction
                          title="Editar fatura"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleEdit(subscription);
                          }}
                        >
                          <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                        </TableCellAction>
                        <TableCellAction
                          title="Deletar Fatura"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDelete(subscription);
                          }}
                        >
                          <FontAwesomeIconStatus icon="trash" status="error" size="sm" />
                        </TableCellAction>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          ) : (
            <>
              <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
                <HintText>Nenhum registro de assinatura localizado.</HintText>
              </Box>
              <Divider />
            </>
          )}
        </>
      )}
    </>
  );
}
export default AsaasSubscriptionHistoryTable;
