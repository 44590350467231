/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import CustomFormControl from '../controls/CustomFormControl';

import { Field, getIn } from 'formik';

export const RenderFormSelect = ({
  field,
  form: { touched, errors },
  label,
  tooltip,
  children,
  dataSource,
  onChange,
  selectProps,
  ...others
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  const myChildren =
    dataSource && dataSource.length > 0
      ? dataSource.map((o) => {
          return (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          );
        })
      : children;
  return (
    <CustomFormControl error={touch && error != null} variant="standard" {...others}>
      {tooltip && (
        <Tooltip title={tooltip}>
          <InputLabel shrink>
            {label} <InfoOutlinedIcon style={{ verticalAlign: 'middle' }} color="action" />
          </InputLabel>
        </Tooltip>
      )}
      {!tooltip && <InputLabel shrink>{label}</InputLabel>}
      <Select
        placeholder={label}
        value={field.value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(e);
        }}
        children={myChildren}
        name={field.name}
        {...others}
        {...selectProps}
      />
      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </CustomFormControl>
  );
};

export const FormSelect = ({ name, dataSource, ...others }) => (
  <Field name={name} component={RenderFormSelect} dataSource={dataSource} {...others} />
);
