import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button, Card,
  CardActions,
  CardContent,
  Divider,
  Grid, IconButton, InputAdornment, Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import showNotification from '../../commons/helpers/showNotification';
import yup from '../../commons/validators/customYup';
import { FormTextField } from '../../components/forms/FormikFields';
import AuthService from '../../services/AuthService';

const schema = yup.object().shape({
  currentPassword: yup.string().min(8, 'O comprimento minimo é 8.').required(),
  newPassword: yup
    .string()
    .min(8, 'O comprimento minimo é 8.')
    .notOneOf([yup.ref('oldPassword'), null], 'A nova senha não pode ser igual a senha antiga.')
    .required(),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'As senhas devem ser iguais.')
    .required(),
});

// COMPONENT
function ProfileSecurityPage() {
  const [values, setValues] = React.useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showRepeatPassword: false,
  });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await AuthService.updatePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      showNotification('success', 'Senha atualizada com successo.');
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  const handleClickShowPassword = (field) => {
    setValues({
      ...values,
      ...field,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{ password: '' }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item sm={4} display={'flex'} alignItems={'center'}>
                  <Typography variant="overline">Mudar Senha</Typography>
                </Grid>
                <Grid item sm={8}>
                  <FormTextField
                    label="Senha Atual"
                    name="currentPassword"
                    fullWidth
                    type={values.showCurrentPassword ? 'text' : 'password'}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleClickShowPassword({ showCurrentPassword: !values.showCurrentPassword });
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete:"current-password"
                    }}
                  />
                  <FormTextField
                    label="Nova Senha"
                    name="newPassword"
                    fullWidth
                    type={values.showNewPassword ? 'text' : 'password'}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleClickShowPassword({ showNewPassword: !values.showNewPassword });
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete:"new-password"
                    }}
                  />
                  <FormTextField
                    label="Confirme a Senha"
                    name="repeatPassword"
                    fullWidth
                    type={values.showRepeatPassword ? 'text' : 'password'}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleClickShowPassword({ showRepeatPassword: !values.showRepeatPassword });
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete:"new-password"
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: 'end' }}>       
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                Atualizar
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default ProfileSecurityPage;
