import React from 'react';

import { Box, Card, CardContent, CardHeader, Chip, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import MaskUtils from '../../commons/utils/maskUtils';
import {
  ACC_STATUS_PAID,
  ACC_STATUS_PAID_GRACE_PERIOD,
  ACC_STATUS_TRIAL_ACTIVE,
  ACC_STATUS_TRIAL_EXPIRED_OR_FREE,
  getAccountStatusDescription,
  getAccountStatusName,
} from '../../commons/helpers/accountHelpers';
import showNotification from '../../commons/helpers/showNotification';

const NoResultsLabel = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  fontSize: '14px',
  color: '#939393',
}));

function selectChipStyledThemeColor(statusName) {
  switch (statusName) {
    case ACC_STATUS_PAID:
      return 'success';
    case ACC_STATUS_TRIAL_ACTIVE:
      return 'info';
    case ACC_STATUS_TRIAL_EXPIRED_OR_FREE:
      return 'error';
    case ACC_STATUS_PAID_GRACE_PERIOD:
      return 'warning';
    default:
      return showNotification('error', 'Opção inválida.');
  }
}

const ChipStyled = styled(Chip)(({ theme, accountstatus }) => {
  const themeColor = theme.palette[selectChipStyledThemeColor(accountstatus)];
  return {
    color: themeColor.contrastText,
    backgroundColor: themeColor.main,
    '&:hover': {
      backgroundColor: themeColor.dark,
    },
    '&:focus': {
      backgroundColor: themeColor.dark,
    },
  };
});

// COMPONENT
function TransferSubscriptionCompanyCard({ company, title }) {
  const accountStatusName = company != null ? getAccountStatusName(company) : null;
  const accountStatusDescription = company != null ? getAccountStatusDescription(company) : null;

  return (
    <Card sx={{ minWidth: 400 }}>
      <CardHeader
        title={`${title}${company?.deleted ? ' - DELETADA' : ''}`}
        titleTypographyProps={company?.deleted ? { color: 'error' } : null}
      />
      <Divider />
      <CardContent>
        {company != null ? (
          <>
            <Typography gutterBottom>
              Nome:{' '}
              <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                {company.name} - {company.locale ?? 'PT-BR'}
              </Typography>
            </Typography>

            <Typography gutterBottom>
              Tel:{' '}
              <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                {MaskUtils.phone(company.phone)}
              </Typography>
              {company.phone2 && (
                <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                  {' - '}
                  {MaskUtils.phone(company.phone2)}
                </Typography>
              )}
            </Typography>

            <Typography gutterBottom>
              Email:{' '}
              <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                {company.email}
              </Typography>
            </Typography>

            <Typography gutterBottom>
              Criado em:{' '}
              <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                {dayjs(company.createdAt).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Typography>

            <Typography gutterBottom>
              Numero de Usuários:{' '}
              <Typography color={company.deleted ? 'error' : 'textSecondary'} component="span">
                {company.maxNumberUsers}
              </Typography>
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography component="div" sx={{ mr: 1 }}>
                Tipo de Conta:
              </Typography>
              <ChipStyled
                accountstatus={accountStatusName}
                label={accountStatusDescription}
                color="primary"
                icon={
                  accountStatusName === ACC_STATUS_PAID || accountStatusName === ACC_STATUS_TRIAL_ACTIVE ? (
                    <CheckCircleIcon />
                  ) : (
                    <WarningIcon />
                  )
                }
              />

              {company.subscriptionType && (
                <Chip label={company.subscriptionType} sx={{ ml: 1 }} color="primary" />
              )}
            </Box>
          </>
        ) : (
          <>
            <NoResultsLabel variant="subtitle1" gutterBottom>
              Nenhum resultado encontrado.
            </NoResultsLabel>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default TransferSubscriptionCompanyCard;
