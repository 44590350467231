import numeral from 'numeral';

const selectedLocaleSettings = { localCode: 'pt-br', currencyFormat: '$ 0,0.00' };

const BASE_DECIMAL_FORMAT = '0,0';
const NumberUtils = {
  toCurrency: (value) => {
    return numeral(value).format(selectedLocaleSettings.currencyFormat);
  },
  toDecimals: (value, fractionDigits = 0) => {
    let fractionPart = '';
    if (fractionDigits && fractionDigits > 0) {
      const fractionZeroes = '0'.padEnd(fractionDigits, '0');
      fractionPart = `.${fractionZeroes}`;
    }

    return value != null && !isNaN(value)
      ? numeral(value).format(`${BASE_DECIMAL_FORMAT}${fractionPart}`)
      : value;
  },
  formatAsPercent: (value, fractionDigits = 0) => {
    return `${NumberUtils.toDecimals(value, fractionDigits)}%`;
  },
};
export default NumberUtils;
