import React from 'react';
import { currentUserSelector, useAppStore } from '../stores/appStore';
import NotAuthorizedView from './NotAuthorizedView';
import { isAuthorized } from '../commons/helpers/authorizationHelpers';

function RequireRolesPage({ requiredRoles, children }) {
  const currentUser = useAppStore(currentUserSelector);
  const userRoles = currentUser != null ? currentUser.roles : [];

  if (isAuthorized(userRoles, requiredRoles)) {
    return children;
  }
  return <NotAuthorizedView />;
}

export default RequireRolesPage;
