import React, { useEffect, useState } from 'react';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import yup from '../../commons/validators/customYup';
import AnamneseService from '../../services/AnamneseService';
import showNotification from '../../commons/helpers/showNotification';

import { Formik, Form, FieldArray } from 'formik';
import { FormTextField } from '../../components/forms/FormikFields';
import {
  Button,
  Typography,
  Paper,
  Box,
  styled,
  IconButton,
  Container,
  Grid,
  LinearProgress,
} from '@mui/material';
import CompanyAutocomplete from '../../components/typeahead/CompanyAutocomplete';
import CompanyService from '../../services/CompanyService';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import {
  getMessageConfirmationDialog,
  isSectionFieldInFirstOrLastPositionOfQuestions,
  isSomeOfQuestionsWithLessOfTwoItems,
  transformDataToSaveAnamneseForm,
  transformInitDataFromAnamneseForm,
} from './anamneseFormHelpers';
import { getAccountStatusDescription } from '../../commons/utils/paymentTypeUtils';
import AnamneseFormFieldArray from './AnamneseFormFieldArray';

const PaperStyled = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

const validationSchema = yup.object().shape({
  name: yup.string().min(2).max(100).required(),
  description: yup.string().max(255),
  commentsPlaceholder: yup.string().max(100),
  questions: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().max(150).required(),
        anamneseQuestionItems: yup
          .array()
          .of(
            yup.object().shape({
              title: yup.string().max(150).required(),
            }),
          )
          .nullable(),
      }),
    )
    .min(1, 'Informe pelo menos 1 item')
    .required(),
});

const DEFAULT_VALUES = {
  name: '',
  description: '',
  commentsPlaceholder: '',
  questions: [],
  firstSectionTitle: 'Questionário Anamnese',
};

function AnamneseFormPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [formId, setFormId] = useState();
  const [companyId, setCompanyId] = useState();
  const [isCloneRoute, setIsCloneRoute] = useState();

  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [modalConfirmSave, setModalConfirmSave] = useState({ open: false });
  const [formValuesToSave, setFormValuesToSave] = useState();

  useEffect(() => {
    async function initData() {
      setFormId(location.state?.formId);
      setCompanyId(location.state?.companyId);
      setIsCloneRoute(location.state?.shouldClone);
      if (companyId || formId || isCloneRoute) {
        setIsLoading(true);
        try {
          if (formId || isCloneRoute) {
            if (isCloneRoute) {
              const resp = await AnamneseService.getByIdToClone(formId);
              setInitialValues(transformInitDataFromAnamneseForm(resp));
            } else {
              const resp = await AnamneseService.getById(formId);
              setInitialValues(transformInitDataFromAnamneseForm(resp));
            }
          }
          if (companyId) {
            const resp = await CompanyService.getById(companyId);
            setSelectedCompany(resp);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    initData();
  }, [formId, companyId, isCloneRoute, location]);

  const myHandleSubmit = async (values, actions) => {
    const formToSave = { ...values };
    let questionsToSave = [...values.questions];

    //verify if section is last or first field
    if (!isSectionFieldInFirstOrLastPositionOfQuestions(questionsToSave)) {
      if (!isSomeOfQuestionsWithLessOfTwoItems(questionsToSave)) {
        transformDataToSaveAnamneseForm(questionsToSave, formToSave);

        if (selectedCompany) {
          formToSave.companyId = selectedCompany.id;
        }
        setFormValuesToSave(formToSave);
        setModalConfirmSave({
          open: true,
          actions,
        });
      } else {
        showNotification(
          'warning',
          'Todos os campos Multi e Single devem ter pelo menos 2 opções ou 1 opção e opção outra.',
        );
      }
    } else {
      showNotification(
        'warning',
        'Não é permitido salvar com um Título de Seção sendo a primeiro ou último item da lista.',
      );
    }
  };

  const handleSaveConfirmation = async () => {
    setIsLoading(true);
    try {
      await AnamneseService.save(formValuesToSave);
      showNotification('success', formValuesToSave.id ? 'Atualizado com successo.' : 'Criado com successo.');
      setModalConfirmSave((prev) => ({ ...prev, open: false }));
      companyId ? navigate('..', { state: { companyId } }) : navigate('..');
    } catch (e) {
      modalConfirmSave.actions.setSumitting(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title={formId && !isCloneRoute ? 'Atualização' : 'Criação'}
        message={getMessageConfirmationDialog(formId, formValuesToSave?.name, selectedCompany, isCloneRoute)}
        onConfirm={handleSaveConfirmation}
        open={modalConfirmSave.open}
        onClose={() => {
          setModalConfirmSave((prev) => ({ ...prev, open: false }));
        }}
      />

      <Container maxWidth="lg" sx={{ pr: { xs: 0, md: 1 }, pl: { xs: 0, md: 1 } }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            onClick={() => (companyId ? navigate('..', { state: { companyId } }) : navigate('..'))}
            sx={{ mr: 1 }}
          >
            <FontAwesomeIconStatus icon="arrow-left" status="primary" />
          </IconButton>
          <Typography variant="h5">{formId ? 'Atualizando' : 'Criando'} Ficha Amanese</Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
        </Box>

        <PaperStyled>
          {(selectedCompany || isCloneRoute) && (
            <Box sx={{ mb: 2, width: '60%' }}>
              <CompanyAutocomplete
                minLengthRequired={3}
                disabled={formId && !isCloneRoute ? true : false}
                value={selectedCompany}
                onChange={(newSelected) => {
                  setSelectedCompany(newSelected);
                }}
                getSuggestionLabel={(object) => {
                  if (!object) {
                    return '';
                  }
                  return `${object.name} - ${
                    object.email ? object.email : 'SEM EMAIL'
                  } - (${getAccountStatusDescription(object)})`;
                }}
                color="primary"
                label="Empresa"
                placeholder="Procure pelo nome ou email"
              />
            </Box>
          )}

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={myHandleSubmit}
          >
            {({ values, errors, dirty, isSubmitting, touched, resetForm }) => (
              <Form>
                <Grid container columnGap={2} mb={2}>
                  <Grid item sm={3}>
                    <FormTextField
                      color="primary"
                      fullWidth
                      name="name"
                      type="text"
                      label="Nome da Ficha"
                      placeholder="Nome"
                      fastField
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <FormTextField
                      color="primary"
                      fullWidth
                      name="description"
                      type="text"
                      label="Descrição da Ficha"
                      placeholder="Descrição"
                      fastField
                    />
                  </Grid>
                  <Grid item sm={5}>
                    <FormTextField
                      color="primary"
                      fullWidth
                      name="commentsPlaceholder"
                      type="text"
                      label="Placeholder do campo Observações gerais"
                      placeholder="Placeholder"
                      fastField
                    />
                  </Grid>
                </Grid>
                <FieldArray
                  name="questions"
                  render={({ remove, push, replace, move }) => {
                    return (
                      <AnamneseFormFieldArray
                        remove={remove}
                        push={push}
                        replace={replace}
                        move={move}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                    );
                  }}
                />
                <Box sx={{ mt: 2 }} display="flex" flexDirection="row" justifyContent="space-between">
                  <Button
                    sx={{ ml: 2 }}
                    variant="outlined"
                    type="button"
                    onClick={() => resetForm()}
                    color="secondary"
                  >
                    Resetar
                  </Button>
                  <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                    Salvar
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </PaperStyled>
      </Container>
    </>
  );
}

export default AnamneseFormPage;
