import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, styled } from '@mui/material';
import yup from '../../../commons/validators/customYup';
import { FormTextField } from '../../../components/forms/FormikFields';
import showNotification from '../../../commons/helpers/showNotification';
import SuperUserService from '../../../services/SuperUserService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  asaasCustomerId: yup.string(),
  asaasSubscriptionId: yup.string(),
  stripeCustomerId: yup.string(),
  stripeSubscriptionId: yup.string(),
});

const DEFAULT_INIT_VALUES = {
  asaasCustomerId: '',
  asaasSubscriptionId: '',
  stripeCustomerId: '',
  stripeSubscriptionId: '',
};

// COMPONENT
function SubscriptionSettingsForCompanyModal({ selectedCompany, modalVisible, setModalVisible, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        asaasCustomerId: selectedCompany.asaasCustomerId ?? '',
        asaasSubscriptionId: selectedCompany.asaasSubscriptionId ?? '',
        stripeCustomerId: selectedCompany.stripeCustomerId ?? '',
        stripeSubscriptionId: selectedCompany.stripeSubscriptionId ?? '',
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    SuperUserService.changeStripeAsaasSubscriptionSettings({
      companyId: selectedCompany.id,
      ...values,
    })
      .then((companyUpdated) => {
        showNotification('success', 'Atualizado com successo.');
        onSave({ ...companyUpdated });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const isAsaasOrStripe =
    'STRIPE' === selectedCompany.subscriptionType || 'ASAAS' === selectedCompany.subscriptionType;

  return (
    <>
      {/* DIALOG */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
        <DialogTitle variant="h5">Configuração de conta</DialogTitle>

        {isAsaasOrStripe ? (
          <>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={myHandleSubmit}
            >
              {({ dirty, isSubmitting }) => (
                <Form>
                  <DialogContent dividers>
                    <div>
                      {'ASAAS' === selectedCompany.subscriptionType && (
                        <>
                          <FormTextField fullWidth label="asaasCustomerId" name="asaasCustomerId" />
                          <FormTextField fullWidth label="asaasSubscriptionId" name="asaasSubscriptionId" />
                        </>
                      )}
                      {'STRIPE' === selectedCompany.subscriptionType && (
                        <>
                          <FormTextField fullWidth label="stripeCustomerId" name="stripeCustomerId" />
                          <FormTextField fullWidth label="stripeSubscriptionId" name="stripeSubscriptionId" />
                        </>
                      )}
                    </div>
                  </DialogContent>

                  <DialogActionsStyled>
                    <Button variant="outlined" onClick={handleClose}>
                      Fechar
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={!dirty || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </DialogActionsStyled>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <DialogContent dividers>
              <Alert severity="warning">Empresa não é assinante Stripe ou Asaas.</Alert>
            </DialogContent>
            <DialogActionsStyled>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
            </DialogActionsStyled>
          </>
        )}
      </Dialog>
    </>
  );
}

export default SubscriptionSettingsForCompanyModal;
