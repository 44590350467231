import React from 'react';
import { Typography } from '@mui/material';

function LabelValueDisplay({ label, value }) {
  return (
    <Typography variant="body1">
      <Typography component="span" variant="subtitle2">
        {label}:{' '}
      </Typography>
      {value}
    </Typography>
  );
}

export default LabelValueDisplay;
