import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  styled,
  Box,
  CircularProgress,
} from '@mui/material';
import showNotification from '../../../commons/helpers/showNotification';
import SuperUserService from '../../../services/SuperUserService';
import LabelStatus from '../../../components/labels/LabelStatus';
import ConfirmationDialog from '../../../components/modals/ConfirmationDialog';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function ImportAgendaModal({ selectedCompany, modalVisible, setModalVisible }) {
  const refFileInput = React.useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isWorking, setIsWorking] = useState(false);
  const [modalConfirmImportingOpen, setModalConfirmImportingOpen] = useState(false);
  const [importingResults, setImportingResults] = useState();

  useEffect(() => {
    if (modalVisible) {
      setSelectedFile(null);
      setImportingResults(null);
      if (refFileInput.current) {
        refFileInput.current.value = '';
      }
    }
  }, [modalVisible, selectedCompany]);

  const handleConfirmImporting = async () => {
    setIsWorking(true);
    setModalConfirmImportingOpen(false);
    try {
      const resp = await SuperUserService.importIcsCalendar(selectedCompany.id, selectedFile);
      setImportingResults(resp);
    } catch (err) {
      console.log(err);
      showNotification('error', 'Erro ao importar dados.');
    } finally {
      setIsWorking(false);
    }
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleClose = () => {
    if (!isWorking) {
      setModalVisible(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Confirmar importação"
        message="Você tem certeza que deseja importar o arquivo selecionado?"
        onConfirm={handleConfirmImporting}
        open={modalConfirmImportingOpen}
        onClose={() => {
          setModalConfirmImportingOpen(false);
        }}
      />

      {/* FORM */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
        <DialogTitle variant="h5">Importar Agenda - {selectedCompany.name}</DialogTitle>

        <DialogContent dividers>
          {isWorking && (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography sx={{ mb: 2 }} color="primary">
                Importando dados, aguarde...
              </Typography>
              <CircularProgress />
            </Box>
          )}

          {!isWorking && importingResults == null && (
            <>
              <Alert severity="info">
                Selecione um arquivo .ics do Google Agenda ou Apple Calendar para fazer a importação.
              </Alert>
              <Box sx={{ mt: 2 }}>
                <Typography>Selecione um arquivo ICS</Typography>
                <input type="file" accept=".ics" onChange={handleChange} />
              </Box>
            </>
          )}
          {!isWorking && importingResults != null && (
            <>
              <LabelStatus color="textSecondary" gutterBottom status="success">
                Eventos importados:{' '}
                <Typography component="span">
                  <b>{importingResults?.appIdsSaved?.length ?? '0'}</b>
                </Typography>
              </LabelStatus>

              <LabelStatus color="textSecondary" gutterBottom status="warning">
                Eventos recorrentes NÃO importados:{' '}
                <Typography component="span">
                  <b>{importingResults?.recurringEvents?.length ?? '0'}</b>
                </Typography>
              </LabelStatus>

              <LabelStatus color="textSecondary" gutterBottom status="warning">
                Eventos All-Day NÃO importados:{' '}
                <Typography component="span">
                  <b>{importingResults?.allDayEvents?.length ?? '0'}</b>
                </Typography>
              </LabelStatus>

              <LabelStatus color="textSecondary" gutterBottom status="warning">
                Eventos Conflitantes não importados:{' '}
                <Typography component="span">
                  <b>{importingResults?.conflictingEvents?.length ?? '0'}</b>
                </Typography>
              </LabelStatus>

              <Button
                onClick={() => {
                  showNotification('info', 'Printado no console do navegador.');
                  console.log(importingResults);
                }}
                variant="contained"
              >
                Printar log no console
              </Button>
            </>
          )}
        </DialogContent>

        <DialogActionsStyled>
          <Button variant="outlined" onClick={handleClose} disabled={isWorking}>
            Fechar
          </Button>
          {importingResults == null && (
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedFile || isWorking}
              onClick={() => {
                setModalConfirmImportingOpen(true);
              }}
            >
              Importar
            </Button>
          )}
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default ImportAgendaModal;
