import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  styled,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import showNotification from '../../../../commons/helpers/showNotification';
import booleanUtils from '../../../../commons/utils/booleanUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processImport, templates, } from './importCustomersHelpers';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function ImportCustomersForm({
  selectedCompany,
  modalVisible,
  setModalVisible,
  selectedFile,
  setSelectedFile,
  isWorking,
  setIsWorking,
  phoneDDD,
  setPhoneDDD,
  setImportingStatus,
  setModalConfirmImportingOpen,
}) {
  const refFileInput = React.useRef();
  const [isShowingAutomatic, setIsShowingAutomatic] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const isCompanyBR = !selectedCompany.locale || selectedCompany.locale === 'PT_BR';

  useEffect(() => {
    if (modalVisible) {
      setSelectedFile(null);
      setSelectedTemplate();
      setPhoneDDD('');
      if (refFileInput.current) {
        refFileInput.current.value = '';
      }
    }
  }, [modalVisible, selectedCompany, setPhoneDDD, setSelectedFile]);

  const handleChangeTypeForm = (event) => {
    setIsShowingAutomatic(booleanUtils.str2bool(event.target.value));
  };

  const handleImport = async () => {
    if (isCompanyBR && !phoneDDD) {
      showNotification('warning', 'Por favor selecione o DDD');
      return;
    }
    if (phoneDDD) {
      const parsedDDD = Number.parseInt(phoneDDD, 10);
      if (parsedDDD < 11 || parsedDDD > 99) {
        showNotification('warning', 'Por favor selecione um DDD válido');
        return;
      }
    }
    processImport(selectedTemplate, selectedFile, isCompanyBR, isShowingAutomatic, phoneDDD, setIsWorking, setImportingStatus, setModalConfirmImportingOpen); 
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleClearClick = () => {
    setSelectedTemplate();
    setPhoneDDD('');
  };

  return (
    <>
      {/* FORM */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
        <DialogTitle variant="h5">Importar Clientes - {selectedCompany.name}</DialogTitle>

        <DialogContent dividers>
          <FormControl sx={{ mb: 1 }}>
            <FormLabel>Tipo de Importação</FormLabel>
            <RadioGroup row value={isShowingAutomatic} onChange={handleChangeTypeForm}>
              <FormControlLabel value="true" control={<Radio />} label="Por template" />
              <FormControlLabel value="false" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
          {isShowingAutomatic ? (
            <>
              <Box marginTop={2} display="flex" flexDirection="row" alignItems="center" width={'100%'}>
                <FormControl fullWidth>
                  <InputLabel>Template</InputLabel>
                  <Select
                    value={selectedTemplate ?? ''}
                    onChange={(e) => {
                      setSelectedTemplate(e.target.value);
                    }}
                    label="Template"
                    endAdornment={
                      selectedTemplate && (
                        <IconButton onClick={handleClearClick} sx={{ mr: 1 }}>
                          <FontAwesomeIcon icon="times" />
                        </IconButton>
                      )
                    }
                  >
                    {Object.keys(templates).map((template) => (
                      <MenuItem value={template} key={template}>
                        {templates[template].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2 }}>
                {selectedTemplate && (
                  <>
                    <TextField
                      type="number"
                      label="DDD do Telefone"
                      value={phoneDDD}
                      onChange={(e) => {
                        setPhoneDDD(e.target.value);
                      }}
                    />
                    <br />
                    <br />

                    <Typography>
                      Selecione um arquivo{' '}
                      {templates[selectedTemplate]?.format.replace(/^./, '').toUpperCase()}
                    </Typography>
                    <input type="file" accept={templates[selectedTemplate]?.format} onChange={handleChange} />
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Alert severity="info">
                O arquivo csv precisa ter as seguintes colunas (Nome e Telefone são obrigatórios, e as outras
                opcionais):
                <br />
                <b>Nome</b>,<b>Telefone</b>,<b>Email</b>,<b>Endereço</b>,<b>Data Nascimento</b>,<b>CPF</b>
                <br />
                <br />
                Como alternativa ao campo endereço, vc pode ter também os campos seguintes separados que serão{' '}
                unificados:
                <br />
                <b>Rua</b>,<b>Numero</b>,<b>Bairro</b>,<b>Cidade</b>,<b>Estado</b>,<b>CEP</b>
                <br />
                Sera montado assim (formato google):
                <br />
                Rua Nome, 17 - Bairro, Araguari - MG, 38440-000
              </Alert>
              <Box sx={{ mt: 2 }}>
                <TextField
                  type="number"
                  label="DDD do Telefone"
                  value={phoneDDD}
                  onChange={(e) => {
                    setPhoneDDD(e.target.value);
                  }}
                />
                <br />
                <br />
                <Typography>Selecione um arquivo CSV</Typography>
                <input type="file" accept=".csv" onChange={handleChange} />
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActionsStyled>
          <Button variant="outlined" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedFile || isWorking}
            onClick={handleImport}
          >
            Importar
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default ImportCustomersForm;
