/* eslint-disable eqeqeq */
import {
  Table, TableBody, TableCell, TableHead,
  TableRow
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ButtonCopyToClipboard from '../../../components/buttons/ButtonCopyToClipboard';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../../components/table/TableCellAction';
import SuperUserService from '../../../services/SuperUserService';
import AccountInfoStoreDetailModal from './AccountInfoStoreDetailModal';


// COMPONENT
function AndroidAccountInfoStoreTableModal({ historySubscription }) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [historySubscriptionDetail, setHistorySubscriptionDetail] = useState();

  const handlePaymentHistoryDetail = (androidReceiptHistoryId) => {
    setIsLoading(true);
    SuperUserService.getAndroidHistorySubscriptionDetail(androidReceiptHistoryId)
      .then((subscription) => {
        setHistorySubscriptionDetail(subscription);
        setModalDetailOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <AccountInfoStoreDetailModal
        json={historySubscriptionDetail}
        open={modalDetailOpen}
        onClose={() => {
          setModalDetailOpen(false);
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>AutoRenew</TableCell>
            <TableCell>cancelReason</TableCell>
            <TableCell>createdAt</TableCell>
            <TableCell>emailAddress</TableCell>
            <TableCell>expiryTimeMillis</TableCell>
            <TableCell>familyName</TableCell>
            <TableCell>givenName</TableCell>
            <TableCell>id</TableCell>
            <TableCell>orderId</TableCell>
            <TableCell>paymentState</TableCell>
            <TableCell>productId</TableCell>
            <TableCell>purchaseToken</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historySubscription?.map((subscription, i) => {
            return (
              <TableRow key={subscription.id}>
                <TableCell>{subscription.autoRenew}</TableCell>
                <TableCell>{subscription.cancelReason}</TableCell>
                <TableCell>{dayjs(subscription.createdAt).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>{subscription.emailAddress}</TableCell>
                <TableCell>{dayjs(subscription.expiryTimeMillis).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>{subscription.familyName}</TableCell>
                <TableCell>{subscription.givenName}</TableCell>
                <TableCell>{subscription.id}</TableCell>
                <TableCell>{subscription.orderId}</TableCell>
                <TableCell>{subscription.paymentState}</TableCell>
                <TableCell>{subscription.productId}</TableCell>
                <TableCell>{subscription.purchaseToken.substring(0,7) + '...'} <ButtonCopyToClipboard copyValue={subscription.purchaseToken} /></TableCell>

                <TableCellAction
                  title="Pesquisar na Loja"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handlePaymentHistoryDetail(subscription.id);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="magnifying-glass" size="sm" />
                </TableCellAction>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default AndroidAccountInfoStoreTableModal;
