import MockService from '../../services/MockService';
import CustomAutocomplete from './CustomAutocomplete';

function MockAutocomplete({ getSuggestionLabel, ...others }) {
  const handleSearchTerm = (inputValue, setIsLoading) => {
    setIsLoading(true);
    return MockService.search(inputValue)
      .then((data) => {
        return data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomAutocomplete
      onChangeSearchTerm={handleSearchTerm}
      getSuggestionLabel={getSuggestionLabel}
      {...others}
    />
  );
}

export default MockAutocomplete;
