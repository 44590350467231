import dayjs from 'dayjs';

const ACCOUNT_PAID = 'PAID';
const ACCOUNT_TRIAL = 'TRIAL';

const getAccountStatusDescription = (company) => {
  if (company.accountType === ACCOUNT_PAID && company.paidGracePeriodEndDate) {
    const suffix = dayjs(company.paidGracePeriodEndDate).isBefore(dayjs()) ? 'Expirado' : 'Irá Expirar';
    return `PAGA - ${suffix} ${dayjs(company.paidGracePeriodEndDate).format('DD/MM/YY')}`;
  }

  if (company.accountType === ACCOUNT_TRIAL && company.trialExpirationDate) {
    const daysRemaning = Math.ceil(dayjs(company.trialExpirationDate).diff(dayjs(), 'days', true));
    return `${company.accountType} - ${dayjs(company.trialExpirationDate).format('DD/MM/YY')} - ${
      daysRemaning < 0 ? 'Expirado' : `Faltam ${daysRemaning} dias`
    }`;
  }

  return company.accountType === ACCOUNT_PAID ? 'PAGA' : company.accountType;
};


export { getAccountStatusDescription };
