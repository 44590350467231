import { Box } from '@mui/material';
import React from 'react';
import { FormSelect } from '../../components/forms/formik/FormSelect';
import { MENU_APP } from './featureListHelpers';

// COMPONENT
function FeatureMenusFormSelect(props) {
  const menuHelper = Object.keys(MENU_APP).map((key) => {
    return { id: MENU_APP[key].id, name: MENU_APP[key].name };
  });
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormSelect
        fullWidth
        label="Menu"
        name="menu"
        placeholder="Selecione qual o menu..."
        dataSource={menuHelper}
        {...props}
      />
    </Box>
  );
}

export default FeatureMenusFormSelect;
