import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Alert,
  Box,
  IconButton,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { FormTextField } from '../../components/forms/FormikFields';
import ButtonCopyToClipboard from '../../components/buttons/ButtonCopyToClipboard';
import yup from '../../commons/validators/customYup';
import PhoneUtils from '../../commons/utils/phoneUtils';
import SuperUserService from '../../services/SuperUserService';
import showNotification from '../../commons/helpers/showNotification';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';

const schema = yup.object().shape({
  nameOrPhoneOrEmail: yup.string().min(4, 'O comprimento minimo é 4.').required(),
});

const INIT_VALUES = { nameOrPhoneOrEmail: '' };

// COMPONENT
function ListUsersPage() {
  const [dataSource, setDataSource] = useState();

  const myHandleSubmit = (values, actions) => {
    let searchTermSanitized =
      values.nameOrPhoneOrEmail.indexOf('mailto:') !== -1
        ? values.nameOrPhoneOrEmail.substring(7)
        : values.nameOrPhoneOrEmail;
    if (!searchTermSanitized.includes('@')) {
      searchTermSanitized = PhoneUtils.sanitizePhoneNumber(searchTermSanitized);
    }

    SuperUserService.searchUsers(searchTermSanitized)
      .then((userList) => {
        if (userList.length === 0) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        } else {
          setDataSource(userList);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  const handleSearchUrl = (companyId) => {

    window.open('/app/manage-company?companyId='+ companyId, '_blank');
  };


  return (
    <Box sx={{ s: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Formik
          enableReinitialize
          initialValues={INIT_VALUES}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormTextField
                  name="nameOrPhoneOrEmail"
                  label="Procure por nome, email ou telefone"
                  sx={{ minWidth: '300px' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{ ml: 2 }}
                >
                  Buscar {isSubmitting && <CircularProgress />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      {dataSource?.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Master?</TableCell>
                <TableCell>Force Reset</TableCell>
                <TableCell>Profissional?</TableCell>
                <TableCell>Enabled?</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource?.map((u) => {
                const userIsMaster = u.user.email === u.company.email;
                console.log(u);
                return (
                  <TableRow key={u.company.id}>
                    <TableCell>{u.user.name}</TableCell>
                    <TableCell>{u.user.email}</TableCell>
                    <TableCell>
                      <Alert severity={userIsMaster ? 'success' : 'warning'} variant="standard">
                        {u.company.email}
                        <ButtonCopyToClipboard copyValue={u.company.email} />
                      </Alert>
                    </TableCell>
                    <TableCell>{u.user.forceResetPassword ? 'Sim' : 'Não'}</TableCell>
                    <TableCell>{u.user.professional ? 'Sim' : 'Não'}</TableCell>
                    <TableCell>{u.user.enabled ? 'Sim' : 'Não'}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleSearchUrl(u.company.id);
                        }}
                      >
                        <FontAwesomeIconStatus icon="arrow-up-right-from-square" status="info" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
}

export default ListUsersPage;
