import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const TypographyStyled = styled(Typography)(({ theme, status }) => {
  return {
    color: theme.palette[status]?.main ?? 'black',
  };
});

// COMPONENT
function LabelStatus({ status, className, children, ...rest }) {
  return (
    <TypographyStyled className={className} status={status} {...rest}>
      {children}
    </TypographyStyled>
  );
}

export default LabelStatus;
