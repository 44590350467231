/* eslint-disable react/forbid-prop-types */
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, TextField, Autocomplete, Box } from '@mui/material';
import { debounce } from 'lodash';

// COMPONENT
function CustomAutocomplete({
  value,
  onChange,
  label,
  placeholder,
  getSuggestionLabel,
  onChangeSearchTerm,
  minLengthRequired,
  disabled,
  ...others
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(
    debounce((query) => {
      onChangeSearchTerm(query, setIsLoading).then((resp) => {
        setSuggestions(resp);
      });
    }, 350),
    [onChangeSearchTerm],
  );

  return (
    <Autocomplete
      fullWidth
      openOnFocus={false}
      disabled={disabled}
      noOptionsText={`Nenhum resultado encontrado. Digite para buscar ${
        minLengthRequired > 0 ? ` (minimo ${minLengthRequired} caracteres)` : ''
      }...`}
      value={value}
      onChange={(event, newValue) => {
        if (!newValue) {
          setSuggestions([]);
        }
        onChange(newValue);
      }}
      onInputChange={(evt, newInputValue) => {
        if (!newInputValue || newInputValue.trim().length < minLengthRequired) {
          setSuggestions([]);
        } else {
          debounceOnChange(newInputValue);
        }
      }}
      isOptionEqualToValue={(option, optionValue) => option?.id === optionValue?.id}
      getOptionLabel={(option) => (option ? getSuggestionLabel(option) : '')}
      options={suggestions}
      loadingText="Carregando..."
      loading={isLoading}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {getSuggestionLabel(option)}
        </Box>
      )}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          InputProps={{
            autoComplete: 'off',
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          //   error={touch && error != null}
          //   helperText={touch && error}
          //   className={classes.formControl}
        />
      )}
    />
  );
}

CustomAutocomplete.defaultProps = {
  placeholder: null,
  minLengthRequired: 0,
};

CustomAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChangeSearchTerm: PropTypes.func.isRequired,
  getSuggestionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  minLengthRequired: PropTypes.number,
};

CustomAutocomplete.defaultProps = {
  onChange: () => {},
};

export default CustomAutocomplete;
