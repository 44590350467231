import React, { useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import yup from '../../../commons/validators/customYup';
import { FormSelect, FormTextField } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';
import NumberUtils from '../../../commons/utils/numberUtils';
import showNotification from '../../../commons/helpers/showNotification';

const DialogActionsStyled = styled(DialogActions)(({ theme }) => {
  return {
    justifyContent: 'space-between',
    margin: '10px',
  };
});

const schema = yup.object().shape({
  textConfirmation: yup.string().max(150),
  refundValue: yup.number().required(),
});

const DEFAULT_INIT_VALUES = {
  textConfirmation: '',
  refund: 0,
  refundValue: 0,
};

// COMPONENT
function ConfirmationDeleteSubscriptionDialog({
  selectedCompany,
  open,
  onSave,
  onClose,
  loading,
  stripeSubscriptionDetails,
  setStripeSubscriptionDetails,
}) {
  const [initialValues, setInitialValues] = React.useState(DEFAULT_INIT_VALUES);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const isMyLoading = isLoading || loading;
  const [subscriptionRefundInfo, setSubscriptionRefundInfo] = React.useState();
  const textConfirm = 'confirmo deletar assinatura stripe';

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      setInitialValues({
        textConfirmation: '',
        refund: 0,
        refundValue: 0,
      });
      const fetchData = async () => {
        const stripeSubscriptionRefundInfo = await SuperUserService.getStripeRefundInfo(selectedCompany.id);
        setSubscriptionRefundInfo(stripeSubscriptionRefundInfo);
      };
      fetchData();
      setIsLoading(false);
    }
  }, [open, selectedCompany]);

  const handleChange = (value) => {
    if (textConfirm.toLowerCase() === value.textConfirmation.toLowerCase()) {
      setConfirmation(true);
    } else {
      setConfirmation(false);
    }
  };

  const handleOk = (values) => {
    setIsLoading(true);
    SuperUserService.deleteStripeSubscription(
      selectedCompany.id,
      subscriptionRefundInfo ? subscriptionRefundInfo.chargeId : '',
      values.refundValue,
    )
      .then((companyUpdated) => {
        onSave(companyUpdated);
        setStripeSubscriptionDetails(
          stripeSubscriptionDetails.filter((stripeSubscription) => {
            return stripeSubscription.id !== selectedCompany.stripeSubscriptionId;
          }),
        );

        showNotification('success', 'Assinatura cancelada com sucesso.');
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  const handleChangeRefundValue = (newSelection, setFieldValue) => {
    setFieldValue('refund', newSelection);
    setFieldValue('refundValue', REFUND_OPTIONS[newSelection].value);
  };

  const REFUND_OPTIONS = React.useMemo(() => {
    return [
      {
        id: 0,
        name: `Sem reembolso`,
        value: 0,
      },
      {
        id: 1,
        name: `Último pagamento: ${NumberUtils.toCurrency(subscriptionRefundInfo?.refundFull)} `,
        value: subscriptionRefundInfo?.refundFull,
      },
      {
        id: 2,
        name: `${NumberUtils.toCurrency(
          subscriptionRefundInfo?.refundProportional,
        )} do valor proporcional de ${NumberUtils.toCurrency(subscriptionRefundInfo?.refundFull)}`,
        value: subscriptionRefundInfo?.refundProportional,
      },
    ];
  }, [subscriptionRefundInfo]);

  return (
    <Dialog maxWidth="xs" onClose={onClose} aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle>Deletar assinatura Stripe</DialogTitle>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleOk}>
        {({ dirty, isSubmitting, values, isValid, setFieldValue }) => {
          return (
            <Form>
              <DialogContent dividers>
                <Typography>
                  Isso deletará todas as cobranças em aberto. Você tem certeza que deseja deletar está
                  assinatura Stripe?
                </Typography>
                <Box>
                  <FormTextField
                    label={`Digite a confirmação abaixo: ${textConfirm}`}
                    fullWidth
                    name="textConfirmation"
                    inputProps={{
                      onKeyUp: () => {
                        handleChange(values);
                      },
                      onPaste: (e) => {
                        e.preventDefault();
                        return false;
                      },
                      autoComplete: 'off',
                    }}
                  ></FormTextField>
                  <FormSelect
                    fullWidth
                    label="Reembolso"
                    name="refund"
                    dataSource={REFUND_OPTIONS}
                    onChange={(evt) => {
                      handleChangeRefundValue(evt.target.value, setFieldValue);
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActionsStyled>
                <Button disabled={isMyLoading} variant="outlined" onClick={onClose}>
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isMyLoading || isSubmitting || !isValid || !confirmation}
                >
                  Sim
                  {isMyLoading && <CircularProgress size={12} />}
                </Button>
              </DialogActionsStyled>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default ConfirmationDeleteSubscriptionDialog;
