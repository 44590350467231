import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';

import ExampleQuestionCategoryService from '../../../services/ExampleQuestionCategoryService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// COMPONENT
function ExampleQuestionListFilter({ selectedCategoryId, setSelectedCategoryId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    ExampleQuestionCategoryService.findAll()
      .then((resp) => {
        setDataSource(resp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClearClick = () => {
    setSelectedCategoryId(undefined);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormControl sx={{ width: '195px' }}>
        <InputLabel>Filtrar por categoria</InputLabel>
        <Select
          value={selectedCategoryId ?? ''}
          onChange={(e) => {
            setSelectedCategoryId(e.target.value);
          }}
          label="Filtrar por categoria"
          endAdornment={
            selectedCategoryId && (
              <IconButton onClick={handleClearClick} sx={{ mr: 1 }}>
                <FontAwesomeIcon icon="times" size="sm" />
              </IconButton>
            )
          }
        >
          {dataSource.map((m) => (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
    </Box>
  );
}

export default ExampleQuestionListFilter;
