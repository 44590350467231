/* eslint-disable no-param-reassign */

export const RECEIVE_IN_INSTALLMENTS = 306090;
export const TIME_TO_REVENUE_IN_DAYS_OPTIONS = [
  {
    name: 'Conforme parcelas',
    id: RECEIVE_IN_INSTALLMENTS,
  },
  {
    name: 'Hoje',
    id: 0,
  },
  {
    name: 'Amanhã',
    id: 1,
  },
  {
    name: '2 dias',
    id: 2,
  },
  {
    name: '3 dias',
    id: 3,
  },
  {
    name: '4 dias',
    id: 4,
    secondary: true,
  },
  {
    name: '5 dias',
    id: 5,
    secondary: true,
  },
  {
    name: '6 dias',
    id: 6,
    secondary: true,
  },
  {
    name: '7 dias',
    id: 7,
    secondary: true,
  },
  {
    name: '14 dias',
    id: 14,
  },
  {
    name: '21 dias',
    id: 21,
    secondary: true,
  },
  {
    name: '30 dias',
    id: 30,
  },
  {
    name: '31 dias',
    id: 31,
    secondary: true,
  },
  {
    name: '60 dias',
    id: 60,
    secondary: true,
  },
  {
    name: '90 dias',
    id: 90,
  },
];

export const TIME_TO_REVENUE_IN_DAYS_OPTIONS_TO_LABEL = TIME_TO_REVENUE_IN_DAYS_OPTIONS.reduce(
  (obj, item) => {
    obj[item.id] = item;
    return obj;
  },
  {},
);
