import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableCell,
  CircularProgress,
  styled,
  Box,
  Chip,
} from '@mui/material';
import AnamneseService from '../../services/AnamneseService';
import TableRowClickable from '../../components/table/TableRowClickable';
import { getQuestionTypeColor, getQuestionTypeName, QuestionType } from './anamneseFormHelpers';
import { useNavigate } from 'react-router-dom';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const TableRowClickableStyled = styled(TableRowClickable)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// COMPONENT
function AnamnesePreviewModal({ anamneseForm, open, onClose, isShowingGlobals, handleEdit }) {
  const navigate = useNavigate();
  const [anamneseFormReceived, setAnamneseFormReceived] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      AnamneseService.getById(anamneseForm.id)
        .then((resp) => {
          setAnamneseFormReceived(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, anamneseForm]);

  const handleClone = () => {
    navigate('novo', { state: { shouldClone: true, formId: anamneseFormReceived.id } });
  };

  return (
    <Dialog maxWidth="lg" fullWidth={isLoading} onClose={onClose} open={open}>
      {/* <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md"> */}
      <DialogTitle>
        <Typography component="span" variant="h5">
          {anamneseForm && anamneseForm.name}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0.5 }} dividers>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</Box>

          {!isLoading && anamneseFormReceived && anamneseFormReceived.questions.length > 0 && (
            <Table>
              <TableBody>
                {anamneseFormReceived.questions?.map((question, i) => {
                  return (
                    <TableRowClickableStyled sx={{ cursor: 'default' }} key={question.id}>
                      <TableCell size="small">
                        <>
                          {(question.sectionTitle || i === 0) && (
                            <Box sx={{ mb: 1 }}>
                              <Box component="span">
                                {i === 0 ? 'Questionário Anamnese' : question.sectionTitle}
                              </Box>
                              <Chip
                                sx={{ ml: 1.5 }}
                                size="small"
                                color={getQuestionTypeColor(QuestionType.SECTION)}
                                label="Título de Seção"
                              />
                            </Box>
                          )}
                          <Box component="span">{question.title}</Box>
                          <Chip
                            sx={{ ml: 1.5 }}
                            size="small"
                            color={getQuestionTypeColor(question.questionType)}
                            label={getQuestionTypeName(question.questionType)}
                          />
                          {question.questionType === QuestionType.BOOLEAN && question.booleanWithDetails && (
                            <Chip
                              sx={{ ml: 1.5 }}
                              variant="outlined"
                              size="small"
                              color="primary"
                              label="Com detalhes"
                            />
                          )}
                          <Box display="flex" flexDirection="row" flexWrap="wrap">
                            {(question.questionType === QuestionType.MULTI ||
                              question.questionType === QuestionType.SINGLE) &&
                              question.anamneseQuestionItems.map((item, i) => {
                                return (
                                  <Chip
                                    key={i}
                                    sx={{
                                      mt: 0.5,
                                      mr: i < question.anamneseQuestionItems.length - 1 ? 1 : 0,
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label={item.title}
                                  />
                                );
                              })}
                          </Box>
                        </>
                      </TableCell>
                    </TableRowClickableStyled>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Grid>
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Box>
          <Button
            sx={{ mr: 1 }}
            color="secondary"
            variant="contained"
            onClick={handleClone}
            disabled={isLoading}
          >
            Clonar
          </Button>

          <Button
            color="primary"
            onClick={() => handleEdit(anamneseFormReceived)}
            disabled={isLoading}
            variant="contained"
          >
            Editar
          </Button>
        </Box>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default AnamnesePreviewModal;
