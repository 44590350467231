
const moveAnamneseForm = (from, to, isMovingDown, dataSource) => {
  let copyDataSource = [...dataSource];
  copyDataSource[from].orderIndex = isMovingDown
    ? copyDataSource[from].orderIndex + 1
    : copyDataSource[from].orderIndex - 1;
  copyDataSource[to].orderIndex = isMovingDown
    ? copyDataSource[from].orderIndex - 1
    : copyDataSource[from].orderIndex + 1;
  copyDataSource.splice(to, 0, copyDataSource.splice(from, 1)[0]);
  return copyDataSource;
};

export { moveAnamneseForm };
