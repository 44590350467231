import { styled, TableRow } from '@mui/material';

const TableRowStyled = styled(TableRow)(() => {
  return {
    cursor: 'pointer',
  };
});

function TableRowClickable(props) {
  return <TableRowStyled hover {...props} />;
}

export default TableRowClickable;
