import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { FormSwitchField, FormTextField } from '../../components/forms/FormikFields';
import { Button, Box, IconButton, Grid, Divider, Stack } from '@mui/material';
import { QuestionType, addItemToQuestion, removeItemFromQuestion } from './anamneseFormHelpers';

function AnamneseFormFieldArrayRow({ question, index, remove, replace, move, questionsLength }) {
  return (
    <Box>
      <Stack
        direction="row"
        m={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb:
            question.questionType === QuestionType.MULTI || question.questionType === QuestionType.SINGLE
              ? 0
              : 2,
          ml: 1,
        }}
      >
        <Grid container justifyContent="space-between">
          {index === 0 && (
            <FormTextField
              sx={{ width: { xs: '100%', sm: '70%', md: '30%' } }}
              color="info"
              placeholder="Titulo da seção"
              name={`firstSectionTitle`}
              type="text"
              disabled={true}
              focused
              label="Seção"
              fastField
            />
          )}
          {question.questionType === QuestionType.BOOLEAN && (
            <Grid item sm={9}>
              <Stack direction="row">
                <FormTextField
                  color="primary"
                  sx={{ width: { xs: '100%', lg: '60%' } }}
                  placeholder="Descrição do campo"
                  name={`questions.${index}.title`}
                  type="text"
                  focused
                  label="Tipo Booleano"
                  forceNoError
                  fastField
                />
                <Box
                  sx={{
                    ml: { md: 2 },
                    alignSelf: { md: 'center' },
                    minWidth: { md: 'fit-content' },
                  }}
                >
                  <FormSwitchField
                    color="primary"
                    name={`questions.${index}.booleanWithDetails`}
                    label="Com detalhes?"
                  />
                </Box>
              </Stack>
            </Grid>
          )}
          {question.questionType === QuestionType.TEXT && (
            <Grid item sm={9}>
              <FormTextField
                color="secondary"
                sx={{ width: { xs: '100%', lg: '60%' } }}
                placeholder="Descrição do campo"
                name={`questions.${index}.title`}
                type="text"
                focused
                label="Tipo Texto"
                forceNoError
                fastField
              />
            </Grid>
          )}
          {(question.questionType === QuestionType.SINGLE ||
            question.questionType === QuestionType.MULTI) && (
            <Grid item sm={9}>
              <Stack direction="row">
                <FormTextField
                  sx={{ width: { xs: '100%', lg: '60%' } }}
                  color={question.questionType === QuestionType.SINGLE ? 'success' : 'warning'}
                  placeholder="Titulo do campo"
                  name={`questions.${index}.title`}
                  type="text"
                  focused
                  label={`Tipo Resposta ${
                    question.questionType === QuestionType.SINGLE ? 'Única' : 'Multipla'
                  }`}
                  forceNoError
                  fastField
                />
                {(question.questionType === QuestionType.SINGLE ||
                  question.questionType === QuestionType.MULTI) && (
                  <Box
                    sx={{
                      ml: { md: 2 },
                      alignSelf: { md: 'center' },
                      minWidth: { md: 'fit-content' },
                    }}
                  >
                    <FormSwitchField
                      color={question.questionType === QuestionType.SINGLE ? 'success' : 'warning'}
                      name={`questions.${index}.containOtherItem`}
                      label="Opção outra?"
                    />
                  </Box>
                )}
              </Stack>
              <Grid item sm={3}>
                <Button
                  color={question.questionType === QuestionType.SINGLE ? 'success' : 'warning'}
                  startIcon={<AddIcon />}
                  onClick={() => replace(index, addItemToQuestion(question))}
                >
                  ADD OPÇÃO
                </Button>
              </Grid>

              {question.anamneseQuestionItems.length > 0 &&
                question.anamneseQuestionItems.map((item, indexItem) => {
                  return (
                    <Box key={indexItem}>
                      {!item.theOtherOption && (
                        <Stack direction="row" mt={2}>
                          <FormTextField
                            sx={{
                              width: { xs: '100%', sm: '60%', md: '40%' },
                              mb: 1,
                            }}
                            color={question.questionType === QuestionType.SINGLE ? 'success' : 'warning'}
                            placeholder="Titulo da opção"
                            name={`questions.${index}.anamneseQuestionItems.${indexItem}.title`}
                            type="text"
                            focused
                            label="Opção"
                            forceNoError
                            fastField
                          />

                          {indexItem !== 0 && (
                            <IconButton
                              onClick={() => replace(index, removeItemFromQuestion(question, indexItem))}
                              color="error"
                              sx={{ ml: { md: 1 }, mb: 1, alignSelf: 'center' }}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      )}
                    </Box>
                  );
                })}
            </Grid>
          )}
          {question.questionType === QuestionType.SECTION && (
            <FormTextField
              sx={{ width: { xs: '100%', sm: '70%', md: '30%' } }}
              color="info"
              placeholder="Titulo da seção"
              name={`questions.${index}.title`}
              type="text"
              focused
              label="Seção"
              forceNoError
              fastField
            />
          )}
          <Grid item sm={3} sx={{ textAlign: 'end' }}>
            {index < questionsLength - 1 && (
              <IconButton
                color="primary"
                onClick={() => move(index, index + 1)}
                sx={{ mr: 1, alignSelf: 'center' }}
                aria-label="move down"
              >
                <ArrowDownwardIcon />
              </IconButton>
            )}
            {index > 0 && (question.questionType !== QuestionType.SECTION || index > 1) && (
              <IconButton
                color="primary"
                onClick={() => move(index, index - 1)}
                sx={{ mr: 1, alignSelf: 'center' }}
                aria-label="move up"
              >
                <ArrowUpwardIcon />
              </IconButton>
            )}

            <IconButton
              color="error"
              sx={{ mr: 1, alignSelf: 'center' }}
              aria-label="delete"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Stack>
      {question.questionType !== QuestionType.SECTION && <Divider variant="middle" />}
    </Box>
  );
}

export default React.memo(AnamneseFormFieldArrayRow);
