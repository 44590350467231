import { Box, Button, Container, IconButton, Paper, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import showNotification from '../../commons/helpers/showNotification';
import yup from '../../commons/validators/customYup';
import { FormDateField, FormSwitchField, FormTextField } from '../../components/forms/FormikFields';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import EmployeeService from '../../services/EmployeeService';
import EmployeeProfileFormSelect from './EmployeeProfileFormSelect';

const PaperStyled = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

const schemaFull = yup.object().shape({
  name: yup.string().min(2).max(50).required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(40).required(),
  roleId: yup.number().required(),
  enabled: yup.boolean().required(),
  birthDate: yup.date().required(),
});

const schemaWithPassword = yup.object().shape({
  name: yup.string().min(2).max(50).required(),
  email: yup.string().email().required(),
  roleId: yup.number().required(),
  enabled: yup.boolean().required(),
  birthDate: yup.date().required(),
});

const DEFAULT_VALUES = {
  name: '',
  email: '',
  password: '',
  roleId: '',
  enabled: true,
  birthDate: null,
};

// COMPONENT
function EmployeeFormPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    EmployeeService.findAllRoles().then((resp) => {
      setDataSource(resp);
    });
    if (id) {
      EmployeeService.getById(id).then((resp) => {
        setInitialValues({
          id: resp.id,
          name: resp.name,
          email: resp.email,
          roleId: resp.role.id,
          enabled: resp.enabled,
          birthDate: resp.birthDate ? dayjs(resp.birthDate) : null,
        });
      });
    }
  }, [id]);

  const myHandleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await EmployeeService.save(values);
      showNotification('success', values.id ? 'Atualizado com successo.' : 'Criado com successo.');
      navigate(-1);
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <FontAwesomeIconStatus icon="arrow-left" status="primary" />
        </IconButton>
        <Typography variant="h5">{id ? 'Atualizando' : 'Criando'} Funcionário</Typography>
      </Box>

      <PaperStyled>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={initialValues.id ? schemaWithPassword : schemaFull}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, isValid }) => (
            <Form>
              <FormTextField fullWidth label="Nome" name="name" />
              <FormTextField fullWidth label="Email" name="email" />
              {!initialValues.id && <FormTextField fullWidth label="Senha" name="password" />}
              <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                <FormDateField fullWidth label="Data de Nascimento" name="birthDate" />
              </Box>
              <EmployeeProfileFormSelect profiles={dataSource} />
              <FormSwitchField color="success" name="enabled" label="Ativo?" fullWidth />
              {/* <FormSwitchField name="forceResetPassord" label="Forçar reset de password?" fullWidth /> */}

              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      </PaperStyled>
    </Container>
  );
}

export default EmployeeFormPage;
