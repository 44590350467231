import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

import dayjs from 'dayjs';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../components/table/TableCellAction';
import TableRowClickable from '../../components/table/TableRowClickable';

function EmployeeTable({ employees, isLoading, handleDelete, handleChangePassword, handleEdit }) {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Perfil</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Data Criação</TableCell>
            <TableCell>Últ. Alteração</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {employees?.map((employee, i) => {
            return (
              <TableRowClickable
                key={employee.id}
                onClick={() => {
                  handleEdit(employee);
                }}
              >
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.email}</TableCell>
                <TableCell>{employee.role.description ?? employee.role.name}</TableCell>
                <TableCell>{employee.enabled ? 'Ativo' : 'Inativo'}</TableCell>
                {employee.createdAt != null ? (
                  <TableCell>{dayjs(employee.createdAt).format('DD/MM/YY HH:mm')}</TableCell>
                ) : (
                  <TableCell>---</TableCell>
                )}
                {employee.updatedAt != null ? (
                  <TableCell>{dayjs(employee.updatedAt).format('DD/MM/YY HH:mm')}</TableCell>
                ) : (
                  <TableCell>---</TableCell>
                )}

                <TableCellAction
                  title="Alterar Senha"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleChangePassword(employee);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="lock" size="sm" />
                </TableCellAction>
                <TableCellAction
                  title="Editar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit(employee);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                </TableCellAction>
                <TableCellAction
                  title="Deletar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDelete(employee);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                </TableCellAction>
              </TableRowClickable>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default EmployeeTable;
