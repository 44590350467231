import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  styled,
  Box,
} from '@mui/material';
import SuperUserService from '../../../services/SuperUserService';
import dayjs from 'dayjs';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function LoginHistoryModal({ userId, open, onClose }) {
  const [loginHistoryList, setLoginHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getLoginHistory(userId)
        .then((resp) => {
          setLoginHistoryList(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, userId]);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography component="span" variant="h5">
          Histórico de Login
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</Box>

          {!isLoading && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Versão de Build</TableCell>
                  <TableCell>Id do dispostivo</TableCell>
                  <TableCell>App</TableCell>
                  <TableCell>Criado em</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loginHistoryList.map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.name}</TableCell>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>{u.buildNumber}</TableCell>
                    <TableCell>{u.deviceId}</TableCell>
                    <TableCell>
                      {u.clientApplicationId === 'minhaagenda-app' ? 'Mobile' : ''}
                      {u.clientApplicationId === 'minhaagenda-spa' ? 'Web' : ''}
                      {u.clientApplicationId === 'minhaagenda-app-tablet' ? 'Tablet' : ''}
                    </TableCell>
                    <TableCell>{dayjs(u.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default LoginHistoryModal;
