import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import showNotification from '../../commons/helpers/showNotification';
import yup from '../../commons/validators/customYup';
import { FormTextField } from '../../components/forms/FormikFields';
import TableRowClickable from '../../components/table/TableRowClickable';
import SuperUserService from '../../services/SuperUserService';
import StripeUserDetailModal from './stripe/StripeUserDetailModal';

const schema = yup.object().shape({
  email: yup.string().min(4, 'O comprimento minimo é 4.').required(),
});

const INIT_VALUES = { email: '' };

// COMPONENT
function ListStripeUsersPage() {
  const [dataSource, setDataSource] = useState();
  const [userDetails, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [stripeUserDetailModalOpen, setStripeUserDetailModalOpen] = useState(false);

  const myHandleSubmit = (values, actions) => {
    let email = values.email.indexOf('mailto:') !== -1 ? values.email.substring(7) : values.email;
    setIsLoading(true);
    SuperUserService.stripeSearchUser(email)
      .then((userList) => {
        if (userList.length === 0) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        } else {
          setDataSource(userList);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const handleClick = (customerId) => {
    setIsLoading(true);
    setUserDetails();
    SuperUserService.stripeSearchUserDetails(customerId)
      .then((userDetail) => {
        let userDetailsSearch = dataSource.find((obj) => obj.id === customerId);
        userDetailsSearch.charges = userDetail[0].charges;
        userDetailsSearch.invoices = userDetail[0].invoices;

        setUserDetails(userDetailsSearch);
      })
      .finally(() => {
        setIsLoading(false);
        setStripeUserDetailModalOpen(true);
      });
  };

  return (
    <>
      <StripeUserDetailModal
        userDetails={userDetails}
        open={stripeUserDetailModalOpen}
        onClose={() => {
          setStripeUserDetailModalOpen(false);
        }}
        onSave={() => {
          setStripeUserDetailModalOpen(false);
        }}
      />

      <Box sx={{ s: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Formik
            enableReinitialize
            initialValues={INIT_VALUES}
            validationSchema={schema}
            onSubmit={myHandleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormTextField name="email" label="Procure por email" sx={{ minWidth: '300px' }} />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    sx={{ ml: 2 }}
                  >
                    Buscar {isSubmitting && <CircularProgress />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
        </Box>

        {dataSource?.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Typography
              component="span"
              variant="h5"
              sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
            >
              Clientes
            </Typography>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource?.map((c) => {
                  return (
                    <TableRowClickable
                      key={c.id}
                      onClick={() => {
                        handleClick(c.id);
                      }}
                    >
                      <TableCell>
                        {c.id}               
                      </TableCell>
                      <TableCell>{c.name}</TableCell>
                      <TableCell>{c.email}</TableCell>
                    </TableRowClickable>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
}

export default ListStripeUsersPage;
