import { Avatar, Box, Button, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Form, Formik } from 'formik';
import { FormTextField } from '../../components/forms/FormikFields';
import { useLocation, useNavigate } from 'react-router-dom';
import yup from '../../commons/validators/customYup';
import AuthService from '../../services/AuthService';
import { setCurrentUser } from '../../stores/appStore';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import showNotification from '../../commons/helpers/showNotification';

const schema = yup.object().shape({
    newPassword: yup.string().min(8, 'O comprimento minimo é 8.').required(),
  repeatPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Senhas devem ser iguais.').required(),
});

//COMPONENT
function DefinePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({
    showNewPassword: false,
    showRepeatPassword: false,
  });

  const handleSubmit = (values, actions) => {
    AuthService.updatePassword(
      { currentPassword: location.state.password, newPassword: values.newPassword },
      location.state.userLogged.token,      
    )
      .then(() => {
        setCurrentUser(location.state.userLogged);
        showNotification('success', 'Senha atualizada com successo.');
        navigate(location.state.fromPath ?? '/'); 
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  };

  const handleClickShowPassword = (field) => {
    setValues({
      ...values,
      ...field,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        width: '400px',
        margin: 'auto auto',
        marginBottom: '10px',
      }}
    >
      <Paper
        sx={{
          mt: 26,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          py: 2,
        }}
      >
        <Avatar
          sx={{
            m: 1,
            backgroundColor: 'secondary.main',
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Você está utilizando uma senha temporária.
          <br />
          Por favor defina sua senha.
        </Typography>
        <Formik
        enableReinitialize
          initialValues={{ newPassword: '', repeatPassword: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ dirty, isSubmitting, isValid }) => (
            <Form
              sx={{
                marginTop: '10px',
                width: '100%',
              }}
            >
                <FormTextField
                    label="Nova Senha"
                    name="newPassword"
                    fullWidth
                    type={values.showCurrentPassword ? 'text' : 'password'}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleClickShowPassword({ showCurrentPassword: !values.showCurrentPassword });
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete:"new-password"
                    }}
                  />
                    <br />
              <br />
                   <FormTextField
                    label="Confirme sua Senha"
                    name="repeatPassword"
                    fullWidth
                    type={values.showRepeatPassword ? 'text' : 'password'}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleClickShowPassword({ showRepeatPassword: !values.showRepeatPassword });
                            }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete:"new-password"
                    }}
                  />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate('/login');
                  }}
                >
                  Voltar para login
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || !isValid}
                  sx={{ mt: 3 }}
                >
                  Definir Senha
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
}

export default DefinePassword;
