import React from 'react';
import { alpha, styled } from '@mui/material/styles';

const ContainerStyled = styled('div')(({ theme, color, disabled }) => {
  return {
    cursor: 'default',
    height: '32px',
    display: 'inline-flex',
    outline: '0',
    padding: '0',

    boxSizing: 'border-box',
    transition:
      'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    whiteSpace: 'nowrap',
    borderRadius: '16px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: color === 'primary' ?? theme.palette.primary.contrastText,
    backgroundColor: color === 'default' ? '#d0d0d0' : theme.palette.primary.main,
    borderColor: alpha(theme.palette.primary.light, 0.6),
    borderWidth: disabled ? 0 : 2,
    borderStyle: 'solid',
    marginRight: theme.spacing(1),
  };
});

const ButtonStyled = styled('button')(({ theme, color, disabled, label }) => {
  return {
    backgroundColor: 'transparent !important',
    border: 'none',
    color: color === 'primary' ? 'white' : 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled && 'none',
    opacity: disabled && '0.5',
  };
});

export default function CustomChip({ label, disabled, onClick, color, className, style, children }) {
  return (
    <ContainerStyled className={className} color={color} disabled={disabled}>
      <ButtonStyled color={color} disabled={disabled} onClick={onClick} style={style}>
        {label}
      </ButtonStyled>
      {children}
    </ContainerStyled>
  );
}
