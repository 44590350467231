import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { failures, statusOutcomeDetail, statusCharge, statusInvoice, statusOutcome } from './stripeHelpers';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import { faBoxArchive, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import HintText from '../../../components/labels/HintText';
import NumberUtils from '../../../commons/utils/numberUtils';

// COMPONENT
function StripeDetailPaymentIntentModal({ detailPaymentIntent, open, onClose }) {
  const DialogActionsStyled = styled(DialogActions)(({ theme }) => {
    return {
      justifyContent: 'space-between',
      margin: '10px',
    };
  });

  const dotCard = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
      ••••
    </Box>
  );

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Detalhes da Fatura
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {(!detailPaymentIntent || detailPaymentIntent.length === 0) && (
          <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
            <HintText>Esta fatura não possue informações adicionais.</HintText>
          </Box>
        )}
        {detailPaymentIntent?.map((charge, index) => {
          return (
            <div key={charge?.id}>
              {charge?.refunds?.length > 0 && !charge?.refunded ? (
                <>
                  {charge?.refunds?.map((refund, i) => {
                    return (
                      <>
                        <Grid container mt={i > 0 && 2} justifyContent={'space-between'} width="530px">
                          <Grid item width="530px">
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography marginRight={'10px'}>
                                <FontAwesomeIconStatus status="info" icon={faRotateLeft} />
                                {`  Reembolso parcial de ${NumberUtils.toCurrency(refund.amount, 2)}`}
                              </Typography>
                            </Box>
                            <Typography variant="body2" color="textSecondary" component="span">
                              {dayjs(refund?.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                            </Typography>
                            <Box display="flex" flexDirection="row" alignItems={'center'}>
                              <Typography variant="body2" color="textSecondary" component="span">
                                {'Os fundos podem levar alguns dias para chegar à conta bancária do cliente.'}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider sx={{ mt: '5px' }} />
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {charge?.refunded && (
                    <>
                      <Grid container justifyContent={'space-between'} width="530px">
                        <Grid item width="530px">
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Typography marginRight={'10px'}>
                              <FontAwesomeIconStatus status="info" icon={faRotateLeft} />
                              {'  Pagamento reembolsado'}
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="textSecondary" component="span">
                            {dayjs(charge?.refunds[0]?.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                          </Typography>
                          <Box display="flex" flexDirection="row" alignItems={'center'}>
                            <Typography variant="body2" color="textSecondary" component="span">
                              {'Os fundos podem levar alguns dias para chegar à conta bancária do cliente.'}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: '5px' }} />
                    </>
                  )}
                </>
              )}

              <Grid container mt={2} justifyContent={'space-between'} width="530px">
                <Grid item width="530px">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography marginRight={'10px'}>
                      <FontAwesomeIconStatus
                        status={statusCharge[charge?.status].color}
                        icon={statusCharge[charge?.status].icon}
                      />
                      {'  '}
                      {charge?.outcomeReason !== null || charge?.failureCode !== null ? (
                        <>
                          {charge?.outcomeReason !== null ? (
                            <>{statusOutcome[charge?.outcomeReason]}</>
                          ) : (
                            <>{charge?.failureCode !== null && <>{failures[charge?.failureCode]}</>}</>
                          )}
                        </>
                      ) : (
                        <>{statusInvoice[charge?.status].label}</>
                      )}
                    </Typography>
                    {charge?.paymentMethodDetail && (
                      <Typography variant="body2" color="textSecondary" component="span">
                        <>
                          <FontAwesomeIconStatus status="primary" icon="credit-card" />{' '}
                          {charge.paymentMethodDetail?.brand}
                          {dotCard}
                          {charge.paymentMethodDetail?.last4}
                        </>
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="body2" color="textSecondary" component="span">
                    {dayjs(charge?.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                  </Typography>
                  {charge?.outcomeReason && (
                    <Box display="flex" flexDirection="row" alignItems={'center'}>
                      <Typography variant="body2" color="textSecondary" component="span">
                        {statusOutcomeDetail[charge?.outcomeReason]}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ mt: '5px' }} />
              {index === detailPaymentIntent.length - 1 && (
                <Grid container mt={2} justifyContent={'space-between'}>
                  <Grid item>
                    <Box display="flex" flexDirection="row" alignItems={'center'}>
                      <Typography marginRight={'10px'}>
                        <FontAwesomeIconStatus status="success" icon={faBoxArchive} />
                        {'  '}
                        Pagamento iniciado
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary" component="span">
                      {dayjs(charge?.created).format('DD [de] MMM[.] [de] YY HH:mm')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
      </DialogContent>

      <DialogActionsStyled>
        <Button onClick={onClose} color="primary" variant="outlined">
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default StripeDetailPaymentIntentModal;
