import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Field, getIn } from 'formik';

export const RenderDateSelectField = ({
  field,
  form: { touched, errors, setFieldValue },
  onChangeChecker,
  fullWidth,
  ...others
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <DatePicker
      dayOfWeekFormatter={(day) => `${day}.`}
      format="DD/MM/YYYY"
      name={field.name}
      value={field.value}
      onChange={(e) => {
        if (e?.isValid()) {
          setFieldValue(field.name, e);
        } else {
          setFieldValue(field.name, null);
        }
      }}
      slotProps={{
        textField: {
          fullWidth: fullWidth,
          error: touch && error != null,
          helperText: touch && error,
          variant: 'standard',
          InputLabelProps: { shrink: true },
        },
      }}
      {...others}
    />
  );
};

export const FormDateField = ({ name, ...others }) => (
  <Field name={name} component={RenderDateSelectField} {...others} />
);
