const NONE = 'NONE';
const DONE = 'DONE';
const NO_SHOW = 'NO_SHOW';
const TO_BE_CONFIRMED = 'TO_BE_CONFIRMED';
const DELAYED = 'DELAYED';
const CANCELED = 'CANCELED';
const CONFIRMED = 'CONFIRMED';

export const APPOINTMENT_TAG = {
  [NONE]: {
    id: 'NONE',
    name: 'Nenhum',
  },
  [DONE]: {
    id: 'DONE',
    name: 'Finalizado',
  },
  [NO_SHOW]: {
    id: 'NO_SHOW',
    name: 'Não comparecimento',
  },
  [TO_BE_CONFIRMED]: {
    id: 'TO_BE_CONFIRMED',
    name: 'À confirmar',
  },
  [DELAYED]: {
    id: 'DELAYED',
    name: 'Atrasou',
  },
  [CANCELED]: {
    id: 'CANCELED',
    name: 'Cancelado',
  },
  [CONFIRMED]: {
    id: 'CONFIRMED',
    name: 'Confirmado',
  },
};

export function getAppointmentTagLabelById(id) {
  return APPOINTMENT_TAG[id].name;
}
