import { Box } from '@mui/material';
import React from 'react';
import { FormSelect } from '../../components/forms/formik/FormSelect';
import { priorities } from './featureListHelpers';

// COMPONENT
function FeaturePriorityFormSelect(props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormSelect
        fullWidth
        label="Prioridade"
        name="priority"
        placeholder="Selecione uma prioridade..."
        dataSource={priorities}
        {...props}
      />
    </Box>
  );
}

export default FeaturePriorityFormSelect;
