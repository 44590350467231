import React, { useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';

import { currentUserSelector, useAppStore } from '../../stores/appStore';
import menuItems from '../../routes/menuItems';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardLayoutContent from './DashboardLayoutContent';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 240,
  },
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

// COMPONENT
const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const currentUser = useAppStore(currentUserSelector);
  const location = useLocation();

  const isLoggedIn = currentUser != null && currentUser.id > 0;

  const handleMobileClose = React.useCallback(() => setMobileNavOpen(false), []);
  const handleMobileOpen = React.useCallback(() => setMobileNavOpen(true), []);

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={handleMobileOpen} />
      <DashboardSidebar
        onMobileClose={handleMobileClose}
        openMobile={isMobileNavOpen}
        menuItems={menuItems}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
