import HttpService from './commons/HttpService';

const ExampleQuestionService = {
  create(exampleQuestion) {
    return HttpService.post('/exampleQuestions', exampleQuestion);
  },

  update(exampleQuestion) {
    return HttpService.put(`/exampleQuestions/${exampleQuestion.id}`, exampleQuestion);
  },

  save(exampleQuestion) {
    if (exampleQuestion.id && exampleQuestion.id > 0) {
      return this.update(exampleQuestion);
    }
    return this.create(exampleQuestion);
  },

  delete(exampleQuestion) {
    return HttpService.delete(`/exampleQuestions/${exampleQuestion.id}`);
  },

  getById(id) {
    return HttpService.get(`/exampleQuestions/${id}`);
  },

  findAll(employeeId, exampleQuestionCategoryId) {
    return HttpService.get('/exampleQuestions', { params: { employeeId, exampleQuestionCategoryId } });
  },
};

export default ExampleQuestionService;
