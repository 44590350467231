/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, Tooltip, IconButton, styled } from '@mui/material';

const TableCellStyled = styled(TableCell)(() => ({
  paddingRight: 0,
  paddingLeft: 0,
  width: '60px',
}));

// COMPONENT
function TableCellAction({
  title,
  children,
  onClick,

  buttonClassName,
  paddingNone,
  actionsDisabled,
}) {
  return (
    <TableCellStyled padding={paddingNone ? 'none' : 'default'} align="center">
      <Tooltip title={title}>
        <IconButton
          aria-label={title}
          color="primary"
          onClick={onClick}
          className={buttonClassName}
          disabled={actionsDisabled}
        >
          {children}
        </IconButton>
      </Tooltip>
    </TableCellStyled>
  );
}

TableCellAction.defaultProps = {
  children: null,
  paddingNone: true,
  actionsDisabled: false,
};

TableCellAction.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  paddingNone: PropTypes.bool,
  actionsDisabled: PropTypes.bool,
};
export default TableCellAction;
