import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import CompanyOnlineSchedulingSettingsExpedient from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsExpedient';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function CompanyWorkingScheduleModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [companyOnlineSchedulingExpedient, setCompanyOnlineSchedulingExpedient] = useState();

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalVisible}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle variant="h5">Configuração de expediente da empresa</DialogTitle>

        <DialogContent dividers>
          <CompanyOnlineSchedulingSettingsExpedient
            companyId={selectedCompany.id}
            companyOnlineSchedulingExpedient={companyOnlineSchedulingExpedient}
            setCompanyOnlineSchedulingExpedient={setCompanyOnlineSchedulingExpedient}
            open={modalVisible}
          />
        </DialogContent>

        <DialogActionsStyled>
          <Button variant="outlined" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default CompanyWorkingScheduleModal;
