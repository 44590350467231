export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

const getRemaining = value => (value % 11 < 2 ? 0 : 11 - (value % 11));

const generateCheckSums = (numbers, validators) => {
  const initialCheckSums = [0, 0];

  return validators.reduce(
    ([checkerA, checkerB], validator, index) => [
      index === 0 ? 0 : checkerA + numbers[index - 1] * validator,
      checkerB + numbers[index] * validator,
    ],
    initialCheckSums,
  );
};

const isRepeatedArray = items => items.every(item => items[0] === item);

const mapToNumeric = value => value.replace(/\D/g, '');

const mapToNumbers = value =>
  mapToNumeric(value)
    .split('')
    .map(Number);

export function isValidCNPJ(value) {
  if (typeof value !== 'string') return false;
  value = value.replace(/[\s.-]*/gim, '');
  value = value.replace(/\//g, '');

  if (!/^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/.test(value)) return false;
  const numbers = mapToNumbers(value);
  if (isRepeatedArray(numbers)) return false;
  const validators = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const checkers = generateCheckSums(numbers, validators);
  return numbers[12] === getRemaining(checkers[0]) && numbers[13] === getRemaining(checkers[1]);
}
