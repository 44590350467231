/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import { Field, getIn } from 'formik';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';

export const RenderFormSelectAutocomplete = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  tooltip,
  children,
  dataSource,
  isLoading,
  placeholder,
  getSuggestionLabel,
  onChangeCallback,
  ...others
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <Autocomplete
      options={dataSource}
      value={field.value ?? null}
      onChange={(event, value) => {
        if (onChangeCallback) {
          onChangeCallback(value);
        }
        setFieldValue(field.name, value);
      }}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (option ? getSuggestionLabel(option) : '')}
      loadingText="Loading..."
      loading={isLoading}
      noOptionsText="No options found."
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true
          }}
          variant="standard"
          InputProps={{
            autoComplete: 'off',
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          error={touch && error != null}
          helperText={touch && error}
        />
      )}
      {...others}
    />
  );
};

RenderFormSelectAutocomplete.defaultProps = {
  placeholder: null,
  label: null,
  getSuggestionLabel: (opt) => opt.name
};

export const FormSelectAutocomplete = ({ name, dataSource, ...others }) => (
  <Field
    name={name}
    component={RenderFormSelectAutocomplete}
    dataSource={dataSource}
    {...others}
  />
);
