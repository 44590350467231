const MONTH_LIST = [
  { id: 'January', name: 'Janeiro' },
  { id: 'February', name: 'Fevereiro' },
  { id: 'March', name: 'Março' },
  { id: 'April', name: 'Abril' },
  { id: 'May', name: 'Maio' },
  { id: 'June', name: 'Junho'},
  { id: 'July', name: 'Julho' },
  { id: 'August', name: 'Agosto' },
  { id: 'September', name: 'Setembro' },
  { id: 'October', name: 'Outubro' },
  { id: 'November', name: 'Novembro' },
  { id: 'December', name: 'Dezembro' },
];

const MonthUtils = {
  getByIndex(index) {
    return MONTH_LIST[index];
  },
  values() {
    return MONTH_LIST;
  }
};
export { MonthUtils as default };
