import React from 'react';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, styled
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FormSelect } from '../../../components/forms/FormikFields';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const DEFAULT_INIT_VALUES = {
  coupon: '',
};

// COMPONENT
function StripeDiscountCoupon({ discountCoupons, open, onClose, onSave }) {
  
  const myHandleSubmit = (values, actions) => {
    onSave(values.coupon);
    onClose();
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle variant="h5">Selecione o copum que será aplicado</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={DEFAULT_INIT_VALUES}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <div>
                <FormSelect fullWidth label="Cupom" name="coupon" dataSource={discountCoupons} />
              </div>
            </DialogContent>
            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default StripeDiscountCoupon;
