import { STORAGE_USER } from '../commons/constants';

export const STORAGE_WHATSAPP_IS_DESKTOP = 'minhaagenda-spa-STORAGE_WHATSAPP_IS_DESKTOP';

const StorageService = {
  getCurrentUser() {
    const loggedInUserInStorage = window.localStorage.getItem(STORAGE_USER);
    if (loggedInUserInStorage && loggedInUserInStorage !== 'undefined') {
      try {
        return JSON.parse(loggedInUserInStorage);
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  },

  setCurrentUser(loggedInUser) {
    window.localStorage.setItem(STORAGE_USER, JSON.stringify(loggedInUser));
  },

  removeUser() {
    window.localStorage.setItem(STORAGE_USER, null);
  },

  setIsWhatsAppDesktop(isWhatsAppDesktop) {
    window.localStorage.setItem(STORAGE_WHATSAPP_IS_DESKTOP, `${isWhatsAppDesktop}`);
  },
  getIsWhatsAppDesktop() {
    const selectedString = window.localStorage.getItem(STORAGE_WHATSAPP_IS_DESKTOP);
    return selectedString != null && selectedString !== '' ? selectedString === 'true' : true;
  },
};
export default StorageService;
