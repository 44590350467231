/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'Preencha esse campo para continuar',
  },
  number: {
    min: 'Deve ser maior que ${min}',
    max: 'Deve ser menor que ${max}',
    moreThan: 'Deve ser maior que ${more}',
    lessThan: 'Deve ser menor que ${less}',
  },
  string: {
    min: 'Deve ser maior que ${min}',
    max: 'Deve ser menor que ${max}',
    email: 'Preencha um e-mail válido',
  },
  date: {
    min: '${path} deve ser posterior a ${min}',
    max: '${path} deve ser anterior a ${max}',
  },
});

export default yup;
