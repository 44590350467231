import React from 'react';
import { FormTextFieldWithMask } from './FormTextFieldWithMask';

const MASK = '000.000.000-00';
const DEFINITIONS = {
  '#': /[1-9]/,
};

export function FormCpfField({ ...others }) {
  return <FormTextFieldWithMask mask={MASK} definitions={DEFINITIONS} {...others} />;
}
