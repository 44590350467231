import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Root = styled('span')(({ theme, status }) => {
  const themeForStatus = theme.palette[status];
  return {
    backgroundColor: alpha(themeForStatus?.light ?? 'black', 0.1),
    cursor: 'default',
    height: '20px',
    display: 'inline-flex',
    padding: '12px 8px',
    flexGrow: '0',
    minWidth: '20px',
    alignItems: 'center',
    flexShrink: '0',
    borderRadius: '3px',
    justifyContent: 'center',
  };
});

const TypographyStyled = styled(Typography)(({ theme, status, uppercase }) => {
  const themeForStatus = theme.palette[status];
  return {
    color: themeForStatus?.main ?? 'black',
    textTransform: uppercase ? 'uppercase' : 'lowercase',
  };
});

// COMPONENT
function HighLabelStatus({ status, children, uppercase, variant, className, ...rest }) {
  return (
    <Root className={className} status={status} uppercase={uppercase} {...rest}>
      <TypographyStyled status={status} variant={variant}>
        {children}
      </TypographyStyled>
    </Root>
  );
}

export default HighLabelStatus;
