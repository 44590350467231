import React, { useState } from 'react';
import ImportCustomersConfirm from './ImportCustomersConfirm';
import ImportCustomersForm from './ImportCustomersForm';


// COMPONENT
function ImportCustomersModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isWorking, setIsWorking] = useState(false);
  const [phoneDDD, setPhoneDDD] = useState('');
  const [importingStatus, setImportingStatus] = useState();
  const [modalConfirmImportingOpen, setModalConfirmImportingOpen] = useState(false);

  return (
    <>
      <ImportCustomersForm
        selectedCompany={selectedCompany}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        isWorking={isWorking}
        setIsWorking={setIsWorking}
        phoneDDD={phoneDDD}
        setPhoneDDD={setPhoneDDD}
        setImportingStatus={setImportingStatus}
        setModalConfirmImportingOpen={setModalConfirmImportingOpen}
      />

      <ImportCustomersConfirm
        selectedCompany={selectedCompany}
        setModalVisible={setModalVisible}
        isWorking={isWorking}
        setIsWorking={setIsWorking}
        importingStatus={importingStatus}
        modalConfirmImportingOpen={modalConfirmImportingOpen}
        setModalConfirmImportingOpen={setModalConfirmImportingOpen}
      />
    </>
  );
}

export default ImportCustomersModal;
