import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Field } from 'formik';

export const RenderSwitchField = ({ field, form: { touched, errors }, color, label, ...others }) => {
  // const touch = getIn(touched, field.name);
  // const error = getIn(errors, field.name);

  const handleChange = (event) => {
    field.onChange(event);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={field.value} color={color} onChange={handleChange} name={field.name} />}
        label={label}
      />
    </FormGroup>
  );
};

export const FormSwitchField = ({ name, ...others }) => (
  <Field name={name} component={RenderSwitchField} {...others} />
);
