import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  styled,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { Form, Formik } from 'formik';
import { FormCheckbox, FormDateField, FormTextField } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';

import showNotification from '../../../commons/helpers/showNotification';
import { isValidCNPJ, isValidCPF } from '../../../commons/utils/personIdentifierDocumentNumberUtils';
import yup from '../../../commons/validators/customYup';
import FontAwesomeDoubleIconStatus from '../../../components/icon/FontAwesomeDoubleIconStatus';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import LabelStatus from '../../../components/labels/LabelStatus';
import AsaasCreateChargeModal from './AsaasCreateChargeModal';
import AsaasCreateSubscriptionModal from './AsaasCreateSubscriptionModal';
import AsaasPaymentHistoryTable from './AsaasPaymentHistoryTable';
import AsaasSubscriptionHistoryTable from './AsaasSubscriptionHistoryTable';
import AsaasUpdatePaymentModal from './AsaasUpdatePaymentModal';
import AsaasUpgradeSubscriptionModal from './AsaasUpgradeSubscriptionModal';
import NumberUtils from '../../../commons/utils/numberUtils';
import { FormTextFieldWithMask } from '../../../components/forms/formik/FormTextFieldWithMask';
import dayjs from 'dayjs';
import { ACCOUNT_TRIAL } from '../../../commons/utils/accountUtils';
import AsaasSubscriptionDiscountModal from './AsaasSubscriptionDiscountModal';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
  cpfCnpj: yup
    .string()
    .test({
      name: 'cpf-validation',
      // eslint-disable-next-line no-template-curly-in-string
      message: 'Não é um cpf/cnpj válido',
      test: (val) => {
        if (val) {
          return isValidCPF(val) ? true : isValidCNPJ(val);
        }
        return true;
      },
    })
    .required(),
  dueDate: yup.date().nullable().required(),
  planId: yup.number().required(),
  applySpecialDiscountPrice: yup.boolean().required(),
  applyIntroductoryPrice: yup.boolean().required(),
});

const schemaUpdate = yup.object().shape({
  email: yup.string().email().required(),
  cpfCnpj: yup
    .string()
    .test({
      name: 'cpf-validation',
      // eslint-disable-next-line no-template-curly-in-string
      message: 'Não é um cpf/cnpj válido',
      test: (val) => {
        if (val) {
          return isValidCPF(val) ? true : isValidCNPJ(val);
        }
        return true;
      },
    })
    .required(),
});

const DEFAULT_INIT_VALUES = {
  email: '',
  cpfCnpj: '',
  dueDate: null,
  planId: '',
  applySpecialDiscountPrice: false,
  applyIntroductoryPrice: false,
};
const DEFAULT_INIT_VALUES_UPDATE = {
  email: '',
  cpfCnpj: '',
};

// COMPONENT
function AsaasAccountInfoModal({
  selectedCompany,
  open,
  onClose,
  onSave,
  openConfirmationModal,
  setManageAsaasInfoModalOpen,
  setModalConfirm,
}) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [initialValuesUpdate, setInitialValuesUpdate] = useState(DEFAULT_INIT_VALUES_UPDATE);
  const [modalCreateSubOpen, setModalCreateSubOpen] = useState(false);
  const [modalUpgradeSubOpen, setModalUpgradeSubOpen] = useState(false);
  const [modalCreateChargeOpen, setModalCreateChargeOpen] = useState(false);
  const [modalUpgradePayment, setModalUpgradePayment] = useState(false);
  const [modalUpdateSubscription, setModalUpdateSubscription] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [value, setValue] = useState(0);
  const [payment, setPayment] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [asaasSubscription, setAsaasSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaymentHistory, setIsLoadingPaymentHistory] = useState(false);
  const [isLoadingAsaasSubscription, setIsLoadingAsaasSubscription] = useState(false);
  const [asaasPlans, setAsaasPlans] = useState([]);
  const [suggestedIntroductoryPrice, setSuggestedIntroductoryPrice] = useState(null);
  const [suggestedSpecialPrice, setSuggestedSpecialPrice] = useState(null);
  const { maxNumberUsers } = selectedCompany;
  const mask = [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }];
  const asaasSubscriptionId = selectedCompany.asaasSubscriptionId;

  useEffect(() => {
    if (open) {
      setValue(0);
      setPaymentHistory(null);
      setAsaasSubscription(null);
      setIsLoading(true);
      setSuggestedIntroductoryPrice(null);
      setSuggestedSpecialPrice(null);

      if (!selectedCompany.asaasCustomerId) {
        setInitialValues({
          email: selectedCompany.email,
          cpfCnpj: selectedCompany.cpf ? selectedCompany.cpf : selectedCompany.cnpj,
          timestamp: new Date().getTime(),
          dueDate:
            selectedCompany.accountType === ACCOUNT_TRIAL &&
            !dayjs().isAfter(selectedCompany.trialExpirationDate)
              ? dayjs(selectedCompany.trialExpirationDate)
              : dayjs(),
          planId: '',
          applySpecialDiscountPrice: false,
          applyIntroductoryPrice: selectedCompany.accountType === ACCOUNT_TRIAL ? true : false,
        });
        SuperUserService.getAsaasPlan(maxNumberUsers)
          .then((respPlan) => {
            setAsaasPlans(respPlan);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setInitialValuesUpdate({
          email: selectedCompany.email,
          cpfCnpj: selectedCompany.cpf ? selectedCompany.cpf : selectedCompany.cnpj,
        });
      }
    }
  }, [open, selectedCompany, maxNumberUsers]);

  const myHandleUpdateAsaasSubmit = (values, actions) => {
    SuperUserService.updateAsaasCustomer({
      companyId: selectedCompany.id,
      email: values.email,
      cpfCnpj: values.cpfCnpj,
    })
      .then((asaasCustomerUpdateInfo) => {
        showNotification('success', 'Atualizado com successo.');
        if (asaasCustomerUpdateInfo.cpfCnpj.length === 11) {
          onSave({
            ...selectedCompany,
            cpf: asaasCustomerUpdateInfo.cpfCnpj,
            cnpj: null,
            ...asaasCustomerUpdateInfo,
          });
        } else {
          onSave({
            ...selectedCompany,
            cnpj: asaasCustomerUpdateInfo.cpfCnpj,
            cpf: null,
            ...asaasCustomerUpdateInfo,
          });
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const myHandleCreateAsaasSubmit = (values, actions) => {
    SuperUserService.createAsaasSubscription({
      dueDate: values.dueDate.format('YYYY-MM-DD'),
      email: values.email,
      cpfCnpj: values.cpfCnpj,
      planId: values.planId,
      applyIntroductoryPrice: values.applyIntroductoryPrice,
      applySpecialDiscountPrice: values.applySpecialDiscountPrice,
      companyId: selectedCompany.id,
    })
      .then((asaasCustomerUpdateInfo) => {
        showNotification('success', 'Atualizado com successo.');
        onSave({
          ...selectedCompany,
          email: values.email,
          cpf: values.cpfCnpj.length === 11 ? values.cpfCnpj : null,
          cnpj: values.cpfCnpj.length === 14 ? values.cpfCnpj : null,
          asaasSubscriptionId: asaasCustomerUpdateInfo.id,
          asaasCustomerId: asaasCustomerUpdateInfo.customer,
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleChange = (event, tab) => {
    setValue(tab);
  };

  const handleEditCharge = (payment) => {
    setPayment(payment);
    setModalUpgradePayment(true);
  };

  const handleEditSubscription = (subscription) => {
    setSubscription(subscription);
    setModalUpdateSubscription(true);
  };

  const handleCancel = (payment) => {
    openConfirmationModal(
      'Deletar cobrança do Asaas',
      'Ao confirmar, a cobrança será deletada. Deseja deletar a cobrança do Asaas?',
      () => {
        handleCancelCharge(payment);
      },
      '',
      '',
    );
  };

  const handleCancelCharge = (charge) => {
    setIsLoading(true);
    SuperUserService.deleteAsaasCharge({
      companyId: selectedCompany.id,
      chargeId: charge.id,
    })
      .then(() => {
        setPaymentHistory((paymentHistory) => paymentHistory.filter((payment) => payment.id !== charge.id));
        showNotification('success', 'Cobrança deletada com sucesso.');
      })
      .finally(() => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setIsLoading(false);
      });
  };

  const handlePaymentHistoryTab = () => {
    if (selectedCompany.asaasCustomerId) {
      if (!paymentHistory) {
        setIsLoadingPaymentHistory(true);
        SuperUserService.getAsaasPaymentHistory(selectedCompany.asaasCustomerId, 5)
          .then((asaasPaymentHistory) => {
            setPaymentHistory(asaasPaymentHistory);
          })
          .finally(() => {
            setIsLoadingPaymentHistory(false);
          });
      }
      if (!asaasSubscription) {
        setIsLoadingAsaasSubscription(true);
        SuperUserService.getAsaasSubscription(selectedCompany.id)
          .then((asaasSubscriptions) => {
            setAsaasSubscription(asaasSubscriptions);
          })
          .finally(() => {
            setIsLoadingAsaasSubscription(false);
          });
      }
    }
  };

  const handleAsaasDeleteAccount = () => {
    openConfirmationModal(
      'Deletar conta no Asaas',
      'Ao confirma, o cadastro de CPF e CNPJ do cliente serão removidos. Deseja excluir o cadastro do usuário no Asaas?',
      asaasDeleteAccount,
      'confirmo deletar cliente asaas',
    );
  };

  const handleDeleteSubscription = () => {
    const answer = window.confirm(
      'Você tem certeza que deseja deletar está assinatura Asaas? Isso deletará todas as cobranças em aberto.',
    );
    if (answer) {
      setIsDeleting(true);
      SuperUserService.deleteAsaasSubscription(selectedCompany.id)
        .then((updatedCompany) => {
          showNotification('success', 'Deletado com successo.');
          onSave(updatedCompany);
          onClose();
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  const asaasDeleteAccount = () => {
    setIsLoading(true);
    SuperUserService.deleteAsaas(selectedCompany.id)
      .then((company) => {
        showNotification('success', 'Cliente apagado com sucesso no Asaas!');
        onSave({
          ...company,
        });
        setManageAsaasInfoModalOpen(false);
      })
      .finally(() => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      onClose={() => {
        if (!isDeleting) {
          onClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      {/* MODAL */}
      <AsaasCreateSubscriptionModal
        selectedCompany={selectedCompany}
        open={modalCreateSubOpen}
        onClose={() => {
          setModalCreateSubOpen(false);
        }}
        onSave={(asaasSubcription) => {
          setModalCreateSubOpen(false);
          onSave({
            ...selectedCompany,
            asaasSubscriptionId: asaasSubcription.id,
          });
          onClose();
        }}
      />
      <AsaasUpdatePaymentModal
        selectedCompany={selectedCompany}
        payment={payment}
        open={modalUpgradePayment}
        onClose={() => {
          setModalUpgradePayment(false);
        }}
        onSave={() => {
          onClose();
        }}
      />
      <AsaasSubscriptionDiscountModal
        selectedCompany={selectedCompany}
        subscription={subscription}
        open={modalUpdateSubscription}
        onClose={() => {
          setModalUpdateSubscription(false);
        }}
        onSave={() => {
          onClose();
        }}
      />
      <AsaasUpgradeSubscriptionModal
        selectedCompany={selectedCompany}
        paymentHistory={paymentHistory}
        open={modalUpgradeSubOpen}
        onClose={() => {
          setModalUpgradeSubOpen(false);
        }}
        onSave={(company) => {
          setModalUpgradeSubOpen(false);
          onSave({ ...company });
          onClose();
        }}
      />
      <AsaasCreateChargeModal
        selectedCompany={selectedCompany}
        open={modalCreateChargeOpen}
        onClose={() => {
          setModalCreateChargeOpen(false);
        }}
        onSave={() => {
          setModalCreateChargeOpen(false);
          onClose();
        }}
      />
      {/* MODAL */}
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography component="span" variant="h5">
                Asaas - {selectedCompany.name}
              </Typography>
              {!selectedCompany.asaasCustomerId ? (
                <LabelStatus variant="subtitle2" component="div" status="warning">
                  Primeiro crie o cadastro Asaas abaixo
                </LabelStatus>
              ) : (
                <LabelStatus
                  variant="subtitle2"
                  component="div"
                  status="success"
                  onClick={() => {
                    const asaasUrlPrefix = process.env.NODE_ENV === 'production' ? 'www' : 'sandbox';
                    window.open(
                      `https://${asaasUrlPrefix}.asaas.com/customerAccount/show/${selectedCompany.asaasCustomerId}`,
                      '_blank',
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Cadastro Asaas ok
                </LabelStatus>
              )}
              {selectedCompany.asaasCustomerId && (
                <LabelStatus
                  variant="subtitle2"
                  component="div"
                  status="warning"
                  onClick={() => {
                    handleAsaasDeleteAccount();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Remover Cadastro Asaas
                </LabelStatus>
              )}
            </Box>

            {selectedCompany.asaasCustomerId && (
              <IconButton
                onClick={() => {
                  setModalCreateChargeOpen(true);
                }}
                disabled={isDeleting}
                style={{ marginLeft: '10px' }}
              >
                <FontAwesomeIconStatus icon="hand-holding-usd" status="primary" />
              </IconButton>
            )}
          </Box>

          {asaasSubscriptionId ? (
            <>
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={() => {
                    setModalUpgradeSubOpen(true);
                  }}
                  disabled={isDeleting}
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeDoubleIconStatus icon1="crown" icon2="angle-double-up" status="success" />
                </IconButton>
              </Box>
            </>
          ) : (
            <>
              {selectedCompany.asaasCustomerId && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setModalCreateSubOpen(true);
                    }}
                    disabled={!selectedCompany.asaasCustomerId}
                  >
                    Criar assinatura
                  </Button>
                </div>
              )}
            </>
          )}
        </Box>
        {selectedCompany.asaasCustomerId && (
          <Tabs onChange={handleChange} value={value}>
            <Tab color="primary" label="Dados do usuário" />
            <Tab
              color="primary"
              label="Assinatura"
              onClick={() => {
                handlePaymentHistoryTab();
              }}
            />
          </Tabs>
        )}
        <Divider />
      </DialogTitle>
      {!selectedCompany.asaasCustomerId ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleCreateAsaasSubmit}
        >
          {({ dirty, isSubmitting, values, setFieldValue, errors, isValid }) => (
            <>
              <DialogContent>
                <Box sx={{ width: '100%' }}>
                  <>
                    {isLoading ? (
                      <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress color="inherit" size={20} />
                      </Box>
                    ) : (
                      <>
                        <Form>
                          <Grid
                            container
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={3}>
                              <FormTextField fullWidth label="Email" name="email" />
                            </Grid>
                            <Grid item xs={3}>
                              <FormTextFieldWithMask fullWidth mask={mask} label="CPF/CNPJ" name="cpfCnpj" />
                            </Grid>
                            <Grid item xs={3}>
                              <FormDateField fullWidth label="Data do vencimento" name="dueDate" />
                            </Grid>
                          </Grid>
                          <Divider />
                          <Box
                            sx={{
                              mt: 3,
                              mb: 3,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {!isLoading && asaasPlans && (
                              <>
                                <Typography marginBottom={1} component="span" variant="h5">
                                  Dados do plano
                                </Typography>
                                <ToggleButtonGroupStyled
                                  sx={{ margin: 0 }}
                                  fullWidth
                                  size="small"
                                  value={`${values.planId}`}
                                  onChange={(evt, newValue) => {
                                    if (newValue != null) {
                                      setFieldValue('planId', newValue);
                                    }
                                  }}
                                  exclusive
                                >
                                  {asaasPlans.map((plan) => (
                                    <ToggleButtonStyled
                                      key={plan.id}
                                      value={`${plan.id}`}
                                      onClick={() => {
                                        setSuggestedIntroductoryPrice(plan.introductoryPrice);
                                        setSuggestedSpecialPrice(plan.specialDiscountPrice);
                                      }}
                                    >
                                      <ToggleTextStyled>
                                        {plan.name}
                                        {plan.introductoryPrice && (
                                          <>
                                            <br />
                                            Intro: {NumberUtils.toCurrency(plan.introductoryPrice, 2)}
                                          </>
                                        )}
                                        <br />
                                        Preço: {NumberUtils.toCurrency(plan.price, 2)}
                                      </ToggleTextStyled>
                                    </ToggleButtonStyled>
                                  ))}
                                </ToggleButtonGroupStyled>
                              </>
                            )}
                            {!isLoading && asaasPlans && asaasPlans.length === 0 && (
                              <Typography variant="h5">Nenhum plano encontrado</Typography>
                            )}
                            <Typography color="error">{errors?.planId}</Typography>
                          </Box>
                          {suggestedIntroductoryPrice && (
                            <Grid item xs={12}>
                              <FormCheckbox
                                label={
                                  'Aplicar preço ' +
                                  NumberUtils.toCurrency(suggestedIntroductoryPrice, 2) +
                                  ' na primeira cobrança'
                                }
                                name="applyIntroductoryPrice"
                              />
                            </Grid>
                          )}
                          {suggestedSpecialPrice && (
                            <Grid item xs={12}>
                              <FormCheckbox
                                label={
                                  'Aplicar preço especial de desconto de  ' +
                                  NumberUtils.toCurrency(suggestedSpecialPrice, 2) +
                                  ' em todas as cobranças'
                                }
                                name="applySpecialDiscountPrice"
                              />
                            </Grid>
                          )}
                        </Form>
                      </>
                    )}
                  </>
                </Box>
              </DialogContent>
              <Divider />
              <Form>
                <DialogActionsStyled>
                  <Button variant="outlined" onClick={onClose}>
                    Fechar
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    Criar assinatura
                  </Button>
                </DialogActionsStyled>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <>
          {value === 0 && (
            <Formik
              enableReinitialize
              initialValues={initialValuesUpdate}
              validationSchema={schemaUpdate}
              onSubmit={myHandleUpdateAsaasSubmit}
            >
              {({ dirty, isSubmitting, values, setFieldValue, errors, isValid }) => (
                <>
                  <DialogContent>
                    <Box sx={{ width: '100%' }}>
                      <Form>
                        <Grid
                          container
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={3}>
                            <FormTextField fullWidth label="Email" name="email" />
                          </Grid>
                          <Grid item xs={3}>
                            <FormTextFieldWithMask fullWidth mask={mask} label="CPF/CNPJ" name="cpfCnpj" />
                          </Grid>
                          <Grid item xs={3}></Grid>
                        </Grid>
                      </Form>
                    </Box>
                  </DialogContent>

                  <Divider />
                  <Form>
                    <DialogActionsStyled>
                      <Button variant="outlined" onClick={onClose}>
                        Fechar
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !dirty || !isValid}
                      >
                        Salvar
                      </Button>
                    </DialogActionsStyled>
                  </Form>
                </>
              )}
            </Formik>
          )}
          {value === 1 && (
            <>
              <DialogContent>
                <AsaasSubscriptionHistoryTable
                  asaasSubscription={asaasSubscription}
                  isLoading={isLoadingAsaasSubscription}
                  handleEdit={handleEditSubscription}
                  handleDelete={handleDeleteSubscription}
                />
                <AsaasPaymentHistoryTable
                  paymentHistory={paymentHistory}
                  isLoading={isLoadingPaymentHistory}
                  handleEdit={handleEditCharge}
                  handleCancel={handleCancel}
                />
              </DialogContent>
              <Divider />
              <DialogActionsStyled>
                <Button variant="outlined" onClick={onClose}>
                  Fechar
                </Button>
              </DialogActionsStyled>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}

export default AsaasAccountInfoModal;
