import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Box,
  Link,
} from '@mui/material';
import SuperUserService from '../../../services/SuperUserService';
import { FormTextField } from '../../../components/forms/FormikFields';
import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));
const DialogContentStyled = styled(DialogContent)(() => ({ overflow: 'auto', height: '70vh' }));

const schema = yup.object().shape({
  name: yup.string().max(150),
  headerText: yup.string().max(200),
  whatsNumber: yup.string().max(20),
  whatsTitle: yup.string().max(50),
  whatsMessage: yup.string().max(255),
  whatsNumber2: yup.string().max(20),
  whatsTitle2: yup.string().max(50),
  whatsMessage2: yup.string().max(255),
  whatsNumber3: yup.string().max(20),
  whatsTitle3: yup.string().max(40),
  whatsMessage3: yup.string().max(255),
  instagramUser: yup.string().max(100),
  address: yup.string().max(255),
  externalLinkTitle: yup.string().max(50),
  externalLinkUrl: yup.string().max(255),
});

const DEFAULT_INIT_VALUES = {
  name: '',
  headerText: '',
  whatsNumber: '',
  whatsTitle: '',
  whatsMessage: '',
  whatsNumber2: '',
  whatsTitle2: '',
  whatsMessage2: '',
  whatsNumber3: '',
  whatsTitle3: '',
  whatsMessage3: '',
  instagramUser: '',
  address: '',
  externalLinkTitle: '',
  externalLinkUrl: '',
};

// COMPONENT
function PageBioSettingsForCompanyModal({ companyPageBio, modalVisible, setModalVisible, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        name: companyPageBio.name ?? '',
        headerText: companyPageBio.headerText ?? '',
        whatsNumber: companyPageBio.whatsNumber ?? '',
        whatsTitle: companyPageBio.whatsTitle ?? '',
        whatsMessage: companyPageBio.whatsMessage ?? '',
        whatsNumber2: companyPageBio.whatsNumber2 ?? '',
        whatsTitle2: companyPageBio.whatsTitle2 ?? '',
        whatsMessage2: companyPageBio.whatsMessage2 ?? '',
        whatsNumber3: companyPageBio.whatsNumber3 ?? '',
        whatsTitle3: companyPageBio.whatsTitle3 ?? '',
        whatsMessage3: companyPageBio.whatsMessage3 ?? '',
        instagramUser: companyPageBio.instagramUser ?? '',
        instagramTitle: companyPageBio.instagramTitle ?? '',
        instagramUser2: companyPageBio.instagramUser2 ?? '',
        instagramTitle2: companyPageBio.instagramTitle2 ?? '',
        address: companyPageBio.address ?? '',
        address2: companyPageBio.address2 ?? '',
        externalLinkTitle: companyPageBio.externalLinkTitle ?? '',
        externalLinkUrl: companyPageBio.externalLinkUrl ?? '',
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, companyPageBio]);

  const myHandleSubmit = (values, actions) => {
    SuperUserService.changePageBioSettings({
      companyId: companyPageBio.companyId,
      ...values,
    })
      .then((pageBioUpdated) => {
        showNotification('success', 'Atualizado com successo.');
        actions.setSubmitting(false);
        onSave(pageBioUpdated);
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* DIALOG */}
      <Dialog onClose={handleClose} open={modalVisible}>
        <DialogTitle>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">Configuração da Página de BIO</Typography>
            <Link
              href={`https://maapp.com.br/${companyPageBio.slug}`}
              underline="hover"
              variant="h5"
              color="info"
              target="__blank"
            >
              {companyPageBio.slug}
            </Link>
          </Box>
        </DialogTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting }) => (
            <Form>
              <DialogContentStyled dividers>
                <FormTextField fullWidth label="Nome" name="name" />
                <FormTextField fullWidth label="Título da Página" name="headerText" />
                <FormTextField fullWidth label="Número telefone Whats" name="whatsNumber" />
                <FormTextField fullWidth label="Título botão Whats" name="whatsTitle" />
                <FormTextField fullWidth label="Mensagem Whats que será enviada" name="whatsMessage" />
                <FormTextField fullWidth label="Número telefone Whats 2" name="whatsNumber2" />
                <FormTextField fullWidth label="Título botão Whats 2" name="whatsTitle2" />
                <FormTextField fullWidth label="Mensagem Whats que será enviada 2" name="whatsMessage2" />
                <FormTextField fullWidth label="Número telefone Whats 3" name="whatsNumber3" />
                <FormTextField fullWidth label="Título botão Whats 3" name="whatsTitle3" />
                <FormTextField fullWidth label="Mensagem Whats que será enviada 3" name="whatsMessage3" />
                <FormTextField fullWidth label="Nome de Usuário Instagram" name="instagramUser" />
                <FormTextField fullWidth label="Título do Usuário Instagram" name="instagramTitle" />
                <FormTextField fullWidth label="Nome de Usuário Instagram 2" name="instagramUser2" />
                <FormTextField fullWidth label="Título do Usuário Instagram 2" name="instagramTitle2" />
                <FormTextField fullWidth label="Endereço" name="address" />
                <FormTextField fullWidth label="Endereço 2" name="address2" />
                <FormTextField fullWidth label="Título do link externo" name="externalLinkTitle" />
                <FormTextField fullWidth label="URL do link externo" name="externalLinkUrl" />
              </DialogContentStyled>

              <DialogActionsStyled>
                <Button variant="outlined" onClick={handleClose}>
                  Fechar
                </Button>
                <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                  Salvar
                </Button>
              </DialogActionsStyled>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default PageBioSettingsForCompanyModal;
