import React from 'react';

import { styled, Box } from '@mui/material';

const WorkScheduleBoxStyled = styled('div')(({ theme }) => ({
  padding: '4px',
  marginRight: '6px',
  background: theme.palette.primary.main,
  textAlign: 'center',
  borderRadius: '4px',
  color: theme.palette.primary.contrastText,
  maxWidth: '120px',
}));

// COMPONENT
function WorkScheduleListView({ workSchedule }) {
  function getWorkingScheduleStringForDay(day, workingSchedule) {
    if (workingSchedule[`${day}Enabled`]) {
      const startTime1 = workingSchedule[`${day}StartTime1`];
      const endTime1 = workingSchedule[`${day}EndTime1`];
      const startTime2 = workingSchedule[`${day}StartTime2`];
      const endTime2 = workingSchedule[`${day}EndTime2`];

      // contains second part
      if (startTime2) {
        return `${startTime1}-${endTime1} ${startTime2}-${endTime2}`;
      }
      return `${startTime1}-${endTime1}`;
    }
    return '-';
  }
  return (
    <Box display="flex">
      <WorkScheduleBoxStyled>
        <Box>Domingo</Box>
        {getWorkingScheduleStringForDay('sun', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Segunda</Box>
        {getWorkingScheduleStringForDay('mon', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Terça</Box>
        {getWorkingScheduleStringForDay('tue', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Quarta</Box>
        {getWorkingScheduleStringForDay('wed', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Quinta</Box>
        {getWorkingScheduleStringForDay('thu', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Sexta</Box>
        {getWorkingScheduleStringForDay('fri', workSchedule)}
      </WorkScheduleBoxStyled>
      <WorkScheduleBoxStyled>
        <Box>Sábado</Box>
        {getWorkingScheduleStringForDay('sat', workSchedule)}
      </WorkScheduleBoxStyled>
    </Box>
  );
}

export default WorkScheduleListView;
