import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Button, List, ListItem, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import NavItem from './NavItem';

const FontAwesomeIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  paddingLeft: 1,
  paddingRight: 2,
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    width: '100%',
    border: `0`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  paddingLeft: 0,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

//
function NavItemAndSubItem({ href, iconName, title, subItems, ...rest }) {
  const location = useLocation();
  const isExpandable = subItems?.length > 0;
  const [expanded, setExpanded] = useState(true);

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname,
      )
    : false;

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0,
          flexDirection: 'column',
        }}
        {...rest}
      >
        {isExpandable ? (
          <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary aria-controls="panel-content" id="panel-header">
              <Typography
                variant="span"
                sx={{
                  fontSize: '14px',
                  color: 'text.secondary',
                  fontWeight: 'medium',
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  // py: 1.25,
                  textTransform: 'none',
                  width: '100%',
                  ...(active && {
                    color: 'primary.main',
                  }),
                  '& svg': {
                    mr: 1,
                  },
                }}
              >
                {iconName && <FontAwesomeIconStyled icon={iconName} size="lg" fixedWidth />}
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{ paddingTop: 0 }}>
                {subItems.map((item, index) => (
                  <NavItem
                    href={item.href}
                    key={index}
                    title={item.title}
                    titleStyle={{ marginLeft: '32px' }}
                  />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Button
            component={RouterLink}
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1.25,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main',
              }),
              '& svg': {
                mr: 1,
              },
            }}
            to={href}
          >
            {iconName && <FontAwesomeIconStyled icon={iconName} size="lg" fixedWidth />}
            <span>{title}</span>
          </Button>
        )}
      </ListItem>
    </>
  );
}

export default NavItemAndSubItem;
