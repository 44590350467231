import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';

import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import SuperUserService from '../../../services/SuperUserService';
import { FormDateField, FormSelect } from '../../../components/forms/FormikFields';
import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  subscriptionType: yup.string().required(),
});

const DEFAULT_INIT_VALUES = {
  subscriptionType: '',
  lastPaidAt: null,
};

const SUBSCRIPTION_TYPES = ['STRIPE', 'ASAAS', 'APPLE', 'ANDROID', 'PAYPAL'].map((v) => ({ id: v, name: v }));

// COMPONENT
function ManageSubscriptionTypeInfoModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (open) {
      setInitialValues({
        lastPaidAt: selectedCompany.lastPaidAt ? dayjs(selectedCompany.lastPaidAt) : null,
        subscriptionType: selectedCompany.subscriptionType ?? '',
        timestamp: new Date().getTime(),
      });
    }
  }, [open, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const newValues = {
      lastPaidAt: values.lastPaidAt ? values.lastPaidAt.format('YYYY-MM-DD') : '',
      subscriptionType: values.subscriptionType ? values.subscriptionType : null,
    };
    SuperUserService.changeSubscriptionType({
      companyId: selectedCompany.id,
      ...newValues,
    })
      .then((companyUpdated) => {
        showNotification('success', 'Atualizado com successo.');
        onSave({ ...companyUpdated });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle variant="h5">Tipo de Assinatura - {selectedCompany.name}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <div>
                <FormSelect
                  fullWidth
                  label="Tipo de Assinatura"
                  name="subscriptionType"
                  dataSource={SUBSCRIPTION_TYPES}
                />
                <FormDateField fullWidth label="Último pagamento em" name="lastPaidAt" />
              </div>
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ManageSubscriptionTypeInfoModal;
