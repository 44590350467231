import { Box } from '@mui/material';
import React from 'react';
import { FormSelect } from '../../components/forms/formik/FormSelect';
import {  status } from './featureListHelpers';

// COMPONENT
function FeatureStatusFormSelect(props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormSelect
        fullWidth
        label="Status"
        name="status"
        placeholder="Selecione qual o status..."
        dataSource={status}
        {...props}
      />
    </Box>
  );
}

export default FeatureStatusFormSelect;
