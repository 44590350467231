import { ROLES_ADMIN } from '../commons/constants';

const menuItems = [
  {
    href: '/app/home-page',
    title: 'Home Page',
    iconName: 'house',
  },
  {
    href: '/app/manage-company',
    title: 'Empresas',
    iconName: 'building',
    requiredRoles: ROLES_ADMIN,
    subItems: [
      {
        href: '/app/manage-company',
        title: 'Gerenciar Empresa',
        iconName: 'user-gear',
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: '/app/manage-company/list-users',
        title: 'Pesquisa Aberta',
        iconName: 'search',
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: '/app/manage-company/list-stripe-users',
        title: 'Pesquisa Stripe',
        iconName: ['fab', 'stripe'],
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: '/app/manage-company/apple-order-search',
        title: 'Pesquisar Recibo Apple',
        iconName: ['fab', 'apple'],
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: '/app/manage-company/apple-transaction-history-search',
        title: 'Pesquisar Histórico de Transação Apple',
        iconName: ['fab', 'apple'],
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: '/app/manage-company/transfer-subscription',
        title: 'Transferir Assinatura',
        iconName: 'arrow-right-arrow-left',
        requiredRoles: ROLES_ADMIN,
      },
    ],
  },
  // {
  //   href: '/app/anamnese',
  //   title: 'Fichas Anamnese',
  //   iconName: 'file',
  //   requiredRoles: ROLES_ADMIN,
  // },
  {
    href: '/app/examples',
    title: 'Component Examples',
    iconName: 'coffee',
    isDevelopmentOnly: true,
  },
  {
    href: '/app/playground',
    title: 'Playground',
    iconName: 'coffee',
    isDevelopmentOnly: true,
  },
  {
    href: '/app/crudexample',
    title: 'Crud Example',
    iconName: 'coffee',
    isDevelopmentOnly: true,
  },
  {
    href: '/app/feature',
    title: 'Features',
    iconName: 'plus',
  },
  {
    href: '/app/employee',
    title: 'Funcionários',
    iconName: 'users-gear',
    requiredRoles: ROLES_ADMIN,
  },
];

export default menuItems.filter((p) => {
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv !== 'development') {
    return !p.isDevelopmentOnly;
  }
  return true;
});
