import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import exportToCsv from '../../../commons/helpers/exportToCsv';
import SuperUserService from '../../../services/SuperUserService';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';

// COMPONENT
function StripeDisputeDocumentsModal({ selectedCompany, chargeId, open, onClose }) {
  const DialogActionsStyled = styled(DialogActions)(({ theme }) => {
    return {
      justifyContent: 'space-between',
      margin: '10px',
    };
  });

  const handleDisputeAppointmentReport = async () => {
    const appointmentStripeDisputeReport = await SuperUserService.getStripeDisputeDocAppointmentReport({
      companyId: selectedCompany.id,
    });

    const preparedReport = appointmentStripeDisputeReport?.map((object) => {
      const baseObj = {
        'Data Criação': object.createdAt,
        'Criado Por': object.createdBy,
        'Data Atualização': object.updatedAt,
        'Atualizado Por': object.updatedBy,
        'Data do Agendamento': object.date,
        Horario: object.startTime,
        Serviço: object.serviceName,
        Cliente: object.customerName,
        Profissional: object.userName,
      };

      return baseObj;
    });

    const appointmentsFileName = `RelatorioAgendamentos_${selectedCompany.name}_Gerado${dayjs().format(
      'DD-MM-YY HH:mm:ss',
    )}`;
    exportToCsv(preparedReport, appointmentsFileName);
  };

  const handleDisputeReceiptUrl = async () => {
    const receiptUrl = await SuperUserService.getStripeDisputeDocReceiptUrl({
      companyId: selectedCompany.id,
      chargeId: chargeId,
    });
    window.open(receiptUrl, '_blank');
  };

  const handleDisputeStripeSubscription = async () => {
    const stripeSubscriptions = await SuperUserService.getStripeDisputeDocSubscriptionReport({
      companyId: selectedCompany.id,
    });

    const stripeSubscriptionReport = stripeSubscriptions?.map((object) => {
      const baseObj = {
        Id: object.id,
        'Código Empresa': object.companyId,
        'Stripe Subscription Id': object.stripeSubscriptionId,
        'Customer Id': object.customerId,
        'Session Id': object.sessionId,
        'Live Mode': object.liveMode,
        'Data Início': object.startDate,
        'Cancelado Em': object.canceledAt,
        Cancelado: object.cancelAt,
        'Dias até o vencimento': object.daysUntilDue,
        'Endereço Ip': object.ipAddress,
        'User Agent': object.userAgent,
        'Código Cupom': object.couponCode,
        'Data Criação': object.createdAt,
        'Data Atualização': object.updatedAt,
        'Data Ultimo Pagamento': object.lastPaidAt,
      };

      return baseObj;
    });
    const stripeSubscriptionFileName = `RelatorioAssinaturas_${selectedCompany.name}_Gerado${dayjs().format(
      'DD-MM-YY HH:mm:ss',
    )}`;
    exportToCsv(stripeSubscriptionReport, stripeSubscriptionFileName);
  };

  const handleDisputeAdditionalDetails = async () => {
    const createdAt = document.getElementById('createdAt');
    createdAt.style.display = '';
    const additionalDetails = document.getElementById('additionalDetails');
    const canvas = await html2canvas(additionalDetails);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    link.href = data;
    link.download = 'downloaded-image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    createdAt.style.display = 'none';
  };

  const handleDisputeTerms = () => {
    const url = '/images/Termos.jpg';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Termos.jpg`); //set the attribute of the <a> link tag to be downloadable when clicked and name the sheet based on the date and time right now.
    document.body.appendChild(link);
    link.click(); //programmatically click the link so the user doesn't have to
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm">
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Baixar documentos para disputa
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography gutterBottom>Log de atividade </Typography>
          <IconButton
            onClick={() => {
              handleDisputeAppointmentReport();
            }}
          >
            <FontAwesomeIconStatus icon="download" status="info" />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography gutterBottom>Recibo Stripe </Typography>
          <IconButton
            onClick={() => {
              handleDisputeReceiptUrl();
            }}
          >
            <FontAwesomeIconStatus icon="arrow-up-right-from-square" status="info" />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography gutterBottom>Registro de Ativação </Typography>
          <IconButton
            onClick={() => {
              handleDisputeStripeSubscription();
            }}
          >
            <FontAwesomeIconStatus icon="download" status="info" />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography gutterBottom>Situação da assinatura </Typography>
          <IconButton
            onClick={() => {
              handleDisputeAdditionalDetails();
            }}
          >
            <FontAwesomeIconStatus icon="download" status="info" />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography gutterBottom>Termos da assinatura </Typography>
          <IconButton
            onClick={() => {
              handleDisputeTerms();
            }}
          >
            <FontAwesomeIconStatus icon="download" status="info" />
          </IconButton>
        </div>
      </DialogContent>

      <DialogActionsStyled>
        <Button onClick={onClose} color="primary" variant="outlined">
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default StripeDisputeDocumentsModal;
