import * as XLSX from 'xlsx';

const importXlsxToJson = async (file) => {

      var data = await file.arrayBuffer();
      let readedData = XLSX.read(data);
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
      return dataParse;  
}

export default importXlsxToJson;
