import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  LinearProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';

import { Formik, Form } from 'formik';
import SuperUserService from '../../../services/SuperUserService';
import { FormCheckbox, FormSelect } from '../../../components/forms/FormikFields';
import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';
import NumberUtils from '../../../commons/utils/numberUtils';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  planId: yup.number(),
  maxNumberUsers: yup.number().required(),
  applySpecialPrice: yup.boolean().required(),
  applyIntroductoryPrice: yup.boolean().required(),
});

const DEFAULT_INIT_VALUES = {
  planId: '',
  maxNumberUsers: '',
  applySpecialPrice: false,
  applyIntroductoryPrice: false,
};

// COMPONENT
function AsaasUpgradeSubscriptionModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [asaasPlans, setAsaasPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { maxNumberUsers } = selectedCompany;
  const [currentAsaasPlans, setCurrentAsaasPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [diffBetweenPlans, setDiffBetweenPlans] = useState(0);
  const [suggestedSpecialPrice, setSuggestedSpecialPrice] = useState(false);
  const [suggestedIntroductoryPrice, setSuggestedIntroductoryPrice] = useState(null);

  useEffect(() => {
    if (open) {
      setAsaasPlans(null);
      setDiffBetweenPlans(0);
      setSuggestedSpecialPrice(false);
      setSuggestedIntroductoryPrice(null);

      const fetchData = async () => {
        const asaasSubscription = await SuperUserService.getAsaasSubscription(selectedCompany.id);
        setCurrentAsaasPlans(asaasSubscription);
      };

      fetchData();
    }
  }, [open, selectedCompany]);

  useEffect(() => {
    setInitialValues({
      planId: '',
      maxNumberUsers: '',
      applySpecialPrice: false,
      applyIntroductoryPrice: false,
      timestamp: new Date().getTime(),
    });
  }, [currentAsaasPlans]);

  const PLAN_OPTIONS = React.useMemo(() => {
    return [1, 3, 6, 9, 12, 18, 25, 35, 50].map((numberOfUsers) => {
      const currentPlan = maxNumberUsers === numberOfUsers;
      return {
        id: numberOfUsers,
        name: `Empresarial - ${numberOfUsers} usuários ${currentPlan ? ' (Plano atual)' : ''}`,
      };
    });
  }, [maxNumberUsers]);

  const myHandleSubmit = (values, actions) => {
    if (
      maxNumberUsers < values.maxNumberUsers &&
      currentAsaasPlans[0]?.cycle === 'YEARLY' &&
      selectedPlan?.periodType === 'MONTHLY'
    ) {
      actions.setSubmitting(false);
      return showNotification(
        'warning',
        'Não é permitido fazer upgrade de plano anual para plano mensal. Este tem que ser feito manualmente.',
      );
    }

    const answer =
      diffBetweenPlans > 0
        ? window.confirm(
            `Você tem certeza que deseja realizar o upgrade de assinatura Asaas e gerar um boleto de ${NumberUtils.toCurrency(
              diffBetweenPlans,
              2,
            )} ?`,
          )
        : window.confirm('Você tem certeza que deseja realizar o upgrade de assinatura Asaas?');

    if (answer) {
      SuperUserService.upgradeAsaasSubscription({
        email: selectedCompany.email,
        cpfCnpj: selectedCompany.cpf ? selectedCompany.cpf : selectedCompany.cnpj,
        planId: values.planId,
        applyIntroductoryPrice: values.applyIntroductoryPrice,
        applySpecialDiscountPrice: values.applySpecialDiscountPrice,
        companyId: selectedCompany.id,
      })
        .then((company) => {
          showNotification('success', 'Atualizado com successo.');
          onSave(company);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const handleChangeSelectedPlan = (planId, setFieldValue, values) => {
    setFieldValue('planId', planId);
    setFieldValue('applySpecialPrice', false);
    setFieldValue('applyIntroductoryPrice', false);
    setDiffBetweenPlans(0);
    const plan = asaasPlans.find((asaasPlan) => asaasPlan.id === +planId);

    if (plan) {
      setSuggestedSpecialPrice(plan.specialDiscountPrice);
      setSuggestedIntroductoryPrice(plan.introductoryPrice);
      setSelectedPlan(plan);
      if (
        currentAsaasPlans[0]?.cycle === 'YEARLY' &&
        plan?.periodType === 'YEARLY' &&
        maxNumberUsers < plan.maxNumberUsers
      ) {
        setIsLoading(true);
        SuperUserService.asaasSubscriptionProRataInfo({
          planId: plan.id,
          selectedCompanyId: selectedCompany.id,
        })
          .then((asaasCustomerUpdateInfo) => {
            setDiffBetweenPlans(asaasCustomerUpdateInfo);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleChangeMaxNumberUsers = (newSelection, setFieldValue) => {
    setIsLoading(true);
    setFieldValue('planId', '');
    setDiffBetweenPlans(0);
    setSelectedPlan([]);
    setSuggestedSpecialPrice(false);
    setSuggestedIntroductoryPrice(false);

    SuperUserService.getAsaasPlan(newSelection)
      .then((respPlan) => {
        setAsaasPlans(respPlan);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Upgrade assinatura - {selectedCompany.name}
          </Typography>

          {selectedCompany.asaasCustomerId && (
            <Button
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const asaasUrlPrefix = process.env.NODE_ENV === 'production' ? 'www' : 'sandbox';
                window.open(
                  `https://${asaasUrlPrefix}.asaas.com/customerAccount/show/${selectedCompany.asaasCustomerId}`,
                  '_blank',
                );
              }}
            >
              Ver no Asaas
            </Button>
          )}
        </Box>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values, errors, isValid }) => (
          <>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Card sx={{ maxWidth: 800, height: 'auto', mt: 2, display: 'table', alignSelf: 'center' }}>
                  <CardHeader title="Plano atual" sx={{ flexDirection: 'column' }}></CardHeader>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item>
                        {currentAsaasPlans.length > 0 &&
                          currentAsaasPlans.map((plan) => (
                            <Typography key={plan.id}>
                              {plan.description} ({NumberUtils.toCurrency(plan.value, 2)})
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <ArrowDownwardIcon sx={{ alignSelf: 'center', m: 2, fontSize: '50px' }} />

                <Card sx={{ minWidth: 450, maxWidth: 800, display: 'table', alignSelf: 'center' }}>
                  <CardHeader title="Novo Plano" sx={{ flexDirection: 'column' }}></CardHeader>
                  <CardContent>
                    <Form>
                      {values.maxNumberUsers && (
                        <>
                          {maxNumberUsers === values.maxNumberUsers && (
                            <Alert severity="warning" sx={{ mb: 1.2 }}>
                              Você está selecionando a mesma quantidade de usuários do plano atual.
                              <br />
                              Isto é uma seleção válida, porém certifique a seleção
                            </Alert>
                          )}
                          {maxNumberUsers < values.maxNumberUsers && (
                            <>
                              <Alert severity="warning" sx={{ mb: 1.2 }}>
                                Você está fazendo um <b>upgrade</b>
                              </Alert>
                              {currentAsaasPlans[0]?.cycle === 'YEARLY' &&
                                selectedPlan?.periodType === 'MONTHLY' && (
                                  <Alert severity="warning" sx={{ mb: 1.2 }}>
                                    Não é permitido fazer upgrade de plano anual para plano mensal. Este tem
                                    que ser feito manualmente.
                                  </Alert>
                                )}
                            </>
                          )}
                          {maxNumberUsers > values.maxNumberUsers && (
                            <Alert severity="warning" sx={{ mb: 1.2 }}>
                              Você está fazendo um <b>downgrade</b>
                            </Alert>
                          )}
                        </>
                      )}
                      <FormSelect
                        fullWidth
                        label="Plano"
                        name="maxNumberUsers"
                        dataSource={PLAN_OPTIONS}
                        onChange={(evt) => {
                          handleChangeMaxNumberUsers(evt.target.value, setFieldValue);
                        }}
                      />

                      <Box sx={{ textAlign: 'center', mb: 2 }}>
                        {isLoading && <LinearProgress size={24} />}
                        {asaasPlans && (
                          <ToggleButtonGroupStyled
                            size="small"
                            value={`${values.planId}`}
                            onChange={(evt, newValue) => {
                              if (newValue != null) {
                                handleChangeSelectedPlan(newValue, setFieldValue, values);
                              }
                            }}
                            exclusive
                          >
                            {asaasPlans.map((plan) => (
                              <ToggleButtonStyled key={plan.id} value={`${plan.id}`}>
                                <ToggleTextStyled>
                                  {plan.name} - {NumberUtils.toCurrency(plan.price, 2)}
                                </ToggleTextStyled>
                              </ToggleButtonStyled>
                            ))}
                          </ToggleButtonGroupStyled>
                        )}
                        {!isLoading && asaasPlans && asaasPlans.length === 0 && (
                          <Typography variant="h5">Nenhum plano encontrado</Typography>
                        )}

                        <Typography color="error">{errors?.planId}</Typography>
                      </Box>

                      {suggestedIntroductoryPrice && (
                        <Grid item xs={12}>
                          <FormCheckbox
                            label={
                              'Aplicar preço ' +
                              NumberUtils.toCurrency(suggestedIntroductoryPrice, 2) +
                              ' na primeira cobrança'
                            }
                            name="applyIntroductoryPrice"
                          />
                        </Grid>
                      )}
                      {suggestedSpecialPrice && (
                        <Grid item xs={12}>
                          <FormCheckbox
                            label={
                              'Aplicar preço especial de desconto de  ' +
                              NumberUtils.toCurrency(suggestedSpecialPrice, 2) +
                              ' em todas as cobranças'
                            }
                            name="applySpecialPrice"
                            onChange={(evt, newValue) => {
                              if (newValue != null) {
                                handleChangeSelectedPlan(newValue, setFieldValue, values);
                              }
                            }}
                          />
                        </Grid>
                      )}
                      {diffBetweenPlans > 0 && (
                        <Alert severity="warning" sx={{ mb: 1.2 }}>
                          Será gerado uma cobrança avulsa no valor de{' '}
                          <b>{NumberUtils.toCurrency(diffBetweenPlans, 2)}</b>
                        </Alert>
                      )}
                    </Form>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
            <Form>
              <DialogActionsStyled>
                <Button variant="outlined" onClick={onClose}>
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || isLoading || !isValid}
                >
                  Salvar
                </Button>
              </DialogActionsStyled>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

export default AsaasUpgradeSubscriptionModal;
