import React, { useEffect, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  Alert,
  styled,
} from '@mui/material';

import { Formik, Form } from 'formik';
import SuperUserService from '../../../services/SuperUserService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormDateField, FormSelect } from '../../../components/forms/FormikFields';
import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';

dayjs.extend(utc);

const schema = yup.object().shape({
  startDate: yup.object().required(),
});

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const DEFAULT_INIT_VALUES = {
  startDate: null,
};

// COMPONENT
function StripeStartSubscriptionInfoModal({ selectedCompany, open, onClose }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [isLoading, setIsLoading] = useState(false);
  const [discountList, setDiscountList] = useState([]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);

      SuperUserService.containsStripeScheduledSubscription(selectedCompany.id)
        .then((containsScheduledSub) => {
          setInitialValues({
            containsScheduledSub,
            timestamp: new Date().getTime(),
          });
          if (containsScheduledSub) {
            return null;
          }

          return Promise.all([
            SuperUserService.getStripeSubscription(selectedCompany.id),
            SuperUserService.getDiscountList(),
          ]).then(([stripeSubscriptionDetails, respDiscountList]) => {
            let startDateMoment = dayjs
              .utc(stripeSubscriptionDetails.startDate)
              .local()
              .month(dayjs().month())
              .year(dayjs().year())
              .startOf('day');
            if (startDateMoment.isBefore(dayjs())) {
              startDateMoment = dayjs(startDateMoment).add(1, 'months');
            }
            setInitialValues({
              ...stripeSubscriptionDetails,
              containsScheduledSub,
              startDate: startDateMoment,
              status: stripeSubscriptionDetails.status,
              timestamp: new Date().getTime(),
            });

            setDiscountList(
              respDiscountList
                .filter(
                  (d) =>
                    d.stripeDiscount.internalDescription &&
                    d.stripeDiscount.internalDescription.includes('RETAIN'),
                )
                .map((discount) => ({
                  id: discount.stripeDiscount.id,
                  name: discount.stripeDiscount.internalDescription,
                })),
            );
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm('Você tem certeza que deseja iniciar a assinatura stripe?');
    if (answer) {
      setIsLoading(true);
      SuperUserService.scheduleStripeSubscription({
        companyId: selectedCompany.id,
        priceCode: initialValues.priceCode,
        startDate: values.startDate.format('YYYY-MM-DD'),
        stripeDiscountId: values.stripeDiscountId,
      })
        .then(() => {
          showNotification('success', 'Assinatura agendada com sucesso!');
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const now = dayjs().startOf('day');

  return (
    <Dialog
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Assinatura Stripe - {selectedCompany.name}
          </Typography>

          {selectedCompany.stripeCustomerId && (
            <Button
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(
                  `https://dashboard.stripe.com/customers/${selectedCompany.stripeCustomerId}`,
                  '_blank',
                );
              }}
            >
              Ver no Stripe
            </Button>
          )}
        </Box>
      </DialogTitle>

      {isLoading ? (
        <DialogContent dividers>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <CircularProgress />
          </Box>
        </DialogContent>
      ) : (
        <>
          {initialValues.status === 'active' ? (
            <DialogContent dividers>
              <Alert severity="success">A assinatura do cliente se encontrada ativa.</Alert>
            </DialogContent>
          ) : (
            <>
              {initialValues.containsScheduledSub ? (
                <DialogContent dividers>
                  <Alert severity="info">A empresa já contem assinatura agendada.</Alert>
                </DialogContent>
              ) : (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={myHandleSubmit}
                >
                  {({ dirty, isSubmitting, values }) => {
                    const diffFromNow = values.startDate ? values.startDate.diff(now, 'days') : 0;
                    const isStartDateFuture = diffFromNow > 0;
                    return (
                      <Form>
                        <DialogContent dividers>
                          <Box>
                            <FormSelect
                              fullWidth
                              label="Desconto"
                              name="stripeDiscountId"
                              dataSource={discountList}
                            />
                            <FormDateField label="Data inicio sugerida" name="startDate" />
                          </Box>

                          {values.startDate && (
                            <Alert severity={isStartDateFuture ? 'success' : 'info'}>
                              {isStartDateFuture ? (
                                <>
                                  Você está selecionando uma data futura.{' '}
                                  <b>A assinatura iniciará em {diffFromNow} dias.</b>
                                </>
                              ) : (
                                <>
                                  Você está selecionando uma data atual ou passada.{' '}
                                  <b>A assinatura iniciará imediatamente.</b>
                                </>
                              )}
                            </Alert>
                          )}
                        </DialogContent>

                        <DialogActionsStyled>
                          <Button variant="outlined" onClick={onClose}>
                            Fechar
                          </Button>
                          <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}>
                            Reiniciar Assinatura Stripe?
                          </Button>
                        </DialogActionsStyled>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
        </>
      )}
    </Dialog>
  );
}

export default StripeStartSubscriptionInfoModal;
