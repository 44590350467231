import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Alert,
} from '@mui/material';
import styled from '@emotion/styled';

import AnamneseTable from './AnamneseTable';
import CompanyAutocomplete from '../../components/typeahead/CompanyAutocomplete';
import AnamneseService from '../../services/AnamneseService';
import HintText from '../../components/labels/HintText';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import showNotification from '../../commons/helpers/showNotification';
import booleanUtils from '../../commons/utils/booleanUtils';
import { moveAnamneseForm } from './anamneseListHelpers';
import { getAccountStatusDescription } from '../../commons/utils/paymentTypeUtils';
import AnamnesePreviewModal from './AnamnesePreviewModal';
import CompanyService from '../../services/CompanyService';

const PaperStyled = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});

function AnamneseListPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [companyId, setCompanyId] = useState();
  const [isShowingGlobals, setIsShowingGlobals] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false });
  const [modalConfirmEnabled, setModalConfirmEnabled] = useState({ open: false });
  const [inEditMode, setInEditMode] = useState(false);
  const [modalAnamnesePreview, setModalAnamnesePreview] = useState({ open: false });

  useEffect(() => {
    (async () => {
      if (isShowingGlobals || selectedCompany) {
        setIsLoading(true);
        try {
          if (isShowingGlobals) {
            setSelectedCompany(null);
            const resp = await AnamneseService.getAnamneseForms();
            setDataSource(resp);
          } else {
            const companyIdSelected = selectedCompany.id;
            const resp = await AnamneseService.getAnamneseForms(companyIdSelected);
            setDataSource(resp);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        setDataSource([]);
      }
    })();
  }, [isShowingGlobals, selectedCompany]);

  useEffect(() => {
    (async () => {
      setCompanyId(location.state?.companyId);
      if (companyId) {
        setIsLoading(true);
        try {
          setIsShowingGlobals(false);
          const resp = await CompanyService.getById(companyId);
          setSelectedCompany(resp);
          const respAnamneseForm = await AnamneseService.getAnamneseForms(companyId);
          setDataSource(respAnamneseForm);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [companyId, location]);

  const handleChangeTypeForm = (event) => {
    setIsShowingGlobals(booleanUtils.str2bool(event.target.value));
  };

  const handleDelete = async (targetObject) => {
    setModalConfirmDelete({
      open: true,
      targetObject,
    });
  };

  const handleSelectAnamneseFormItem = async (targetObject) => {
    setModalAnamnesePreview({
      open: true,
      targetObject,
    });
  };

  const handleDeleteConfirmation = async () => {
    setIsLoading(true);
    try {
      await AnamneseService.delete(modalConfirmDelete.targetObject);
      setDataSource(dataSource.filter((data) => data.id !== modalConfirmDelete.targetObject.id));
      showNotification('success', 'Deletado com sucesso');
    } finally {
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setIsLoading(false);
    }
  };

  const handleReoderForms = async () => {
    setIsLoading(true);
    try {
      await AnamneseService.reorderForms(dataSource);
      showNotification('success', 'Reordenado com sucesso');
      setInEditMode(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReorderForm = (index, isMovingDown) => {
    const indexTo = isMovingDown ? index + 1 : index - 1;
    setDataSource(moveAnamneseForm(index, indexTo, isMovingDown, dataSource));
  };

  const navigateToNovo = () => {
    if (selectedCompany) {
      // companyId
      //   ? navigate('../novo', { state: { companyId: selectedCompany.id } })
      //   : navigate('novo', { state: { companyId: selectedCompany.id } });
      navigate('novo', { state: { companyId: selectedCompany.id } });
    } else {
      // companyId ? navigate('../novo') : navigate('novo');
      navigate('novo');
    }
  };

  const handleEdit = (ficha) => {
    if (selectedCompany) {
      navigate('edit', { state: { formId: ficha.id, companyId: selectedCompany.id } });
      // companyId
      //   ? navigate('../edit', { state: { formId: ficha.id, companyId: selectedCompany.id } })
      //   : navigate('edit', { state: { formId: ficha.id, companyId: selectedCompany.id } });
    } else {
      navigate('edit', { state: { formId: ficha.id } });
      // companyId
      //   ? navigate('../edit', { state: { formId: ficha.id }, replace: true })
      //   : navigate('edit', { state: { formId: ficha.id } });
    }
  };

  const handleEnabledChange = (targetObject) => {
    setModalConfirmEnabled({
      open: true,
      targetObject,
    });
  };

  const handleEnabledConfirmation = async () => {
    setIsLoading(true);
    let enabledValue = !modalConfirmEnabled.targetObject.enabled;
    try {
      await AnamneseService.updateEnabled(modalConfirmEnabled.targetObject.id, enabledValue);
      await setDataSource(
        dataSource.map((data) => {
          if (data.id === modalConfirmEnabled.targetObject.id) {
            data.enabled = enabledValue;
          }
          return data;
        }),
      );
      showNotification('success', `${enabledValue ? 'Ativada' : 'Desativada'} com sucesso`);
    } finally {
      setModalConfirmEnabled((prev) => ({ ...prev, open: false }));
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

      <ConfirmationDialog
        title="Ativação"
        message={`Você tem certeza que deseja ${
          modalConfirmEnabled.targetObject?.enabled ? 'desativar' : 'ativar'
        } esta ficha?`}
        onConfirm={handleEnabledConfirmation}
        open={modalConfirmEnabled.open}
        onClose={() => {
          setModalConfirmEnabled((prev) => ({ ...prev, open: false }));
        }}
      />

      <AnamnesePreviewModal
        anamneseForm={modalAnamnesePreview.targetObject}
        open={modalAnamnesePreview.open}
        isShowingGlobals={isShowingGlobals}
        handleEdit={handleEdit}
        onClose={() => {
          setModalAnamnesePreview((prev) => ({ ...prev, open: false }));
        }}
      />

      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="overline">Fichas Anamnese</Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigateToNovo();
              }}
            >
              Nova Ficha
            </Button>
          </Box>

          <Box sx={{ mt: 1 }}>
            {isLoading ? <LinearProgress style={{ width: '100%' }} /> : <div style={{ height: '4px' }} />}
          </Box>

          <PaperStyled square>
            {/* TOOLBAR */}
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <FormControl sx={{ mb: 1 }}>
                <FormLabel>Filtrar por tipo</FormLabel>
                <RadioGroup row value={isShowingGlobals} onChange={handleChangeTypeForm}>
                  <FormControlLabel value="true" control={<Radio />} label="Globais" />
                  <FormControlLabel value="false" control={<Radio />} label="Personalizadas" />
                </RadioGroup>
              </FormControl>
              {dataSource.length > 0 && (
                <Box>
                  {!inEditMode && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setInEditMode(true);
                      }}
                    >
                      Reordenar
                    </Button>
                  )}

                  {inEditMode && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        color="secondary"
                        onClick={() => {
                          setInEditMode(false);
                        }}
                      >
                        Cancelar
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleReoderForms();
                        }}
                      >
                        Salvar
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
            {!isShowingGlobals && (
              <Box sx={{ width: '50%' }}>
                <CompanyAutocomplete
                  minLengthRequired={3}
                  value={selectedCompany}
                  onChange={(newSelected) => {
                    setSelectedCompany(newSelected);
                  }}
                  getSuggestionLabel={(object) => {
                    if (!object) {
                      return '';
                    }
                    return `${object.name} - ${
                      object.email ? object.email : 'SEM EMAIL'
                    } - (${getAccountStatusDescription(object)})`;
                  }}
                  label="Empresa"
                  placeholder="Procure pelo nome ou email"
                />
              </Box>
            )}

            {!isShowingGlobals && !selectedCompany && (
              <Alert severity="info" sx={{ mt: 1 }}>
                Favor, selecionar uma Empresa para listar ou criar fichas personalizadas!
              </Alert>
            )}

            {dataSource?.length === 0 ? (
              <Box sx={{ mt: 5, mb: 5 }}>
                <HintText>Nenhum resultado encontrado.</HintText>
              </Box>
            ) : (
              <AnamneseTable
                dataSource={dataSource}
                isLoading={isLoading}
                handleDelete={handleDelete}
                inEditMode={inEditMode}
                handleReorderForm={handleReorderForm}
                handlePreview={handleSelectAnamneseFormItem}
                handleEdit={handleEdit}
                handleEnabledChange={handleEnabledChange}
              />
            )}
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
}

export default AnamneseListPage;
