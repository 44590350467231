/* eslint-disable no-restricted-globals */

const PhoneUtils = {

  sanitizePhoneNumberRemovePlus(phoneNumber) {
    if (!phoneNumber) {
      return '';
    }

    const normalized = `${phoneNumber
      .replace(/\+/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '')
      .replace(/ /g, '')}`;
    return normalized;
  },

  sanitizePhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return '';
    }
    const normalized = `${phoneNumber
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '')
      .replace(/ /g, '')}`;
    return normalized;
  },
  sanitizeWithoutCountry(phoneNumber) {
    if (!phoneNumber) {
      return '';
    }
    const sanitized = this.sanitizePhoneNumber(phoneNumber);
    return sanitized.replace(/\+\d{2}/, '');
  },
  sanitizePhoneNumberWithCountryPrefix(phoneNumber, countryPrefix = '55') {
    if (!phoneNumber) {
      return '';
    }
    return `${countryPrefix}${this.sanitizePhoneNumber(phoneNumber)}`;
  },
  cleanUpPhoneNumberAndGetLast8Digits(phone) {
    const sanitizedPhoneNumber = PhoneUtils.sanitizePhoneNumber(phone);
    // remove country prefix
    let phoneNumber = sanitizedPhoneNumber.replace(/\+\d{2}/, '');
    if (phoneNumber.startsWith('0')) {
      // remove 0 for local area code >> 034 becomes 34
      phoneNumber = phoneNumber.replace(/^0/, '');
    }

    // remove local area code or 9 prefix
    let justlastPartOfPhone = sanitizedPhoneNumber;
    if (sanitizedPhoneNumber.length > 8) {
      justlastPartOfPhone = justlastPartOfPhone.substr(-8);
    }
    return justlastPartOfPhone;
  },
};
export default PhoneUtils;
