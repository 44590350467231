import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';

import { Formik, Form } from 'formik';
import yup from '../../../commons/validators/customYup';
import SuperUserService from '../../../services/SuperUserService';
import PhoneUtils from '../../../commons/utils/phoneUtils';
import showNotification from '../../../commons/helpers/showNotification';
import { FormPhoneField } from '../../../components/forms/FormikFields';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  phone: yup.string().max(16),
  phone2: yup.string().max(16),
});

const DEFAULT_INIT_VALUES = {
  phone: '',
};

// COMPONENT
function EditPhoneNumberModal({ selectedCompany, modalVisible, setModalVisible, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        phone: selectedCompany.phone ?? '',
        phone2: selectedCompany.phone2 ?? '',
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const updatedPhone =
      values.phone != null && values.phone !== '' ? PhoneUtils.sanitizePhoneNumber(values.phone) : null;
    const updatedPhone2 =
      values.phone2 != null && values.phone2 !== '' ? PhoneUtils.sanitizePhoneNumber(values.phone2) : null;
    SuperUserService.updatePhoneNumber({
      phoneNumber: updatedPhone,
      phoneNumber2: updatedPhone2,
      companyId: selectedCompany.id,
    })
      .then(() => {
        showNotification('success', 'Atualizado com successo.');
        onSave({ updatedPhone, updatedPhone2 });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
      <DialogTitle variant="h5">Atualizando Telefone da empresa - {selectedCompany.name}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <FormPhoneField name="phone" label="Telefone 1" fullWidth />
              <FormPhoneField name="phone2" label="Telefone 2" fullWidth />
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditPhoneNumberModal;
