/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import { Box, Button, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import yup from '../../commons/validators/customYup';
import SuperUserService from '../../services/SuperUserService';
import showNotification from '../../commons/helpers/showNotification';
import { FormTextField } from '../../components/forms/FormikFields';
import TransferSubscriptionCompanyCard from './TransferSubscriptionCompanyCard';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';

const schema = yup.object().shape({
  emailOrigin: yup.string().required(),
  emailDestination: yup.string().required(),
});

const INIT_VALUES = { emailOrigin: '', emailDestination: '' };

// COMPONENT
function TransferSubscriptionPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [results, setResults] = useState();
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);

  const myHandleSubmit = (values, actions) => {
    setIsLoading(true);
    SuperUserService.findCompaniesToTransferSubscription(values.emailOrigin, values.emailDestination)
      .then((resp) => {
        if (resp == null) {
          showNotification('warning', 'Nenhum resultado encontrado.');
        }
        setResults(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const handleTransferSubscription = () => {
    setModalConfirmOpen(false);
    setIsTransfering(true);
    SuperUserService.transferSubscription(results)
      .then(() => {
        showNotification('success', 'Transferido com successo.');
        setResults(null);
      })
      .finally(() => {
        setIsTransfering(false);
      });
  };

  return (
    <Box>
      <ConfirmationDialog
        title="Confirmação de transferencia"
        message="Você tem certeza que deseja transferir a assinatura?"
        onConfirm={handleTransferSubscription}
        open={modalConfirmOpen}
        onClose={() => {
          setModalConfirmOpen(false);
        }}
      />

      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <FormTextField
                name="emailOrigin"
                label="Empresa Origem"
                sx={{ minWidth: '400px' }}
                placeholder="Digite o email da empresa..."
              />
              <div style={{ width: '20px' }} />
              <FormTextField
                name="emailDestination"
                label="Empresa Destino (esta ficará com a assinatura)"
                placeholder="Digite o email da empresa..."
                sx={{ minWidth: '400px' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || isLoading || isTransfering}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {results != null && (
        <div>
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ mt: 5 }}>
            <TransferSubscriptionCompanyCard company={results.originCompany} title="Empresa Origem" />
            <FontAwesomeIconStatus
              icon="arrow-right"
              status="primary"
              size="2x"
              style={{ marginLeft: '10px', marginRight: '10px' }}
            />
            <TransferSubscriptionCompanyCard company={results.destinationCompany} title="Empresa Destino" />

            <Button
              variant="contained"
              color="success"
              disabled={isTransfering || results.originCompany == null || results.destinationCompany == null}
              sx={{ ml: 2 }}
              onClick={() => {
                setModalConfirmOpen(true);
              }}
            >
              Transferir {isTransfering && <CircularProgress />}
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
}

export default TransferSubscriptionPage;
