import { Box, Drawer, Hidden, List } from '@mui/material';
import { useMemo } from 'react';
import { isAuthorized } from '../../commons/helpers/authorizationHelpers';
import { getCurrentUser } from '../../stores/appStore';
import NavItemAndSubItem from './NavItemAndSubItem';

const DashboardSidebar = ({ onMobileClose, openMobile, menuItems }) => {
  const menuItemsFiltered = useMemo(() => {
    const initialCurrentUser = getCurrentUser();
    const userRoles = initialCurrentUser != null ? initialCurrentUser.roles : [];

    if (menuItems) {
      return menuItems.filter((menuItem) => isAuthorized(userRoles, menuItem.requiredRoles));
    }
    return menuItems;
  }, [menuItems]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ px: 2, py: 1 }}>
        <List>
          {menuItemsFiltered.map((item) => (
            <NavItemAndSubItem
              href={item.href}
              key={item.title}
              title={item.title}
              iconName={item.iconName}
              subItems={item.subItems}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      {/* <Hidden lgUp> */}
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 240,
          },
        }}
      >
        {content}
      </Drawer>

      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 240,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
