import React, { useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Form, Formik } from 'formik';
import showNotification from '../../../commons/helpers/showNotification';
import NumberUtils from '../../../commons/utils/numberUtils';
import yup from '../../../commons/validators/customYup';
import { FormCheckbox, FormSelect } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';
import StripeDiscountCoupon from './StripeDiscountCoupon';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  planId: yup.number(),
  maxNumberUsers: yup.number().required(),
  ignoreDiffBetweenPlans: yup.boolean(),
});

const DEFAULT_INIT_VALUES = {
  planId: '',
  maxNumberUsers: '',
  ignoreDiffBetweenPlans: false,
};

// COMPONENT
function StripeUpgradeSubscriptionModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [stripePlans, setStripePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { maxNumberUsers } = selectedCompany;
  const [currentStripePlan, setCurrentStripePlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [discountCoupons, setDiscountCoupons] = useState([]);
  const [diffBetweenPlans, setDiffBetweenPlans] = useState(0);
  const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    if (open) {
      setCurrentStripePlan(null);
      setStripePlans(null);
      setDiffBetweenPlans(0);
      setCoupon(0);
      setDiscountCoupons([]);

      const fetchData = async () => {
        const stripeSubscription = await SuperUserService.getStripeSubscription(selectedCompany.id);
        setCurrentStripePlan(stripeSubscription);
      };
      fetchData();
    }
  }, [open, selectedCompany]);

  useEffect(() => {
    setInitialValues({
      planId: '',
      maxNumberUsers: '',
      timestamp: new Date().getTime(),
      ignoreDiffBetweenPlans: false,
    });
  }, [currentStripePlan]);

  const PLAN_OPTIONS = React.useMemo(() => {
    return [3, 6, 9, 12, 18, 25, 35, 50].map((numberOfUsers) => {
      const currentPlan = maxNumberUsers === numberOfUsers;
      return {
        id: numberOfUsers,
        name: `Empresarial - ${numberOfUsers} usuários ${currentPlan ? ' (Plano atual)' : ''}`,
      };
    });
  }, [maxNumberUsers]);

  const myHandleSubmit = (values, actions) => {
    if (
      maxNumberUsers < values.maxNumberUsers &&
      currentStripePlan?.cycle === 'YEAR' &&
      selectedPlan?.periodType === 'MONTHLY'
    ) {
      actions.setSubmitting(false);
      return showNotification(
        'warning',
        'Não é permitido fazer upgrade de plano anual para plano mensal. Este tem que ser feito manualmente.',
      );
    }

    const answer =
      diffBetweenPlans > 0
        ? window.confirm(
            `Você tem certeza que deseja realizar o upgrade de assinatura Stripe e gerar um boleto de ${NumberUtils.toCurrency(
              diffBetweenPlans,
              2,
            )} ?`,
          )
        : window.confirm('Você tem certeza que deseja realizar o upgrade de assinatura Stripe?');

    if (answer) {
      SuperUserService.upgradeStripeSubscription({
        planId: values.planId,
        selectedCompanyId: selectedCompany.id,
        couponId: coupon,
        ignoreDiffBetweenPlans: values.ignoreDiffBetweenPlans,
      })
        .then((stripeCustomerUpdateInfo) => {
          showNotification('success', 'Atualizado com successo.');
          onSave(stripeCustomerUpdateInfo, values.maxNumberUsers);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const handleChangeSelectedPlan = (planId, setFieldValue) => {
    setFieldValue('planId', planId);
    setFieldValue('ignoreDiffBetweenPlans', false);
    setDiffBetweenPlans(0);
    setCoupon(0);
    setDiscountCoupons([]);
    const plan = stripePlans.find((stripePlan) => stripePlan.id === +planId);
    if (plan) {
      setFieldValue('priceOverride', plan.price);
      setSelectedPlan(plan);

      const fetchData = async () => {
        const stripeDiscountCoupons = await SuperUserService.getStripeDiscountsCupons({
          companyId: selectedCompany.id,
          planId: planId,
        });
        setDiscountCoupons(stripeDiscountCoupons);
      };
      fetchData();
      if (
        currentStripePlan?.interval === 'YEAR' &&
        plan?.periodType === 'YEARLY' &&
        maxNumberUsers < plan?.maxNumberUsers
      ) {
        setIsLoading(true);
        SuperUserService.stripeSubscriptionProRataInfo({
          planId: plan.id,
          selectedCompanyId: selectedCompany.id,
        })
          .then((stripeCustomerUpdateInfo) => {
            setDiffBetweenPlans(stripeCustomerUpdateInfo);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleChangeMaxNumberUsers = (newSelection, setFieldValue) => {
    setIsLoading(true);
    setFieldValue('priceOverride', '');
    setFieldValue('planId', '');
    setFieldValue('ignoreDiffBetweenPlans', false);
    setDiffBetweenPlans(0);
    setSelectedPlan([]);
    setCoupon(0);
    setDiscountCoupons([]);

    SuperUserService.getStripePlan(newSelection)
      .then((respPlan) => {
        setStripePlans(respPlan);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StripeDiscountCoupon
        discountCoupons={discountCoupons}
        open={modalDiscountOpen}
        onClose={() => {
          setModalDiscountOpen(false);
        }}
        onSave={(coupon) => {
          setCoupon(coupon);
        }}
      />
      <Dialog maxWidth="md" onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography component="span" variant="h5">
              Upgrade assinatura - {selectedCompany.name}
            </Typography>

            {selectedCompany.stripeCustomerId && (
              <Button
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(
                    `https://dashboard.stripe.com/customers/${selectedCompany.stripeCustomerId}`,
                    '_blank',
                  );
                }}
              >
                Ver no Stripe
              </Button>
            )}
          </Box>
        </DialogTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, setFieldValue, values, errors, isValid }) => (
            <>
              <DialogContent dividers>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Card sx={{ maxWidth: 800, height: 'auto', mt: 2, display: 'table', alignSelf: 'center' }}>
                    <CardHeader title="Plano atual" sx={{ flexDirection: 'column' }}></CardHeader>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item>
                          {currentStripePlan && (
                            <Typography key={currentStripePlan.id}>
                              {currentStripePlan?.plan?.name} (
                              {NumberUtils.toCurrency(currentStripePlan?.plan?.price, 2)})
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <ArrowDownwardIcon sx={{ alignSelf: 'center', m: 2, fontSize: '50px' }} />

                  <Card sx={{ minWidth: 450, maxWidth: 800, display: 'table', alignSelf: 'center' }}>
                    <CardHeader title="Novo Plano" sx={{ flexDirection: 'column' }}></CardHeader>
                    <CardContent>
                      <Form>
                        {values.maxNumberUsers && currentStripePlan && (
                          <>
                            {maxNumberUsers === values.maxNumberUsers && (
                              <Alert severity="warning" sx={{ mb: 1.2 }}>
                                Você está selecionando a mesma quantidade de usuários do plano atual.
                                <br />
                                Isto é uma seleção válida, porém certifique a seleção{' '}
                                <b>(Data de vencimento e periodicidade do plano)</b>
                              </Alert>
                            )}
                            {maxNumberUsers < values.maxNumberUsers && (
                              <>
                                {currentStripePlan?.interval === 'YEAR' &&
                                selectedPlan?.periodType === 'MONTHLY' ? (
                                  <Alert severity="warning" sx={{ mb: 1.2 }}>
                                    Não é permitido fazer upgrade de plano anual para plano mensal. Este tem
                                    que ser feito manualmente.
                                  </Alert>
                                ) : (
                                  <Alert severity="warning" sx={{ mb: 1.2 }}>
                                    Você está fazendo um <b>upgrade</b>
                                  </Alert>
                                )}
                              </>
                            )}
                            {maxNumberUsers > values.maxNumberUsers && (
                              <Alert severity="warning" sx={{ mb: 1.2 }}>
                                Você está fazendo um <b>downgrade</b>
                              </Alert>
                            )}
                          </>
                        )}
                        <FormSelect
                          fullWidth
                          label="Plano"
                          name="maxNumberUsers"
                          dataSource={PLAN_OPTIONS}
                          onChange={(evt) => {
                            handleChangeMaxNumberUsers(evt.target.value, setFieldValue);
                          }}
                        />

                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                          {isLoading && <LinearProgress size={24} />}
                          {stripePlans && (
                            <ToggleButtonGroupStyled
                              size="small"
                              value={`${values.planId}`}
                              onChange={(evt, newValue) => {
                                if (newValue != null) {
                                  handleChangeSelectedPlan(newValue, setFieldValue);
                                }
                              }}
                              exclusive
                            >
                              {stripePlans.map((plan) => (
                                <ToggleButtonStyled key={plan.id} value={`${plan.id}`}>
                                  <ToggleTextStyled>
                                    {plan.name} - {NumberUtils.toCurrency(plan.price, 2)}
                                  </ToggleTextStyled>
                                </ToggleButtonStyled>
                              ))}
                            </ToggleButtonGroupStyled>
                          )}
                          {!isLoading && stripePlans && stripePlans.length === 0 && (
                            <Typography variant="h5">Nenhum plano encontrado</Typography>
                          )}

                          <Typography color="error">{errors?.planId}</Typography>
                        </Box>
                        {diffBetweenPlans > 0 && (
                          <>
                            <Alert severity="warning" sx={{ mb: 1.2 }}>
                              Será gerado uma cobrança avulsa no valor de{' '}
                              <b>{NumberUtils.toCurrency(diffBetweenPlans, 2)}</b>
                              <Grid>
                                <FormCheckbox
                                  label="Não aplicar cobrança avulsa"
                                  name="ignoreDiffBetweenPlans"
                                />
                              </Grid>
                            </Alert>
                          </>
                        )}
                      </Form>
                      {coupon > 0 ? (
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>
                            <FontAwesomeIconStatus status="info" icon={faCheckCircle} />
                            <b> Desconto aplicado: </b>
                            {discountCoupons.find((coupons) => coupons.id === coupon).name}
                          </Typography>
                          <IconButton onClick={() => setCoupon(0)} sx={{ mr: 1 }}>
                            <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                          </IconButton>
                        </Grid>
                      ) : (
                        <>
                          {discountCoupons.length > 0 && (
                            <Box display="flex" justifyContent={'flex-end'}>
                              <Button
                                sx={{ ml: 2 }}
                                onClick={() => {
                                  setModalDiscountOpen(true);
                                }}
                              >
                                Adicionar Cupom
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </DialogContent>
              <Form>
                <DialogActionsStyled>
                  <Button variant="outlined" onClick={onClose}>
                    Fechar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isLoading || !isValid}
                  >
                    Salvar
                  </Button>
                </DialogActionsStyled>
              </Form>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default StripeUpgradeSubscriptionModal;
