import React from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MENU_APP } from './featureListHelpers';

// COMPONENT
function FeatureListFilterByMenu({ selectedMenu, setSelectedMenu }) {
  const handleClearClick = () => {
    setSelectedMenu();
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormControl sx={{ width: '300px' }}>
        <InputLabel>Filtrar por menu</InputLabel>
        <Select
          value={selectedMenu ?? ''}
          onChange={(e) => {
            setSelectedMenu(e.target.value);
          }}
          label="Filtrar por menu"
          endAdornment={
            selectedMenu !== '' && (
              <IconButton onClick={handleClearClick} sx={{ mr: 1 }}>
                <FontAwesomeIcon icon="times" />
              </IconButton>
            )
          }
        >
          {Object.keys(MENU_APP).map((key) => (
            <MenuItem value={key} key={key}>
              {MENU_APP[key].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default FeatureListFilterByMenu;
