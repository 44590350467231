/* eslint-disable no-param-reassign */
const values = [
  {
    id: 1,
    name: 'Salão de Beleza, Estética, Esmalteria, Barberia, Tatoo, Piercing, e afins',
    icon: 'paint-brush',
  },
  { id: 2, name: 'Massoterapia, Massagem, Podologia', icon: 'hands' },
  { id: 3, name: 'Odontologia, Medicina, Psicologia', icon: 'medkit' },
  { id: 4, name: 'Personal e Fitness', icon: 'dumbbell' },
  { id: 5, name: 'Pet e Veterinário', icon: 'paw' },
  {
    id: 6,
    name: 'Beach Tennis, Futebol, Vôlei, Tennis, Aluguel de Quadras em Geral',
    icon: 'baseball-ball',
  },
  { id: 99, name: 'Outros', icon: 'briefcase' },
];

const idToValue = values.reduce((obj, item) => {
  obj[item.id] = item;
  return obj;
}, {});

const OccupationAreas = {
  values,
  getById(id) {
    return idToValue[id];
  },
};
export { OccupationAreas };
