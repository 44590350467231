import React, { useState, useEffect } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import SuperUserService from '../../../services/SuperUserService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

function getWorkingScheduleStringForDay(day, workingSchedule) {
  if (workingSchedule[`${day}Enabled`]) {
    const startTime1 = workingSchedule[`${day}StartTime1`];
    const endTime1 = workingSchedule[`${day}EndTime1`];
    const startTime2 = workingSchedule[`${day}StartTime2`];
    const endTime2 = workingSchedule[`${day}EndTime2`];

    // contains second part
    if (startTime2) {
      return `${startTime1}-${endTime1} ${startTime2}-${endTime2}`;
    }
    return `${startTime1}-${endTime1}`;
  }
  return '-';
}

// COMPONENT
function CompanyWorkingScheduleChangeLogModal({
  workingScheduleId,
  modalVisible,
  setModalVisible,
  titleDesc,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (modalVisible && workingScheduleId) {
      setIsLoading(true);
      SuperUserService.getCompanyWorkScheduleChangeLogs(workingScheduleId)
        .then((resp) => {
          setDataSource(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, workingScheduleId]);

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalVisible}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle component="h5">Historico de alteração do expediente {titleDesc ?? 'empresa'}</DialogTitle>

      <DialogContent dividers>
        {isLoading && <CircularProgress />}

        {!isLoading && dataSource.length === 0 && (
          <Typography variant="p" gutterBottom>
            Nenhum resultado encontrado
          </Typography>
        )}

        {/* TABLE */}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Dom</TableCell>
              <TableCell>Seg</TableCell>
              <TableCell>Ter</TableCell>
              <TableCell>Qua</TableCell>
              <TableCell>Qui</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Sab</TableCell>
              <TableCell>Alterado as</TableCell>
              <TableCell>Alterado por</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataSource.map((workingSchedule) => {
              return (
                <TableRow key={workingSchedule.id}>
                  <TableCell>{getWorkingScheduleStringForDay('sun', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('mon', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('tue', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('wed', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('thu', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('fri', workingSchedule)}</TableCell>
                  <TableCell>{getWorkingScheduleStringForDay('sat', workingSchedule)}</TableCell>
                  <TableCell>{dayjs(workingSchedule.changedAt).format('L LT')}</TableCell>
                  <TableCell>{workingSchedule.changedBy?.name ?? '-'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default CompanyWorkingScheduleChangeLogModal;
