import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

/**
 * @param {('info'|'warning'|'error'|'success')} type
 * @param {string} message
 */
const showNotification = (type, message) => {
  if (type === 'info') {
    toast.info(message);
  } else if (type === 'warning') {
    toast.warning(message);
  } else if (type === 'error') {
    toast.error(message);
  } else if (type === 'success') {
    toast.success(message);
  } else {
    toast.info(message);
  }
};

showNotification.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  message: PropTypes.string.isRequired,
};
export { showNotification as default };
