import { Button, CircularProgress, DialogActions, DialogContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import showNotification from '../../../../commons/helpers/showNotification';
import yup from '../../../../commons/validators/customYup';
import {
  FormNumberFormatField,
  FormSwitchField,
  FormTextField,
} from '../../../../components/forms/FormikFields';
import SuperUserService from '../../../../services/SuperUserService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const DEFAULT_INITIAL_VALUES = {
  anticipatedPaymentValue: '',
  mercadoPagoAccessToken: '',
  enabled: true,
};

const schema = yup.object().shape({
  anticipatedPaymentValue: yup.number().required(),
  mercadoPagoAccessToken: yup.string().max(250).required(),
  enabled: yup.boolean().required(),
});

// COMPONENT
function CompanyOnlineSchedulingSettingsAnticipatedPayment({ selectedCompany, open }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_VALUES);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingAnticipatedPayment(selectedCompany.company.id)
        .then((resp) => {
          setInitialValues({
            companyId: selectedCompany.company.id,
            anticipatedPaymentValue:
              resp?.anticipatedPaymentValue ?? DEFAULT_INITIAL_VALUES.anticipatedPaymentValue,
            mercadoPagoAccessToken:
              resp?.mercadoPagoAccessToken ?? DEFAULT_INITIAL_VALUES.mercadoPagoAccessToken,
            enabled: resp?.enabled ?? DEFAULT_INITIAL_VALUES.enabled,
            datetime: new Date().getTime(),
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany]);

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);

    try {
      await SuperUserService.updateCompanyOnlineSchedulingAnticipatedPayment(values);
      showNotification('success', 'Alterado com sucesso.');
    } finally {
      setIsLoading(false);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, resetForm }) => (
          <Form>
            <DialogContent dividers>
              <Grid container>
                <Grid item sm={12}>
                  <FormNumberFormatField
                    fullWidth
                    label="Taxa de Antecipação (em %)"
                    name="anticipatedPaymentValue"
                    suffix="%"
                    prefix=""
                    decimalScale={1}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormTextField fullWidth name="mercadoPagoAccessToken" label="Access Token Mercado Pago" />
                </Grid>
                <Grid item sm={12}>
                  <FormSwitchField color="success" name="enabled" label="Ativo?" />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActionsStyled>
              <Button
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm({ values: DEFAULT_INITIAL_VALUES });
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting || isLoading}
              >
                Salvar
                {isLoading && <CircularProgress size={18} />}
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CompanyOnlineSchedulingSettingsAnticipatedPayment;
