const statusInvoice = {
  draft: { label: 'Provisória', color: 'stripeGrey' },
  open: { label: 'Aberta', color: 'info' },
  paid: { label: 'Paga', color: 'success' },
  uncollectible: { label: 'Pagamento improvável', color: 'warning' },
  void: { label: 'Anulada', color: 'stripeGrey' },
  trying: { label: 'Tentado pagamento', color: 'secondary' },
  succeeded: { label: 'Pagamento Realizado', color: 'success', icon: 'circle-check' },
  failed: { label: 'Falhou', color: 'error', icon: 'building-columns' },
};

const statusCharge = {
  succeeded: { label: 'Ok', color: 'success', icon: 'circle-check' },
  pending: { label: 'Pendente', color: 'secondary', icon: 'plus' },
  failed: { label: 'Falhou', color: 'error', icon: 'building-columns' },
  void: { label: 'Cancelada', color: 'stripeGrey' },
  open: { label: 'Incompleto', color: 'stripeGrey' },
  trying: { label: 'Tentado pagamento', color: 'secondary' },
  draft: { label: 'Provisória', color: 'stripeGrey' },
};

const statusSubscription = {
  active: { label: 'Ativa', color: 'success' },
  canceled: { label: 'Cancelada', color: 'stripeGrey' },
  unpaid: { label: 'Não Paga', color: 'warning' },
  past_due: { label: 'Vencida', color: 'error' },
  incomplete: { label: 'Incompleta', color: 'secondary' },
  incomplete_expired: { label: 'Incompleta Expirada', color: 'warning' },
  trialing: { label: 'Tentativa', color: 'warning' },
};
const statusSubscriptionSchedules = {
  active: { label: 'Ativa', color: 'success' },
  not_started: { label: 'Não Iniciada', color: 'secondary' },
  completed: { label: 'Completa', color: 'warning' },
  released: { label: 'Liberada', color: 'primary' },
  canceled: { label: 'Cancelada', color: 'secondary' },
};
const failures = {
  incorrect_number: 'O número do cartão está incorreto.',
  invalid_number: 'O número do cartão não é um número válido de cartão de crédito.',
  invalid_expiry_month: 'O mês de validade do cartão não é válido.',
  invalid_expiry_year: 'O ano de validade do cartão não é válido.',
  invalid_cvc: 'O código de segurança do cartão não é válido.',
  expired_card: 'O cartão expirou.',
  incorrect_cvc: 'O código de segurança do cartão está incorreto.',
  incorrect_zip: 'O CEP do cartão falhou a validação.',
  card_declined: 'O cartão foi recusado',
  missing: 'Não existe qualquer cartão no cliente que está sendo cobrado.',
  processing_error: 'Ocorreu um erro durante o processamento do cartão.',
  rate_limit: 'Excesso de pedidos a API. Nos avise se persistir.',
  payment_intent_payment_attempt_expired: 'O boleto expirou. Você pode fornecer um novo método de pagamento para tentar novamente.'
};

const invoiceError = {
  quote: { accepted: { label: 'Citação aceita', icon: 'plus', color: 'primary' } },
  invoice: {
    created: { label: 'Pagamento iniciado', icon: 'plus', color: 'primary' },
    payment_failed: { label: 'Pagamento falhou', icon: 'circle-xmark', color: 'error' },
    payment_action_required: {
      label: 'Pagamento falhou. Ação requerida',
      icon: 'circle-xmark',
      color: 'error',
    },
    payment_succeeded: { label: 'Sucesso no pagamento', icon: 'check', color: 'success' },
    sent: { label: 'Fatura enviada', icon: 'share', color: 'success' },
    updated: { label: 'Pagamento atualizado', icon: 'arrows-rotate', color: 'primary' },
    voided: { label: 'Pagamento anulado', icon: 'ban', color: 'warning' },
    finalized: { label: 'Pagamento finalizado', icon: 'check-double', color: 'success' },
    payment: {
      overpaid: { label: 'Pagamento em excesso', icon: 'circle-dollar', color: 'secondary' },
    },
  },
  credit_note: {
    created: { label: 'Nota de crédito criada', icon: 'plus', color: 'primary' },
    voided: { label: 'Nota de crédito anulada', icon: 'ban', color: 'warning' },
  },
};

const statusOutcome = {
  card_not_supported: 'Cartão não suportado',
  do_not_honor: 'Pagamento recusado pelo banco emissor',
  expired_card: 'Falha no pagamento devido a cartão vencido',
  generic_decline: 'Pagamento recusado pelo banco emissor',
  incorrect_cvc: 'Falha no pagamento devido a CVC incorreto',
  incorrect_number: 'Este pagamento não foi concluído devido a número de cartão incorreto',
  insufficient_funds: 'Este pagamento não foi concluído devido a fundos insuficientes',
  invalid_account: 'Este pagamento não foi concluído devido a número incorreto do cartão ou da conta',
  invalid_amount: 'Este pagamento não foi concluído porque o valor era muito alto',
  invalid_pin: 'Este pagamento não foi concluído devido a PIN incorreto',
  lost_card: 'Este pagamento não foi concluído porque o cartão foi declarado como perdido',
  pickup_card: 'Este pagamento não foi concluído porque o cartão foi declarado como perdido ou roubado',
  pin_try_exceeded: 'Este pagamento não foi concluído devido a PIN incorreto',
  processing_error: 'Este pagamento não foi concluído devido a um erro de rede',
  reenter_transaction: 'Este pagamento não foi concluído porque o banco emissor não conseguiu processá-lo',
  restricted_card: 'Pagamento recusado pelo banco emissor',
  revocation_of_all_authorizations: 'Pagamento recusado pelo banco emissor',
  revocation_of_authorization: 'Este pagamento não foi concluído porque o cliente não o autorizou',
  service_not_allowed: 'Pagamento recusado pelo banco emissor',
  stolen_card: 'Este pagamento não foi concluído porque o cartão foi declarado como roubado',
  stop_payment_order: 'Pagamento recusado',
  transaction_not_allowed: 'Pagamento recusado pelo banco emissor',
  try_again_later: 'Pagamento recusado',
  withdrawal_count_limit_exceeded: 'Este pagamento não foi concluído devido ao limite de saldo ou de crédito',
};

const statusOutcomeDetail = {
  do_not_honor:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações. É possível que novas tentativas funcionem.',
  expired_card:
    'Peça ao cliente para inserir os dados do cartão novos ou atualizados ou tentar outra forma de pagamento.',
  generic_decline:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações. É possível que novas tentativas funcionem.',
  incorrect_cvc:
    'Peça ao cliente para tentar novamente com o CVC correto. Se não funcionar, será possível tentar outra forma de pagamento.',
  incorrect_number:
    'Peça ao cliente para tentar novamente com o número correto. Se não funcionar, ele precisará entrar em contato com o banco emissor.',
  insufficient_funds:
    'Peça ao cliente para tentar outra forma de pagamento ou tentar fazer o pagamento em uma data posterior.',
  invalid_account:
    'Peça ao cliente para tentar novamente com os dados de pagamento corretos. Se não funcionar, será possível tentar outra forma de pagamento.',
  invalid_amount:
    'Verifique se você tentou cobrar o valor correto. Se o valor estiver correto, peça ao cliente para usar outra forma de pagamento ou entrar em contato com o banco emissor.',
  invalid_pin:
    'Peça ao cliente para tentar novamente com o PIN correto. Se não funcionar, será possível tentar outra forma de pagamento.',
  lost_card:
    'Não diga ao cliente que o cartão foi reportado como perdido, mas peça que entre em contato com o banco emissor. Novas tentativas não funcionarão.',
  pickup_card:
    'Não diga ao cliente que o cartão foi reportado como perdido ou roubado, mas peça que entre em contato com o banco emissor. Novas tentativas não funcionarão.',
  pin_try_exceeded:
    'Peça ao cliente para tentar novamente com o PIN correto. Se não funcionar, será possível tentar outra forma de pagamento.',
  processing_error:
    'Peça ao cliente para tentar novamente. Se não funcionar, será possível usar outra forma de pagamento ou tentar novamente mais tarde.',
  reenter_transaction:
    'Peça ao cliente para tentar novamente ou entrar em contato com o banco emissor para obter mais informações.',
  restricted_card:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações.',
  revocation_of_all_authorizations:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações.',
  revocation_of_authorization:
    'Isso aconteceu porque o cliente revogou a aprovação da forma de pagamento ou solicitou a interrupção de uma assinatura. Entre em contato com o cliente para obter mais informações ou tentar outra forma de pagamento.',
  service_not_allowed:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor. É possível que novas tentativas funcionem.',
  stolen_card:
    'Não diga ao cliente que o cartão foi reportado como roubado, mas peça que entre em contato com o banco emissor. Novas tentativas não funcionarão.',
  stop_payment_order:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações.',
  transaction_not_allowed:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor. É improvável que novas tentativas funcionem.',
  try_again_later:
    'Peça ao cliente para tentar outra forma de pagamento ou entrar em contato com o banco emissor para obter mais informações.',
  withdrawal_count_limit_exceeded:
    'Peça ao cliente para usar outra forma de pagamento ou tentar fazer o pagamento em uma data posterior.',
};

const stripePeriodType = {
  YEARLY: 'Anual',
  DAILY: 'Diário', 
  WEEKLY: 'Semanal',
  MONTHLY: 'Mensal'
}
export {
  statusInvoice,
  statusCharge,
  statusSubscription,
  statusSubscriptionSchedules,
  failures,
  invoiceError,
  statusOutcome,
  statusOutcomeDetail,
  stripePeriodType
};
