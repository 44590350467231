import React from 'react';
import { Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../components/table/TableCellAction';
import TableRowClickable from '../../components/table/TableRowClickable';

function AnamneseTable({
  dataSource,
  isLoading,
  handleDelete,
  inEditMode,
  handleReorderForm,
  handlePreview,
  handleEdit,
  handleEnabledChange,
}) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome da Ficha</TableCell>
          <TableCell />
          <TableCell />
          {!inEditMode && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {dataSource?.map((ficha, index) => (
          <TableRowClickable
            key={ficha.id}
            onClick={() => {
              handlePreview(ficha);
            }}
          >
            <TableCell>{ficha.name}</TableCell>

            {inEditMode && (
              <>
                {index < dataSource.length - 1 ? (
                  <TableCellAction
                    title="Mover para baixo"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleReorderForm(index, true);
                    }}
                    disabled={isLoading}
                  >
                    <FontAwesomeIconStatus status="info" icon="arrow-down" size="sm" />
                  </TableCellAction>
                ) : (
                  <TableCellAction
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    title=""
                  ></TableCellAction>
                )}

                {index > 0 ? (
                  <TableCellAction
                    title="Mover para cima"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleReorderForm(index, false);
                    }}
                    disabled={isLoading}
                  >
                    <FontAwesomeIconStatus status="info" icon="arrow-up" size="sm" />
                  </TableCellAction>
                ) : (
                  <TableCellAction
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    title=""
                  ></TableCellAction>
                )}
              </>
            )}
            {!inEditMode && (
              <>
                <TableCell
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  title={ficha.enabled ? 'Ficha Ativada' : 'Ficha Desativada'}
                >
                  <Switch
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleEnabledChange(ficha);
                    }}
                    checked={ficha.enabled}
                    size="small"
                    label="Ativada?"
                  />
                </TableCell>
                <TableCellAction
                  title="Editar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit(ficha);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                </TableCellAction>
                <TableCellAction
                  title="Deletar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDelete(ficha);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                </TableCellAction>
              </>
            )}
          </TableRowClickable>
        ))}
      </TableBody>
    </Table>
  );
}

export default AnamneseTable;
