import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Button, Typography, Paper, Box, styled, IconButton, Container } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';

import { FormTextField } from '../../../components/forms/FormikFields';

import ExampleQuestionService from '../../../services/ExampleQuestionService';

import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';

import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import LabelStatus from '../../../components/labels/LabelStatus';
import ExampleQuestionCategoryFormSelect from './ExampleQuestionCategoryFormSelect';

const PaperStyled = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

const schema = yup.object().shape({
  title: yup.string().min(2).max(50).required(),
  description: yup.string().max(100).nullable(),
  exampleQuestionCategoryId: yup.number().nullable(),
  items: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().min(2).max(50).required(),
      }),
    )
    .min(1, 'Informe pelo menos 1 item')
    .required(),
});

const DEFAULT_VALUES = {
  title: '',
  description: '',
  exampleQuestionCategoryId: '',
  items: [],
};

// COMPONENT
function ExampleQuestionFormPage() {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      ExampleQuestionService.getById(id).then((resp) => {
        setInitialValues({
          id: resp.id,
          title: resp.title,
          description: resp.description ?? '',
          exampleQuestionCategoryId: resp.exampleQuestionCategoryId,
          items: resp.items,
        });
      });
    }
  }, [id]);

  const myHandleSubmit = async (values, actions) => {
    try {
      await ExampleQuestionService.save(values);
      showNotification('success', values.id ? 'Atualizado com successo.' : 'Criado com successo.');
      navigate(-1);
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <FontAwesomeIconStatus icon="arrow-left" status="primary" />
        </IconButton>
        <Typography variant="h5">{id ? 'Atualizando' : 'Criando'} Questão Exemplo</Typography>
      </Box>

      <PaperStyled>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, values, touched, errors }) => (
            <Form>
              <FormTextField fullWidth label="Titulo" name="title" />
              <FormTextField fullWidth label="Descrição" name="description" />
              <ExampleQuestionCategoryFormSelect />

              <div>
                <Typography gutterBottom variant="overline">
                  Itens:
                </Typography>
              </div>

              <FieldArray
                name="items"
                render={(arrayHelpers) => {
                  return (
                    <>
                      {values.items.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                          <FormTextField
                            fullWidth
                            label={index === 0 ? 'Titulo' : null}
                            name={`items.${index}.title`}
                            placeholder="Informe o titulo do item..."
                          />

                          <IconButton
                            aria-label="Remove Item"
                            style={{ padding: '10px 15px', alignSelf: 'flex-end' }}
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            size="small"
                            color="secondary"
                          >
                            <FontAwesomeIconStatus status="error" icon="trash" />
                          </IconButton>
                        </Box>
                      ))}

                      {touched.items && typeof errors.items === 'string' && (
                        <LabelStatus sx={{ mb: 2 }} status="error">
                          {errors.items}
                        </LabelStatus>
                      )}

                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          arrayHelpers.push({
                            title: '',
                          });
                        }}
                        style={{ marginBottom: 15 }}
                      >
                        Adicionar Item
                      </Button>
                    </>
                  );
                }}
              />

              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      </PaperStyled>
    </Container>
  );
}

export default ExampleQuestionFormPage;
