import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
}));

// COMPONENT
function HintText({ className, children, ...rest }) {
  return (
    <TypographyStyled className={className} {...rest}>
      {children}
    </TypographyStyled>
  );
}

export default HintText;
