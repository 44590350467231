import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, lighten, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import theme from '../../theme';

import dayjs from 'dayjs';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../components/table/TableCellAction';
import TableRowClickable from '../../components/table/TableRowClickable';
import { getPriorityByParam, getStatusByParam, MENU_APP } from './featureListHelpers';

function FeatureTable({ features, isLoading, handleDelete, handlePreview, handleEdit, handleAddCompany }) {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Menu</TableCell>
            <TableCell>Prioridade</TableCell>
            <TableCell>Solicitações</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Data Criação</TableCell>
            <TableCell>Últ. Adição Sugestão</TableCell>
            <TableCell>Funcionário Ult.Adição</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {features?.map((feature, i) => {
            let backgroundColor;
            if (feature.countCompanies >= 15) {
              backgroundColor = lighten(theme.palette.error.main, 0.4);
            } else if (feature.countCompanies >= 10) {
              backgroundColor = lighten(theme.palette.warning.main, 0.4);
            } else if (feature.countCompanies >= 5) {
              backgroundColor = lighten(theme.palette.info.main, 0.4);
            }

            return (
              <TableRowClickable
                key={feature.id}
                onClick={() => {
                  handlePreview(feature);
                }}
                style={{ backgroundColor }}
              >
                <TableCell>{feature.name}</TableCell>
                <TableCell>{feature.description}</TableCell>
                <TableCell>{feature.menu ? MENU_APP[feature.menu].name : ''}</TableCell>
                <TableCell>{getPriorityByParam(feature.priority)}</TableCell>
                <TableCell
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleAddCompany(feature);
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {feature.countCompanies}
                    <AddCircleOutlineIcon color="success" sx={{ ml: 0.5 }} fontSize="medium" />
                  </Box>
                </TableCell>
                <TableCell>{getStatusByParam(feature.status)}</TableCell>
                <TableCell>{dayjs(feature.createdAt).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>
                  {feature.lastUpdatedAt
                    ? dayjs(feature.lastUpdatedAt).format('DD/MM/YY HH:mm')
                    : dayjs(feature.updatedAt).format('DD/MM/YY HH:mm')}
                </TableCell>
                <TableCell>
                  {feature.lastUpdateCompanyEmployeeName
                    ? feature.lastUpdateCompanyEmployeeName
                    : feature.employeeName}
                </TableCell>

                <TableCellAction
                  title="Editar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit(feature);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                </TableCellAction>
                <TableCellAction
                  title="Deletar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDelete(feature);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                </TableCellAction>
              </TableRowClickable>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default FeatureTable;
