import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  styled,
  LinearProgress,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

import { Formik, Form } from 'formik';
import SuperUserService from '../../../services/SuperUserService';
import yup from '../../../commons/validators/customYup';
import showNotification from '../../../commons/helpers/showNotification';
import NumberUtils from '../../../commons/utils/numberUtils';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  discountId: yup.number().required(),
});

const DEFAULT_INIT_VALUES = {
  discountId: '',
};

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

// COMPONENT
function AsaasSubscriptionDiscountModal({ selectedCompany, subscription, open, onClose, onSave }) {
  const [asaasDiscount, setAsaasDiscount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // added hardcode temporarily
  const fixedDiscount = useMemo(() => {
    return [
      {
        id: 999,
        name: 'Desconto Fixo Mensal Vitalício',
        discountPrice: 18.9,
        maxNumberUsers: 3,
        periodType: 'MONTHLY',
      },
    ];
  }, []);

  useEffect(() => {
    if (open) {
      setAsaasDiscount([]);
      setIsLoading(true);
      if (subscription.status === 'ACTIVE') {
        if (subscription.cycle === 'MONTHLY') {
          setAsaasDiscount(fixedDiscount);
        } else {
          showNotification('warning', 'Sem descontos cadastrados para essa recorrencia');
        }
        setIsLoading(false);
      } 
    }
  }, [open, subscription, fixedDiscount]);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm('Você tem certeza que deseja alterar o valor da assinatura Asaas?');
    if (answer) {
      setIsLoading(true);
      SuperUserService.updateAsaasSubscription({
        id: subscription.id,
        selectedCompanyId: selectedCompany.id,
        discountId: values.discountId,
        applyFixedSpecialDiscountPrice: true,
      })
        .then(() => {
          showNotification('success', 'Atualizado com successo.');
          onClose();
          onSave();
        })
        .finally(() => {
          actions.setSubmitting(false);
          setIsLoading(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Atualizar valor da assinatura
          </Typography>
        </Box>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={DEFAULT_INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, values, setFieldValue, isValid }) => (
          <Form>
            <DialogContent dividers>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                {isLoading && <LinearProgress size={24} />}
                {!isLoading && asaasDiscount && (
                  <ToggleButtonGroupStyled
                    size="small"
                    value={`${values.planId}`}
                    onChange={(evt, newValue) => {
                      if (newValue != null) {
                        setFieldValue('discountId', newValue);
                        setFieldValue('planId', newValue);
                      }
                    }}
                    exclusive
                  >
                    {asaasDiscount.map((discount) => (
                      <ToggleButtonStyled key={discount.id} value={`${discount.id}`}>
                        <ToggleTextStyled>
                          {discount.name} - {NumberUtils.toCurrency(discount.discountPrice, 2)}
                        </ToggleTextStyled>
                      </ToggleButtonStyled>
                    ))}
                  </ToggleButtonGroupStyled>
                )}
                {!isLoading && asaasDiscount && asaasDiscount.length === 0 && (
                  <Typography variant="h5">Nenhum desconto encontrado</Typography>
                )}
              </Box>
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isSubmitting || isValid}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AsaasSubscriptionDiscountModal;
