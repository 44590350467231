import React, { useEffect, useState } from 'react';

import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';

import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import SuperUserService from '../../../services/SuperUserService';
import { FormDateField, FormSelect } from '../../../components/forms/FormikFields';
import yup from '../../../commons/validators/customYup';
import { ACCOUNT_FREE, ACCOUNT_PAID, ACCOUNT_TRIAL } from '../../../commons/utils/accountUtils';
import showNotification from '../../../commons/helpers/showNotification';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  accountType: yup.string().nullable().required(),
  paidGracePeriodEndDate: yup.date().nullable(),
  trialExpirationDate: yup
    .date()
    .nullable()
    .when('accountType', (myAccountType, itemSchema) => {
      return myAccountType === ACCOUNT_TRIAL ? itemSchema.required() : itemSchema.notRequired();
    }),
});

const DEFAULT_INIT_VALUES = {
  accountType: '',
  paidGracePeriodEndDate: null,
  trialExpirationDate: null,
};

const ACCOUNT_TYPES = [ACCOUNT_TRIAL, ACCOUNT_PAID, ACCOUNT_FREE].map((accType) => ({
  id: accType,
  name: accType,
}));

// COMPONENT
function ChangeAccountTypeModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (open) {
      setInitialValues({
        accountType: selectedCompany.accountType,
        paidGracePeriodEndDate: selectedCompany.paidGracePeriodEndDate
          ? dayjs(selectedCompany.paidGracePeriodEndDate)
          : null,
        trialExpirationDate: selectedCompany.trialExpirationDate
          ? dayjs(selectedCompany.trialExpirationDate)
          : null,
        timestamp: new Date().getTime(),
      });
    }
  }, [open, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const submission = {
      accountType: values.accountType,
      paidGracePeriodEndDate: values.paidGracePeriodEndDate
        ? dayjs(values.paidGracePeriodEndDate).format('YYYY-MM-DD')
        : null,
      trialExpirationDate: values.trialExpirationDate
        ? dayjs(values.trialExpirationDate).format('YYYY-MM-DD')
        : null,
      companyId: selectedCompany.id,
    };

    SuperUserService.updateAccountType(submission)
      .then((updatedAccountStatus) => {
        showNotification('success', 'Atualizado com successo.');
        onSave(updatedAccountStatus);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle>
        <Typography component="span" variant="h5">
          Atualizar tipo da conta - {selectedCompany.name}
        </Typography>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, values, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <FormSelect fullWidth label="Tipo da Conta" name="accountType" dataSource={ACCOUNT_TYPES} />
              {values.accountType === ACCOUNT_PAID && (
                <FormDateField fullWidth label="Período de carência pago" name="paidGracePeriodEndDate" />
              )}
              {values.accountType === ACCOUNT_TRIAL && (
                <FormDateField fullWidth label="Data de expiração do teste" name="trialExpirationDate" />
              )}
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ChangeAccountTypeModal;
