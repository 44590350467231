import HttpService from './commons/HttpService';
import {  getCurrentUser, resetStore } from '../stores/appStore';
import jwt from 'jwt-decode';

const AuthService = {
  login: (userLogin) => {
    return HttpService.post('/auth/login', userLogin).then((loggedInUser) => {
      const decodedUser = jwt(loggedInUser.token);
      const expiryDate = new Date(decodedUser.exp * 1000);

      return {
        ...loggedInUser,
        roles: loggedInUser.authorities.map((o) => o.authority),
        expiryDate,
      };
    });
  },
  logout: () => {
    const loggedInUser = getCurrentUser();
    return HttpService.post('/auth/logout', { refreshToken: loggedInUser?.refreshToken }).then((resp) => {
      resetStore();
      return resp;
    });
  },
  checkCredentials: () => HttpService.get('/auth/checkCredentials'),
  // updatePassword: (userUpdate) => {

  //   return HttpService.put('/updatePassword', userUpdate).then((loggedInUser) => {
  //     const decodedUser = jwt(loggedInUser.token);
  //     const expiryDate = new Date(decodedUser.exp * 1000);

  //     return {
  //       ...loggedInUser,
  //       roles: loggedInUser.authorities.map((o) => o.authority),
  //       expiryDate,
  //     };
  //   });
  //   },

    updatePassword: (passwordUpdateRequest, acessToken) => {
      if (acessToken) {
        const config = {
          headers: {
            Authorization: `Bearer ${acessToken}`,
          },
        };
        return HttpService.post('/resetPassword', passwordUpdateRequest, config);
      }
      return HttpService.post('/resetPassword', passwordUpdateRequest);
    },
};

export default AuthService;
