import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import showNotification from '../../../commons/helpers/showNotification';
import { ACCOUNT_TRIAL } from '../../../commons/utils/accountUtils';
import NumberUtils from '../../../commons/utils/numberUtils';
import yup from '../../../commons/validators/customYup';
import { FormCheckbox, FormDateField } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const ToggleTextStyled = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: 'capitalize',
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  dueDate: yup.date().nullable().required(),
  planId: yup.number(),
  applySpecialDiscountPrice: yup.boolean().required(),
  applyIntroductoryPrice: yup.boolean().required(),
});

const DEFAULT_INIT_VALUES = {
  dueDate: null,
  planId: '',
  applySpecialDiscountPrice: false,
  applyIntroductoryPrice: false,
};

// COMPONENT
function AsaasCreateSubscriptionModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [asaasPlans, setAsaasPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedIntroductoryPrice, setSuggestedIntroductoryPrice] = useState(null);
  const [suggestedSpecialPrice, setSuggestedSpecialPrice] = useState(null);

  const { maxNumberUsers } = selectedCompany;

  useEffect(() => {
    if (open) {
      setSuggestedIntroductoryPrice(null);
      setSuggestedSpecialPrice(null);

      setInitialValues({
        dueDate:
          selectedCompany.accountType === ACCOUNT_TRIAL &&
          !dayjs().isAfter(selectedCompany.trialExpirationDate)
            ? dayjs(selectedCompany.trialExpirationDate)
            : dayjs(),
        planId: '',
        timestamp: new Date().getTime(),
        applySpecialDiscountPrice: false,
        applyIntroductoryPrice: selectedCompany.accountType === ACCOUNT_TRIAL ? true : false,
      });
    }
  }, [open, selectedCompany]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getAsaasPlan(maxNumberUsers)
        .then((respPlan) => {
          setAsaasPlans(respPlan);
        })
        .finally(() => {});
      setIsLoading(false);
    }
  }, [open, maxNumberUsers]);

  const myHandleSubmit = (values, actions) => {
    SuperUserService.createAsaasSubscription({
      email: selectedCompany.email,
      cpfCnpj: selectedCompany.cpf ? selectedCompany.cpf : selectedCompany.cnpj,
      dueDate: values.dueDate.format('YYYY-MM-DD'),
      planId: values.planId,
      applyIntroductoryPrice: values.applyIntroductoryPrice,
      applySpecialDiscountPrice: values.applySpecialDiscountPrice,
      companyId: selectedCompany.id,
    })
      .then((asaasCustomerUpdateInfo) => {
        showNotification('success', 'Atualizado com successo.');
        onSave(asaasCustomerUpdateInfo);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Criando assinatura - {selectedCompany.name}
          </Typography>
        </Box>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent dividers>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                {isLoading && <CircularProgress size={24} />}
                {!isLoading && asaasPlans && (
                  <ToggleButtonGroupStyled
                    size="small"
                    value={`${values.planId}`}
                    onChange={(evt, newValue) => {
                      if (newValue != null) {
                        setFieldValue('planId', newValue);
                      }
                    }}
                    exclusive
                  >
                    {asaasPlans.map((plan) => (
                      <ToggleButtonStyled
                        key={plan.id}
                        value={`${plan.id}`}
                        onClick={() => {
                          setSuggestedIntroductoryPrice(plan.introductoryPrice);
                          setSuggestedSpecialPrice(plan.specialDiscountPrice);
                        }}
                      >
                        <ToggleTextStyled>
                          {plan.name}
                          {plan.introductoryPrice && (
                            <>
                              <br />
                              Intro: {NumberUtils.toCurrency(plan.introductoryPrice, 2)}
                            </>
                          )}
                          <br />
                          Preço: {NumberUtils.toCurrency(plan.price, 2)}
                        </ToggleTextStyled>
                      </ToggleButtonStyled>
                    ))}
                  </ToggleButtonGroupStyled>
                )}
                {!isLoading && asaasPlans && asaasPlans.length === 0 && (
                  <Typography variant="h5">Nenhum plano encontrado</Typography>
                )}
                <Typography scolor="error">{errors?.planId}</Typography>
              </Box>
              <Grid item xs={12}>
                <FormDateField fullWidth label="Data do vencimento" name="dueDate" />
              </Grid>
              {suggestedIntroductoryPrice && (
                <Grid item xs={12}>
                  <FormCheckbox
                    label={
                      'Aplicar preço ' +
                      NumberUtils.toCurrency(suggestedIntroductoryPrice, 2) +
                      ' na primeira cobrança'
                    }
                    name="applyIntroductoryPrice"
                  />
                </Grid>
              )}
              {suggestedSpecialPrice && (
                <Grid item xs={12}>
                  <FormCheckbox
                    label={
                      'Aplicar preço especial de desconto de  ' +
                      NumberUtils.toCurrency(suggestedSpecialPrice, 2) +
                      ' em todas as cobranças'
                    }
                    name="applySpecialDiscountPrice"
                  />
                </Grid>
              )}
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}>
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AsaasCreateSubscriptionModal;
