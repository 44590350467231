import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';
import CompanyOnlineSchedulingSettingsAnticipatedPayment from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsAnticipatedPayment';
import CompanyOnlineSchedulingSettingsBlockedCustomers from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsBlockedCustomers';
import CompanyOnlineSchedulingSettingsExpedient from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsExpedient';
import CompanyOnlineSchedulingSettingsInfo from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsInfo';
import CompanyOnlineSchedulingSettingsProfessionals from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsProfessionals';
import CompanyOnlineSchedulingSettingsServices from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsServices';
import CompanyOnlineSchedulingSettingsUnavailablePeriod from './CompanyOnlineSchedulingSettings/CompanyOnlineSchedulingSettingsUnavailablePeriod';

function CompanyOnlineSchedulingSettings({ selectedCompany, open, tabIndex, setTabIndex }) {
  const [companyOnlineSchedulingServices, setCompanyOnlineSchedulingServices] = useState();
  const [companyOnlineSchedulingProfessionals, setCompanyOnlineSchedulingProfessionals] = useState();
  const [companyOnlineSchedulingExpedient, setCompanyOnlineSchedulingExpedient] = useState();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label="Configuração de Agendamento Online">
          <Tab label="Info" />
          <Tab label="Serviços" />
          <Tab label="Profissionais Disponíveis" />
          <Tab label="Expediente" />
          <Tab label="Períodos Indisponíveis" />
          <Tab label="Clientes Bloqueados" />
          <Tab label="Antecipação de Pagamento" />
        </Tabs>
      </Box>
      {tabIndex === 0 && <CompanyOnlineSchedulingSettingsInfo selectedCompany={selectedCompany} />}
      {tabIndex === 1 && (
        <CompanyOnlineSchedulingSettingsServices
          selectedCompany={selectedCompany}
          companyOnlineSchedulingServices={companyOnlineSchedulingServices}
          setCompanyOnlineSchedulingServices={setCompanyOnlineSchedulingServices}
          open={open}
        />
      )}
      {tabIndex === 2 && (
        <CompanyOnlineSchedulingSettingsProfessionals
          selectedCompany={selectedCompany}
          companyOnlineSchedulingProfessionals={companyOnlineSchedulingProfessionals}
          setCompanyOnlineSchedulingProfessionals={setCompanyOnlineSchedulingProfessionals}
          open={open}
        />
      )}
      {tabIndex === 3 && (
        <CompanyOnlineSchedulingSettingsExpedient
          companyId={selectedCompany.company.id}
          companyOnlineSchedulingExpedient={companyOnlineSchedulingExpedient}
          setCompanyOnlineSchedulingExpedient={setCompanyOnlineSchedulingExpedient}
          open={open}
        />
      )}
      {tabIndex === 4 && (
        <CompanyOnlineSchedulingSettingsUnavailablePeriod selectedCompany={selectedCompany} open={open} />
      )}
      {tabIndex === 5 && (
        <CompanyOnlineSchedulingSettingsBlockedCustomers
          selectedCompany={selectedCompany}
          companyOnlineSchedulingExpedient={companyOnlineSchedulingExpedient}
          setCompanyOnlineSchedulingExpedient={setCompanyOnlineSchedulingExpedient}
          open={open}
        />
      )}
      {tabIndex === 6 && (
        <CompanyOnlineSchedulingSettingsAnticipatedPayment selectedCompany={selectedCompany} open={open} />
      )}
    </Box>
  );
}

export default CompanyOnlineSchedulingSettings;
