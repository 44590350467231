import CustomAutocomplete from './CustomAutocomplete';

import CompanyService from '../../services/CompanyService';

function CompanyAutocomplete({ getSuggestionLabel, ...others }) {
  const handleSearchTerm = (inputValue, setIsLoading) => {
    setIsLoading(true);
    return CompanyService.search(inputValue)
      .then((data) => {
        return data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomAutocomplete
      onChangeSearchTerm={handleSearchTerm}
      getSuggestionLabel={getSuggestionLabel}
      {...others}
    />
  );
}

export default CompanyAutocomplete;
