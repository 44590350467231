/* eslint-disable eqeqeq */
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import ReactJson from 'react-json-view';

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({ justifyContent: 'space-between' }));

// COMPONENT
function AccountInfoStoreDetailModal({ json, open, onClose }) {
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography component="span" variant="h5">
          Informações da Loja
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <ReactJson src={json} style={{ wordBreak: 'break-all' }} theme="monokai" />
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default AccountInfoStoreDetailModal;
