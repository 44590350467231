import React from 'react';
import { styled } from '@mui/material/styles';
import { FontAwesomeIconStatus } from './FontAwesomeIconStatus';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
}));

const DEFAULT_STYLE_2 = { position: 'absolute', top: -12, right: -14 };

// COMPONENT
function FontAwesomeDoubleIconStatus({
  status,
  status2,
  icon1,
  size1,
  icon2,
  size2,
  style1,
  style2,
  ...rest
}) {
  return (
    <Root>
      <FontAwesomeIconStatus icon={icon1} size={size1} status={status} style={style1} />
      <FontAwesomeIconStatus icon={icon2} size={size2} status={status2 ?? status} style={style2} />
    </Root>
  );
}

FontAwesomeDoubleIconStatus.defaultProps = {
  size1: 'lg',
  size2: 'sm',
  style2: DEFAULT_STYLE_2,
};

export default FontAwesomeDoubleIconStatus;
