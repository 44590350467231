import React from 'react';
import { Box, FormControl, IconButton, Input, InputLabel} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// COMPONENT
function EmployeeListFilter({ filterEmployee, setFilterEmployee }) {
  const handleClearClick = () => {
    setFilterEmployee('');
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormControl sx={{ width: '300px' }}>
        <InputLabel>Filtrar por nome ou email</InputLabel>
        <Input
          value={filterEmployee}
          onChange={(e) => {
            setFilterEmployee(e.target.value);
          }}
          endAdornment={
            filterEmployee !== '' && (
              <IconButton onClick={handleClearClick} sx={{ mr: 1 }}>
                <FontAwesomeIcon icon="times" />
              </IconButton>
            )
          }
        ></Input>
      </FormControl>
    </Box>
  );
}

export default EmployeeListFilter;
