import React, { useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import showNotification from '../../../commons/helpers/showNotification';
import yup from '../../../commons/validators/customYup';
import { FormSelect, FormSwitchField } from '../../../components/forms/FormikFields';
import SuperUserService from '../../../services/SuperUserService';
import CompanyOnlineSchedulingSettings from './CompanyOnlineSchedulingSettings';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));
const DialogContentStyled = styled(DialogContent)(() => ({ overflow: 'auto', height: '70vh' }));

const DEFAULT_INITIAL_VALUES = {
  canScheduleOnHolidays: false,
  maxNumberOfFutureAppointments: '',
  timeZoneOffset: '',
  automaticAppointmentPayment: false,
  allowSimultaneousAppointment: false,
  allowAppointmentAsGuest: false,
  birthDateFieldEnabled: false,
};

const validationSchema = yup.object().shape({
  canScheduleOnHolidays: yup.boolean().required(),
  maxNumberOfFutureAppointments: yup.number().nullable().max(100),
  timeZoneOffset: yup.string(),
  automaticAppointmentPayment: yup.boolean().required(),
  allowSimultaneousAppointment: yup.boolean().required(),
  allowAppointmentAsGuest: yup.boolean().required(),
  birthDateFieldEnabled: yup.boolean().required(),
});

const TIMEZONE_OFFSET_OPTIONS = [
  { id: '-05:00', name: 'Acre Time' },
  { id: '-04:00', name: 'Amazon Time' },
  { id: '-03:00', name: 'Brasília Time (PADRÃO DO SISTEMA)' },
  { id: '-02:00', name: 'Fernando de Noronha Time' },
  { id: '+01:00', name: 'Portugal Standard Time (Lisboa)' },
].map((obj) => ({ ...obj, name: `${obj.name} (${obj.id})` }));

// COMPONENT
function CompanyOnlineSchedulingSettingsModal({ selectedCompany, open, onClose, onSave }) {
  const [isEditing, setIsEditing] = useState(false);
  const [myInitialValues, setMyInitialValues] = useState(DEFAULT_INITIAL_VALUES);
  const [tabIndex, setTabIndex] = useState(0);

  const { companyOnlineScheduling } = selectedCompany;

  const handleSubmit = async (values, actions) => {
    const resp = await SuperUserService.updateCompanyOnlineSchedulingSettings({
      id: companyOnlineScheduling.id,
      ...values,
    });
    showNotification('success', 'Atualizado com sucesso.');
    actions.setSubmitting(false);
    onSave(resp);
    setIsEditing(false);
  };

  useEffect(() => {
    const scheduling = selectedCompany?.companyOnlineScheduling;

    setMyInitialValues({
      canScheduleOnHolidays: scheduling.canScheduleOnHolidays,
      maxNumberOfFutureAppointments: scheduling.maxNumberOfFutureAppointments,
      timeZoneOffset: scheduling.timeZoneOffset ?? '-03:00',
      automaticAppointmentPayment: scheduling.automaticAppointmentPayment,
      allowSimultaneousAppointment: scheduling.allowSimultaneousAppointment,
      allowAppointmentAsGuest: scheduling.allowAppointmentAsGuest,
      allowLoginWithPhone: scheduling.allowLoginWithPhone,
      birthDateFieldEnabled: scheduling.birthDateFieldEnabled,
    });
  }, [selectedCompany]);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Configuração Agendamento Online</Typography>
          <Link
            href={`https://online.maapp.com.br/${selectedCompany.companyOnlineScheduling.slug}`}
            underline="hover"
            variant="h5"
            color="info"
            target="__blank"
          >
            {selectedCompany.companyOnlineScheduling.slug}
          </Link>
        </Box>
      </DialogTitle>
      <>
        {isEditing ? (
          <>
            <Formik
              enableReinitialize
              initialValues={myInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ dirty, isSubmitting, setFieldValue, values, isValid }) => (
                <Form>
                  <DialogContent dividers>
                    {/* <FormNumberFormatField
                      fullWidth
                      label="Número máximo de agendamentos futuros"
                      name="maxNumberOfFutureAppointments"
                      decimalScale={0}
                      prefix={''}
                    /> */}
                    <FormSelect
                      fullWidth
                      label="Fuso Horário"
                      name="timeZoneOffset"
                      dataSource={TIMEZONE_OFFSET_OPTIONS}
                    />
                    <FormSwitchField
                      color="success"
                      checked={values.canScheduleOnHolidays}
                      onChange={(e) => {
                        setFieldValue('canScheduleOnHolidays', e.target.checked);
                      }}
                      name="canScheduleOnHolidays"
                      label="Pode agendar no feriado?"
                    />
                    <FormSwitchField
                      color="success"
                      checked={values.automaticAppointmentPayment}
                      onChange={(e) => {
                        setFieldValue('automaticAppointmentPayment', e.target.checked);
                      }}
                      name="automaticAppointmentPayment"
                      label="Dar Baixa De Atendimento Automaticamente?"
                    />
                    <Box display="flex" alignItems="center">
                      <FormSwitchField
                        color="success"
                        checked={values.allowSimultaneousAppointment}
                        onChange={(e) => {
                          setFieldValue('allowSimultaneousAppointment', e.target.checked);
                        }}
                        name="allowSimultaneousAppointment"
                        label="Permitir agendamento Simultaneo?"
                      />

                      <Tooltip title="Cliente marcar no mesmo horario com 2 ou mais profisisonais diferentes">
                        <InfoIcon color="info" />
                      </Tooltip>
                    </Box>
                    <FormSwitchField
                      color="success"
                      checked={values.allowAppointmentAsGuest}
                      onChange={(e) => {
                        setFieldValue('allowAppointmentAsGuest', e.target.checked);
                      }}
                      name="allowAppointmentAsGuest"
                      label="Permitir agendamento sem login?"
                    />
                    <FormSwitchField
                      color="success"
                      checked={values.allowLoginWithPhone}
                      onChange={(e) => {
                        setFieldValue('allowLoginWithPhone', e.target.checked);
                      }}
                      name="allowLoginWithPhone"
                      label="Permitir agendamento com telefone?"
                    />
                    <FormSwitchField
                      color="success"
                      checked={values.birthDateFieldEnabled}
                      onChange={(e) => {
                        setFieldValue('birthDateFieldEnabled', e.target.checked);
                      }}
                      name="birthDateFieldEnabled"
                      label="Campo data de nascimento habilitado?"
                    />
                  </DialogContent>
                  <DialogActionsStyled>
                    <Button variant="outlined" onClick={onClose}>
                      Fechar
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={!dirty || isSubmitting || !isValid}
                    >
                      Salvar
                    </Button>
                  </DialogActionsStyled>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <DialogContentStyled dividers>
              <CompanyOnlineSchedulingSettings
                selectedCompany={selectedCompany}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                open={open}
              />
            </DialogContentStyled>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              {tabIndex === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Editar
                </Button>
              )}
            </DialogActionsStyled>
          </>
        )}
      </>
    </Dialog>
  );
}

export default CompanyOnlineSchedulingSettingsModal;
