import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, styled, Box } from '@mui/material';

import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import yup from '../../../commons/validators/customYup';
import SuperUserService from '../../../services/SuperUserService';
import showNotification from '../../../commons/helpers/showNotification';
import { getAppointmentTagLabelById } from '../../../commons/enums/appointmentTags';
import exportToExcel from '../../../commons/helpers/exportToExcel';
import { FormDateField } from '../../../components/forms/FormikFields';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .max(yup.ref('endDate'), 'Data Inicio não pode ser maior que Data Fim.')
    .required(),
  endDate: yup.date().nullable().required(),
});

const DEFAULT_INIT_VALUES = {
  startDate: null,
  endDate: null,
};

// COMPONENT
function ExportAppointmentsWithPaymentInfoModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        ...DEFAULT_INIT_VALUES,
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = async (values, actions) => {
    try {
      const respAppointments = await SuperUserService.getAppointmentWithPaymentsReports({
        companyId: selectedCompany.id,
        startDate: values.startDate.format('YYYY-MM-DD'),
        endDate: values.endDate.format('YYYY-MM-DD'),
      });

      if (respAppointments.length === 0) {
        showNotification('warning', 'Nenhum resultado encontrado.');
        actions.setSubmitting(false);
        return;
      }

      const preparedDataSource = respAppointments.map((object) => {
        const baseObj = {
          'Data de Agendamento': dayjs(object.date).format('ddd, DD/MM/YYYY'),
          Horário: `${object.startTime} às ${object.endTime}`,
          Cliente: object.customerName,
          'Serviço(s)': object.serviceName,
          // Situação: object.tag != null ? AppointmentTagsEnum.getById(object.tag).name : '-',
          Situação: object.tag != null ? getAppointmentTagLabelById(object.tag) : '-',
          Observação: object.comments ?? '',
          'Valor Total': object.totalValue ?? 0,
          'Valor Pago': object.totalPaid ?? 0,
          'Valor De Fato Pago': object.actualTotalPaid ?? 0,
          'Pagamento Automatico': object.totalPaid == null ? 'SIM' : 'NAO',
          'Tem comanda': object.comandaId != null ? 'SIM' : 'NAO',
          Profissional: object.professionalName,
          'Data Criação': object.createdAt ? dayjs(object.createdAt).format('L LT') : '',
        };

        return baseObj;
      });

      const fileName = `RelatorioAgendamentosComPagamentos_${
        selectedCompany.name
      }_De${values.startDate.format('DD-MM-YYYY')}Ate${values.endDate.format(
        'DD-MM-YYYY',
      )}_Gerado${dayjs().format('DD-MM-YY HH:mm:ss')}`;
      exportToExcel(preparedDataSource, fileName);
      showNotification('success', 'Relatório gerado com sucesso.');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} fullWidth>
      <DialogTitle variant="h5">Exportar Agendamentos Com Pagamentos - {selectedCompany.name}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column" rowGap={2}>
                <FormDateField fullWidth label="Data Inicio" name="startDate" />
                <FormDateField fullWidth label="Data Fim" name="endDate" />
              </Box>
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}>
                Exportar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ExportAppointmentsWithPaymentInfoModal;
