import { styled, Typography, Container } from '@mui/material';

const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const Content = styled('div')(({ theme }) => ({
  paddingTop: 150,
  textAlign: 'center',
}));

function NotAuthorizedView() {
  return (
    <Root>
      <Content>
        <Typography variant="h1">403: Você não está autorizado a acessar está página.</Typography>
        <Typography variant="subtitle2">
          Você pode ter chegado aqui por engano ou por acidente. Em caso de dúvida contate a equipe de
          suporte.
        </Typography>
      </Content>
    </Root>
  );
}

export default NotAuthorizedView;
