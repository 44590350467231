import { Typography, Grid, Card, CardContent, CardActions, Button, Divider } from '@mui/material';

import { FormDateField, FormTimeField } from '../../components/forms/FormikFields';
import { Formik, Form } from 'formik';
import yup from '../../commons/validators/customYup';
import { currentUserSelector, useAppStore, setCurrentUser } from '../../stores/appStore';

const schema = yup.object().shape({
  // firstName: yup.string().min(2).max(50).required(),
});

export default function Playground() {
  const currentUser = useAppStore(currentUserSelector);

  const myHandleSubmit = (values, actions) => {
    console.log('/////////');
    console.log(values);
    actions.setSubmitting(false);
    console.log('/////////');
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Typography>Playground</Typography>
      </Grid>

      <Grid item sm={12}>
        <Button
          onClick={() => {
            // TestService.getPagesVisited(2020).then((resp) => {
            //   console.log('test');
            //   console.log(resp);
            //   console.log('test');
            // });
          }}
        >
          Make API request
        </Button>
      </Grid>

      <Grid item sm={12}>
        <Button
          onClick={() => {
            setCurrentUser({
              ...currentUser,
              token: `${new Date().getTime()}`,
            });
          }}
        >
          Change token
        </Button>
      </Grid>

      <Grid item sm={12}>
        <Typography>My form</Typography>

        <Card>
          <Formik
            enableReinitialize
            initialValues={{
              date: null,
              startTime: '',
            }}
            validationSchema={schema}
            onSubmit={myHandleSubmit}
          >
            {({ dirty, isSubmitting }) => (
              <Form>
                <CardContent>
                  {/* <FormTextFieldOutlined name="firstName" type="text" label="First Name" fullWidth /> */}
                  <FormDateField name="date" label="Is Active" fullWidth />
                  <FormTimeField name="startTime" label="Is Active" fullWidth />
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    style={{ marginLeft: '15px' }}
                  >
                    Submit
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
}
