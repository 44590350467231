/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Field, getIn } from 'formik';

export const RenderCheckboxGroup = (props) => {
  const {
    field,
    form: { touched, errors, setFieldValue },
    label,
    dataSource,
  } = props;

  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  // const value = field.value || [];
  return (
    <FormControl error={touch && error != null} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {dataSource.map((d) => (
          <FormControlLabel
            key={d.id}
            control={
              <Checkbox
                checked={field.value && field.value.includes(d.id) ? true : false}
                onChange={(event) => {
                  const name = Number.parseInt(event.target.name);
                  const values = field.value || [];
                  const checked = event.target.checked;
                  if (checked) {
                    setFieldValue(field.name, [...values, name]);
                  } else {
                    setFieldValue(
                      field.name,
                      values.filter((v) => v !== name),
                    );
                  }
                }}
                name={`${d.id}`}
                color="primary"
              />
            }
            label={d.name}
          />
        ))}
      </FormGroup>
      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export const FormCheckboxGroup = ({ name, dataSource, ...others }) => (
  <Field name={name} component={RenderCheckboxGroup} dataSource={dataSource} {...others} />
);
