import React from 'react';
import { useLocation,useNavigate, Navigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import yup from '../../commons/validators/customYup';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import AuthService from '../../services/AuthService';
import { FormTextField } from '../../components/forms/FormikFields';
import { setCurrentUser } from '../../stores/appStore';

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);

  const handleSubmit = (values, actions) => {
    AuthService.login(values)
      .then((userLogged) => {       
        if (userLogged.forceResetPassword) {
          const { from } = location.state || { from: { pathname: '/' } };

          navigate(`/define-password`, {
            state: {
              username: values.username,
              password: values.password,
              userLogged,
              fromPath: from.pathname,
            },
          });

        } else {
          setCurrentUser(userLogged);
          setRedirectToReferrer(true);
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  };

  const { from } = location.state || { from: { pathname: '/' } };
  if (redirectToReferrer) return <Navigate to={from} replace />;

  return (
    <div
      style={{
        width: '400px',
        margin: 'auto auto',
        marginBottom: '10px',
      }}
    >
      <Paper
        sx={{
          mt: 26,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          py: 2,
        }}
      >
        <Avatar
          sx={{
            m: 1,
            backgroundColor: 'secondary.main',
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ dirty, isSubmitting }) => (
            <Form
              style={{
                marginTop: '10px',
                width: '100%',
              }}
            >
              <FormTextField fullWidth name="username" label="Username" type="text" />
              <br />
              <br />
              <FormTextField fullWidth name="password" label="Password" type="password" />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting}
                sx={{ mt: 3 }}
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
}

export default SignInPage;
