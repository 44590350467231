const MaskUtils = {
  phone(value) {
    if (!value) {
      return value;
    }
    return value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d{1})/, '($1) $2')
      .replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');
  },

  cpf(value) {
    if (!value) {
      return value;
    }
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
};

export { MaskUtils as default };
