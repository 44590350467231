import { Box } from '@mui/material';
import React from 'react';
import { FormSelect } from '../../components/forms/formik/FormSelect';

// COMPONENT
function EmployeeProfileFormSelect({profiles}) {


  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormSelect
        fullWidth
        label="Perfil"
        name="roleId"
        placeholder="Selecione o perfil ..."
        dataSource={profiles}
      />
    </Box>
  );

}

export default EmployeeProfileFormSelect;
