import { Box, Chip, CircularProgress, List, ListItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberUtils from '../../../../commons/utils/numberUtils';
import { useTheme } from '@mui/material/styles';
import SuperUserService from '../../../../services/SuperUserService';

// COMPONENT
function CompanyOnlineSchedulingSettingsServices({
  selectedCompany,
  open,
  companyOnlineSchedulingServices,
  setCompanyOnlineSchedulingServices,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (open && !companyOnlineSchedulingServices) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingServices(selectedCompany.company.id)
        .then((resp) => {
          setCompanyOnlineSchedulingServices(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany, companyOnlineSchedulingServices, setCompanyOnlineSchedulingServices]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        <List>
          {companyOnlineSchedulingServices &&
            companyOnlineSchedulingServices.map((service, index) => (
              <ListItem key={index}>
                <Box sx={{ my: '6px' }}>
                  <Typography>{`${service.name} - ${NumberUtils.toCurrency(service.price, 2)}`}</Typography>
                  <Box sx={{ fontSize: '0.875rem' }}>
                    Exibição do preço:{' '}
                    {
                      <Box
                        component="span"
                        sx={{
                          color:
                            service.onlineSchedulingPriceDisplay === 'FIXED'
                              ? theme.palette.success.main
                              : service.onlineSchedulingPriceDisplay === 'STARTS_AT'
                              ? theme.palette.info.main
                              : theme.palette.warning.main,
                        }}
                      >
                        {service.onlineSchedulingPriceDisplay === 'FIXED'
                          ? 'Normal'
                          : service.onlineSchedulingPriceDisplay === 'STARTS_AT'
                          ? 'A partir de'
                          : 'Não exibir'}
                      </Box>
                    }
                    <Chip
                      sx={{ ml: 1.5 }}
                      variant={service.onlineSchedulingEnabled ? 'contained' : 'outlined'}
                      size="small"
                      color={service.onlineSchedulingEnabled ? 'success' : 'error'}
                      label={service.onlineSchedulingEnabled ? 'Ativo' : 'Inativo'}
                    />
                  </Box>
                  <Box sx={{ fontSize: '0.800rem' }}>Duração: {service.duration + ' minutos'}</Box>
                </Box>
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
}

export default CompanyOnlineSchedulingSettingsServices;
