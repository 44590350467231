/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';

// COMPONENT
export function FontAwesomeIconStatus({ status, disabled, style, ...rest }) {
  const theme = useTheme();
  if (disabled) {
    return <FontAwesomeIcon color={theme.palette.action.disabled} style={style} {...rest} />;
  }
  return <FontAwesomeIcon color={theme.palette[status]?.main ?? 'black'} style={style} {...rest} />;
}
