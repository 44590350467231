import { chunk } from 'lodash';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import showNotification from '../../../../commons/helpers/showNotification';
import SuperUserService from '../../../../services/SuperUserService';
import LabelStatus from '../../../../components/labels/LabelStatus';
import dayjs from 'dayjs';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const PAGE_SIZE = 1500;

// COMPONENT
function ImportCustomersConfirm({
  selectedCompany,
  setModalVisible,
  isWorking,
  setIsWorking,
  importingStatus,
  modalConfirmImportingOpen,
  setModalConfirmImportingOpen,
}) {
  const handleConfirmImporting = async () => {
    if (!importingStatus || !importingStatus.goodCount) {
      showNotification('error', 'Nada a importar');
      return;
    }
    setIsWorking(true);
    try {
      const { uniqList } = importingStatus;

      // REQUESST TO IMPORT
      const contactsPagesToUpload = chunk(uniqList, PAGE_SIZE);
      const respArrayOfCounts = await Promise.all(
        contactsPagesToUpload.map((innerContactList) =>
          SuperUserService.importCustomers({ companyId: selectedCompany.id, forms: innerContactList }),
        ),
      );

      const totalCount = respArrayOfCounts.reduce((acc, current) => {
        return acc + current;
      }, 0);

      showNotification(
        'success',
        `Contatos importados com sucesso. Total de contatos não repetidos importados: ${totalCount}`,
      );

      setModalConfirmImportingOpen(false);
      setModalVisible(false);
    } catch (err) {
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <>
      {/* IMPORT CONFIRMATION */}
      <Dialog
        onClose={() => {
          setModalConfirmImportingOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={modalConfirmImportingOpen}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle variant="h5">
          <Box display={'flex'} justifyContent={'space-between'}>
            Confirmar Importação de clientes - {selectedCompany.name}
            <Button
              sx={{ padding: 0 }}
              onClick={() => {
                console.log(importingStatus);
                showNotification('info', 'Verifique os logs. (Atalho F12)');
              }}
            >
              Checar log no console{' '}
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography color="textSecondary" gutterBottom style={{ fontWeight: 'bold' }}>
              Total: <Typography component="span">{importingStatus?.totalCount ?? '-'}</Typography>
            </Typography>

            <LabelStatus color="textSecondary" gutterBottom status="error" style={{ fontWeight: 'bold' }}>
              Sem Telefone:{' '}
              <Typography component="span">{importingStatus?.excludedNoPhoneCount ?? '-'}</Typography>
            </LabelStatus>
            <LabelStatus gutterBottom status="warning" style={{ fontWeight: 'bold' }}>
              Duplicados: <Typography component="span">{importingStatus?.duplicatesCount ?? '-'}</Typography>
            </LabelStatus>
            <LabelStatus color="textSecondary" gutterBottom status="info" style={{ fontWeight: 'bold' }}>
              Diferença total:{' '}
              <Typography component="span">
                {(importingStatus?.totalCount ?? 0) - (importingStatus?.goodCount ?? 0)}
              </Typography>
            </LabelStatus>
            <LabelStatus color="textSecondary" gutterBottom status="success" style={{ fontWeight: 'bold' }}>
              À importar: <Typography component="span">{importingStatus?.goodCount ?? '-'}</Typography>
            </LabelStatus>
          </Box>
          <Divider />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Nascimento</TableCell>
                <TableCell>Endereço</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {importingStatus?.uniqList?.map((contact, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>{contact.phone1}</TableCell>
                    <TableCell>{contact.cpf}</TableCell>
                    <TableCell>
                      {contact.birthDate ? dayjs(contact.birthDate).format('DD/MM/YYYY') : ''}
                    </TableCell>
                    <TableCell>{contact.address}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* {importingStatus && importingStatus.fieldsFound && importingStatus.fieldsFound.length > 0 && (
            <>
              <Typography component="span">Campos encontrados:</Typography>
              {importingStatus.fieldsFound.map((objectKey) => {
                return (
                  <Chip
                    key={objectKey}
                    label={objectKey}
                    color="primary"
                    variant="outlined"
                    sx={{ ml: 0.2, mr: 0.2 }}
                  />
                );
              })}
            </>
          )} */}
        </DialogContent>

        <DialogActionsStyled>
          <Button
            variant="outlined"
            onClick={() => {
              setModalConfirmImportingOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!importingStatus || isWorking}
            onClick={handleConfirmImporting}
          >
            Confirmar Importação
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default ImportCustomersConfirm;
