const QuestionType = {
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  SINGLE: 'SINGLE_CHOICE',
  MULTI: 'MULTI_CHOICE',
  SECTION: 'SECTION',
};

const getQuestionTypeName = (questionType) => {
  switch (questionType) {
    case QuestionType.BOOLEAN:
      return 'Booleano';
    case QuestionType.TEXT:
      return 'Textual';
    case QuestionType.SINGLE:
      return 'Resposta Única';
    case QuestionType.MULTI:
      return 'Resposta Múltipla';
    case QuestionType.SECTION:
      return 'Nova Seção';
    default:
      return '';
  }
};

const getQuestionTypeColor = (questionType) => {
  switch (questionType) {
    case QuestionType.BOOLEAN:
      return 'primary';
    case QuestionType.TEXT:
      return 'secondary';
    case QuestionType.SINGLE:
      return 'success';
    case QuestionType.MULTI:
      return 'warning';
    case QuestionType.SECTION:
      return 'info';
    default:
      return '';
  }
};

const transformInitDataFromAnamneseForm = (data) => {
  let sectionFieldsToInsert = [];

  data.questions.forEach((question, index) => {
    //set the containOtherItem for question that have otherOption
    if (question.otherItemId && question.anamneseQuestionItems && question.anamneseQuestionItems.length > 0) {
      question.containOtherItem = true;
    } else if (
      question.questionType === QuestionType.SINGLE ||
      question.questionType === QuestionType.MULTI
    ) {
      question.containOtherItem = false;
    }

    //saving the sectionTitle values to insert fields after this foreach
    if (question.sectionTitle) {
      sectionFieldsToInsert.push({ index, sectionTitle: question.sectionTitle });
    }
  });
  //adding the section elements to it's own field (it was necessary to be a normal for because if the current Anamnese Form have more than one section it is necessary to increment the index to be inserted the sectionTitles in the correct field)
  for (let i = 0; i < sectionFieldsToInsert.length; i++) {
    let sectionFieldToInsert = {
      questionType: QuestionType.SECTION,
      title: sectionFieldsToInsert[i].sectionTitle,
    };
    data.questions.splice(sectionFieldsToInsert[i].index + i, 0, sectionFieldToInsert);
  }

  return {
    id: data.id,
    name: data.name,
    description: data.description ?? '',
    commentsPlaceholder: data.commentsPlaceholder ?? '',
    version: data.version,
    firstSectionTitle: 'Questionário Anamnese',
    questions: data.questions,
  };
};

const addNewQuestionToForm = (type) => {
  switch (type) {
    case QuestionType.BOOLEAN:
      return {
        questionType: QuestionType.BOOLEAN,
        title: '',
        booleanWithDetails: false,
      };
    case QuestionType.TEXT:
      return {
        questionType: QuestionType.TEXT,
        title: '',
        booleanWithDetails: false,
      };
    case QuestionType.SINGLE:
      return {
        questionType: QuestionType.SINGLE,
        title: '',
        otherItemId: '',
        containOtherItem: false,
        anamneseQuestionItems: [
          {
            title: '',
            theOtherOption: false,
          },
        ],
      };
    case QuestionType.MULTI:
      return {
        questionType: QuestionType.MULTI,
        title: '',
        otherItemId: '',
        containOtherItem: false,
        anamneseQuestionItems: [
          {
            title: '',
            theOtherOption: false,
          },
        ],
      };
    case QuestionType.SECTION:
      return {
        questionType: QuestionType.SECTION,
        title: '',
      };
    default:
      return {};
  }
};

const isSectionFieldInFirstOrLastPositionOfQuestions = (questions) => {
  return (
    questions[0].questionType === QuestionType.SECTION ||
    questions[questions.length - 1].questionType === QuestionType.SECTION
  );
};

const isSomeOfQuestionsWithLessOfTwoItems = (questions) => {
  return questions.some((q) => {
    if (q.questionType === QuestionType.MULTI || q.questionType === QuestionType.SINGLE) {
      if (!q.containOtherItem) {
        if (q.anamneseQuestionItems.length < 2) {
          return true;
        } else if (
          q.anamneseQuestionItems.length === 2 &&
          q.anamneseQuestionItems.some((i) => i.theOtherOption)
        ) {
          return true;
        }
      }
    }
    return false;
  });
};

const transformDataToSaveAnamneseForm = (questionsToSave, formToSave) => {
  let sectionFields = [];
  questionsToSave.forEach((question, index) => {
    //cleaning all sectionTitles to prevent error when updating
    question.sectionTitle = null;

    if (question.questionType === QuestionType.SECTION) {
      sectionFields.push({ index, title: question.title });
    }

    if (
      (question.questionType === QuestionType.SINGLE || question.questionType === QuestionType.MULTI) &&
      question.containOtherItem &&
      !question.anamneseQuestionItems.some((i) => i.theOtherOption)
    ) {
      question.anamneseQuestionItems.push({ title: 'Outra', theOtherOption: true });
    } else if (
      (question.questionType === QuestionType.SINGLE || question.questionType === QuestionType.MULTI) &&
      !question.containOtherItem &&
      question.anamneseQuestionItems.some((i) => i.theOtherOption)
    ) {
      question.anamneseQuestionItems = question.anamneseQuestionItems.filter((i) => !i.theOtherOption);
    }
  });

  //add section titles to their own questions
  sectionFields.forEach((sectionField) => {
    questionsToSave[sectionField.index + 1].sectionTitle = sectionField.title;
  });

  //removing secion titles to not be sent to API
  questionsToSave = questionsToSave.filter((question) => question.questionType !== QuestionType.SECTION);

  formToSave.questions = questionsToSave;
};

const addItemToQuestion = (question) => {
  let questionCloned = { ...question };
  let anamneseQuestionItems = [...questionCloned.anamneseQuestionItems];
  anamneseQuestionItems.push({
    title: '',
    theOtherOption: false,
  });
  questionCloned.anamneseQuestionItems = anamneseQuestionItems;
  return questionCloned;
};

const removeItemFromQuestion = (question, indexItem) => {
  let questionCloned = { ...question };
  let anamneseQuestionItems = [...questionCloned.anamneseQuestionItems];
  anamneseQuestionItems.splice(indexItem, 1);
  questionCloned.anamneseQuestionItems = anamneseQuestionItems;
  return questionCloned;
};

const getMessageConfirmationDialog = (formId, formName, selectedCompany, isCloneRoute) => {
  if (formId && formName && !isCloneRoute) {
    if (selectedCompany) {
      return (
        'Você tem certeza que deseja atualizar a ficha personalizada ' +
        formName +
        ' para a empresa ' +
        selectedCompany.name +
        '?'
      );
    } else {
      return 'Você tem certeza que deseja atualizar a ficha global ' + formName + '?';
    }
  } else {
    if (selectedCompany) {
      return (
        'Você tem certeza que deseja criar uma nova ficha personalizada para ' + selectedCompany.name + '?'
      );
    } else {
      return 'Você tem certeza que deseja criar uma nova ficha global?';
    }
  }
};

export {
  getQuestionTypeName,
  getQuestionTypeColor,
  transformInitDataFromAnamneseForm,
  addNewQuestionToForm,
  transformDataToSaveAnamneseForm,
  isSectionFieldInFirstOrLastPositionOfQuestions,
  isSomeOfQuestionsWithLessOfTwoItems,
  addItemToQuestion,
  removeItemFromQuestion,
  getMessageConfirmationDialog,
  QuestionType,
};
