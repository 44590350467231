import React, { useState } from 'react';
import { Typography, Box, ToggleButtonGroup, ToggleButton, Card, styled } from '@mui/material';
import StorageService from '../../services/StorageService';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root.Mui-selected': {
    color: 'rgb(250, 250, 253)',
    backgroundColor: theme.palette.primary.main,
  },
}));

// COMPONENT
function ProfileToolsPage() {
  const [whatsAppOption, setWhatsAppOption] = useState(() => {
    return StorageService.getIsWhatsAppDesktop();
  });
  return (
    <Card>
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ p: 2 }}>
        <Typography style={{ marginRight: '0.5rem' }}>Integração WhatApp</Typography>
        <ToggleButtonGroupStyled
          color="primary"
          size="small"
          value={whatsAppOption}
          exclusive
          sx={{ margin: 0 }}
          fullWidth
          onChange={(e, newValue) => {
            if (newValue != null) {
              StorageService.setIsWhatsAppDesktop(newValue);
              setWhatsAppOption(newValue);
            }
          }}
        >
          <ToggleButtonStyled value={true} color="primary">
            <span>Usar WhatsApp Desktop</span>
          </ToggleButtonStyled>
          <ToggleButtonStyled value={false} color="primary">
            <span>Usar WhatsApp WEB</span>
          </ToggleButtonStyled>
        </ToggleButtonGroupStyled>
      </Box>
    </Card>
  );
}

export default ProfileToolsPage;
