import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  styled,
  DialogActions,
  IconButton,
} from '@mui/material';

import { FormSelect } from '../../../components/forms/FormikFields';
import { Formik, Form } from 'formik';
import showNotification from '../../../commons/helpers/showNotification';
import SuperUserService from '../../../services/SuperUserService';
import yup from '../../../commons/validators/customYup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  stripeDiscountInternalId: yup.number(),
});

const DEFAULT_INIT_VALUES = {
  stripeDiscountInternalId: '',
};

// COMPONENT
function UpdateStripeDiscountModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [discountList, setDiscountList] = useState([]);

  useEffect(() => {
    if (open && selectedCompany) {
      Promise.all([
        SuperUserService.getCompanyDiscount(selectedCompany.id),
        SuperUserService.getDiscountList(),
      ]).then(([respCompanyDiscount, respDiscountList]) => {
        if (respCompanyDiscount) {
          setInitialValues({
            stripeDiscountInternalId:
              respDiscountList.find(
                (discountObj) => discountObj.stripeDiscountId === respCompanyDiscount.stripeDiscountId,
              )?.id ?? '',
            timestamp: new Date().getTime(),
          });
        } else {
          setInitialValues({
            ...DEFAULT_INIT_VALUES,
            timestamp: new Date().getTime(),
          });
        }

        setDiscountList(
          respDiscountList.map((discount) => ({
            id: discount.id,
            name: `${discount.name} - Para ${discount.stripePlan.name}`,
          })),
        );
      });
    }
  }, [open, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    SuperUserService.updateCompanyDiscount({
      companyId: selectedCompany.id,
      stripeDiscountInternalId: values.stripeDiscountInternalId,
    })
      .then(() => {
        showNotification('success', 'Atualizado com successo.');
        onSave();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <DialogTitle variant="h5">Aplicar desconto para {selectedCompany.name}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values }) => {
          const hasValueSelected =
            values.stripeDiscountInternalId != null && values.stripeDiscountInternalId !== '';
          return (
            <Form>
              <DialogContent dividers>
                <Grid item xs={12}>
                  <FormSelect
                    fullWidth
                    label="Desconto"
                    name="stripeDiscountInternalId"
                    dataSource={discountList}
                    selectProps={{
                      sx: hasValueSelected
                        ? {
                            '& .MuiSelect-iconStandard': { display: 'none' },
                          }
                        : null,
                      endAdornment: hasValueSelected ? (
                        <IconButton
                          onClick={() => {
                            setFieldValue('stripeDiscountInternalId', '');
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          <FontAwesomeIcon icon="times" />
                        </IconButton>
                      ) : null,
                    }}
                  />
                </Grid>
              </DialogContent>

              <DialogActionsStyled>
                <Button variant="outlined" onClick={onClose}>
                  Fechar
                </Button>
                <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting}>
                  Salvar
                </Button>
              </DialogActionsStyled>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default UpdateStripeDiscountModal;
