import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../../components/table/TableCellAction';
import dayjs from 'dayjs';
import NumberUtils from '../../../commons/utils/numberUtils';
import { getStatusTypeColor, status } from './asaasHelpers';
import HintText from '../../../components/labels/HintText';
import { useState } from 'react';

function AsaasPaymentHistoryTable({ paymentHistory, isLoading, handleEdit, handleCancel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handlePopoverOpen = (e, popoverId) => {
    setAnchorEl(e.target);
    setOpenedPopoverId(popoverId);
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  }

  return (
    <>
      {isLoading ? (
        <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        <>
          {paymentHistory?.length > 0 ? (
            <>
              <Typography
                component="span"
                variant="h5"
                sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
              >
                Histórico de Cobranças
              </Typography>
              <Divider />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data de vencimento</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Editar</TableCell>
                    <TableCell>Deletar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentHistory?.map((payment, i) => {
                    return (
                      <TableRow key={payment.id}>
                        <TableCell>{payment.description}</TableCell>
                        <TableCell>{payment.billingType}</TableCell>
                        <TableCell>{NumberUtils.toCurrency(payment.value, 2)}</TableCell>
                        <TableCell>
                            <Chip
                              color={getStatusTypeColor(payment.status)}
                              label={status[payment.status]}
                            />
                        </TableCell>
                        <TableCell>
                          {dayjs(payment.dueDate).format('DD/MM/YY')}
                          {payment.paymentDate !== null && (
                            <>
                              <FontAwesomeIconStatus
                                status="primary"
                                icon="fa-check"
                                onMouseEnter={(e) => handlePopoverOpen(e, payment.id)}
                                onMouseLeave={handlePopoverClose}
                              />
                              <Popover
                                id="mouse-over-popover"
                                sx={{
                                  pointerEvents: 'none',
                                }}
                                open={openedPopoverId === payment.id}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography sx={{ p: 1 }}>{dayjs(payment.paymentDate).format('DD/MM/YY')}</Typography>
                              </Popover>
                            </>
                          )}
                        </TableCell>
                        <TableCellAction
                          title="Link para pagamento"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.open(payment.invoiceUrl, '_blank');
                          }}
                        >
                          <FontAwesomeIconStatus status="primary" icon="file-invoice-dollar" size="sm" />
                        </TableCellAction>
                        {payment.status === 'OVERDUE' || payment.status === 'PENDING' ? (
                          <TableCellAction
                            title="Editar fatura"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEdit(payment);
                            }}
                          >
                            <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                          </TableCellAction>
                        ) : (
                          <TableCell />
                        )}
                        {payment.status === 'OVERDUE' || payment.status === 'PENDING' ? (
                          <TableCellAction
                            title="Deletar cobrança"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleCancel(payment);
                            }}
                          >
                            <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                          </TableCellAction>
                        ) : (
                          <TableCell />
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          ) : (
            <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
              <HintText>Nenhum registro de cobrança localizado.</HintText>
            </Box>
          )}
        </>
      )}
    </>
  );
}
export default AsaasPaymentHistoryTable;
