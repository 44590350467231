import React from 'react';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from '@mui/material';
import { Formik, Form } from 'formik';
import {  FormTextField } from '../../components/forms/FormikFields';
import yup from '../../commons/validators/customYup';
import EmployeeService from '../../services/EmployeeService';
import showNotification from '../../commons/helpers/showNotification';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const schema = yup.object().shape({
  password: yup.string().min(8).max(40).required(),
});

// COMPONENT
function EmployeeChangePasswordModal({ employeeForm, open, onClose }) {



  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await EmployeeService.savePassword(employeeForm, values.password);
      showNotification('success', 'Senha atualizada com sucesso.');
      onClose();
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <DialogTitle>
        <Typography component="span" variant="h4">
          Atualizar senha
        </Typography>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{password : ''}}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form>
            <DialogContent dividers>
              <FormTextField fullWidth label="Nova Senha" name="password" />
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                Atualizar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EmployeeChangePasswordModal;
