import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import SuperUserService from '../../../services/SuperUserService';
import dayjs from 'dayjs';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function EmployeeChangesCompanySubscriptionAuditLogModal({ selectedCompany, modalVisible, setModalVisible }) {
  const [employeeChangesLog, setEmployeeChangesLog] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      SuperUserService.getEmployeeChangesCompanySubscriptionAuditLog(selectedCompany.id, page)
        .then((result) => {
          setEmployeeChangesLog(result?.content);
          setTotalPages(result?.totalPages);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, selectedCompany, page]);

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalVisible} maxWidth="xl">
      <DialogTitle variant="h5">Log de alterações - {selectedCompany.name}</DialogTitle>

      <Box sx={{ mt: 1 }}>
        {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
      </Box>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Funcionário</TableCell>
              <TableCell>Evento</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Detalhe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeChangesLog?.map((c) => {
              return (
                <TableRow key={c.id}>
                  <TableCell>{dayjs(c.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>{c.employee?.name}</TableCell>
                  <TableCell>{c.eventMessage}</TableCell>
                  <TableCell>{c.subscriptionType}</TableCell>
                  <TableCell>{c.eventDetails}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" marginTop={1}>
          <Pagination
            count={totalPages}
            page={page}
            shape="rounded"
            onChange={(e, p) => {
              setPage(p);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActionsStyled>
        <Button variant="outlined" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default EmployeeChangesCompanySubscriptionAuditLogModal;
