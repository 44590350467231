import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SuperUserService from '../../../../services/SuperUserService';
import dayjs from 'dayjs';

// COMPONENT
function CompanyOnlineSchedulingSettingsUnavailablePeriod({ selectedCompany, open }) {
  const [isLoading, setIsLoading] = useState(false);
  const [unavailablePeriods, setUnavailablePeriods] = useState([]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingUnavailablePeriods(selectedCompany.company.id)
        .then((resp) => {
          setUnavailablePeriods(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, selectedCompany]);

  return (
    <>
      {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell>Profissionais</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unavailablePeriods?.map((obj) => {
            return (
              <TableRow key={obj.id}>
                <TableCell>
                  {dayjs(obj.startDate).format('L')} até {dayjs(obj.endDate).format('L')}
                </TableCell>
                <TableCell>{obj.employees.map((e) => e.name).join(', ')}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default CompanyOnlineSchedulingSettingsUnavailablePeriod;
