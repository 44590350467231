import dayjs from 'dayjs';
import { VAR_CUSTOMER_NAME, VAR_DATE_AND_START_TIME, VAR_SERVICE_NAME } from './messageConstants';

const CALENDAR_FORMAT_CONFIG = {
  sameDay: '([Hoje]) D [de] MMMM',
  nextDay: '([Amanhã]) D [de] MMMM',
  lastDay: 'D [de] MMMM',
  lastWeek: 'D [de] MMMM',
  nextWeek: '(dddd) D [de] MMMM',
  sameElse: '(dddd) D [de] MMMM',
};

export function prepareMessageForAllVariables({ message, customerName, serviceName, date, startTime }) {
  let selectedMsgString = message;
  if (customerName) {
    const nameParts = customerName.trim().split(' ');
    if (nameParts.length > 0) {
      selectedMsgString = selectedMsgString.replace(VAR_CUSTOMER_NAME, nameParts[0]);
    } else {
      selectedMsgString = selectedMsgString.replace(VAR_CUSTOMER_NAME, '');
    }
  } else {
    selectedMsgString = selectedMsgString.replace(VAR_CUSTOMER_NAME, '');
  }

  if (serviceName) {
    selectedMsgString = selectedMsgString.replace(VAR_SERVICE_NAME, serviceName);
  }
  if (date && startTime) {
    const formattedDate = dayjs(date).calendar(null, CALENDAR_FORMAT_CONFIG);
    selectedMsgString = selectedMsgString.replace(
      VAR_DATE_AND_START_TIME,
      `dia ${formattedDate} às ${startTime}`,
    );
  }

  return selectedMsgString;
}
