/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import showNotification from '../../../commons/helpers/showNotification';
import SuperUserService from '../../../services/SuperUserService';
import yup from '../../../commons/validators/customYup';
import { FormTextField } from '../../../components/forms/FormikFields';

const schema = yup.object().shape({
  transactionId: yup.string().required(),
});

const INIT_VALUES = { transactionId: '' };

// COMPONENT
function AppleTransactionHistorySearch() {
  const [isLoading, setIsLoading] = useState(false);
  const [resultsInfo, setResultsInfo] = useState({ transactions: [] });

  const myHandleSubmit = (values, actions) => {
    setIsLoading(true);
    SuperUserService.getAppleTransactionHistory(values.transactionId)
      .then((resp) => {
        if (resp.transactions.length === 0) {
          showNotification('warning', 'Nenhum usuário encontrado.');
        }
        setResultsInfo(resp);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsLoading(false);
      });
  };

  const handleClean = (setFieldValue) => {
    setResultsInfo({ transactions: [] });
    setFieldValue('transactionId', '');
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <FormTextField
                name="transactionId"
                label="Procure histórico por Id da Trasação"
                sx={{ minWidth: '300px' }}
                inputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        handleClean(setFieldValue);
                      }}
                    >
                      <FontAwesomeIcon icon="times" />
                    </IconButton>
                  ),
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || isLoading}
                sx={{ ml: 2 }}
              >
                Buscar {(isSubmitting || isLoading) && <CircularProgress />}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {resultsInfo != null && resultsInfo.transactions.length > 0 && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>transactionId</TableCell>
                <TableCell>originalTransactionId</TableCell>
                <TableCell>webOrderLineItemId</TableCell>
                <TableCell>bundleId</TableCell>
                <TableCell>productId</TableCell>
                <TableCell>subscriptionGroupIdentifier</TableCell>
                <TableCell>purchaseDate</TableCell>
                <TableCell>originalPurchaseDate</TableCell>
                <TableCell>expiresDate</TableCell>
                <TableCell>quantity</TableCell>
                <TableCell>type</TableCell>
                <TableCell>inAppOwnershipType</TableCell>
                <TableCell>signedDate</TableCell>
                <TableCell>offerType</TableCell>
                <TableCell>offerIdentifier</TableCell>
                <TableCell>environment</TableCell>
                <TableCell>revocationReason</TableCell>
                <TableCell>revocationDate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultsInfo.transactions?.map((receipt, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{receipt.transactionId}</TableCell>
                    <TableCell>{receipt.originalTransactionId}</TableCell>
                    <TableCell>{receipt.webOrderLineItemId}</TableCell>
                    <TableCell>{receipt.bundleId}</TableCell>
                    <TableCell>{receipt.productId}</TableCell>
                    <TableCell>{receipt.subscriptionGroupIdentifier}</TableCell>
                    <TableCell>
                      {receipt.purchaseDate ? dayjs(receipt.purchaseDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>
                      {receipt.originalPurchaseDate
                        ? dayjs(receipt.originalPurchaseDate).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {receipt.expiresDate ? dayjs(receipt.expiresDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>{receipt.quantity}</TableCell>
                    <TableCell>{receipt.type}</TableCell>
                    <TableCell>{receipt.inAppOwnershipType}</TableCell>
                    <TableCell>
                      {receipt.signedDate ? dayjs(receipt.signedDate).format('DD/MM/YYYY HH:mm:ss') : '-'}
                    </TableCell>
                    <TableCell>{receipt.offerType}</TableCell>
                    <TableCell>{receipt.offerIdentifier}</TableCell>
                    <TableCell>{receipt.environment}</TableCell>
                    <TableCell>{receipt.revocationReason}</TableCell>
                    <TableCell>{receipt.revocationDate}</TableCell>
                  </TableRow>
                );
              })}
              {resultsInfo.hasMore && (
                <TableRow>
                  <TableCell>Possui mais pagina de dados</TableCell>
                  <TableCell>Revision: {resultsInfo.revision}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
}

export default AppleTransactionHistorySearch;
