/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Field, getIn } from 'formik';

export const RenderRadioGroup = (props) => {
  const {
    field,
    form: { touched, errors, setFieldValue },
    label,
    labelStyle,
    row,
    labelTrue,
    labelFalse,
  } = props;

  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <FormControl error={touch && error != null} component="fieldset">
      <FormLabel component="legend" style={labelStyle}>
        {label}
      </FormLabel>
      <RadioGroup
        name={field.name}
        value={`${field.value}`}
        onChange={(e) => {
          setFieldValue(field.name, !field.value);
        }}
        row={row}
      >
        <FormControlLabel value="true" control={<Radio color="primary" />} label={labelTrue} />
        <FormControlLabel value="false" control={<Radio color="primary" />} label={labelFalse} />
      </RadioGroup>

      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
export const FormBooleanRadioGroup = ({ name, labelTrue, labelFalse, row, ...others }) => (
  <Field
    name={name}
    component={RenderRadioGroup}
    labelTrue={labelTrue}
    labelFalse={labelFalse}
    row={row}
    {...others}
  />
);
