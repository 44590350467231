import HttpService from './commons/HttpService';

const ProfileService = {

  update(profile) {
    return HttpService.put('/profile', profile);
  },

};

export default ProfileService;
