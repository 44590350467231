import { status } from '../pages/feature/featureListHelpers';
import HttpService from './commons/HttpService';

const FeatureService = {
  create(feature) {
    return HttpService.post('/featureRequests', feature);
  },

  update(feature) {
    return HttpService.put(`/featureRequests/${feature.id}`, feature);
  },

  save(feature) {
    if (feature.id && feature.id > 0) {
      return this.update(feature);
    }
    return this.create(feature);
  },

  delete(feature) {
    return HttpService.delete(`/featureRequests/${feature.id}`);
  },

  addCompany(feature) {
    return HttpService.post('/featureRequests/companies', feature);
  },

  getCompaniesById(id) {
    return HttpService.get(`/featureRequests/companies/${id}`);
  },

  getById(id) {
    return HttpService.get(`/featureRequests/${id}`);
  },

  getByStatus(statusId) {
    return HttpService.get(`/featureRequests/status/${status[statusId].param}`);
  },

  getByStatusAndMenu(statusId, menuId) {
    return HttpService.get(`/featureRequests/status/${status[statusId].param}/menu/${menuId}`);
  },

  getAll() {
    return HttpService.get('/featureRequests');
  },

  find(status, menu) {
    if (menu && menu !== '') {
      return this.getByStatusAndMenu(status, menu);
    } else {
      return this.getByStatus(status);
    }
  },

  addCompanyEmail(featureRequestId, companyEmailForm) {
    return HttpService.post(`/featureRequests/${featureRequestId}/companyEmails`, companyEmailForm);
  },

  getCompanyEmailsById(featureRequestId) {
    return HttpService.get(`/featureRequests/${featureRequestId}/companyEmails`);
  },

  deleteCompanyEmails({ featureRequestId, companyEmailId }) {
    return HttpService.delete(`/featureRequests/${featureRequestId}/companyEmails/${companyEmailId}`);
  },
};

export default FeatureService;
