import dayjs from 'dayjs';
import importXlsxToJson from '../../../../commons/helpers/importFromExcel';
import parseCsvFile from '../../../../commons/helpers/parseCsvFile';
import showNotification from '../../../../commons/helpers/showNotification';
import PhoneUtils from '../../../../commons/utils/phoneUtils';

const AVEC = 'AVEC';
const TUA = 'TUA';
const BEAUTY = 'BEAUTY';
const GENDO = 'GENDO';
const SALAO99 = 'SALAO99';
const TRINKS = 'TRINKS';
const MINHAAGENDA = 'MINHAAGENDA';

const FIELD_LIST = [
  {
    fieldName: 'name',
    maxLength: 50,
    minLenth: 2,
    required: true,
  },
  {
    fieldName: 'email',
    maxLength: 50,
  },
  {
    fieldName: 'address',
    maxLength: 150,
  },
  {
    fieldName: 'cpf',
    maxLength: 14,
  },
];

const checkBadContacts = (list) => {
  for (let index = 0; index < FIELD_LIST.length; index++) {
    const { fieldName, required, minLenth, maxLength } = FIELD_LIST[index];

    const badContacts = list.filter((obj) => {
      const fieldValue = obj[fieldName];

      if (required && !fieldValue) {
        console.log(`${fieldName} value not present`);
        return true;
      }

      if (fieldValue) {
        if (minLenth != null && fieldValue.length < minLenth) {
          console.log(`${fieldName} minLenth ${minLenth} invalid (${fieldValue.length})... see value below`);
          console.log(fieldValue);
          return true;
        }
        if (maxLength != null && fieldValue.length > maxLength) {
          console.log(
            `${fieldName} maxLength ${maxLength} invalid (${fieldValue.length})... see value below`,
          );
          console.log(fieldValue);
          return true;
        }
      }
      return false;
    });
    if (badContacts.length > 0) {
      showNotification(
        'warning',
        `Foi encontrado um ou mais contatos com ${fieldName} inválido. Por favor corrija. Verifique o log do navegador para ver os ${fieldName}s.`,
      );
      console.log(badContacts);
      return true;
    }
  }
  return false;
};

const getDuplicatesByPhone1 = (list) => {
  const uniqList = [];
  const groupedByPhone1ToCount = {};

  list.forEach((customerObj) => {
    const { phone1, name } = customerObj;
    if (phone1 && name) {
      const firstName = name.split(' ')[0];

      // prepare key
      const objectKey = `${phone1}_${firstName}`;

      const currentCountForCustomerObj = groupedByPhone1ToCount[objectKey];
      if (!currentCountForCustomerObj) {
        uniqList.push(customerObj);
        groupedByPhone1ToCount[objectKey] = 1;
      } else {
        groupedByPhone1ToCount[objectKey] = currentCountForCustomerObj + 1;
      }
    }
  });

  const duplicates = [];
  Object.keys(groupedByPhone1ToCount).forEach((phoneNumber) => {
    const count = groupedByPhone1ToCount[phoneNumber];
    if (count && count > 1) {
      duplicates.push(phoneNumber);
    }
  });

  return {
    duplicates,
    uniqList,
  };
};

const templates = {
  [AVEC]: {
    id: 'AVEC',
    name: 'Avec',
    format: '.xlsx',
  },
  [TUA]: {
    id: 'TUA',
    name: 'Tua Agenda',
    format: '.csv',
  },
  [BEAUTY]: {
    id: 'BEAUTY',
    name: 'Sistema Beauty',
    format: '.xls',
  },
  [GENDO]: {
    id: 'GENDO',
    name: 'Gendo',
    format: '.csv',
  },
  [SALAO99]: {
    id: 'SALAO99',
    name: 'Salão 99',
    format: '.csv',
  },
  [TRINKS]: {
    id: 'TRINKS',
    name: 'Trinks',
    format: '.csv',
  },
  [MINHAAGENDA]: {
    id: 'MINHAAGENDA',
    name: 'Minha Agenda',
    format: '.xlsx',
  },
};

const validBirthDate = (birthDate) => {
  let preparedBirthDate = null;
  if (birthDate) {
    if (birthDate.length === 9) {
      const momentBirthDate = dayjs(birthDate, 'DD/M/YYYY');
      if (momentBirthDate.isValid() && momentBirthDate.year() >= 1900) {
        preparedBirthDate = momentBirthDate.format('YYYY-MM-DD');
      }
    } else {
      const momentBirthDate = dayjs(birthDate, 'DD/MM/YYYY');
      if (momentBirthDate.isValid() && momentBirthDate.year() >= 1900) {
        preparedBirthDate = momentBirthDate.format('YYYY-MM-DD');
      }
    }
  }
  return preparedBirthDate;
};

const validPhoneNumber = (isCompanyBR, phoneDDD, customerPhone) => {
  let phoneNumber1;
  if (
    isCompanyBR &&
    customerPhone &&
    customerPhone.startsWith('55') &&
    (customerPhone.length === 13 || customerPhone.length === 12)
  ) {
    phoneNumber1 = PhoneUtils.sanitizeWithoutCountry(`+${customerPhone}`);
  } else {
    phoneNumber1 = PhoneUtils.sanitizeWithoutCountry(customerPhone);
  }

  // remove 0 for local area code >> 034 becomes 34
  if (phoneNumber1.startsWith('0')) {
    phoneNumber1 = phoneNumber1.replace(/^0/, '');
  }

  if (isCompanyBR) {
    if (phoneDDD != null && phoneDDD !== '' && (phoneNumber1.length === 8 || phoneNumber1.length === 9)) {
      phoneNumber1 = `${phoneDDD}${phoneNumber1}`;
    }
  }
  return phoneNumber1;
};

const mountContactAddress = (address, number, neighborhood, city, state, cep) => {
  let completAddress = '';
  if (address !== '') {
    completAddress = address;
    if (number !== '' && number !== '0' && number !== 0) {
      completAddress += ', ' + number;
    }

    if (neighborhood !== '') {
      if (completAddress !== '') {
        completAddress += ' - ';
      }
      completAddress += neighborhood;
    }
    if (city !== '') {
      if (completAddress !== '') {
        completAddress += ', ';
      }
      completAddress += city;
    }
    if (state !== '') {
      if (completAddress !== '') {
        completAddress += ' - ';
      }
      completAddress += state;
    }
    if (cep !== '') {
      if (completAddress !== '') {
        completAddress += ', ';
      }
      completAddress += cep;
    }
  }
  return completAddress;
};

const processImport = async (
  selectedTemplate,
  selectedFile,
  isCompanyBR,
  isShowingAutomatic,
  phoneDDD,
  setIsWorking,
  setImportingStatus,
  setModalConfirmImportingOpen,
) => {
  try {
    if (!isShowingAutomatic) {
      setIsWorking(true);
      const contactList = await parseCsvFile(selectedFile, true);

      if (contactList.length === 0) {
        showNotification('warning', 'Nenhum registro para upload. Verifique o CSV');
        setIsWorking(false);
        return;
      }
      const firstObject = contactList[0];

      if ('Endereço' in firstObject && 'Rua' in firstObject) {
        showNotification(
          'warning',
          'Arquivo não pode conter ambos campos "Endereço" e "Rua". Escolha entre endereço em 1 campo só ou para concatenação.',
        );
        setIsWorking(false);
        return;
      }

      const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
        const phone = contact['Telefone'];
        return phone != null && phone.replace(/\D/g, '').length >= 8;
      });
      if (filteredOutThoseWithoutPhoneNumber.length === 0) {
        showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o CSV');
        setIsWorking(false);
        return;
      }

      // PREPARE
      const preparedConctatsToUpload = filteredOutThoseWithoutPhoneNumber.map((contact) => {
        let preparedCustomerAddress = null;
        const customerName = contact['Nome'] ?? '';
        const customerPhone = contact['Telefone'];
        const customerEmail = contact['Email'];
        const customerBirthDate = contact['Data Nascimento'];
        const customerCpf = contact['CPF'];

        let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

        let preparedBirthDate = null;
        if (customerBirthDate) {
          const momentBirthDate = dayjs(customerBirthDate, 'DD/MM/YYYY');
          if (momentBirthDate.isValid() && momentBirthDate.year() >= 1900) {
            preparedBirthDate = momentBirthDate.format('YYYY-MM-DD');
          }
        }

        const shouldUseConcatenatedAddress = 'Rua' in contact;
        if (shouldUseConcatenatedAddress) {
          // campos de endereço para concatenar
          const addressStreet = contact['Rua'];
          const addressNumber = contact['Numero'];
          const addressNeighborhood = contact['Bairro'];
          const addressCity = contact['Cidade'];
          const addressState = contact['Estado'];
          const addressPostalCode = contact['CEP'];

          if (addressStreet && addressNumber) {
            // expected format
            // Rua Nome, 17 - Bairro, Araguari - MG, 38440-000
            preparedCustomerAddress = `${addressStreet}, ${addressNumber}`;
            if (addressNeighborhood) {
              preparedCustomerAddress += ` - ${addressNeighborhood}`;
            }
            if (addressCity) {
              preparedCustomerAddress += `, ${addressCity}`;
            }
            if (addressState) {
              preparedCustomerAddress += ` - ${addressState}`;
            }
            if (addressPostalCode) {
              preparedCustomerAddress += `, ${addressPostalCode}`;
            }
          }
        } else {
          preparedCustomerAddress = contact['Endereço'];
        }
        return {
          name: customerName,
          phone1: phoneNumber1,
          email: customerEmail,
          address: preparedCustomerAddress,
          birthDate: preparedBirthDate,
          cpf: customerCpf,
        };
      });

      const containsBadContas = checkBadContacts(preparedConctatsToUpload);
      if (containsBadContas) {
        setIsWorking(false);
        return;
      }

      const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
      if (duplicates.length > 0) {
        console.log('-----FOUND DUPLICATES-----');
        console.log(duplicates);
        console.log('----------');
      }

      const firstRow =
        filteredOutThoseWithoutPhoneNumber && filteredOutThoseWithoutPhoneNumber.length > 0
          ? filteredOutThoseWithoutPhoneNumber[0]
          : null;

      const fieldKeyToExists = firstRow
        ? {
            Nome: 'Nome' in firstRow,
            Telefone: 'Telefone' in firstRow,
            Email: 'Email' in firstRow,
            Endereço: 'Endereço' in firstRow,
            'Data Nascimento': 'Data Nascimento' in firstRow,
            CPF: 'CPF' in firstRow,
          }
        : null;
      const fieldsFound = fieldKeyToExists
        ? Object.keys(fieldKeyToExists).filter((objectKey) => {
            return fieldKeyToExists[objectKey];
          })
        : null;

      setImportingStatus({
        uniqList,
        totalCount: contactList.length,
        goodCount: uniqList.length,
        excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutPhoneNumber.length,
        duplicatesCount: duplicates.length,
        fieldsFound,
      });

      setModalConfirmImportingOpen(true);
    } else {
      if (selectedTemplate === templates.AVEC.id) {
        const contactList = await importXlsxToJson(selectedFile);

        let positionsInXlsx = {
          name: { position: null },
          phone: { position: null },
          email: { position: null },
          address: { position: null },
          number: { position: null },
          state: { position: null },
          city: { position: null },
          neighborhood: { position: null },
          birthDate: { position: null },
          CPF: { position: null },
          cep: { position: null },
        };
        positionsInXlsx.name.position = contactList[0].findIndex((column) => column === 'Cliente');
        positionsInXlsx.phone.position = contactList[0].findIndex((column) => column === 'Celular');
        positionsInXlsx.email.position = contactList[0].findIndex((column) => column === 'E-mail');
        positionsInXlsx.address.position = contactList[0].findIndex((column) => column === 'Endereço');
        positionsInXlsx.number.position = contactList[0].findIndex((column) => column === 'Número');
        positionsInXlsx.state.position = contactList[0].findIndex((column) => column === 'Estado');
        positionsInXlsx.city.position = contactList[0].findIndex((column) => column === 'Cidade');
        positionsInXlsx.neighborhood.position = contactList[0].findIndex((column) => column === 'Bairro');
        positionsInXlsx.birthDate.position = contactList[0].findIndex((column) => column === 'Aniversário');
        positionsInXlsx.CPF.position = contactList[0].findIndex((column) => column === 'CPF');
        positionsInXlsx.cep.position = contactList[0].findIndex((column) => column === 'CEP');

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          const phone = contact[positionsInXlsx.phone.position].toString();
          return phone != null && phone.replace(/\D/g, '').length >= 8;
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o XLSX');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact[positionsInXlsx.name.position].trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o XLSX');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact[positionsInXlsx.name.position].trim().substring(0, 49) ?? '';
          const customerPhone = contact[positionsInXlsx.phone.position].toString();
          const customerEmail = contact[positionsInXlsx.email.position].substring(0, 49);
          const customerBirthDate = contact[positionsInXlsx.birthDate.position];
          const customerCpf = contact[positionsInXlsx.CPF.position].toString().replace(/\D/g, '');
          //Concat Address
          const customerAddress = mountContactAddress(
            contact[positionsInXlsx.address.position],
            contact[positionsInXlsx.number.position],
            contact[positionsInXlsx.neighborhood.position],
            contact[positionsInXlsx.city.position],
            contact[positionsInXlsx.state.position],
            contact[positionsInXlsx.cep.position],
          );

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.TUA.id) {
        const contactList = await parseCsvFile(selectedFile, true);

        if (contactList.length === 0) {
          showNotification('warning', 'Nenhum registro para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          if (contact.Telefone) {
            const phone = contact.Telefone.toString();
            return phone != null && phone.replace(/\D/g, '').length >= 8;
          } else {
            return false;
          }
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact.Nome.trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact.Nome.trim().substring(0, 49) ?? '';
          const customerPhone = contact.Telefone.toString();
          const customerEmail = contact['E-mail'].substring(0, 49);
          const customerBirthDate = contact['Data de '];
          const customerCpf = contact.CPF.toString().replace(/\D/g, '');
          //Concat Address
          const customerAddress = mountContactAddress(
            contact.Rua,
            contact.Nume,
            contact.Bairro,
            contact.Cidad,
            contact.Estad,
            contact.CEP,
          );

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.BEAUTY.id) {
        const contactList = await importXlsxToJson(selectedFile);

        let positionsInXlsx = {
          name: { position: null },
          phone: { position: null },
          email: { position: null },
          CPF: { position: null },
        };
        positionsInXlsx.name.position = contactList[2].findIndex((column) => column === 'NOME');
        positionsInXlsx.phone.position = contactList[2].findIndex((column) => column === 'CELULAR');
        positionsInXlsx.email.position = contactList[2].findIndex((column) => column === 'E-MAIL');
        positionsInXlsx.CPF.position = contactList[2].findIndex((column) => column === 'CPF');

        if (contactList.length === 0) {
          showNotification('warning', 'Nenhum registro para upload. Verifique o XLS');
          setIsWorking(false);
          return;
        }
        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          if (contact[positionsInXlsx.phone.position]) {
            const phone = contact[positionsInXlsx.phone.position].toString();
            return phone != null && phone.replace(/\D/g, '').length >= 8;
          } else {
            return false;
          }
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o XLS');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact[positionsInXlsx.name.position].trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact[positionsInXlsx.name.position].trim().substring(0, 49) ?? '';
          const customerPhone = contact[positionsInXlsx.phone.position].toString();
          const customerEmail = contact[positionsInXlsx.email.position]
            ? contact[positionsInXlsx.email.position].substring(0, 49)
            : '';
          const customerCpf = contact[positionsInXlsx.CPF.position]
            ? contact[positionsInXlsx.CPF.position].toString().replace(/\D/g, '')
            : '';

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: null,
            birthDate: null,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.GENDO.id) {
        const contactList = await parseCsvFile(selectedFile, true, 'ISO-8859-1');

        if (contactList.length === 0) {
          showNotification('warning', 'Nenhum registro para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          console.log(contact);
          if (contact.Telefone) {
            const phone = contact.Telefone.toString();
            return phone != null && phone.replace(/\D/g, '').length >= 8;
          } else {
            return false;
          }
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact.Nome.trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact.Nome.trim().substring(0, 49) ?? '';
          const customerPhone = contact.Telefone.toString();
          const customerEmail = contact['E-mail'].substring(0, 49);
          const customerBirthDate = contact['Data de Nascimento'];
          const customerCpf = contact.CPF.toString().replace(/\D/g, '');
          //Concat Address
          const customerAddress = mountContactAddress(
            contact.Endereço,
            contact['Número'],
            '',
            contact.Cidade,
            contact.Estado,
            contact.CEP,
          );

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });
        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.SALAO99.id) {
        const contactList = await parseCsvFile(selectedFile, true, 'ISO-8859-1');

        if (contactList.length === 0) {
          showNotification('warning', 'Nenhum registro para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          console.log(contact);
          if (contact['Telefone 1']) {
            const phone = contact['Telefone 1'].toString();
            return phone != null && phone.replace(/\D/g, '').length >= 8;
          } else {
            return false;
          }
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact.Nome.trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact.Nome.trim().substring(0, 49) ?? '';
          const customerPhone = contact['Telefone 1'].toString();
          const customerEmail = contact['E-mail'].substring(0, 49);
          const customerBirthDate = contact['Data Nascimento'];
          const customerCpf = contact.CPF.toString().replace(/\D/g, '');
          //Concat Address
          const customerAddress = mountContactAddress(
            contact['Endereço'],
            contact['Número'],
            contact.Bairro,
            contact.Cidade,
            contact.Estado,
            contact.CEP,
          );

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.TRINKS.id) {
        const contactList = await parseCsvFile(selectedFile, true, 'ISO-8859-15', 'CPF', true);

        if (contactList.length === 0) {
          showNotification('warning', 'Nenhum registro para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          console.log(contact);
          if (contact['Telefone 1']) {
            const phone = contact['Telefone 1'].toString();
            return phone != null && phone.replace(/\D/g, '').length >= 8;
          } else {
            return false;
          }
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact.Nome.trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o CSV');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact.Nome.trim().substring(0, 49) ?? '';
          const customerPhone = contact['Telefone 1'].toString();
          const customerEmail = contact['E-mail'].substring(0, 49);
          const customerBirthDate = contact['Data de Nascimento'];
          const customerCpf = contact['CPF']?.toString().replace(/\D/g, '');
          //Concat Address
          const customerAddress = mountContactAddress(
            contact['Endereço'],
            contact['Número'],
            contact.Bairro,
            contact.Cidade,
            contact.Estado,
            contact.CEP,
          );

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else if (selectedTemplate === templates.MINHAAGENDA.id) {
        const contactList = await importXlsxToJson(selectedFile);

        let positionsInXlsx = {
          name: { position: null },
          phone: { position: null },
          email: { position: null },
          address: { position: null },
          number: { position: null },
          state: { position: null },
          city: { position: null },
          neighborhood: { position: null },
          birthDate: { position: null },
          CPF: { position: null },
          cep: { position: null },
        };
        positionsInXlsx.name.position = contactList[0].findIndex((column) => column === 'Nome');
        positionsInXlsx.phone.position = contactList[0].findIndex((column) => column === 'Telefone');
        positionsInXlsx.email.position = contactList[0].findIndex((column) => column === 'Email');
        positionsInXlsx.address.position = contactList[0].findIndex((column) => column === 'Endereço');
        positionsInXlsx.birthDate.position = contactList[0].findIndex(
          (column) => column === 'Data Nascimento',
        );
        positionsInXlsx.CPF.position = contactList[0].findIndex((column) => column === 'CPF');

        const filteredOutThoseWithoutPhoneNumber = contactList.filter((contact) => {
          const phone = contact[positionsInXlsx.phone.position];
          return phone != null && phone.toString().replace(/\D/g, '').length >= 8;
        });
        if (filteredOutThoseWithoutPhoneNumber.length === 0) {
          showNotification('warning', 'Nenhum registro com numero de telefone para upload. Verifique o XLSX');
          setIsWorking(false);
          return;
        }

        const filteredOutThoseWithoutName = filteredOutThoseWithoutPhoneNumber.filter((contact) => {
          return contact[positionsInXlsx.name.position]?.trim().length > 2;
        });
        if (filteredOutThoseWithoutName.length === 0) {
          showNotification('warning', 'Nenhum registro com nome para upload. Verifique o XLSX');
          setIsWorking(false);
          return;
        }

        // PREPARE
        const preparedConctatsToUpload = filteredOutThoseWithoutName.map((contact) => {
          const customerName = contact[positionsInXlsx.name.position].trim().substring(0, 49) ?? '';
          const customerPhone = contact[positionsInXlsx.phone.position].toString();
          const customerEmail = contact[positionsInXlsx.email.position]
            ? contact[positionsInXlsx.email.position].substring(0, 49)
            : '';
          const customerBirthDate = contact[positionsInXlsx.birthDate.position]
            ? contact[positionsInXlsx.birthDate.position]
            : '';
          const customerCpf = contact[positionsInXlsx.CPF.position]
            ? contact[positionsInXlsx.CPF.position].toString().replace(/\D/g, '')
            : '';
          const customerAddress = contact[positionsInXlsx.address.position]
            ? contact[positionsInXlsx.address.position]
            : '';

          let phoneNumber1 = validPhoneNumber(isCompanyBR, phoneDDD, customerPhone);

          let preparedBirthDate = validBirthDate(customerBirthDate);

          return {
            name: customerName,
            phone1: phoneNumber1,
            email: customerEmail,
            address: customerAddress,
            birthDate: preparedBirthDate,
            cpf: customerCpf,
          };
        });

        const containsBadContas = checkBadContacts(preparedConctatsToUpload);
        if (containsBadContas) {
          setIsWorking(false);
          return;
        }

        const { duplicates, uniqList } = getDuplicatesByPhone1(preparedConctatsToUpload);
        if (duplicates.length > 0) {
          console.log('-----FOUND DUPLICATES-----');
          console.log(duplicates);
          console.log('----------');
        }

        setImportingStatus({
          uniqList,
          totalCount: contactList.length,
          goodCount: uniqList.length,
          excludedNoPhoneCount: contactList.length - filteredOutThoseWithoutName.length,
          duplicatesCount: duplicates.length,
        });

        setModalConfirmImportingOpen(true);
      } else {
        showNotification('error', 'Template não configurado: ' + selectedTemplate);
      }
    }
  } catch (error) {
    console.log(error);
    showNotification('error', 'Não foi possivel ler o arquivo');
  } finally {
    setIsWorking(false);
  }
};
export { templates, processImport };
