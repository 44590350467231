/* eslint-disable eqeqeq */
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ButtonCopyToClipboard from '../../../components/buttons/ButtonCopyToClipboard';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../../components/table/TableCellAction';
import SuperUserService from '../../../services/SuperUserService';
import AccountInfoStoreDetailModal from './AccountInfoStoreDetailModal';

// COMPONENT
function AppleAccountInfoStoreTableModal({ historySubscription }) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [historySubscriptionDetail, setHistorySubscriptionDetail] = useState();

  const handlePaymentHistoryDetail = (appleReceiptHistoryId) => {
    setIsLoading(true);
    SuperUserService.getAppleHistorySubscriptionDetail(appleReceiptHistoryId)
      .then((subscription) => {
        setHistorySubscriptionDetail(subscription);
        setModalDetailOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <AccountInfoStoreDetailModal
        json={historySubscriptionDetail}
        open={modalDetailOpen}
        onClose={() => {
          setModalDetailOpen(false);
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>receipt</TableCell>
            <TableCell>environment</TableCell>
            <TableCell>applicationVersion</TableCell>
            <TableCell>utcReceiptCreateDate</TableCell>
            <TableCell>productId</TableCell>
            <TableCell>transactionId</TableCell>
            <TableCell>originalTransactionId</TableCell>
            <TableCell>utcPurchaseDate</TableCell>
            <TableCell>utcExpiresDate</TableCell>
            <TableCell>trialPeriod</TableCell>
            <TableCell>inIntroOfferPeriod</TableCell>
            <TableCell>expirationIntent</TableCell>
            <TableCell>autoRenewStatus</TableCell>
            <TableCell>createdAt</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historySubscription?.map((subscription, i) => {
            return (
              <TableRow key={subscription.id}>
                <TableCell>{subscription.id}</TableCell>
                <TableCell>{subscription.receipt.substring(0,7) + '...'} <ButtonCopyToClipboard copyValue={subscription.receipt} /></TableCell>
                <TableCell>{subscription.environment}</TableCell>
                <TableCell>{subscription.applicationVersion}</TableCell>
                <TableCell>{dayjs(subscription.utcReceiptCreateDate).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>{subscription.productId}</TableCell>
                <TableCell>{subscription.transactionId}</TableCell>
                <TableCell>{subscription.originalTransactionId}</TableCell>
                <TableCell>{dayjs(subscription.utcPurchaseDate).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>{dayjs(subscription.utcExpiresDate).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>{subscription.trialPeriod}</TableCell>
                <TableCell>{subscription.inIntroOfferPeriod}</TableCell>
                <TableCell>{subscription.expirationIntent}</TableCell>
                <TableCell>{subscription.autoRenewStatus}</TableCell>
                <TableCell>{dayjs(subscription.createdAt).format('DD/MM/YY HH:mm')}</TableCell>

                <TableCellAction
                  title="Pesquisar na Loja"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handlePaymentHistoryDetail(subscription.id);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="primary" icon="magnifying-glass" size="sm" />
                </TableCellAction>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default AppleAccountInfoStoreTableModal;
