import { Box, FormControl } from '@mui/material';
import React from 'react';

import CompanyClientAutocomplete from '../../../components/typeahead/CompanyClientAutocomplete';

// COMPONENT
function ExportAppointmentsIntenalFilterByClient({ companyId, selectedClient, setSelectedClient }) {
  return (
    <Box marginTop={2} display="flex" flexDirection="row" alignItems="center" width={'100%'}>
      <FormControl fullWidth>
        <CompanyClientAutocomplete
          companyId={companyId}
          name={`client.name`}
          minLengthRequired={3}
          onChange={(newSelected) => {
            setSelectedClient(newSelected);
          }}
          getSuggestionLabel={(company) => {
            if (!company || Object.keys(company).length === 0) {
              return '';
            }
            return `${company.name} - ${company.email ? company.email : 'SEM EMAIL'} - (teste)`;
          }}
          color="primary"
          label="Filtrar por Cliente"
          placeholder="Procure pelo nome"
        />
      </FormControl>
    </Box>
  );
}

export default ExportAppointmentsIntenalFilterByClient;
