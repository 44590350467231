/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import SuperUserService from '../../../services/SuperUserService';
import { PAYMENT_TYPE_ANDROID } from '../../../commons/helpers/accountHelpers';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function SubscriptionDuplicatedModal({ open, onClose, selectedCompany }) {
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateSubscriptions, setDuplicateSubscriptions] = useState();

  useEffect(() => {
    if (open) {
      if (selectedCompany.subscriptionType === PAYMENT_TYPE_ANDROID) {
        setIsLoading(true);
        SuperUserService.getAndroidSubscriptionDuplicated(selectedCompany.androidReceiptHistoryId)
          .then((companies) => {
            setDuplicateSubscriptions(companies.filter((company) => company.id != selectedCompany.id));
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        SuperUserService.getAppleSubscriptionDuplicated(selectedCompany.appleReceiptHistoryId)
          .then((companies) => {
            setDuplicateSubscriptions(companies.filter((company) => company.id != selectedCompany.id));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [open, selectedCompany]);

  // subcription, duplicateSubscriptions
  const handleSearchUrl = (companyId) => {
    window.open('/app/manage-company?companyId=' + companyId, '_blank');
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography component="span" variant="h5">
              Assinaturas Duplicadas - {selectedCompany.subscriptionType}
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</Box>

          {!isLoading && (
            <>
              {duplicateSubscriptions?.length === 0 ? (
                <Typography variant="h5">Nenhuma assinatura duplicada encontrada</Typography>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>CPF/CNPJ</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Criado em</TableCell>
                      <TableCell>Deletado</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {duplicateSubscriptions?.map((company, i) => {
                      return (
                        <TableRow key={company.id}>
                          <TableCell>{company.name}</TableCell>
                          <TableCell>{company.cnpj ?? company.cpf}</TableCell>
                          <TableCell>{company.email}</TableCell>
                          <TableCell>{company.phone}</TableCell>
                          <TableCell>{dayjs(company.createdAt).format('DD/MM/YY HH:mm')}</TableCell>
                          <TableCell>{company.enabled ? 'Sim' : 'Não'}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                handleSearchUrl(company.id);
                              }}
                            >
                              <FontAwesomeIconStatus icon="arrow-up-right-from-square" status="info" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default SubscriptionDuplicatedModal;
