const priorities = [
  { id: 0, name: 'Baixa', param: 'LOW' },
  { id: 1, name: 'Média', param: 'MEDIUM' },
  { id: 2, name: 'Alta', param: 'HIGH' },
];

const getPriorityByParam = (param) => {
  return priorities.find((priority) => priority.param === param).name;
};

const getPriorityIdByParam = (param) => {
  return priorities.find((priority) => priority.param === param).id;
};

const getPriorityTypeColor = (priority) => {
  switch (getPriorityIdByParam(priority)) {
    case 0:
      return 'info';
    case 1:
      return 'primary';
    case 2:
      return 'success';
    default:
      return '';
  }
};

const status = [
  { id: 0, name: 'Solicitado', param: 'REQUESTED' },
  { id: 1, name: 'Selecionado para Dev', param: 'SELECTED_FOR_DEV' },
  { id: 2, name: 'Finalizado', param: 'COMPLETED' },
];

const SCHEDULE = 'SCHEDULE';
const CHARGES = 'CHARGES';
const COMMANDS = 'COMMANDS';
const PREDEFINED_MESSAGES = 'PREDEFINED_MESSAGES';
const CLIENTS_ANAMNESES = 'CLIENTS_ANAMNESES';
const SERVICES_PACKAGES = 'SERVICES_PACKAGES';
const PRODUCTS_INVENTORY = 'PRODUCTS_INVENTORY';
const EXPENSES = 'EXPENSES';
const EMPLOYEES_COMMISSION = 'EMPLOYEES_COMMISSION';
const PERFORMANCE = 'PERFORMANCE';
const FINANCIAL_SUMMARY = 'FINANCIAL_SUMMARY';
const BEST_CLIENTS = 'BEST_CLIENTS';
const SETTINGS = 'SETTINGS';
const NO_MENU = 'NO_MENU';

const MENU_APP = {
  [SCHEDULE]:{
    id: 'SCHEDULE',
    name: 'Agenda',
    param: 'SCHEDULE',
  },
  [CHARGES]:{
    id: 'CHARGES',
    name: 'Cobranças',
    param: 'CHARGES',
  },
  [COMMANDS]:{
    id: 'COMMANDS',
    name: 'Comandas',
    param: 'COMMANDS',
  },
  [PREDEFINED_MESSAGES]:{
    id: 'PREDEFINED_MESSAGES',
    name: 'Msgs Pre-definidas',
    param: 'PREDEFINED_MESSAGES',
  },
  [CLIENTS_ANAMNESES]:{
    id: 'CLIENTS_ANAMNESES',
    name: 'Clientes & Anamneses',
    param: 'CLIENTS_ANAMNESES',
  },
  [SERVICES_PACKAGES]:{
    id: 'SERVICES_PACKAGES',
    name: 'Serviços & Pacotes',
    param: 'SERVICES_PACKAGES',
  },
  [PRODUCTS_INVENTORY]:{
    id: 'PRODUCTS_INVENTORY',
    name: 'Produtos & Estoque',
    param: 'PRODUCTS_INVENTORY',
  },
  [EXPENSES]:{
    id: 'EXPENSES',
    name: 'Despesas',
    param: 'EXPENSES',
  },
  [EMPLOYEES_COMMISSION]:{
    id: 'EMPLOYEES_COMMISSION',
    name: 'Funcionários & Comissão',
    param: 'EMPLOYEES_COMMISSION',
  },
  [PERFORMANCE]:{
    id: 'PERFORMANCE',
    name: 'Performance',
    param: 'PERFORMANCE',
  },
  [FINANCIAL_SUMMARY]:{
    id: 'FINANCIAL_SUMMARY',
    name: 'Resumo Financeiro',
    param: 'FINANCIAL_SUMMARY',
  },
  [BEST_CLIENTS]:{
    id: 'BEST_CLIENTS',
    name: 'Melhores Clientes',
    param: 'BEST_CLIENTS',
  },
  [SETTINGS]:{
    id: 'SETTINGS',
    name: 'Configurações',
    param: 'SETTINGS',
  },
  [NO_MENU]:{
    id: 'NO_MENU',
    name: 'Sem Menu',
    param: 'NO_MENU',
  }
};

const getStatusByParam = (param) => {
  return status.find((stat) => stat.param === param).name;
};
const getStatusIdByParam = (param) => {
  return status.find((stat) => stat.param === param).id;
};

const getStatusTypeColor = (statusId) => {
  switch (getStatusIdByParam(statusId)) {
    case 0:
      return 'info';
    case 1:
      return 'warning';
    case 2:
      return 'success';
    default:
      return '';
  }
};

export { getStatusTypeColor, getPriorityTypeColor, getPriorityByParam, getStatusByParam, getPriorityIdByParam, getStatusIdByParam, priorities, status, MENU_APP };
