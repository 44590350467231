import React from 'react';
import LabelStatus from '../../components/labels/LabelStatus';
import AnamneseFormFieldArrayRow from './AnamneseFormFieldArrayRow';
import AnamneseFormFieldArrayHeader from './AnamneseFormFieldArrayHeader';

function AnamneseFormFieldArray({ remove, push, replace, move, values, touched, errors }) {
  return (
    <>
      <AnamneseFormFieldArrayHeader push={push} questionsLength={values.questions.length} />
      {values.questions.length > 0 &&
        values.questions.map((question, index) => {
          return (
            <AnamneseFormFieldArrayRow
              key={index}
              question={question}
              index={index}
              remove={remove}
              replace={replace}
              move={move}
              questionsLength={values.questions.length}
            />
          );
        })}
      {touched.questions && typeof errors.questions === 'string' && (
        <LabelStatus sx={{ mb: 2 }} status="error">
          {errors.questions}
        </LabelStatus>
      )}
    </>
  );
}

export default AnamneseFormFieldArray;
