import { styled, Box, Container } from '@mui/material';

const DivWrapper = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

function DashboardLayoutContent({ children }) {
  return (
    <DivWrapper>
      <BoxWrapper>
        <Container maxWidth={false}>{children}</Container>
      </BoxWrapper>
    </DivWrapper>
  );
}

export default DashboardLayoutContent;
