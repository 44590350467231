import numeral from 'numeral';
import dayjs from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import calendarPlugin from 'dayjs/plugin/calendar';
import 'dayjs/locale/pt-br';
import 'numeral/locales/pt-br';

const initLocaleConfig = () => {
  numeral.locale('pt-br');

  dayjs.locale('pt-br');
  dayjs.extend(calendarPlugin);
  dayjs.extend(customParseFormatPlugin);
};
export default initLocaleConfig;
