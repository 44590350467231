import { Box, Button, CircularProgress, List, ListItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SuperUserService from '../../../../services/SuperUserService';
import WorkScheduleListView from '../WorkScheduleListView';
import CompanyWorkingScheduleChangeLogModal from '../CompanyWorkingScheduleChangeLogModal';

// COMPONENT
function CompanyOnlineSchedulingSettingsExpedient({
  companyId,
  open,
  companyOnlineSchedulingExpedient,
  setCompanyOnlineSchedulingExpedient,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalChangeLog, setModalChangeLog] = useState({ open: false });

  useEffect(() => {
    if (open && !companyOnlineSchedulingExpedient) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingExpedient(companyId)
        .then((resp) => {
          setCompanyOnlineSchedulingExpedient(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, companyId, companyOnlineSchedulingExpedient, setCompanyOnlineSchedulingExpedient]);

  return (
    <>
      {/* DIALOG */}
      {modalChangeLog.workingScheduleId && (
        <CompanyWorkingScheduleChangeLogModal
          modalVisible={modalChangeLog.open}
          setModalVisible={(isOpen) => {
            setModalChangeLog((prev) => ({ ...prev, open: isOpen }));
          }}
          workingScheduleId={modalChangeLog.workingScheduleId}
          titleDesc={modalChangeLog.titleDesc}
        />
      )}

      {isLoading ? (
        <Box sx={{ mt: 5, mb: 5, p: 5, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        <List>
          {companyOnlineSchedulingExpedient && companyOnlineSchedulingExpedient.companyWorkSchedule && (
            <ListItem key={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h5">Expediente da empresa</Typography>
              <WorkScheduleListView workSchedule={companyOnlineSchedulingExpedient.companyWorkSchedule} />

              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => {
                  setModalChangeLog({
                    open: true,
                    workingScheduleId: companyOnlineSchedulingExpedient.companyWorkSchedule.id,
                    titleDesc: 'da Empresa',
                  });
                }}
                disabled={companyOnlineSchedulingExpedient.companyWorkSchedule.id == null}
              >
                Ver log de atividade
              </Button>
            </ListItem>
          )}

          {companyOnlineSchedulingExpedient &&
            companyOnlineSchedulingExpedient.employeesWorkSchedule.length > 0 &&
            companyOnlineSchedulingExpedient.employeesWorkSchedule.map((empWorkSchedule, index) => (
              <ListItem
                key={index + 1}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography variant="h5">Funcionário: {empWorkSchedule.user.name}</Typography>
                <WorkScheduleListView workSchedule={empWorkSchedule} />
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setModalChangeLog({
                      open: true,
                      workingScheduleId: empWorkSchedule.id,
                      titleDesc: `de ${empWorkSchedule.user.name}`,
                    });
                  }}
                >
                  Ver log de atividade
                </Button>
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
}

export default CompanyOnlineSchedulingSettingsExpedient;
