const status = {
  PENDING: 'Pendente',
  RECEIVED: 'Recebida',
  CONFIRMED: 'Pagamento confirmado',
  OVERDUE: 'Vencida',
  REFUNDED: 'Estornada',
  RECEIVED_IN_CASH: 'Recebida em dinheiro',
  REFUND_REQUESTED: 'Estorno Solicitado',
  CHARGEBACK_REQUESTED: 'Recebido chargeback',
  CHARGEBACK_DISPUTE: 'Em disputa de chargeback',
  AWAITING_CHARGEBACK_REVERSAL: 'Disputa vencida, aguardando repasse da adquirente',
  DUNNING_REQUESTED: 'Em processo de negativação',
  DUNNING_RECEIVED: 'Recuperada',
  AWAITING_RISK_ANALYSIS: 'Pagamento em análise',
  EXPIRED: 'Expirada',
  ACTIVE: 'Ativo'
};

const getStatusTypeColor = (status) => {
  switch (status) {
    case 'PENDING':
      return 'info';
    case 'RECEIVED':
      return 'primary';
    case 'CONFIRMED':
      return 'success';
    case 'OVERDUE':
      return 'warning';
    case 'REFUNDED':
      return 'warning';
    case 'RECEIVED_IN_CASH':
      return 'warning';
    case 'REFUND_REQUESTED':
      return 'warning';
    case 'CHARGEBACK_REQUESTED':
      return 'warning';
    case 'CHARGEBACK_DISPUTE':
      return 'warning';
    case 'AWAITING_CHARGEBACK_REVERSAL':
      return 'warning';
    case 'DUNNING_REQUESTED':
      return 'warning';
    case 'DUNNING_RECEIVED':
      return 'warning';
    case 'AWAITING_RISK_ANALYSIS':
      return 'warning';
    case 'EXPIRED':
      return 'error';
    case 'ACTIVE':
      return 'success';
    default:
      return 'warning';
  }
};

export { getStatusTypeColor, status };
