import React, { useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberUtils from '../../../commons/utils/numberUtils';
import ButtonCopyToClipboard from '../../../components/buttons/ButtonCopyToClipboard';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import HintText from '../../../components/labels/HintText';
import { failures, statusCharge, statusInvoice, statusOutcome } from './stripeHelpers';
import dayjs from 'dayjs';

const dotCard = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    ••••
  </Box>
);

// COMPONENT
function StripeUserDetailModal({ userDetails, open, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const DialogActionsStyled = styled(DialogActions)(({ theme }) => {
    return {
      justifyContent: 'space-between',
      margin: '10px',
    };
  });

  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography component="span" variant="h5">
            Detalhes do Cliente Stripe
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ mb: '15px', display: 'flex', justifyContent: 'space-around' }}
        >
          Dados do Cliente
        </Typography>
        <Divider sx={{ mb: 1 }} />

        <Typography gutterBottom>
          Nome:{' '}
          <Typography color="textSecondary" component="span">
            {userDetails?.name ? userDetails?.name : 'Sem Dados'}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Email:{' '}
          <Typography color="textSecondary" component="span">
            {userDetails?.email ? userDetails?.email : 'Sem Dados'}
          </Typography>{' '}
          {userDetails?.email && (
            <ButtonCopyToClipboard copyValue={userDetails?.email} style={{ marginLeft: '5px' }} />
          )}
        </Typography>

        <Typography gutterBottom>
          Customer Id:{' '}
          <Typography color="textSecondary" component="span">
            {userDetails?.id ? userDetails?.id : 'Sem Dados'}
          </Typography>{' '}
          {userDetails?.id && (
            <ButtonCopyToClipboard copyValue={userDetails?.id} style={{ marginLeft: '5px' }} />
          )}
        </Typography>
        <Divider sx={{ mt: 1 }} />

        {!userDetails && (
          <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
            <HintText>Esta fatura não possue informações adicionais.</HintText>
          </Box>
        )}
        <Typography
          component="span"
          variant="h5"
          sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
        >
          Pagamentos
        </Typography>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Valor</TableCell>
              <TableCell></TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cartão</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails?.charges?.map((ch) => {
              return (
                <TableRow key={ch.id}>
                  <TableCell>{NumberUtils.toCurrency(ch.amount, 2)} </TableCell>
                  <TableCell>
                    {ch.status !== null ? (
                      <Chip
                        color={statusCharge[ch.status].color}
                        label={statusCharge[ch.status].label}
                        onMouseEnter={(e) => handlePopoverOpen(e, ch.id)}
                        onMouseLeave={handlePopoverClose}
                      />
                    ) : (
                      <>
                        {ch.statusInvoice === 'paid' ? (
                          <Chip
                            color="success"
                            label="Ok"
                            onMouseEnter={(e) => handlePopoverOpen(e, ch.id)}
                            onMouseLeave={handlePopoverClose}
                          />
                        ) : (
                          <Chip
                            color={statusCharge[ch.statusInvoice].color}
                            label={statusCharge[ch.statusInvoice].label}
                            onMouseEnter={(e) => handlePopoverOpen(e, ch.id)}
                            onMouseLeave={handlePopoverClose}
                          />
                        )}
                      </>
                    )}
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={openedPopoverId === ch.id}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      {(ch.outcomeReason !== null || ch.failureCode !== null) && ch.status !== 'succeeded' ? (
                        <>
                          {ch.outcomeReason !== null ? (
                            <Typography sx={{ p: 1 }}>{statusOutcome[ch.outcomeReason]}</Typography>
                          ) : (
                            <>
                              {ch.failureCode !== null && (
                                <Typography sx={{ p: 1 }}>{failures[ch.failureCode]}</Typography>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {ch.status === 'succeeded' ? (
                            <Typography sx={{ p: 1 }}>Pagamento concluído</Typography>
                          ) : (
                            <Typography sx={{ p: 1 }}>Status não encontrado.</Typography>
                          )}
                        </>
                      )}
                    </Popover>
                  </TableCell>
                  <TableCell>{ch.description}</TableCell>
                  <TableCell>{userDetails.email}</TableCell>
                  <TableCell>
                    {ch?.paymentMethodDetail?.brand && (
                      <>
                        <Typography>
                          <FontAwesomeIconStatus status="primary" icon="credit-card" />{' '}
                          {ch?.paymentMethodDetail?.brand?.toUpperCase()} {dotCard}
                          {ch?.paymentMethodDetail?.last4}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Typography
          component="span"
          variant="h5"
          sx={{ m: '15px', display: 'flex', justifyContent: 'space-around' }}
        >
          Faturas
        </Typography>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subcription Id</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Número da Fatura</TableCell>
              <TableCell>Data Pagamento</TableCell>
              <TableCell>Cliente</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userDetails?.invoices?.map((i) => {
              return (
                <TableRow key={i.id}>
                  <TableCell>
                    {i.subscriptionId}
                    {i.subscriptionId && (
                      <ButtonCopyToClipboard copyValue={i.subscriptionId} style={{ marginLeft: '5px' }} />
                    )}
                  </TableCell>
                  <TableCell>{NumberUtils.toCurrency(i.amount, 2)}</TableCell>
                  <TableCell>
                    <>
                      {i.number} {'  '}
                      <Chip color={statusInvoice[i.status].color} label={statusInvoice[i.status].label} />
                    </>
                  </TableCell>
                  {i.paidAt ? (
                      <TableCell>{dayjs(i.paidAt).format('DD [de] MMM[.] [de] YY HH:mm')}</TableCell>
                    ) : (
                      <TableCell> --- </TableCell>
                    )}
                  <TableCell>{userDetails.email}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogActionsStyled>
        <Button onClick={onClose} color="primary" variant="outlined">
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default StripeUserDetailModal;
