/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { Tooltip, Input, InputLabel, FormHelperText } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FastField, Field, getIn } from 'formik';
import CustomFormControl from '../controls/CustomFormControl';

export const RenderTextField = ({
  field,
  form: { touched, errors, handleChange },
  normalizer,
  label,
  tooltip,
  inputProps,
  labelComponent,
  forceNoError,
  ...others
}) => {
  const { inputComponent, ...restInputProps } = inputProps;
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);

  return (
    <CustomFormControl variant="standard" error={touch && error != null} {...others}>
      {tooltip && (
        <Tooltip title={tooltip}>
          <InputLabel shrink>
            {label} <InfoOutlinedIcon style={{ verticalAlign: 'middle' }} color="action" />
          </InputLabel>
        </Tooltip>
      )}
      {!tooltip && label != null && (
        <>
          {forceNoError ? (
            <InputLabel shrink error={false}>
              {label}
            </InputLabel>
          ) : (
            <InputLabel shrink>{label}</InputLabel>
          )}
        </>
      )}

      <Input
        name={field.name}
        value={field.value}
        onChange={(e) => {
          if (normalizer) {
            handleChange(field.name)(normalizer(e.target.value));
          } else {
            field.onChange(e);
          }
        }}
        onBlur={field.onBlur}
        inputComponent={inputComponent}
        {...restInputProps}
      />
      {touch && error && <FormHelperText>{error}</FormHelperText>}
    </CustomFormControl>
  );
};

export const FormTextField = ({ name, type, placeholder, inputProps, fastField, ...others }) => {
  if (fastField) {
    return (
      <FastField
        name={name}
        component={RenderTextField}
        inputProps={{ type, placeholder, ...inputProps }}
        {...others}
      />
    );
  }
  return (
    <Field
      name={name}
      component={RenderTextField}
      inputProps={{ type, placeholder, ...inputProps }}
      {...others}
    />
  );
};
