import { Button, Card, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { FormTextField } from '../../components/forms/FormikFields';

function ProfileGeneralPage({ initialValues, schema, handleSubmit }) {
  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item sm={4} display={'flex'} alignItems={'center'} >
                  <Typography variant="overline">Dados Básicos</Typography>
                </Grid>
                <Grid item sm={8}>
                  <FormTextField name="name" type="text" label="Nome" fullWidth />
                </Grid>
                <Grid item sm={4} display={'flex'} alignItems={'center'} >
                </Grid>
                <Grid item sm={8}>
                  <FormTextField name="email" type="text" label="Email" disabled fullWidth />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions style={{ justifyContent: 'end' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                Salvar
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default ProfileGeneralPage;
