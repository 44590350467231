import HttpService from './commons/HttpService';

const EmployeeService = {
  create(employee) {
    return HttpService.post('/employees', employee);
  },

  update(employee) {
    return HttpService.put(`/employees/${employee.id}`, employee);
  },

  savePassword(employee, newPassword) {
    employee.password = newPassword;
    return HttpService.put(`/employees/${employee.id}`, employee);
  },

  save(employee) {
    if (employee.id && employee.id > 0) {
      return this.update(employee);
    }
    return this.create(employee);
  },

  delete(employee) {
    return HttpService.delete(`/employees/${employee.id}`);
  },

  getById(id) {
    return HttpService.get(`/employees/${id}`);
  },

  getAll() {
    return HttpService.get('/employees');
  },

  findAllRoles() {
    return HttpService.get('/employees/roles');
  },
  findAllBirthdays() {
    return HttpService.get('/employees/birthdays');
  },
};

export default EmployeeService;
