import React from 'react';
import { IMaskInput } from 'react-imask';
import { Field } from 'formik';
import { RenderTextField } from './FormTextField';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, definitions, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

export const FormTextFieldWithMask = ({ name, type, placeholder, mask, definitions, ...others }) => {
  return (
    <Field
      name={name}
      component={RenderTextField}
      inputProps={{ type, placeholder, inputComponent: TextMaskCustom, inputProps: { mask, definitions } }}
      {...others}
    />
  );
};
