import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, LinearProgress, Paper, styled, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EmployeeService from '../../services/EmployeeService';

import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import HintText from '../../components/labels/HintText';
import showNotification from '../../commons/helpers/showNotification';
import EmployeeListFilter from './EmployeeListFilter';
import EmployeeTable from './EmployeeTable';
import EmployeeChangePasswordModal from './EmployeeChangePasswordModal';
// import EmployeeAddCompanyModal from './EmployeeAddCompanyModal';

const PaperStyled = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});

// COMPONENT
function EmployeeListPage() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState('');
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false });
  const [modalEmployeeChangePassword, setModalEmployeeChangePassword] = useState({ open: false });

  useEffect(() => {
    async function initData() {
      setIsLoading(true);
      try {
        const resp = await EmployeeService.getAll();
        setEmployees(resp);
      } finally {
        setIsLoading(false);
      }
    }

    initData();
  }, []);


  const handleEdit = (employee) => {
    navigate(`${employee.id}`);
  };

  const handleDelete = (targetObject) => {
    setModalConfirmDelete({
      open: true,
      targetObject,
    });
  };

  const handleChangePasswordModal = (targetObject) => {
    setModalEmployeeChangePassword({
      open: true,
      targetObject,
    });
  };

  const handleDeleteConfirmation = async () => {
    setIsLoading(true);
    try {
      await EmployeeService.delete(modalConfirmDelete.targetObject);
      setEmployees(employees.filter((data) => data.id !== modalConfirmDelete.targetObject.id));
      showNotification('success', 'Deletado com sucesso');
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
    } finally {
      setIsLoading(false);
    }
  };



  const filterEmployee = employees.filter(employee => employee.name.toLowerCase().includes(searchEmployee.toLowerCase()) || employee.email.toLowerCase().includes(searchEmployee.toLowerCase()));

  return (
    <>
      {/* MODAL */}
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

       <EmployeeChangePasswordModal
        employeeForm={modalEmployeeChangePassword.targetObject}
        open={modalEmployeeChangePassword.open}
        onClose={() => {
            setModalEmployeeChangePassword((prev) => ({ ...prev, open: false }));
        }}
      />     

       {/* CONTENT */}
       <Grid container spacing={3}>
         <Grid item sm={12}>
           <Box display="flex" flexDirection="row" justifyContent="space-between">
             <Typography variant="overline">Funcionários</Typography>
             <Button variant="contained" component={Link} to="novo">
               Adicionar Funcionário
             </Button>
           </Box>

           <Box sx={{ mt: 1 }}>
             {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
           </Box>

           <PaperStyled square>
             {/* TOOLBAR */}
               <Box display="flex" flexDirection="row" >
                 <EmployeeListFilter
                   filterEmployee={searchEmployee}
                   setFilterEmployee={setSearchEmployee}
                 />
               </Box>
             {filterEmployee?.length === 0 ? (
              <Box sx={{ mt: 5, mb: 5 }}>
                <HintText>Nenhum resultado encontrado.</HintText>
              </Box>
             ) : (
              <EmployeeTable
                employees={filterEmployee}
                isLoading={isLoading}
                handleChangePassword={handleChangePasswordModal}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
               />
             )}
           </PaperStyled>
         </Grid>
       </Grid>
    </>
  );
}

export default EmployeeListPage;
