/* eslint-disable no-else-return */
// import store from 'store'
import axios from 'axios';
import { getCurrentUser, resetStore, updateTokens } from '../../stores/appStore';
import showNotification from '../../commons/helpers/showNotification';

const SERVER_MESSAGE_MAP = {
  'Bad credentials': 'Usuário ou senha inválidos.',
};

let baseUrl = 'http://localhost:8086';
if (process.env.REACT_APP_STAGE === 'staged') {
  baseUrl = 'https://apistagedbackoffice.maapp.com.br';
} else if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://apibackoffice.maapp.com.br';
}

const AXIOS_PARAMS = {
  baseURL: baseUrl,
  withCredentials: true,
  timeout: 20000,
};

// MAIN HTTP CODES
const UNAUTHORIZED_ACCESS = 401;
const FORBIDDEN = 403;

// test base url

const httpService = axios.create(AXIOS_PARAMS);

let refreshPromise = null;
function handleRefreshToken(refreshToken) {
  if (!refreshPromise) {
    // console.log('WILL REFRESH');
    refreshPromise = httpService
      .post('/auth/refreshToken', {
        refreshToken,
      })
      .then((resp) => {
        // 1) put token to LocalStorage
        updateTokens(resp);

        // 2) Change Authorization header
        axios.defaults.headers.common.Authorization = `Bearer ${resp.token}`;

        return resp;
      })
      .finally(() => {
        refreshPromise = null;
      });
  } else {
    // console.log('ALREADY REFRESHED OR GOING!!!!!!!!!!!!!!!!!!!!');
  }
  return refreshPromise;
}

httpService.interceptors.request.use(
  (config) => {
    // store.dispatch({ type: 'REQUEST_LOADING', data: { isLoading: true } })
    // globalRequestState.store.setState({ isLoading: true });

    // token
    const theCurrentUser = getCurrentUser();
    if (theCurrentUser && theCurrentUser.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${theCurrentUser.token}`;
    }

    // Do something before request is sent)
    return config;
  },
  (error) => {
    // Do something with request error
    // eslint-disable-next-line no-console
    console.log('request error');
    // eslint-disable-next-line no-console
    console.error(error);

    // store.dispatch({ type: 'REQUEST_LOADING', data: { isLoading: false } })
    // globalRequestState.store.setState({ isLoading: false });

    return Promise.reject(error);
  },
);

httpService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status) {
      const { response } = error;
      if (response.status === UNAUTHORIZED_ACCESS) {
        // 401 and any endpoint other than /auth/login, retrying refreshing
        const originalRequest = error.config;
        const theCurrentUser = getCurrentUser();
        if (
          !response.config.url.includes('/auth/login') &&
          !response.config.url.includes('/auth/refreshToken') &&
          !originalRequest.isRetry &&
          theCurrentUser &&
          theCurrentUser.refreshToken
        ) {
          originalRequest.isRetry = true;
          return handleRefreshToken(theCurrentUser.refreshToken, originalRequest).then(() => {
            return httpService(originalRequest);
          });
        }
        const message = response.data.message || 'Acesso não autorizado.';
        const messageReplacement = SERVER_MESSAGE_MAP[response.data.message] ?? message;
        showNotification('warning', messageReplacement);
        resetStore();

        // store.dispatch({ type: 'SHOW_SNACKBAR', data: { type: 'warning', message } })
        // store.dispatch({ type: 'LOGOUT' })
      } else if (response.status === FORBIDDEN) {
        const message = response.data.message || 'Acesso não autorizado.';
        showNotification('error', message);
        resetStore();
        // store.dispatch({ type: 'SHOW_SNACKBAR', data: { type: 'warning', message } })
        // store.dispatch({ type: 'LOGOUT' })
      } else if (response.status >= 400 && response.status < 499) {
        const body = response.data || {};
        // 'Incorrect usage!'
        let message = body.message || 'Uso incorreto!';
        // error messages
        if (body.errors && body.errors.length > 0) {
          body.errors.forEach((el) => {
            const field = el.field ? `${el.field}: ` : '';
            message += el.defaultMessage || '';
            message += field + message;
            // store.dispatch({ type: 'SHOW_SNACKBAR', data: { type: 'warning', message: errorMessage } })
          });
        }
        // globalMessageQueue.store.setState({ type: 'warning', message: message });
        showNotification('warning', message);
      } else if (response.status >= 500 && response.status < 599) {
        // store.dispatch({ type: 'SHOW_SNACKBAR', data: { type: 'error', message: 'Server Error. Please contact the support team.' } })
        // Server side error. Please contact our support team.
        // globalMessageQueue.store.setState({ type: 'error', message: 'Server Error. Please contact the support team.' });
        showNotification('error', 'Erro. Por favor contate a equipe de suporte.');
      }
      // eslint-disable-next-line no-console
    } else if (error) {
      // store.dispatch({ type: 'SHOW_SNACKBAR', data: { type: 'error', message: 'Server unavailable.' } })
      // globalMessageQueue.store.setState({ type: 'error', message: 'Server unavailable.' });

      if (error && error.toJSON().message === 'Network Error') {
        showNotification('error', 'Problema com sua conexão com a internet.');
        // dispatch({type: RELOAD});
      } else {
        showNotification('error', 'Problema de conexão. Contate o suporte em caso de dúvidas.');
      }
      // 'Could not reach the server.'
    }

    return Promise.reject(error);
  },
);

export default httpService;
