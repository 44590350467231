import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import showNotification from '../../../../commons/helpers/showNotification';
import yup from '../../../../commons/validators/customYup';
import { FormPhoneField, FormSelect, FormTextField } from '../../../../components/forms/FormikFields';
import { FontAwesomeIconStatus } from '../../../../components/icon/FontAwesomeIconStatus';
import TableCellAction from '../../../../components/table/TableCellAction';
import SuperUserService from '../../../../services/SuperUserService';
import PhoneUtils from '../../../../commons/utils/phoneUtils';
import ConfirmationDialog from '../../../../components/modals/ConfirmationDialog';
import maskUtils from '../../../../commons/utils/maskUtils';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

const types = [
  { id: 'EMAIL', name: 'Email' },
  { id: 'PHONE', name: 'Telefone' },
].map((obj) => ({ ...obj, name: `${obj.name}` }));

const DEFAULT_INITIAL_VALUES = {
  id: null,
  name: '',
  type: '',
  value: '',
};

const validationSchemaEmail = yup.object().shape({
  name: yup.string().min(1).required(),
  type: yup.string().required(),
  value: yup.string().email().required(),
});
const validationSchemaPhone = yup.object().shape({
  name: yup.string().min(1).required(),
  type: yup.string().required(),
  value: yup.string().min(8).max(16),
});

// COMPONENT
function CompanyOnlineSchedulingSettingsBlockedCustomers({ selectedCompany, open }) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false });
  const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_VALUES);
  const [selectedType, setSelectedType] = useState();
  const [companyOnlineSchedulingBlockedCustomers, setcompanyOnlineSchedulingBlockedCustomers] = useState();

  useEffect(() => {
    if (open && !companyOnlineSchedulingBlockedCustomers) {
      setIsLoading(true);
      SuperUserService.getCompanyOnlineSchedulingBlockedCustomers(selectedCompany.company.id)
        .then((resp) => {
          setcompanyOnlineSchedulingBlockedCustomers(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [companyOnlineSchedulingBlockedCustomers, open, selectedCompany]);

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);

    let value = values.value.indexOf('mailto:') !== -1 ? values.value.substring(7) : values.value;

    if (values.type === 'PHONE') {
      value = PhoneUtils.sanitizePhoneNumber(values.value);
    }

    if (!values.id) {
      const resp = await SuperUserService.createCompanyOnlineSchedulingBlockedCustomers({
        companyId: selectedCompany.company.id,
        value: value,
        type: values.type,
        name: values.name,
      });
      setcompanyOnlineSchedulingBlockedCustomers([...companyOnlineSchedulingBlockedCustomers, resp]);
      showNotification('success', 'Adicionado com sucesso.');
    } else {
      await SuperUserService.updateCompanyOnlineSchedulingBlockedCustomers({
        id: values.id,
        companyId: selectedCompany.company.id,
        value: value,
        type: values.type,
        name: values.name,
      });
      setcompanyOnlineSchedulingBlockedCustomers(
        companyOnlineSchedulingBlockedCustomers.map((blockedCustomer) =>
          blockedCustomer.id === values.id ? values : blockedCustomer,
        ),
      );
      showNotification('success', 'Alterado com sucesso.');
    }
    actions.setSubmitting(false);
    actions.resetForm({ values: DEFAULT_INITIAL_VALUES });
    setIsLoading(false);
  };

  const handleDeleteConfirmation = async () => {
    setIsLoading(true);
    await SuperUserService.deleteCompanyOnlineSchedulingBlockedCustomers(modalConfirmDelete.customer.id);
    showNotification('success', 'Removido com sucesso.');
    setIsLoading(false);
    setModalConfirmDelete((prev) => ({ ...prev, open: false }));
    setcompanyOnlineSchedulingBlockedCustomers(
      companyOnlineSchedulingBlockedCustomers.filter((data) => data.id !== modalConfirmDelete.customer.id),
    );
  };

  const handleDelete = async (customer) => {
    setModalConfirmDelete({
      open: true,
      customer,
    });
  };


  const handleEdit = (customer) => {
    setInitialValues({ id: customer.id, name: customer.name, type: customer.type, value: customer.value });
    setSelectedType(customer.type);
  };

  return (
    <>
      {/* MODAL */}
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={selectedType === 'EMAIL' ? validationSchemaEmail : validationSchemaPhone}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid, resetForm }) => (
          <Form>
            <DialogContent dividers>
              <Grid container columnGap={2}>
                <Grid item sm={4}>
                  <FormTextField sx={{ minWidth: '100%' }} label="Nome" name="name" />
                </Grid>
                <Grid item sm={3}>
                  <FormSelect
                    sx={{ minWidth: '100%' }}
                    label="Tipo"
                    name="type"
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                    dataSource={types}
                  />
                </Grid>
                <Grid item sm={4}>
                  {selectedType === 'EMAIL' ? (
                    <FormTextField sx={{ minWidth: '100%' }} color="primary" name="value" label="Valor" />
                  ) : (
                    <FormPhoneField sx={{ minWidth: '100%' }} color="primary" name="value" label="Valor" />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActionsStyled>
              <Button
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm({ values: DEFAULT_INITIAL_VALUES });
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                Salvar
                {isLoading && <CircularProgress size={18} />}
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Adicionado em</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyOnlineSchedulingBlockedCustomers?.map((customer, i) => {
            return (
              <TableRow key={customer.id}>
                <TableCell>{customer.name}</TableCell>
                <TableCell>{types.find((type) => type.id === customer.type).name}</TableCell>
                <TableCell>{customer.type === 'PHONE' ? maskUtils.phone(customer.value) : customer.value}</TableCell>
                <TableCell>{dayjs(customer.createdAt).format('DD/MM/YY')}</TableCell>
                <TableCellAction
                  title="Editar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit(customer);
                  }}
                >
                  <FontAwesomeIconStatus status="primary" icon="pen" size="sm" />
                </TableCellAction>
                <TableCellAction
                  title="Deletar"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleDelete(customer);
                  }}
                  disabled={isLoading}
                >
                  <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                </TableCellAction>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default CompanyOnlineSchedulingSettingsBlockedCustomers;
